import api from "api/v1-jwt/config/api";
import axios from "axios";
import { toast } from "react-toastify";

const TestPocketBookApi = async () => {
  const payload = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  const testRes = await api.get(`/api-key-test`, payload);
  return testRes.data;
};

const getPaymentMethods = async ({ status, user_id }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const id = user_id || clientCognitoId;
  try {
    const { data } = await api.get(
      `/platform/payment_methods/${id}?move_money=${status}`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const checkPayPalIntegration = async (cognito_id) => {
  try {
    const { data } = await api.post(`/paypal/has_paypal`, {
      cognito_id: cognito_id,
    });

    return data;
  } catch (err) {
    console.log("err", err);
  }
};

const connectPayPalIntegration = async ({ cognito_id }) => {
  try {
    const { data } = await api.post(`/paypal/initiate_integration`, {
      cognito_id,
      redirect_uri: window.location.href.split("?")[0],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

const validatePaypalIntegration = async (payload) => {
  try {
    const { data } = await api.post(`/paypal/validate_integration`, payload);

    return data;
  } catch (error) {
    console.log(error);
  }
};

const disconnectPaypalIntegration = async (payment_method_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(`/paypal/disconnect_paypal`, {
      cognito_id: clientCognitoId,
      payment_method_id: payment_method_id,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

const disconnectPaymentMethod = async (
  payment_method_type,
  payment_method_id
) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const urlValue = payment_method_type + "%23" + payment_method_id;

  try {
    const { data } = await api.delete(
      `/platform/payment_methods/${clientCognitoId}/${urlValue}`
    );

    return data;
  } catch (error) {
    console.error("Error in disconnectPaymentMethod:", error);
    toast.error(error.response.detail || "Error disconnecting payment method.");
    throw new Error(
      error.response.detail || "Error disconnecting payment method."
    );
  }
};

const getAccountBalance = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/accounts/${clientCognitoId}/balance`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export {
  getAccountBalance,
  getPaymentMethods,
  disconnectPaymentMethod,
  disconnectPaypalIntegration,
  validatePaypalIntegration,
  connectPayPalIntegration,
  checkPayPalIntegration,
  TestPocketBookApi,
};
