import { useHistory, useLocation } from "react-router-dom";
import { useContact } from "./use-contact";

export function useContactDrawer() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const contactId = queryParams.get("contact");
  const { data: contact, ...rest } = useContact(contactId, {
    enabled: !!contactId,
  });

  const handleOpen = (id) => {
    if (!isRestrictedRoute(location.pathname)) {
      if (queryParams.has("contact")) {
        queryParams.delete("contact");
      }

      queryParams.set("contact", id);
      history.push({
        search: queryParams.toString(),
      });
    }
  };

  const handleClose = () => {
    if (!isRestrictedRoute(location.pathname)) {
      if (queryParams.has("contact")) {
        queryParams.delete("contact");
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  };

  const isRestrictedRoute = (pathname) => {
    return pathname === "/signup" || pathname === "/login";
  };

  return {
    open: queryParams.has("contact") && !isRestrictedRoute(location.pathname),
    drawerData: contact,
    handleClose,
    handleOpen,
    ...rest,
  };
}
