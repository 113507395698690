import styled from "styled-components";

const SmartQuoteDescripton = ({ className, stage, contractText }) => {
  if (stage == 0) {
    return <div className={className}>Please select a contact</div>;
  }
  if (stage == 1) {
    return (
      <div className={className}>
        Try typing something like, “Write a quote for me. I’m selling 30 tons of
        steel for $10,000 to Mike Smith.”
      </div>
    );
  }
  if (stage == 2) {
    return <div className={className}>{`Your prompt: ${contractText}`}</div>;
  }
  return;
};

const SmartContractDescriptionStyled = styled(SmartQuoteDescripton)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

export default SmartContractDescriptionStyled;
