import { useHistory, useLocation } from "react-router-dom";
import { useBill } from "./use-bill";

export function useBillDrawer() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const billId = queryParams.get("bill_id");
  const { data: bill, ...rest } = useBill({
    billId,
    enabled: !!billId,
  });

  const handleOpen = (id) => {
    if (queryParams.has("bill_id")) {
      queryParams.delete("bill_id");
    }

    queryParams.set("bill_id", id);
    history.push({
      search: queryParams.toString(),
    });
  };

  const handleClose = () => {
    if (queryParams.has("bill_id")) {
      queryParams.delete("bill_id");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  return {
    open: queryParams.has("bill_id"),
    drawerData: bill,
    handleClose,
    handleOpen,
    ...rest,
  };
}
