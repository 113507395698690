function NotesCard({ notes, element }) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
      <div className="px-4 py-5 sm:p-6 space-y-2">
        <h2 className="text-sm font-semibold leading-6 text-gray-900">Notes</h2>
        {notes ? (
          <p className="text-sm text-slate-700">{notes}</p>
        ) : (
          <p className="text-sm text-slate-700">
            There are no notes for this {element}
          </p>
        )}
      </div>
    </div>
  );
}

export default NotesCard;
