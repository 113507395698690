import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { banks_logos } from "assets/images/banks";
import { connectPayPalIntegration } from "../../actions/payment-methods/actions";
import { PaymentCardIcon, PaymentMethodButton } from ".";
import { CardTitle, CardDescription } from "../../pages/payment-methods/styled";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import { useState, Fragment } from "react";
import { Plaid } from "components/payment-methods";
import { Info } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "components/ui/hover-card";
import { Button } from "components/ui/button-tw";
import { toast } from "react-toastify";
import { useSetDefaultPayment } from "hooks/payment/use-make-primary";

const PaymentCard = ({
  loading,
  settingData,
  className,
  method_type,
  payment_method_status,
  payment_method_name,
  primary,
  bank_name,
  account_type,
  handleDisconnect,
  payment_method_id,
  last_4,
  access_token = "",
  setPaymentList,
  account,
  name_on_account,
}) => {
  const { mutate: makePrimary } = useSetDefaultPayment();
  const [plaidOpen, setPlaidOpen] = useState(false);

  const togglePlaid = () => {
    setPlaidOpen((prev) => !prev);
  };

  const handleConnectPaypal = async () => {
    const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
    if (!clientCognitoId) {
      throw new Error("It was not possible to identify the platform.");
    }

    await connectPayPalIntegration({
      cognito_id: clientCognitoId,
    }).then((res) => {
      if (res && res.redirect_uri) {
        window.open(res.redirect_uri);
      }
    });
  };

  const handleMakePrimary = () => {
    makePrimary(payment_method_id, {
      onSuccess: () => {
        setPaymentList((prevList) => {
          const newList = prevList.map((item) => {
            if (item.payment_method_default) {
              return { ...item, payment_method_default: false };
            }

            if (item.payment_method_id === payment_method_id) {
              return { ...item, payment_method_default: true };
            }

            return item;
          });

          return newList;
        });

        toast.success("Your primary account has been updated");
      },
      onError: (error) => {
        console.log("ERROR::", error);
        toast.error(
          "There was an error setting default payment method. Please try again."
        );
      },
    });
  };
  // const { linkToken, loadingToken, error, openPlaidLink, readyPlaidLink } =
  //   usePlaidLinkWithStatus(payment_method_status, method_type, access_token);
  if (loading) {
    return (
      <div className={className}>
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (method_type === "PAYPAL") {
    return (
      <div className={className}>
        <div>
          {/* <StyledCircle /> */}
          <PaymentCardIcon bank_name={bank_name} method_type={method_type} />
          <CardTitle>{payment_method_name}</CardTitle>
          <CardDescription>
            {payment_method_status === "active" ? "" : ""}
          </CardDescription>
          <PaymentMethodButton
            primary_color={settingData?.primary_color}
            status={payment_method_status}
            handleDisconnect={() => handleDisconnect()}
            handleConnect={() => handleConnectPaypal}
          />
        </div>
      </div>
    );
  }
  if (method_type === "PLAID") {
    return (
      <div className={className}>
        <div>
          <div className="flex">
            <PaymentCardIcon bank_name={bank_name} method_type={method_type} />
          </div>
          <div className="flex items-center">
            <CardTitle>
              {`${
                !banks_logos[bank_name] ? bank_name || "Unrecognized" : ""
              } ${capitalizeFirstLetter(account_type || "")}`}
            </CardTitle>
            {primary && (
              <div className="ml-2 mt-1.5 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                <svg
                  className="h-1.5 w-1.5 fill-green-500"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
                Primary
                <HoverCard>
                  <HoverCardTrigger>
                    <Info className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                  </HoverCardTrigger>
                  <HoverCardContent>
                    Your default account for transfers and subscription billing.
                  </HoverCardContent>
                </HoverCard>
              </div>
            )}
          </div>
          <CardDescription>{`****${last_4}`}</CardDescription>
          <div className="flex flex-col gap-y-2">
            <PaymentMethodButton
              primary_color={settingData?.primary_color}
              status={payment_method_status}
              handleDisconnect={() =>
                handleDisconnect(
                  method_type,
                  payment_method_id,
                  `${bank_name} ending in ${last_4}`
                )
              }
              handleConnect={togglePlaid}
            />
            {!primary && payment_method_status === "active" && (
              <Button variant="outline" onClick={handleMakePrimary}>
                Make primary
              </Button>
            )}
          </div>
          {plaidOpen && (
            <Plaid
              open={plaidOpen}
              onClose={togglePlaid}
              method_type={method_type}
              payment_method_status={payment_method_status}
              access_token={access_token}
              setPaymentList={setPaymentList}
              key={payment_method_id}
              name_on_account={name_on_account}
            />
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={className}>
      <div>
        <PaymentCardIcon />
        <CardTitle>Add new bank account</CardTitle>
        <>
          <PaymentMethodButton
            handleConnect={togglePlaid}
            primary_color={settingData?.primary_color}
          />
          {plaidOpen ? (
            <Plaid
              open={plaidOpen}
              onClose={togglePlaid}
              method_type={"PLAID"}
              access_token=""
              setPaymentList={setPaymentList}
              key={payment_method_id}
            />
          ) : null}
        </>
      </div>
    </div>
  );
};

const PaymentCardStyled = styled(PaymentCard)`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  height: 285px;
  padding: 35px;
  display: flex;
  align-items: center;
  border: 3px solid transparent; /* default border style */

  ${(props) =>
    props.payment_method_status === "active" && !props.loading
      ? "border-color: #1DB45A"
      : null}

  ${(props) =>
    props.payment_method_status === "pending_manual_verification" &&
    !props.loading
      ? "border-color: #FFA500"
      : null}
`;

export default PaymentCardStyled;
