import { validStatus } from "utils/helpers";
import api from "../config/api";

const baseUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/auth`;

export const validateEmail = async (email) => {
  try {
    const response = await api.get(`/auth/validation/${encodeURI(email)}`, {
      validateStatus: validStatus,
    });

    return response.data;
  } catch (error) {
    console.log("Error validating email: ", error);
    let error_message;
    if (error.response && error.response.status === 401) {
      error_message =
        "Your session has expired. Please log out and log in again.";
    } else if (error.response && error.response.status === 404) {
      error_message = "Email not found";
    } else if (error.response.status === 403) {
      error_message = "Unauthorized access";
    } else {
      error_message = `Error validating email: ${error}`;
      if (error.detail) {
        error_message = `Error validating email: ${error.detail}`;
      }
    }
    throw new Error(error_message);
  }
};

/**
 * @typedef ResetPasswordPayload
 * @type {object}
 * @property {string} userId - The id of the user resetting the password.
 * @property {string} password - The new password to be set.
 * @property {string} code - The confirmation code to validate.
 */

/**
 * @typedef ResetPasswordReturn
 * @type {object}
 * @property {string} platform_client_id
 * @property {string} cognito_id
 * @property {string} user_id
 * @property {boolean} email_verified
 * @property {boolean} phone_verified
 * @property {string} record_status
 * @property {string} user_type
 * @property {string} subdomain
 * @property {boolean} accepted_pb_tos_pp
 * @property {string} account_type
 * @property {string} baas_status
 * @property {string} baas_id
 * @property {string} last_login_dt
 * @property {string} webhook_url
 */

/**
 *
 * @param {ResetPasswordPayload}
 * @return {Promise.<ResetPasswordReturn>}
 * @throws {AxiosError}
 */
export const loggedOutResetPassword = async ({ userId, password, code }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    encodeURI(`${baseUrl}/confirm_reset_password?user_id=${userId}`),
    {
      new_password: password,
      confirmation_code: code,
    }
  );

  return data;
};
