import { useQuery } from "@tanstack/react-query";
import { fetchInvoices } from "api/v1-jwt/invoice/invoice";

export const useInvoices = ({
  queryFn,
  queryKey,
  fromContactId,
  filters = {},
  ...rest
} = {}) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const type = JSON.parse(localStorage.getItem("UserRole")) || "client";
  const id = fromContactId || clientCognitoId;

  return useQuery({
    queryKey: ["invoices", type, id, filters],
    queryFn: async () => {
      return await fetchInvoices({
        id,
        type,
        filters,
      });
    },
    staleTime: 5 * 60 * 1000,
    ...rest,
  });
};
