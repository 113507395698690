import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { Button, textStyles } from '../ui';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  textAlign: "center",
  padding: "30px",
  borderRadius: "15px"
};

// BY DEFAULT THIS COMPONENT WILL ALWAYS HAVE ONE BUTTON
export const BasicModal = ({
  title,
  text,
  buttonText,
  secondaryButtonText,
  open,
  handleClose,
  onButtonClick,
  onSecondaryButtonClick
}) => {
  const hasSecondaryButton = secondaryButtonText && onSecondaryButtonClick;

  return (
      <div>
          <Modal
              open={open}
              onClose={handleClose}
          >
              <Box sx={style}>
                  <Title>{title}</Title>
                  <Text>{text}</Text>
                  <ButtonWrapper hasSecondaryButton={hasSecondaryButton}>
                      <Button variant='black' onClick={onButtonClick}>
                          {buttonText}
                      </Button>
                      {hasSecondaryButton && (
                          <Button variant='white' onClick={onSecondaryButtonClick}>
                              {secondaryButtonText}
                          </Button>
                      )}
                  </ButtonWrapper>
              </Box>
          </Modal>
      </div>
  );
}


const Title=styled.div`
        font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 18px;
`

const Text=styled.div`
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.336%;
`

const ButtonWrapper=styled.div`
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: ${props =>
        props.hasSecondaryButton ? 'space-between' : 'center'};
`
