import bankofamerica from "./bankofamerica.png";
import chase from "./chase.png";
import citibank from "./citibank.png";
import wellsfargo from "./wellsfargo.png";
import usaa from "./usaa.png";
import bank_icon from "../bank.png";


export const banks_logos = {
    "Bank of America": bankofamerica,
    "Chase": chase,
    "Citi Bank": citibank,
    "Wells Fargo": wellsfargo,
    "USAA": usaa,
    "default": bank_icon
}