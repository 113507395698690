import { useQuery } from "@tanstack/react-query";
import { getContractsByContactId } from "api/v1-jwt/contract/contract";

export const useContactContracts = ({
  queryFn,
  queryKey,
  contactId,
  ...rest
} = {}) => {
  return useQuery({
    queryKey: ["contracts", "contact", contactId],
    queryFn: async () => await getContractsByContactId(contactId),
    staleTime: 60 * 1000,
    enabled: !!contactId,
    ...rest,
  });
};
