import { StyledCard, CardLabel, CardHeader, CardBtn } from "../styled";
import { StyledPriceInput } from "pages/utils/styled-input";
import { CustomSelector } from "pages/utils/styled-select";
import styled from "styled-components";
import arrow_up from "assets/images/arrow-up.png";
import arrow_down from "assets/images/arrow-down.png";
import arrow_right from "assets/images/arrow-right.png";
import { ContactsCombobox } from "components/contact/contacts-combobox";
import { useEffect } from "react";

const StyledCardHeader = styled(CardHeader)`
  background-color: ${(props) => props.primary_color};
`;

const StyledCardBtn = styled(CardBtn)`
  background-color: ${(props) => props.primary_color};
`;

const Arrow = ({ direction }) => {
  const arrows = {
    up: arrow_up,
    down: arrow_down,
    right: arrow_right,
  };

  return arrows[direction] ? (
    <img
      src={arrows[direction]}
      alt={`arrow-${direction}`}
      width="49px"
      height="49px"
    />
  ) : null;
};

const formatAccountInfo = (account) => {
  const accountType =
    account?.payment_method_data?.ach?.account_type || "Unknown Account Type";
  const bankName =
    account?.payment_method_data?.institution?.name || "Unknown Bank";
  const accountNumberLast4 =
    account?.payment_method_data?.ach?.account_number || "****";

  return `${bankName} - ${accountType.charAt(0).toUpperCase()}${accountType
    .slice(1)
    .toLowerCase()} *${accountNumberLast4}`;
};

const PaymentCard = ({
  customSelectValue,
  customSelectorSetValue,
  customSelectorSelectList,
  customSelectorPlaceholder,
  priceInputValue,
  priceInputSetValue,
  priceInputPlaceholder,
  cardTitle,
  primary_color,
  accountInfo,
  handleClick,
  arrowDirection,
  has_amount,
  contacts,
  setSelectedContact,
  selectedContact,
  buttonText,
  selectedAccount,
  setSelectedAccount,
  balance,
  moneyOut,
}) => {
  useEffect(() => {
    if (
      setSelectedAccount &&
      customSelectorSelectList &&
      customSelectorSelectList.length > 0
    ) {
      const formattedAccount = formatAccountInfo(customSelectorSelectList[0]);
      setSelectedAccount(formattedAccount);
    }
  }, [customSelectorSelectList, setSelectedAccount]);

  const renderContactSelector = () => (
    <ContactsCombobox className="mb-4" onSelect={setSelectedContact} />
  );

  const renderAccountSelector = () => {
    const accountOptions = (selectedContact?.contact_info?.accounts || []).map(
      (acc) => ({
        label: `${(acc.attribute_values || acc).bank_name} ${
          (acc.attribute_values || acc).account_type.charAt(0).toUpperCase() +
          (acc.attribute_values || acc).account_type.slice(1)
        } **${(acc.attribute_values || acc).account_num_last_4}`,
        value: acc,
      })
    );

    return (
      <CustomSelector
        bank
        value={selectedAccount}
        setValue={setSelectedAccount}
        selectList={accountOptions}
        width="100%"
        menuWidth={310}
        mBottom={14}
        placeholder={customSelectorPlaceholder}
        menuLeft
      />
    );
  };

  const renderCustomSelector = () => {
    const activeAccounts = customSelectorSelectList
      .filter((acc) => acc.payment_method_status === "active")
      .map((acc) => {
        const accountType =
          acc?.payment_method_data?.ach?.account_type || "Unknown Account Type";
        const bankName =
          acc?.payment_method_data?.institution?.name || "Unknown Bank";
        const accountString = `${bankName} - ${accountType
          .charAt(0)
          .toUpperCase()}${accountType.slice(1).toLowerCase()} *${
          acc?.payment_method_data?.ach?.account_number
        }`;

        return {
          label: accountString,
          value: {
            account_name: accountString,
            payment_method_id: acc?.payment_method_id,
          },
        };
      });

    return (
      <CustomSelector
        bank
        value={customSelectValue}
        setValue={customSelectorSetValue}
        selectList={activeAccounts}
        width="100%"
        menuWidth={310}
        mBottom={14}
        placeholder={customSelectorPlaceholder}
        menuLeft
      />
    );
  };

  const renderPriceInput = () => (
    <StyledPriceInput
      value={priceInputValue}
      setValue={priceInputSetValue}
      placeholder={priceInputPlaceholder}
      width="100%"
      balance={balance}
      mBottom={14}
      moneyOut={moneyOut}
    />
  );

  return (
    <StyledCard>
      <StyledCardHeader primary_color={primary_color} />
      <div className="p-22-26">
        <Arrow direction={arrowDirection} />
        <CardLabel>{cardTitle}</CardLabel>
        {contacts ? (
          <>
            {renderContactSelector()}
            {selectedContact &&
              selectedContact.contact_info?.accounts?.length > 0 &&
              renderAccountSelector()}
          </>
        ) : (
          renderCustomSelector()
        )}
        {has_amount && renderPriceInput()}
        <div className={!has_amount && !selectedContact ? "mt-[69px]" : null}>
          <StyledCardBtn onClick={handleClick} primary_color={primary_color}>
            {buttonText}
          </StyledCardBtn>
        </div>
      </div>
    </StyledCard>
  );
};

export { PaymentCard };
