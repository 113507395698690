import { LinkBankStepper } from "pages/dashboard/dashboard";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useAuth } from "providers/auth";
import dayjs from "dayjs";
import { useSubscription } from "hooks/subscription/useGetSubscription";

export function PlanLockModal() {
  const { userRole, accessToken } = useAuth();
  const { data: tenancy } = useTenancy();
  const { data: subscription } = useSubscription({
    enabled: !!accessToken && userRole !== "customer",
  });
  const [open, setOpen] = useState(false);
  const [isPlaidOpen, setIsPlaidOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const trialDaysLeft = useMemo(
    () => 14 - dayjs().diff(subscription?.trial_start_date || dayjs(), "day"),
    [subscription]
  );

  const isLockedBecauseTrialEnded =
    userRole !== "customer" &&
    trialDaysLeft <= 0 &&
    (tenancy?.record_status === "plaid_pending" ||
      tenancy?.record_status === "plaid_micro_deposit");

  useEffect(() => {
    const path = location.pathname;
    if (isPlaidOpen) {
      setOpen(false);
    } else if (isLockedBecauseTrialEnded) {
      setOpen(true);
    } else if (
      (path === "/payment-methods" ||
        path === "/bills" ||
        path === "/move-money") &&
      tenancy?.record_status !== "active" &&
      userRole !== "customer"
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [tenancy, location.pathname, trialDaysLeft, isPlaidOpen]);

  return (
    <Dialog
      open={open}
      onOpenChange={(change) => {
        if (change === false && isLockedBecauseTrialEnded) {
          return;
        }

        setOpen(change);
        if (change === false) {
          history.replace("/");
        }
      }}
    >
      <DialogContent className="max-w-2xl">
        <DialogHeader className="mb-4">
          <DialogTitle className="flex justify-center items-center">
            <ExclamationTriangleIcon className="w-6 h-6 mr-2 text-yellow-600" />{" "}
            Some features are still locked
          </DialogTitle>
          <DialogDescription className=" text-center">
            Finish the steps below to unlock all of your Pocketbook's great
            features like the ability to move money and pay bills.
          </DialogDescription>
        </DialogHeader>
        <LinkBankStepper
          onLinkPlaidStart={() => setIsPlaidOpen(true)}
          onLinkPlaidEnd={() => setIsPlaidOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}
