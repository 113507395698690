import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import InvoiceTable from "./components/invoice-table";
import { CustomSelector } from "pages/utils/styled-select";
import { convertThousandSeparator } from "scripts/functions";
import { chart_date_options, money_list, sort_date_options } from "pages/demo";
import { getAllInvoiceKpis } from "pages/invoices/actions";
import { MainWrapper, MainGreenBtn, OptionLabel } from "pages/styled";
import {
  BalanceLabel,
  CardLabel,
  CardSection,
  MoneyCard,
} from "pages/transactions/styled";
import { Search } from "lucide-react";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { UploadInvoiceButton } from "components/invoices/upload-invoice-button";
import { Button } from "components/ui/button-tw";

const Invoices = () => {
  const history = useHistory();
  const [shortCut, setShortCut] = useState("");
  const [search, setSearch] = useState("");
  const [dateOption, setDateOption] = useState({ label: "30 days", value: 30 });
  const [kpiOption, setKpiOption] = useState({
    label: "Dollars",
    value: "dollars",
  });
  const [sortDate, setSortDate] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [overdueAmount, setOverdueAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);

  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    getInvoiceKpis(dateOption.value);
  }, [dateOption, setDateOption]);

  const getInvoiceKpis = async (dateOption) => {
    await getAllInvoiceKpis(dateOption)
      .then((res) => {
        if (res) {
          setTotalAmount(res.dollars.total_amount_invoices);
          setOverdueAmount(res.dollars.total_amount_invoices_overdue);
          setPaidAmount(res.dollars.total_amount_invoices_paid);
          setUnpaidAmount(res.dollars.total_amount_invoices_unpaid);

          setTotalCount(res.count.total_number_invoices);
          setOverdueCount(res.count.total_number_invoices_overdue);
          setPaidCount(res.count.total_number_invoices_paid);
          setUnpaidCount(res.count.total_number_invoices_unpaid);
        }
      })
      .catch((error) => {
        console.log("INVOICES::GET_ALL_INVOICES_KPIS::ERROR::", error);
      });
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Invoices"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />

          <div className="flex max-w-96 gap-x-4">
            <Button
              className="bg-green-500 hover:bg-green-600"
              onClick={() => history.push("/invoices/new")}
            >
              Create an invoice
            </Button>

            <UploadInvoiceButton />
          </div>
          <div className="pt-24 flex">
            <div>
              <OptionLabel>Summary</OptionLabel>
              <div className="flex">
                <CustomSelector
                  value={dateOption}
                  setValue={setDateOption}
                  selectList={chart_date_options}
                  width={140}
                  menuWidth={140}
                  mRight={18}
                  mBottom={20}
                />
                <CustomSelector
                  value={kpiOption}
                  setValue={setKpiOption}
                  selectList={money_list}
                  width={140}
                  menuWidth={140}
                  mBottom={20}
                  mRight={18}
                />
              </div>
            </div>
            {/* <div>
            <OptionLabel>Sort by</OptionLabel>
            <CustomSelector
              value={sortDate}
              setValue={setSortDate}
              selectList={sort_date_options}
              width={140}
              menuWidth={140}
              mBottom={20}
              placeholder={"Date due"}
            />
          </div> */}
          </div>
          <CardSection>
            <MoneyCard>
              <CardLabel>Paid</CardLabel>
              <BalanceLabel>
                {kpiOption.value === "dollars"
                  ? `$${convertThousandSeparator(paidAmount)}`
                  : paidCount}
              </BalanceLabel>
            </MoneyCard>
            <MoneyCard>
              <CardLabel>Unpaid</CardLabel>
              <BalanceLabel>
                {kpiOption.value === "dollars"
                  ? `$${convertThousandSeparator(unpaidAmount)}`
                  : unpaidCount}
              </BalanceLabel>
            </MoneyCard>
            <MoneyCard>
              <CardLabel>Overdue</CardLabel>
              <BalanceLabel>
                {kpiOption.value === "dollars"
                  ? `$${convertThousandSeparator(overdueAmount)}`
                  : overdueCount}
              </BalanceLabel>
            </MoneyCard>
          </CardSection>

          <div>
            <OptionLabel>All invoices</OptionLabel>
            <div className="flex">
              <div className=" flex rounded-lg shadow-sm h-10 w-[540px]">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Search
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="block w-full rounded-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-skin-primary sm:text-sm sm:leading-6"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
          </div>
          <InvoiceTable
            search={search}
            setSearch={setSearch}
            dateOption={dateOption}
            getInvoiceKpis={getInvoiceKpis}
          />
        </MainWrapper>
      </>
    </div>
  );
};

export default Invoices;
