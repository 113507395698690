import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useFetchKYBData } from "pages/dashboard/hooks/useFetchKYBData";
import { toast } from "react-toastify";
import { cn } from "utils/classnames";
import { Check } from "lucide-react";
import { useKYBData } from "hooks/platform/use-kyb-data";

export function KYBStepper({
  steps,
  setCurrentStep,
  activeStep,
  setActiveStep,
}) {
  const { data } = useKYBData();

  const stepsInfo = steps.map((step, i) => ({
    name: step,
    completed: data?.last_submitted_step >= i + 1,
    current: activeStep === i,
    disabled: data?.last_submitted_step < i,
  }));

  const handleStepChange = (name, i) => {
    setCurrentStep(name);
    setActiveStep(i);
  };

  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex items-center">
        {stepsInfo.map((step, stepIdx) => (
          <li
            key={step.name}
            className={cn(
              stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative"
            )}
          >
            {step.current ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div
                    className={cn("h-0.5 w-full bg-gray-200", {
                      "bg-black": step.completed,
                    })}
                  />
                </div>
                <button
                  type="button"
                  disabled={step.disabled}
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-black bg-white"
                  aria-current="step"
                  onClick={() => handleStepChange(step.name, stepIdx)}
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-black"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            ) : step.completed ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-black" />
                </div>
                <button
                  type="button"
                  disabled={step.disabled}
                  onClick={() => handleStepChange(step.name, stepIdx)}
                  className="relative flex h-8 w-8 items-center justify-center rounded-full bg-black hover:bg-slate-600"
                >
                  <Check className="h-5 w-5 text-white" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <button
                  type="button"
                  disabled={step.disabled}
                  onClick={() => handleStepChange(step.name, stepIdx)}
                  className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </button>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export const KYBStepperOld = ({
  steps,
  setCurrentStep,
  activeStep,
  setActiveStep,
}) => {
  const { data, refetchData } = useFetchKYBData();
  const [hasEffectRun, setHasEffectRun] = useState(false);

  useEffect(() => {
    if (!hasEffectRun && data && data.last_submitted_step !== null) {
      setActiveStep(data.last_submitted_step);
      setCurrentStep(steps[data.last_submitted_step]);
      setHasEffectRun(true);
    }
  }, [data, hasEffectRun, setCurrentStep, setActiveStep, steps]);

  const handleStep = (step, label) => () => {
    if (data) {
      const { last_submitted_step } = data;
      if (last_submitted_step !== null && step <= last_submitted_step) {
        setActiveStep(step);
        setCurrentStep(label);
        refetchData();
      } else {
        toast.error(
          "You need to finish the current step to go to the next one."
        );
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={handleStep(index, label)}>
              {/* {label} */}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
