import { useQuery } from "@tanstack/react-query";
import { getPlatformUsers } from "api/v1-jwt/tenancy/tenancy";

export const usePlatformUsers = ({
  userType,
  queryKey,
  queryFn,
  ...config
} = {}) => {
  const filter = userType || "all";

  return useQuery({
    queryKey: ["tenancy", "users", filter],
    queryFn: async () => getPlatformUsers(userType),
    staleTime: 5 * 60 * 1000,
    ...config,
  });
};
