import { useProfile } from "hooks/profile/use-profile";
import { useInvoices } from "./use-invoices";

export const useCustomerInvoices = () => {
  const cognito_id = JSON.parse(localStorage.getItem("CognitoId"));
  const { data: profile, isLoading: isProfileLoading } = useProfile({
    id: cognito_id,
  });

  const { data, ...rest } = useInvoices({
    fromContactId: cognito_id,
    enabled: !isProfileLoading && !!profile,
  });

  return {
    ...rest,
    data: Array.isArray(data)
      ? data.filter(
          ({ invoice_info }) =>
            invoice_info.invoice_status.toLowerCase() !== "draft"
        )
      : undefined,
  };
};
