import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const getAllAccounts = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(`/platform/accounts/${clientCognitoId}`);

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const transferDeposit = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/transfers/move_money/deposit/${clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const transferWithdraw = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/transfers/move_money/withdraw/${clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const transferThirdParty = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/transfers/move_money/third_party/${clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const transferMoney = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/transfers/${clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};
