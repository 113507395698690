import { useQuery } from "@tanstack/react-query";
import { getInvoiceById } from "api/v1-jwt/invoice/invoice";

export const useInvoice = ({
  invoiceId,
  queryFn,
  queryKey,
  filters = {},
  ...rest
} = {}) => {
  return useQuery({
    queryKey: ["invoices", invoiceId],
    queryFn: async () => {
      return await getInvoiceById(invoiceId);
    },
    staleTime: 5 * 60 * 1000,
    ...rest,
  });
};
