import React from "react";
import { Layout } from "pages/kyb";
import ClientMainHeader from "pages/header";
import styled from "styled-components";
import { textStyles } from "ui";
import { colors } from "ui/colors";
import { NewBillForm } from "./components/NewBillForm";

export const CreateABill = () => {
  return (
    <Layout>
      <ClientMainHeader title="Bills" subTitle="Send a payment to a contact" />
      <Container>
        <LeftSection>
          <NewBillForm />
        </LeftSection>
        <RightSection>
          {/* <BillContainer>
              <img src=''
            </BillContainer> */}
        </RightSection>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  flex-basis: 50%;
`;

const MainText = styled.div`
  ${textStyles.title.h3};
`;

const Subtitle = styled.div`
  ${textStyles.body.b2};
  color: ${colors.textGray};
`;

const RightSection = styled.div`
  flex-basis: 50%;
`;
