import styled from "styled-components";
import { InputBase, FormControl, styled as styledMUI } from "@mui/material";
import { CustomLabel } from "pages/utils/styled-input";

export const StyledInvoiceTable = styled.div`
  white-space: nowrap;
  width: 100%;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin-top: 30px;
  overflow-x: auto;

  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

export const InvoiceTotalSection = styled.div`
  border-top: 1px solid #939393;
  padding-top: 16px;
  margin-top: 16px;
  width: 100%;
  max-width: 512px;
`;

export const NewInvoiceBtn = styled.div`
  border-radius: 12px;
  width: fit-content;
  padding: 10px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const BottomText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  padding-top: 30px;
`;

export const ColumnDetailSection = styled.div`
  grid-column: span 6;
  padding: 33px 46px;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  position: relative;
`;

export const DetailTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-right: 30px;
  min-width: 160px;
`;

export const DetailGrid = styled.div`
  max-width: 300px;
  white-space: nowrap;
  width: 100%;
  gap: 20px;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
`;

export const DetailLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px;
`;

export const DetailBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) =>
    props.disabled ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.6)"};
  padding: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;

  &:hover {
    color: ${(props) =>
      props.disabled ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.9)"};
  }
`;

export const DetailTotalInvoiceSection = styled.div`
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 5px;
  align-items: center;
`;

export const UpdateButtonSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UpdateWrapper = styled.div`
  border-bottom: 1px solid #eaecf0;
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;
  justify-content: flex-end;
`;

export const InvoiceAmountBox = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  padding: 10px 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
`;

export const QuantityTagBox = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  width: 100px;
  height: 42px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 20px;
`;

export const DetailToolSection = styled.div`
  position: absolute;
  right: 30px;
  bottom: 26px;
  text-align: end;
`;

export const InvoiceDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 18px;
`;

export const InvoiceDetailInfo = styled.div`
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  color: #000000;
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

export const PayPalImg = styled.img`
  width: auto;
  height: 19px;
  margin: 0px 10px;
`;

export const VenmoImg = styled.img`
  width: auto;
  height: 30px;
  margin: 0px 4px;
`;

export const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 6;
`;

export const TableToolBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &:hover {
    background-color: #e5e2e2;
  }
`;

export const StyledInput = ({
  type,
  label,
  value,
  onChange,
  placeholder,
  mBottom,
  mRight,
  mLeft,
  mTop,
  width,
  name,
  disabled,
  validation,
  error,
  readOnly = false,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        maxWidth: width ? width : "initial",
        width: "100%",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <CustomInput
        type={type ? type : "text"}
        value={value}
        id="bootstrap-input"
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        readOnly={readOnly}
        disabled={disabled ? true : false}
        validation={validation === undefined ? "true" : validation.toString()}
        style={{
          border: error ? "2px solid red" : "none",
          borderRadius: "8px",
        }}
      />
    </FormControl>
  );
};
const CustomInput = styledMUI(InputBase)(({ theme, validation }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    position: "relative",
    fontFamily: "Inter",
    border: validation === "true" ? "1px solid #D0D5DD" : "1px solid #ff0000",
    color: "#000000",
    fontSize: 14,
    fontWeight: 590,
    padding: "10px 14px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80BDFF",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
