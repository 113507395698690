import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContact } from "api/v1-jwt/contact/contact";

export const useCreateContact = () => {
  const query = useQueryClient();

  return useMutation({
    mutationFn: createContact,
    onSuccess: () => {
      query.invalidateQueries(["contacts"]);
    },
  });
};
