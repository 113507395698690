import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import styled from "styled-components";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import copy_icon from "assets/images/copy.png";
import { StyledTextInput } from "pages/utils/styled-input";
import { copyTextToClipboard } from "scripts/functions";
import { MainWrapper } from "pages/styled";
import { createSmartContract } from "./actions";
import {
  CancelBtn,
  ChatGptSection,
  CopyText,
  GenerateBtn,
  ProgressSection,
  FinalizeDetails,
  DetailsOptions,
  Option,
  DetailsInfoText,
  DetailsButton,
  DetailsButtonContainer,
  SmartContractSection,
  SmartContractTitle,
} from "./styled";
import useUserProfile from "hooks/useProfile";

import ContractComfirmDialog from "./confirm-dialog";
import AddContactModal from "components/contact/add-contact-modal";
import { ContactsCombobox } from "components/contact/contacts-combobox";
import useWebSocket from "./hooks/useWebSocket";
import { QuotesButton, SmartQuoteDescription } from "./components";
import axios from "axios";
import {
  customTimeout,
  formatPhoneNumber,
  getAddressFromContact,
  getAddressFromProfile,
} from "utils/functions";
import { addresseeEmail, addresseePhone } from "utils/invoice";
import moment from "moment";
import QuoteDetailsTable from "./components/QuoteDetailsTable";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { DatePicker } from "components/form/date-picker";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useSettings } from "hooks/setting/use-settings";

const QuotesLogo = styled.img`
  max-height: 250px;
`;

const SmartContractStage = ({
  stage,
  contractText,
  setContractText,
  selectedContact,
  setSelectedContact,
  profileInfo,
  quoteItems,
  setQuoteItems,
  expirationDate,
}) => {
  const { data: settings } = useSettings();
  switch (stage) {
    case 0:
      return (
        <div style={{ margin: "1rem 0 1rem 0" }}>
          <ContactsCombobox onSelect={setSelectedContact} />
        </div>
      );
    case 1:
      return (
        <StyledTextInput
          value={contractText}
          setValue={setContractText}
          rows={20}
          mTop={20}
          mBottom={30}
          width={"100%"}
          placeholder={"Type here..."}
        />
      );
    case 2:
      return (
        <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
          <div className="flex justify-between">
            <h2 className="text-base font-semibold leading-6 text-gray-900">
              Quote
            </h2>
            {settings?.logo ? (
              <QuotesLogo
                src={`https://cdn.pocketbook.tech/${settings?.logo}`}
              />
            ) : null}
          </div>
          <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
            <div className="sm:pr-4">
              <dt className="inline text-gray-500">Estimate Date:</dt>{" "}
              <dd className="inline text-gray-700">
                <time>{moment(new Date()).format("LL")}</time>
              </dd>
            </div>
            <div className="sm:pr-4 ">
              <dt className="inline text-gray-500">Expiration Date:</dt>{" "}
              <dd className="inline text-gray-700">
                <time>{moment(expirationDate).format("LL")}</time>
              </dd>
            </div>
            <div className=""></div>
            <div></div>
            <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
              <dt className="font-semibold text-gray-900">From</dt>
              <dd className="mt-2 text-gray-500">
                <span className="font-medium text-gray-900">
                  {profileInfo?.business_name}
                </span>
                <br />
                {profileInfo?.business_email}
                <br />
                {formatPhoneNumber(profileInfo?.business_phone)}
                <br />
                {getAddressFromProfile(profileInfo?.physical_address)}
              </dd>
            </div>
            <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
              <dt className="font-semibold text-gray-900">To</dt>
              <dd className="mt-2 text-gray-500">
                {selectedContact.contact_info?.business_name ? (
                  <>
                    <span className={`font-medium text-gray-900`}>
                      {selectedContact.contact_info?.business_name}
                    </span>
                    <br />
                  </>
                ) : null}
                <span
                  className={
                    selectedContact.contact_info?.business_name != ""
                      ? null
                      : `font-medium text-gray-900`
                  }
                >
                  {`${selectedContact?.contact_info?.first_name} ${selectedContact?.contact_info?.last_name}`}
                </span>
                <br />
                {selectedContact?.contact_info?.email}
                <br />
                {formatPhoneNumber(
                  selectedContact?.contact_info?.phone_number ||
                    selectedContact?.contact_info?.phone
                )}
                <br />
                {getAddressFromContact(selectedContact?.contact_info)}
              </dd>
            </div>
          </dl>
          <QuoteDetailsTable
            prompt={contractText}
            quoteItems={quoteItems}
            setQuoteItems={setQuoteItems}
          />
          <p className="mt-5 leading-6 text-gray-500 italic text-sm">
            This is a price estimate only, this total does not include any
            applicable taxes or transaction fees.
          </p>
        </div>
      );
    default:
      break;
  }
};

const SmartQuotes = () => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState({
    label: "Create a contract",
    value: "smart-contracts",
  });
  const [contractText, setContractText] = useState("");
  const [stage, setStage] = useState(0);
  const [data, setData] = useState(null);
  const [generate, setGenerate] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [addContact, setAddContact] = useState(false);
  const [textsToFill, setTextToFill] = useState([]);
  const [selectedContact, setSelectedContact] = useState();
  const [quoteOrContract, setQuoteOrContract] = useState("quote");
  const { profileInfo, loading: profileLoading } = useUserProfile();
  const [quoteItems, setQuoteItems] = useState();
  // const [selectedOption, setSelectedOption] = useState("auto");
  const [expirationDate, setExpirationDate] = useState();
  const [expirationDateValidation, setExpirationDateValidation] =
    useState(null);
  const { data: tenancy } = useTenancy();

  const handleSaveSmartQuote = ({ isQuote = true, quoteItems = null }) => {
    if (!expirationDate) {
      setExpirationDateValidation("Expiration date is required.");
      return;
    }
    if (expirationDate.constructor?.name !== "Date") {
      setExpirationDateValidation("Expiration date must be a valid date.");
      return;
    }
    if (moment(expirationDate).isSameOrBefore()) {
      setExpirationDateValidation("Expiration date cannot be a past date.");
      return;
    }

    const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
    createSmartContract(
      tenancy?.platform_client_id || clientCognitoId,
      contractText,
      data?.invoiceId,
      selectedContact,
      isQuote,
      quoteItems,
      expirationDate
      // selectedOption
    ).then((res) => {
      if (res.status === "success") {
        toast.success("Your Smart Quote has been sent");
        customTimeout(() => {
          history.push("/quotes");
        });
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const texts = {
    next: "Next",
    generate: "Generate Response",
    save: quoteOrContract === "contract" ? "Save Contract" : "Save Quote",
  };
  const handleFunctions = {
    moveToNextStage: () => setStage((prev) => prev + 1),
    handleGenerate: () => setStage(2),
    handleSave: () => {
      handleSaveSmartQuote({
        isQuote: true,
        quoteItems: quoteItems,
      });
    },
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Quotes"
            subTitle="Create quotes tailored for your business"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <div className="flex justify-between">
            <SmartContractSection>
              <SmartContractTitle text="Welcome to Smart Quotes" />
              <SmartQuoteDescription
                stage={stage}
                contractText={contractText}
              />
              <SmartContractStage
                stage={stage}
                contractText={contractText}
                setContractText={setContractText}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                profileInfo={profileInfo}
                quoteItems={quoteItems}
                setQuoteItems={setQuoteItems}
                expirationDate={expirationDate}
              />
              {stage == 2 ? null : (
                <div className="flex justify-between">
                  {/* <CopyText onClick={handleCopyText}>
                <img src={copy_icon} alt="copy" width="16px" height="16px" />
                <span className="pl-08">Copy text</span>
              </CopyText>  */}

                  <CancelBtn
                    onClick={() => {
                      setData(null);
                      setStage(0);
                      history.push("/contracts");
                    }}
                  >
                    Cancel
                  </CancelBtn>
                  <QuotesButton
                    stage={stage}
                    settingData={settingData}
                    handleFunctions={handleFunctions}
                    texts={texts}
                  />
                </div>
              )}
            </SmartContractSection>
            {stage == 2 ? (
              <FinalizeDetails>
                <h2 className="text-lg font-medium">Finalize Details</h2>
                <div className="flex flex-col gap-y-1">
                  <label className="text-sm font-semibold">
                    Select Expiration Date
                  </label>
                  <DatePicker
                    selected={expirationDate}
                    onSelect={(newDate) => {
                      setExpirationDate(newDate);
                      setExpirationDateValidation(null);
                    }}
                  />
                  {expirationDateValidation && (
                    <div className="text-sm text-red-600 font-medium">
                      {expirationDateValidation}
                    </div>
                  )}
                </div>
                {/* <input
                  type="date"
                  id="expiration-date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                /> */}
                {/* <h3>Select invoice option</h3>

                <DetailsOptions
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <Option value={"auto"} selected={selectedOption == "auto"}>
                    Let Pocketbook do the work! Send the invoice automatically
                    after approval
                  </Option>
                  <Option value={"draft"} selected={selectedOption == "draft"}>
                    Create a draft invoice
                  </Option>
                  <Option selected={selectedOption == "none"} value={"none"}>
                    Do not create an invoice
                  </Option>
                </DetailsOptions> */}
                <div className="border-b border-gray-500 mb-4 mt-5"></div>
                <p className="text-sm font-medium">
                  {`By clicking ‘Send Quote’, you are confirming that the quote
                  will be sent to ${
                    selectedContact?.contact_info.first_name
                  } on ${moment().format(
                    "LL"
                  )}. Once the quote is sent, it cannot be
                  modified.`}
                </p>
                <div className="flex gap-x-4 mt-4">
                  <DetailsButton
                    type="submit"
                    onClick={() => {
                      setData(null);
                      setContractText("");
                      setStage(0);
                      history.push("/smart-quotes");
                    }}
                  >
                    Start Over
                  </DetailsButton>
                  <DetailsButton
                    type="submit"
                    onClick={() =>
                      handleSaveSmartQuote({
                        isQuote: true,
                        quoteItems: quoteItems,
                      })
                    }
                  >
                    Send Quote
                  </DetailsButton>
                </div>
              </FinalizeDetails>
            ) : null}
          </div>
          <ContractComfirmDialog
            setDialog={setDialogOpen}
            dialog={dialogOpen}
          ></ContractComfirmDialog>
        </MainWrapper>
      </>
    </div>
  );
};

export default SmartQuotes;
