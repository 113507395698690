import React from "react";
import { cn } from "utils/classnames";

const TextArea = React.forwardRef(
  ({ className, onChange, error, ...props }, ref) => {
    const handleChange = (e) => onChange(e.target.value);
    return (
      <textarea
        rows="4"
        className={cn(
          "bg-white flex w-full rounded-lg border border-input px-3 py-2 focus:border-none text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:bg-gray-100",
          {
            "border-red-500 bg-red-50 text-red-900 placeholder-red-700": error,
          },
          className
        )}
        onChange={handleChange}
        ref={ref}
        {...props}
      />
    );
  }
);
TextArea.displayName = "TextArea";

export { TextArea };
