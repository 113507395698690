import { getPaymentMethods } from "actions/payment-methods";
import { useState, useEffect } from "react";

const usePaymentMethods = (cognitoId) => {
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchPaymentMethods = async ({ status = false }) => {
      if (!isMounted) return;
      setLoading(true);
      try {
        const paymentMethods = await getPaymentMethods({
          status,
          user_id: cognitoId,
        });
        setPaymentList(paymentMethods);

        setLoading(false);
      } catch (e) {
        if (isMounted) {
          console.log("Error getting payment methods", e);
          setLoading(false);
        }
      }
    };

    fetchPaymentMethods({ status: false });

    return () => {
      isMounted = false;
    };
  }, [cognitoId]);

  return {
    loading,
    paymentList,
    setPaymentList,
  };
};

export default usePaymentMethods;
