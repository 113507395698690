import styled from "styled-components";

export const ProfileSection = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaecf0;
`;

export const FormField = styled.div`
  display: flex;

  @media (max-width: 500px) {
    display: grid;
  }
`;

export const FormLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 100%;
  max-width: 280px;
  margin-right: 32px;

  @media (max-width: 500px) {
    padding-bottom: 10px;
  }
`;

export const PhoneInputForm = styled.div`
  width: 100%;
  max-width: 512px;
  padding-bottom: 20px;
`;

export const SettingDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 30px;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

export const ColorSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 32px;

  @media (max-width: 840px) {
    gap: 26px;
  }
  @media (max-width: 500px) {
    gap: 18px;
  }
`;

export const ColorSelector = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
`;

export const ActiveColorSelector = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
`;

export const UploadeLogoSection = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 340px;
  padding: 16px 24px;
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
`;

export const UploadTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
`;

export const UploadSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #939393;
`;

export const SaveBtn = styled.a`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: fit-content;
  padding: 10px 16px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const BtnSection = styled.div`
  padding-top: 30px;
  width: 100%;
  max-width: 824px;
  display: flex;
  justify-content: end;
`;

export const FormBtn = styled.div`
  max-width: 184px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px 14px;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const FormOptionBtn = styled.div`
  max-width: 184px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px 14px;
`;

export const FormInputDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  padding-top: 6px;
  padding-bottom: 20px;
`;

// Account Statement

export const StyledCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  height: 370px;
  padding: 35px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    padding: 20px;
    margin-top: 0px;
  }
`;

export const CardTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #101828;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (max-width: 500px) {
    font-size: 20px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const SubmitBtn = styled.div`
  width: 142px;
  background-color: #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
`;

export const ValidationText = styled.div`
  color: #ff0000;
  font-size: 12px;
`;

export const ChecklistLabel = styled.div`
  color: #939393;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
`;

export const ChecklistSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

export const MFACheckboxSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
