import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

import { Button } from "components/ui/button-tw";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { useCreateContact } from "hooks/contact/use-create-contact";
import { toast } from "react-toastify";

const emailRules = {
  required: {
    message: "Email is required",
    value: true,
  },
  pattern: {
    message: "Email format is invalid",
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};

const firstNameRules = {
  required: {
    message: "First name is required",
    value: true,
  },
};

const lastNameRules = {
  required: {
    message: "Last name is required",
    value: true,
  },
};

const phoneRules = {
  required: {
    message: "Phone is required",
    value: true,
  },
};

function AddContactModal({
  open,
  onOpenChange,
  onCreateSuccess,
  values = {},
  isDissmisable = false,
  dismiss = null,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      businessName: values?.businessName || "",
      email: values?.email || "",
      photne: values?.phone || "",
    },
  });

  const { mutate } = useCreateContact();

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, email, businessName, phone }) => {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        business_name: businessName,
        email,
        phone,
      };

      mutate(payload, {
        onSuccess: (data) => {
          onCreateSuccess(data);
          reset();
        },
        onError: (error) => {
          if (error?.response?.status === 422) {
            toast.error(
              error?.response?.data?.detail ||
                "Business name is a required field."
            );
          } else {
            toast.error("Something went wrong. Please try submitting again.");
          }
        },
        onSettled: () => onOpenChange(false),
      });
    }
  );

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <form onSubmit={onSubmit}>
            <DialogHeader className="mb-6">
              <DialogTitle className="text-xl">
                Create a new contact
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-4 pt-2">
              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="firstName">First Name*</Label>
                <Input
                  id="firstName"
                  placeholder=""
                  aria-invalid={errors.firstName ? "true" : "false"}
                  {...register("firstName", firstNameRules)}
                />
                {errors.firstName && (
                  <p role="alert" className="text-sm text-red-600">
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="lastName">Last Name*</Label>
                <Input
                  id="lastName"
                  placeholder=""
                  aria-invalid={errors.lastName ? "true" : "false"}
                  {...register("lastName", lastNameRules)}
                />
                {errors.lastName && (
                  <p role="alert" className="text-sm text-red-600">
                    {errors.lastName.message}
                  </p>
                )}
              </div>

              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="businessName">
                  Business Name (if applicable)
                </Label>
                <Input
                  id="businessName"
                  placeholder=""
                  {...register("businessName")}
                />
              </div>

              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="email">Email*</Label>
                <Input
                  id="email"
                  placeholder=""
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register("email", emailRules)}
                />
                {errors.email && (
                  <p role="alert" className="text-sm text-red-600">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="phone">Phone*</Label>
                <Input
                  id="phone"
                  placeholder=""
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register("phone", phoneRules)}
                />
                {errors.phone && (
                  <p role="alert" className="text-sm text-red-600">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>

            <DialogFooter className="mt-6 gap-1">
              {isDissmisable ? (
                <Button
                  variant="outline"
                  className="w-1/2"
                  onClick={() => {
                    reset();
                    dismiss();
                  }}
                >
                  Don't create Contact
                </Button>
              ) : (
                <Button
                  variant="outline"
                  className="w-1/2"
                  onClick={() => {
                    reset();
                    onOpenChange(false);
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                className="w-1/2"
                type="button"
                disabled={!isValid || isSubmitting}
                onClick={onSubmit}
              >
                {isSubmitting ? (
                  <Loader2 className="w-6 h-6 animate-spin" />
                ) : (
                  <>Create</>
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddContactModal;
