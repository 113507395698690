import {
  CalendarDays,
  UserCircle,
  ChevronRight,
  ChevronDown,
  ChevronLeft,
} from "lucide-react";
import moment from "moment";
import { Status } from "ui/TableHelpers";
import { useState } from "react";
import {
  changeContractStatus,
  downloadContract,
} from "pages/contracts/actions";
import useToast from "hooks/notifications/use-toast";
import { useHistory } from "react-router-dom";
import {
  Actions,
  ApproveContainer,
  ApproveRejectButton,
} from "./ActionCardOld";
import { toast } from "react-toastify";
import { customTimeout } from "utils/functions";

const ActionCard = ({
  item,
  clientEmail,
  status,
  customer,
  children,
  contract_id,
  deliveryOption,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOption, setMenuOption] = useState("manage");
  const { push } = useHistory();

  const { toastNotify } = useToast();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleApproveBack = () => {
    setIsMenuOpen(true);
    setMenuOption("manage");
  };

  const handleStatusChange = async (status) => {
    try {
      await changeContractStatus(item.contract_id, status, deliveryOption);
      status == "approved"
        ? toast.success(`Quote Approved Successfully`)
        : toast.success(`Quote Rejected Successfully`);
      customTimeout(() => (window.location.href = "/quotes"));
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = async () => {
    const payload = {
      file_location: item.contract_info.contract_full_location,
    };
    await downloadContract(payload).then((res) => {
      if (res && res.downloadURLData) {
        toastNotify("Quote downloaded successfully.", "success");
        window.open(res.downloadURLData, "_blank");
      }
    });
  };

  return (
    <div className="pb-6">
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-skin-primary shadow-sm ring-1 ring-gray-900/5 pb-5">
        <dl className="flex flex-col flex-wrap pb-5">
          {status ? (
            <div className="flex">
              <div className="flex-auto pl-6 pt-6">
                {/* <dt className="text-sm font-semibold leading-6 text-skin-a11y">
                      Status
                    </dt> */}
                <dd className="mt-1 text-base font-semibold leading-6 text-skin-a11y">
                  <dd className="text-sm font-medium leading-6 text-white">
                    <Status status={status} white />
                  </dd>
                </dd>
              </div>
            </div>
          ) : null}

          <div className="flex flex-col  justify-between lg:mt-6">
            <div className="mt-4 lg:mt-0 flex flex-none gap-x-4 border-t border-gray-900/5 px-6 lg:pt-0 pt-6">
              <dt className="flex-none">
                <span className="sr-only">Client</span>
                <UserCircle className="h-6 w-5 text-white" aria-hidden="true" />
              </dt>
              <dd className="text-sm font-medium leading-6 text-white">
                {item?.contact_info.first_name +
                  " " +
                  item?.contact_info.last_name}
              </dd>
            </div>
            <div className=" mt-4 flex  flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Date Received</span>
                <CalendarDays
                  className="h-6 w-5 text-white"
                  aria-hidden="true"
                />
              </dt>
              <dd className="text-sm leading-6 text-white">
                <time>
                  {`Received on ${moment(
                    item?.contract_info.uploaded_date
                  ).format("LL")}`}
                </time>
              </dd>
            </div>
          </div>
        </dl>

        <div className="mt-6 border-t border-slate-300/20 px-2 py-2 pl-6">
          {menuOption === "manage" ? (
            <button
              onClick={toggleMenu}
              className="text-sm font-semibold leading-6 text-skin-a11y flex"
            >
              Manage {item?.contract_info.is_quote ? "Quote" : "Contract"}
              {isMenuOpen ? (
                <ChevronDown className="icon-size mr-2" aria-hidden="true" />
              ) : (
                <ChevronRight className="icon-size mr-2" aria-hidden="true" />
              )}
            </button>
          ) : null}
          {isMenuOpen && menuOption === "manage" ? (
            <Actions
              customer={customer}
              clientEmail={clientEmail}
              setMenuOption={setMenuOption}
              handleDownload={handleDownload}
              contract_id={item.contract_id}
              status={status}
              toastNotify={toastNotify}
              isQuote={item?.contract_info.is_quote}
            />
          ) : null}
          {menuOption === "approve" ? (
            <>
              <div>
                <button
                  onClick={handleApproveBack}
                  className="text-sm font-semibold text-skin-a11y flex"
                >
                  <ChevronLeft className="icon-size" aria-hidden="true" />
                  Approve or Reject{" "}
                  {item?.contract_info.is_quote ? "Quote" : "Contract"}
                </button>
              </div>
              <ApproveContainer>
                <ApproveRejectButton
                  approve
                  onClick={() => handleStatusChange("approved")}
                >
                  Approve
                </ApproveRejectButton>
                <ApproveRejectButton
                  onClick={() => handleStatusChange("rejected")}
                >
                  Reject
                </ApproveRejectButton>
              </ApproveContainer>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
