import { CircularProgress } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import { LoadingWrapper } from "pages/styled";
import {
  RightSection,
  PocketbookLogo,
  RightImage,
  SignupFormTitle,
  SignupFormDescription,
} from "./styled";
import { Button } from "components/ui/button-tw";
import { ArrowLeft } from "lucide-react";
import { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSettings } from "../../settings/actions";
import * as actionTypes from "./actionTypes";

export const EmailSent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { settingData } = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(false);

  useLayoutEffect(() => {
    if (!settingData) {
      setLoading(true);
      getSettings().then((res) => {
        if (res)
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: res,
          });
        console.log("Settings res: ", res);
        setLoading(false);
      });
    }
  }, [dispatch]);

  const backToLogin = () => {
    history.push("/login");
  };

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="bg-white w-full sm:w-1/2 relative overflow-auto h-full p-6 sm:p-8 lg:p-12 flex flex-col justify-between">
        <div className="lg:mt-2">
          <PocketbookLogo src={logo} alt="logo" />
        </div>

        <div className="max-w-[600px] lg:pr-20 lg:pl-12 pb-24">
          <div>
            <SignupFormTitle>Check your email!</SignupFormTitle>
            <SignupFormDescription>
              We've sent a link to reset your password to the email address
              provided. You may close this window.
            </SignupFormDescription>
          </div>
        </div>
        <div className="flex justify-start ">
          <Button
            variant="link"
            className="text-slate-600 pl-0"
            onClick={backToLogin}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Button>
        </div>
      </div>
      <RightSection className="">
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
    </div>
  );
};

export default EmailSent;
