import styled from "styled-components";
import paypal_logo from "assets/images/paypal-logo.png";
import venmo_logo from "assets/images/venmo-logo.png";
import bank_icon from "assets/images/bank.png";
import { banks_logos } from "assets/images/banks";

const PaymentCardIcon = ({ className, bank_name, method_type }) => {
  if (method_type === "PAYPAL") {
    return (
      <div className={className}>
        <img src={paypal_logo} alt="paypal" />
        <img src={venmo_logo} alt="venmo" />
      </div>
    );
  }
  if (method_type === "PLAID") {
    return (
      <div className={className}>
        <img
          src={banks_logos[bank_name] || banks_logos["default"]}
          alt={`${bank_name}`}
          style={banks_logos[bank_name] ? null : { maxWidth: "40px" }}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <img src={bank_icon} alt="bank" width={"38px"} height={"38px"} />
    </div>
  );
};

const PaymentCardIconStyled = styled(PaymentCardIcon)`
  display: flex;
  img {
    width: ${(props) => (props.bank_name ? "100px" : "38px")};
    height: auto;
    margin-right: 6px;
  }
`;

export default PaymentCardIconStyled;
