import moment from "moment";

export const getInvoicePayload = (data) => {
  const payload = {
    invoice_to: {
      contact_id: data.contact_id,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      business_name: data.businessName,
      business_dba: data.dbaName,
      business_address: data.mailingAddr,
      business_city: data.city,
      business_state: data.state,
      business_zip: data.zipCode,
    },
    invoice_frequency: data.invoiceType.value,
    invoice_frequency_interval: 0,
    invoice_due_date: data.dueDate
      ? moment(
          data.dueDate.$d,
          "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ"
        ).toISOString()
      : "",
    invoice_send_date: data.sendDate
      ? moment(
          data.sendDate.$d,
          "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ"
        ).toISOString()
      : Date.now(),
    invoice_line_items: data.lineItems.map((item) => {
      return {
        ...item,
        quantity: Number(item.quantity),
        unit_price: Number(item.unit_price),
      };
    }),
    invoice_subtotal: data.subTotal,
    invoice_tax_percentage: data.invoiceTax !== "" ? data.invoiceTax : 0,
    invoice_tax_amount: Number(data.subTotal) * Number(data.invoiceTax / 100),
    invoice_pass_processing_fee: data.transactionFee,
    invoice_processing_fee: data.transactionFeeAmount,
    invoice_add_paypal: data.invoice_add_paypal,
    invoice_total: data.invoiceTotal,
    invoice_status: data.status,
    invoice_notes: data.invoiceText,
    invoice_delivery_method: data.submit_type,
  };
  return payload;
};
