import styled from "styled-components";

export const SidebarWrapper = styled.div`
  width: 100%;
  max-width: 314px;
  min-height: 800px;
  background-color: #000000;
  padding: 38px;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-height: 800px) {
    min-height: 0px;
  }
`;

export const CustomerSidebarWrapper = styled.div`
  width: 100%;
  max-width: 314px;
  min-height: 600px;
  // background-color: #f15a25;
  padding: 38px;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 38px 20px;
    width: 280px;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10;
  }

  @media (max-height: 800px) {
    min-height: 0px;
  }
`;

export const SidebarLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  &:hover {
    opacity: 1;
    font-weight: 700;
  }
`;

export const SidebarToolSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 38px;

  @media (max-height: 800px) {
    gap: 20px;
  }
`;

export const StepIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 38px;
  margin-top: 38px;
  height: calc(100% - 300px);

  @media (max-height: 800px) {
    gap: 20px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const UploadeLogoSection = styled.div`
  max-width: 215px;
  width: 100%;
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
`;

export const UploadTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  padding-top: 12px;
`;

export const MobileNavWrapper = styled.div`
  width: 100% !important;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const MobileNavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DrawerSection = styled.div`
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileNavSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 38px;
  margin-top: 38px;
  height: calc(100% - 200px);

  @media (max-height: 800px) {
    gap: 20px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
