import { Dialog } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import {
  disconnectPaymentMethod,
  disconnectPaypalIntegration,
} from "../../actions/payment-methods/actions";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogSubmitBtn,
} from "../../pages/styled";
import { toast } from "react-toastify";
import { BasicModal } from "ui";

const PaymentDisconnectDialog = ({
  type,
  paymentName,
  dialog,
  setDialog,
  customer,
  connection_id,
  getPaymentMethodList,
  paymentList,
  setPaymentList,
}) => {
  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleDisconnectPayment = () => {
    if (customer) {
      setDialog(false);
    } else {
      if (type === "PAYPAL") {
        disconnectPaypalIntegration(connection_id)
          .then((res) => {
            if (res && res.disconnected) {
              setDialog(false);
              toast.success(
                `Your Paypal account has been removed successfully.`
              );
              getPaymentMethodList();
            }
          })
          .catch((err) => console.error(err));
      }
      if (type === "PLAID") {
        disconnectPaymentMethod(type, connection_id)
          .then((res) => {
            if (res && res.disconnected) {
              setDialog(false);
              toast.success(
                `Your connected bank account has been removed successfully.`
              );
              getPaymentMethodList();
            }
          })
          .catch((err) => toast.error(err));
      }
    }
  };

  return (
    //   <BasicModal
    //   title={'Double checking...'}
    //   text={type === "PAYPAL" ? `Are you sure you'd like to disconnect your ${paymentName} account?`:`Are you sure you'd like to disconnect ${paymentName}?`}
    //   open={dialog}
    //   buttonText={'Yes, disconnect'}
    //   onButtonClick={handleDisconnectPayment}
    //   handleClose={handleDialogClose}
    //   secondaryButtonText={"No! Keep it"}
    //   onSecondaryButtonClick={handleDialogClose}
    // />
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription className="text-start">
          {type === "PAYPAL"
            ? `Are you sure you'd like to disconnect your ${paymentName} account?`
            : `Are you sure you'd like to disconnect ${paymentName}?`}
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            No! Keep it
          </DialogCancelBtn>
          <DialogSubmitBtn onClick={handleDisconnectPayment}>
            Yes, disconnect
          </DialogSubmitBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default PaymentDisconnectDialog;
