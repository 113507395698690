import { useTenancy } from "hooks/tenancy/use-tenancy";
import { FileWarning, X } from "lucide-react";
import { useAuth } from "providers/auth";
import { useEffect, useState } from "react";

const BAAS_STATUS = ["pending", "NOT_FOUND", "not_started"];

export function LimitedAccountWarning() {
  const [showWarning, setShowWarning] = useState(false);
  const { data } = useTenancy();
  const { user } = useAuth();

  useEffect(() => {
    const closed = JSON.parse(localStorage.getItem("BAAS_WARNING_CLOSED"));
    if (
      !closed &&
      BAAS_STATUS.includes(data?.baas_status) &&
      user?.cognito_user_info?.UserRole === "owner"
    ) {
      setShowWarning(true);
    }
  }, [data?.baas_status]);

  const handleClose = () => {
    setShowWarning(false);
    localStorage.setItem("BAAS_WARNING_CLOSED", JSON.stringify(true));
  };

  return (
    showWarning && (
      <div className="flex items-center rounded-md bg-yellow-50 p-4 md:ml-[310px]">
        <div className="flex pl-7">
          <div className="flex-shrink-0">
            <FileWarning
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Attention needed
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Set up your processing account to enable payments and money
                movement.{" "}
                <a
                  href="/kyb"
                  className="font-medium text-yellow-700 underline hover:text-yellow-600"
                >
                  Click here to get started
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="ml-auto pl-3 pr-5">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
              onClick={handleClose}
            >
              <span className="sr-only">Dismiss</span>
              <X className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    )
  );
}
