import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import * as actionTypes from "../actionTypes";
import { getTokenWithInquiry, updateProfile } from "../actions";
import {
  MainDescription,
  MainTitle,
  PlanInfoSubText,
  BottomTextSection,
  Setup2Wrapper,
  BackBtn,
  BackBtnLabel,
  Setup7MainSection,
} from "../styled";

const SubmitBtn = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  min-width: 140px;
  max-width: 168px;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccountSetup7 = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const { personaData, inquiryId } = useSelector((state) => state.account);

  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [verification, setVerification] = useState(
    !personaData || personaData.status !== "completed" ? false : true
  );
  const [token, setToken] = useState("");

  const handleNextSetup = async () => {
    setNextLoading(true);
    const payload = { account_setup_step: 8 };
    await updateProfile(payload).then((res) => {
      setNextLoading(false);
      setStep(res.account_setup_step);
    });
  };

  useEffect(() => {
    if (!personaData) {
      setLoading(true);
      getToken();
    }
  }, [personaData]);

  const getToken = async () => {
    await getTokenWithInquiry(inquiryId).then((res) => {
      console.log("token", res);
      if (res && res.session_token) setToken(res.session_token);
    });
  };

  return (
    <Setup2Wrapper>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup7MainSection>
        <MainTitle>
          Setup Payment Processing Begin hosted Persona flow.
        </MainTitle>
        <MainDescription>Begin hosted Persona flow.</MainDescription>
        {loading && <CircularProgress />}
        {verification && (
          <PlanInfoSubText className="pb-10">
            Your identity has been succesfully confirmed!
          </PlanInfoSubText>
        )}
        {personaData && personaData.status === "failed" && (
          <PlanInfoSubText className="pb-10">
            We were unable to verify your identity. Please verify again or
            contact support.
          </PlanInfoSubText>
        )}
        {token !== "" && (
          <div className="persona-iframe">
            {/*<Persona.Inquiry*/}
            {/*  inquiryId={inquiryId}*/}
            {/*  sessionToken={token}*/}
            {/*  environment="production"*/}
            {/*  onLoad={(error) => {*/}
            {/*    if (error) {*/}
            {/*      console.error(*/}
            {/*        `Failed with code: ${error.code} and message ${error.message}`*/}
            {/*      );*/}
            {/*    } else {*/}
            {/*      console.log("Loaded inline");*/}
            {/*      setLoading(true);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  onStart={(param) => {*/}
            {/*    console.log(`Started inline inquiry ${param.inquiryId}`);*/}
            {/*  }}*/}
            {/*  onComplete={(param) => {*/}
            {/*    console.log(*/}
            {/*      `Sending finished inline inquiry ${param.inquiryId} to backend`*/}
            {/*    );*/}
            {/*    console.log(`Verification of your identity: ${param.status}`);*/}
            {/*    dispatch({*/}
            {/*      type: actionTypes.PERSONA_DATA,*/}
            {/*      payload: param,*/}
            {/*    });*/}
            {/*    if (param.status === "completed" && param.inquiryId) {*/}
            {/*      setVerification(true);*/}
            {/*      setTimeout(() => {*/}
            {/*        setStep(8);*/}
            {/*      }, "2000");*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  onEvent={(name, meta) => {*/}
            {/*    switch (name) {*/}
            {/*      case "start":*/}
            {/*        console.log(`Received inline event: start`);*/}
            {/*        break;*/}
            {/*      default:*/}
            {/*        console.log(*/}
            {/*          `Received inline event: ${name} with meta: ${JSON.stringify(*/}
            {/*            meta*/}
            {/*          )}`*/}
            {/*        );*/}
            {/*        setLoading(false);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        )}
        <div>
          {verification && (
            <SubmitBtn onClick={handleNextSetup}>
              Submit
              {nextLoading && (
                <CircularProgress color="inherit" size={14} className="ml-08" />
              )}
            </SubmitBtn>
          )}
        </div>
      </Setup7MainSection>
      <BottomTextSection>
        <PlanInfoSubText>
          Trusted by today’s leading financial companies, Persona provides the
          building blocks to collect, verify, understand, and orchestrate your
          financial data.{<br />}
          <span className="font-medium cursor-pointer text-underline">
            <a
              href="https://withpersona.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </span>
        </PlanInfoSubText>
      </BottomTextSection>
    </Setup2Wrapper>
  );
};

export default AccountSetup7;
