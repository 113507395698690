import { useQuery } from "@tanstack/react-query";
import { useContracts } from "./use-contracts";
import { useMemo, useEffect } from "react";
import { getContractById } from "pages/contracts/actions";

export const useContract = (
  id,
  { queryKey = "contract", queryFn = getContractById, ...config } = {}
) => {
  const {
    data: contracts,
    isLoading: isLoadingContracts,
    ...rest
  } = useContracts({
    ...config,
    placeholderData: [],
  });

  const memoizedContract = useMemo(
    () => contracts.find((contract) => contract.contract_id === id),
    [contracts, id]
  );
  const {
    data: contract,
    isLoading: isLoadingContract,
    ...queryRest
  } = useQuery({
    queryKey: [queryKey, id],
    queryFn: () => queryFn(id),
    enabled: !!id,
  });

  const finalContractData = memoizedContract || contract;

  return {
    ...rest,
    ...queryRest,
    isLoading: isLoadingContracts || isLoadingContract,
    data: finalContractData,
  };
};
