import { useMutation, useQueryClient } from "@tanstack/react-query";
import { processInvoiceImage } from "api/v1-jwt/invoice/invoice";

export const useProcessInvoiceImage = () => {
  const query = useQueryClient();

  return useMutation({
    mutationFn: processInvoiceImage,
    onSuccess: () => {
      query.invalidateQueries(["invoices"]);
    },
  });
};
