import { useHistory, useLocation } from "react-router-dom";
import { useInvoice } from "./use-invoice";

export function useInvoiceDrawer() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const invoiceId = queryParams.get("open_details");
  const { data: invoice, ...rest } = useInvoice({
    invoiceId,
    enabled: !!invoiceId,
  });

  const handleOpen = (id) => {
    if (queryParams.has("open_details")) {
      queryParams.delete("open_details");
    }

    queryParams.set("open_details", id);
    history.push({
      search: queryParams.toString(),
    });
  };

  const handleClose = () => {
    if (queryParams.has("open_details")) {
      queryParams.delete("open_details");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  return {
    open: queryParams.has("open_details"),
    drawerData: invoice,
    handleClose,
    handleOpen,
    ...rest,
  };
}
