import * as actionTypes from "../pages/bills/utils/actionTypes";

const initialState = () => ({
  file: null,
  contact: null,
  billing: null,
});

const billsReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FILE:
      return {
        ...state,
        file: payload,
      };
    case actionTypes.SET_CONTACT:
      return {
        ...state,
        contact: payload,
      };
    case actionTypes.SET_ACCOUNT:
      return {
        ...state,
        account: payload,
      };
    case actionTypes.SET_BILLING:
      return {
        ...state,
        billing: payload,
      };
    default:
      return state;
  }
};

export default billsReducer;
