import { useQuery } from "@tanstack/react-query";
import { fetchContacts } from "api/contact/contact";

// TODO: Should have stale time of Infinity once the creation of a contact
// is being handled by mutation hook that refetchs the list after success
export const useContacts = ({ queryFn, queryKey, ...rest } = {}) => {
  return useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContacts,
    placeholderData: [],
    ...rest,
  });
};