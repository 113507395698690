import { ACCOUNT_INFO, SETTING_DATA } from "pages/customer/signup/actionTypes";
import {
  BOND_LINKED_ACCOUNT,
  PERSONA_DATA,
  SUBSCRIPTION,
  PERSONA_INQUIRY_ID,
  BOND_BUSINESS_ID,
  LOGO_UPDATE
} from "../pages/setup/actionTypes";

const initialState = {
  subscription: null,
  bondLinkedAccount: null,
  personaData: null,
  accountInfo: null,
  settingData: null,
  inquiryId: null,
  businessId: null,
};

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: payload,
      };
    case SETTING_DATA:
      return {
        ...state,
        settingData: payload,
      };
    case SUBSCRIPTION:
      return {
        ...state,
        subscription: payload,
      };
    case BOND_LINKED_ACCOUNT:
      return {
        ...state,
        bondLinkedAccount: payload,
      };
    case PERSONA_DATA:
      return {
        ...state,
        personaData: payload,
      };
    case PERSONA_INQUIRY_ID:
      return {
        ...state,
        inquiryId: payload,
      };
    case BOND_BUSINESS_ID:
      return {
        ...state,
        businessId: payload,
      };
    case LOGO_UPDATE:
      return {
        ...state,
        settingData: {
          ...state.settingData,  // Copy the existing settingData properties
          logo: payload,    // Update the logo property with the new value
        },
      };
    default:
      return state;
  }
};

export default accountReducer;
