import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowLeft } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import checked_icon from "assets/images/checkbox-checked.png";
import { subscription_options } from "pages/demo";
import { updateProfile } from "../actions";
import {
  CardBody,
  SelectedCardHeader,
  CardHeaderLabel,
  MainDescription,
  MainTitle,
  PlanInfo,
  PlanInfoSubText,
  SelectPlanCard,
  SelectPlanSection,
  AutoPayLabel,
  SubmitBtn,
  BottomTextSection,
  Setup2Wrapper,
  BackBtn,
  BackBtnLabel,
  Setup4MainSection,
} from "../styled";

const AccountSetup4 = ({ step, setStep }) => {
  const { subscription } = useSelector((state) => state.account);
  // const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (subscription && subscription !== "") {
      const filteredData = subscription_options.filter(
        (item) => item.value === subscription
      );
      setSubscriptionData(filteredData[0]);
    }
  }, [subscription, setStep]);

  const handleNextSetup = async () => {
    setSubmitLoading(true);
    const payload = { account_setup_step: 5 };
    await updateProfile(payload).then((res) => {
      setSubmitLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
    <Setup2Wrapper>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup4MainSection>
        <MainTitle>Confirm Your Subscription</MainTitle>
        <MainDescription>
          Your Bank has been securely connected with Plaid.
        </MainDescription>
        <SelectPlanSection>
          <SelectPlanCard>
            <SelectedCardHeader>
              <CardHeaderLabel className="white-color">
                {subscriptionData?.type}{" "}
                <AutoPayLabel className="white-color">(Autopay)</AutoPayLabel>
              </CardHeaderLabel>
              <img src={checked_icon} alt="check" width="16px" height="16px" />
            </SelectedCardHeader>
            <CardBody>
              <PlanInfo>${subscriptionData?.price} / month</PlanInfo>
              <PlanInfoSubText>{subscriptionData?.description}</PlanInfoSubText>
            </CardBody>
          </SelectPlanCard>
        </SelectPlanSection>
        <PlanInfoSubText className="pb-25">
          Upon completion of the 14-day trial period, your selected plan amount
          will be charged. However, should you decide to terminate your
          subscription prior to the trial's conclusion, no charges will be
          incurred. Automatic payment processing will be conducted using the
          pay-by-bank payment information you provide during account set up. Our
          customer support team is available to address any inquiries or
          concerns regarding billing or subscriptions. Thank you for entrusting
          Pocketbook with your business management and cost savings needs.{" "}
          <span className="font-medium">
            Please note that annual plans are non-refundable, and monthly plans
            will be billed on a recurring basis.
          </span>
        </PlanInfoSubText>
        <div>
          <SubmitBtn onClick={handleNextSetup}>
            Subscribe
            {submitLoading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </SubmitBtn>
        </div>
      </Setup4MainSection>
      <BottomTextSection>
        <PlanInfoSubText>
          By clicking Subscribe, you authorize Pocketbook to debit your linked
          bank account according to the terms of your subscription.
        </PlanInfoSubText>
      </BottomTextSection>
    </Setup2Wrapper>
  );
};

export default AccountSetup4;
