import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateContract } from "api/contract/contract";

/**
 * @typedef {Object} SuccessRes
 * @property {string} cognito_id
 * @property {string} contract_id
 * @property {string} status
 */

/**
 * @param {Object} params
 * @param {string} params.contractId                  - The contract id
 * @param {string} [params.contract_name]             - The contract name
 * @param {string} [params.contact_id]                - The contact id associated with the contract
 * @param {string} [params.contract_full_location]    - The contract full location
 * @param {string} [params.contract_status]           - The contract status
 * @param {boolean} [params.active]                   - If the contract is active or not
 * @throws Will throw an error if status code is >= 400 and < 600
 * @returns {Promise<SuccessRes>} A promise to the settings object
 */
const mutationFn = async ({ contractId, ...payload }) => {
  await updateContract(contractId, payload);
};

export const useUpdateContract = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries(["contracts"]);
    },
  });
};
