import api from "../config/api";

export const createNewUser = async (payload, contactId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    encodeURI(
      `/tenancy/clients/${clientCognitoId}/user_signup?from_contact_id=${contactId}`
    ),
    payload
  );

  return data;
};

export const adminSignUp = async ({ apiKey, ...payload }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    encodeURI(`/tenancy/clients/${clientCognitoId}/admin_signup/${apiKey}`),
    { ...payload, platform_client_id: clientCognitoId }
  );

  return data;
};

export const getTenancyInfo = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/tenancy/clients/${clientCognitoId}?user_id=${userId}`
  );

  return data;
};

export const updateTenancyInfo = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/tenancy/clients/${clientCognitoId}`,
    payload
  );

  return data;
};

export const getPlatformUsers = async (userType) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const userTypes = ["owner", "admin", "customer"];
  const query =
    userType && userTypes.includes(userType) ? `?user_type=${userType}` : "";
  const { data } = await api.get(
    `/tenancy/clients/${clientCognitoId}/users${query}`
  );

  return data;
};

export const getPlatformUser = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }
  const { data } = await api.get(
    `/tenancy/clients/${clientCognitoId}/users/${userId}`
  );

  return data;
};

export const sendAdminInvite = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/tenancy/clients/admin_invite`, {
    ...payload,
    platform_client_id: clientCognitoId,
  });

  return data;
};

export const deleteAdmin = async ({ email, userId }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  await api.post(
    `/auth/sign_out/${clientCognitoId}/${email}?global_sign_out=true`,
    {}
  );

  const { data } = await api.delete(
    `/tenancy/clients/${clientCognitoId}/${userId}`
  );

  return data;
};
