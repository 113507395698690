import { OptionLabel } from "pages/styled";
import PhoneInput from "react-phone-input-2";
import { Field, useFormikContext, ErrorMessage } from "formik";
import { StyledInput } from "../styled";
import { StyledError } from ".";
import { forwardRef } from "react";

const PrimaryContactInformation = forwardRef(({ ...props }, ref) => {
  const { setFieldValue, values, errors, touched } = useFormikContext(); // Use formik context

  return (
    <div ref={ref}>
      <OptionLabel>Primary contact information</OptionLabel>
      <div className="flex gap-4 mb-4">
        <div className="flex flex-col">
          <Field
            readOnly
            name="firstName"
            as={StyledInput}
            onChange={(firstName) => setFieldValue("firstName", firstName)}
            placeholder="First name"
            width="244px"
            mRight="24px"
            mBottom="0px"
            error={errors.firstName && touched.firstName}
          />
          <ErrorMessage
            name="firstName"
            component={StyledError}
            className="error"
          />
        </div>
        <div className="flex flex-col">
          <Field
            readOnly
            name="lastName"
            as={StyledInput}
            onChange={(lastName) => setFieldValue("lastName", lastName)}
            placeholder="Last name"
            width="244px"
            mRight="24px"
            mBottom="0px"
            error={errors.lastName && touched.lastName}
          />
          <ErrorMessage
            name="lastName"
            component={StyledError}
            className="error"
          />
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <Field
          readOnly
          name="email"
          as={StyledInput}
          onChange={(email) => setFieldValue("email", email)}
          placeholder="Email to send invoice to"
          width="512px"
          mBottom="0px"
          error={errors.email && touched.email}
        />
        <ErrorMessage name="email" component={StyledError} className="error" />
      </div>
      <div style={{ width: "512px", paddingBottom: 20 }}>
        <PhoneInput
          readOnly
          country={"us"}
          onlyCountries={["us"]}
          value={values.phone}
          onChange={(phone) => setFieldValue("phone", phone)}
          disableCountryCode
        />
        <ErrorMessage name="phone" component={StyledError} className="error" />
      </div>
    </div>
  );
});

export default PrimaryContactInformation;
