import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Pagination } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import moment from "moment";
import styled from "styled-components";
import { convertThousandSeparator, paginator } from "scripts/functions";
import { getAllTransactions } from "./actions";
import {
  ColumnDetailSection,
  DetailGrid,
  DetailLabel,
  DetailTitle,
  PaginationSection,
  ProgressSection,
  StyledTable,
  TableItem,
  TableLabel,
  TableToolBtn,
} from "./styled";
import { Eye } from "lucide-react";

const TransactionTable = ({ search, setSearch }) => {
  const { settingData } = useSelector((state) => state.account);
  const [transactionList, setTransactionList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  // const [allCheck, setAllCheck] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransactionList();
  }, []);

  useEffect(() => {
    const filteredArr = transactionList.filter((item) => {
      return (
        item?.transaction_from_description
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(search.toLowerCase().replace(/\s+/g, "")) ||
        item?.transaction_to_description
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(search.toLowerCase().replace(/\s+/g, ""))
      );
    });
    setFilteredList(filteredArr);
  }, [search, setSearch, transactionList]);

  const count = Math.ceil(filteredList.length / 10);

  const getTransactionList = async () => {
    setLoading(true);
    await getAllTransactions().then((res) => {
      setLoading(false);
      if (res && res.length > 0) {
        setTransactionList(res);
        setFilteredList(res);
      }
    });
  };

  const handlePaginate = (event, value) => {
    setPage(paginator(filteredList, value, 1).page);
  };

  const handleView = (item) => {
    setSelectedTransaction(item);
  };

  const StyledTableLabel = styled(TableLabel)`
    background-color: ${settingData?.primary_color};
  `;

  return (
    <div>
      <StyledTable>
        <StyledTableLabel>Date</StyledTableLabel>
        <StyledTableLabel>Amount</StyledTableLabel>
        <StyledTableLabel>From</StyledTableLabel>
        <StyledTableLabel>To</StyledTableLabel>
        <StyledTableLabel>Status</StyledTableLabel>
        <StyledTableLabel className="justify-center">View</StyledTableLabel>
        {loading && (
          <ProgressSection>
            <CircularProgress />
          </ProgressSection>
        )}
        {paginator(filteredList, page, 10).data.map((item, index) => (
          <React.Fragment key={"transaction" + index}>
            <>
              <TableItem>
                {moment(item?.transaction_date).format("MM/DD/YYYY")}
              </TableItem>
              <TableItem>
                {item?.transaction_direction === "debit"
                  ? `$${convertThousandSeparator(Number(item?.amount))}`
                  : `-$${convertThousandSeparator(
                      Math.abs(Number(item?.amount))
                    )}`}
              </TableItem>
              <TableItem>{item?.transaction_from_description}</TableItem>
              <TableItem>{item?.transaction_to_description}</TableItem>
              <TableItem>{item?.transaction_status}</TableItem>
              <TableItem className="justify-center">
                <TableToolBtn
                  onClick={() => {
                    handleView(item, index);
                  }}
                >
                  <Eye className="w-5 h-5" />
                </TableToolBtn>
              </TableItem>
            </>
            {selectedTransaction &&
              item?.transaction_id === selectedTransaction?.transaction_id && (
                <ColumnDetailSection>
                  <BsXLg
                    style={{ position: "absolute", top: 23, right: 42 }}
                    onClick={() => setSelectedTransaction(null)}
                  />
                  <div className="flex">
                    <DetailTitle>Details</DetailTitle>
                    <DetailGrid>
                      <DetailLabel>
                        <span className="font-medium">Date:</span>{" "}
                        {moment(selectedTransaction?.transaction_date).format(
                          "MM/DD/YYYY"
                        )}
                      </DetailLabel>
                      <DetailLabel>
                        <span className="font-medium">Amount:</span>{" "}
                        {selectedTransaction?.transaction_direction === "debit"
                          ? `$${convertThousandSeparator(
                              Number(selectedTransaction?.amount)
                            )}`
                          : `-$${convertThousandSeparator(
                              Math.abs(Number(selectedTransaction?.amount))
                            )}`}
                      </DetailLabel>
                      <DetailLabel>
                        <span className="font-medium">From:</span>{" "}
                        {selectedTransaction?.transaction_from_description}
                      </DetailLabel>
                      <DetailLabel>
                        <span className="font-medium">To:</span>{" "}
                        {selectedTransaction?.transaction_to_description}
                      </DetailLabel>
                      <DetailLabel>
                        <span className="font-medium">Status:</span>{" "}
                        {selectedTransaction?.transaction_status}
                      </DetailLabel>
                      <DetailLabel>
                        <span className="font-medium">Method:</span>{" "}
                        {selectedTransaction?.transaction_type}
                      </DetailLabel>
                      {selectedTransaction?.invoice_number !== null && (
                        <DetailLabel>
                          <span className="font-medium">Invoice:</span>
                          <span>{` #${selectedTransaction?.invoice_number}`}</span>
                        </DetailLabel>
                      )}
                      <DetailLabel>
                        <span className="font-medium">Initiated Date:</span>{" "}
                        {moment(selectedTransaction?.initiated_at).format(
                          "MM/DD/YYYY"
                        )}
                      </DetailLabel>
                    </DetailGrid>
                  </div>
                  {/* {selectedTransaction?.invoice_id && (
                    <div>
                      <DetailLabel className="font-medium cursor-pointer text-end">
                        View original invoice
                      </DetailLabel>
                    </div>
                  )} */}
                </ColumnDetailSection>
              )}
          </React.Fragment>
        ))}
      </StyledTable>
      <PaginationSection className="transaction-pagination">
        <Pagination
          count={count}
          page={page}
          onChange={handlePaginate}
          color="success"
        />
      </PaginationSection>
    </div>
  );
};

export default TransactionTable;
