import api from "../config/api";

export const fetchBusinessTypes = async () => {
  const { data } = await api.get("/platform/application/business_type_list");
  return data;
};

export const fetchRoleTitle = async () => {
  const { data } = await api.get("/platform/application/role_title_list");
  return data;
};

export const fetchEstimatedMonthlySales = async () => {
  const { data } = await api.get(
    "/platform/application/est_monthly_sales_list"
  );
  return data;
};

export const fetchNaicsList = async () => {
  const { data } = await api.get("/platform/application/naics_list");
  return data;
};

export const getApplicationStep = async (userId, step, applicationId) => {
  const { data } = api.get(
    `/platform/application/submitted/${userId}/step/${step}/${applicationId}`
  );
  return data;
};

export const createApplicationStep = async (
  userId,
  step,
  applicationId,
  payload
) => {
  const { data } = api.post(
    `/platform/application/submitted/${userId}/step/${step}/${applicationId}`,
    payload
  );
  return data;
};

export const getSubmittedApplication = async (userId, applicationId) => {
  const { data } = await api.get(
    `/platform/application/submitted/${userId}/${applicationId}`
  );
  return data;
};
