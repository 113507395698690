import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import { UploadCloud } from "lucide-react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as actionTypes from "../utils/actionTypes";

const fileTypes = ["JPEG", "PNG", "PDF"];

export const BillsDragAndDrop = ({ file, setFile }) => {

  const handleChange = (newFile) => {
    setFile(newFile);
    console.log(newFile);
  };

  return (
    <UploaderContainer>
      <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        onTypeError={(err) => toast(`Error: ${err}`)}
        types={fileTypes}
        children={
          <div class="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:border-skin-primary dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-skin-primary/10 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadCloud className="w-8 h-8 mb-4 text-gray-600" />
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {file ? (
                    <span class="font-semibold text-skin-primary">
                      {file.name}
                    </span>
                  ) : (
                    <span class="font-semibold text-skin-primary">
                      Click to upload or drag and drop
                    </span>
                  )}
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">PNG, JPEG, OR PDF</span> (MAX
                  of 1MB)
                </p>
              </div>
            </label>
          </div>
        }
      />
    </UploaderContainer>
  );
};

const UploaderContainer = styled.div`
  margin-top: 10px;
`;
