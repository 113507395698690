import { CircularProgress } from "@mui/material";
import { convertThousandSeparator } from "scripts/functions";
import { FeatureCardLabel, FeatureCardBalance } from "./styled";
import styled from "styled-components";
import { Button, textStyles } from "ui";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { breakpoints } from "ui/breakpoints";
import { useAuth } from "providers/auth";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useAccountBalance } from "hooks/account/use-account-balance";

const RenderBalanceCard = (className, balance, status) => {
  if (status === "approved") {
    return (
      <div className={className}>
        <div>
          <FeatureCardLabel>Balance</FeatureCardLabel>
          <FeatureCardBalance>
            ${convertThousandSeparator(balance)}
          </FeatureCardBalance>
        </div>
      </div>
    );
  }
  if (
    status === "pending" ||
    status === "NOT_FOUND" ||
    status === "not_started"
  ) {
    return RenderKYB();
  }
  // TODO: THIS MUST BE UPDATED
  if (status === "rejected") {
    return (
      <div className={className}>
        <div>
          <FeatureCardLabel>
            Your payment processing account application has been declined.
            Please see email for additional details.
          </FeatureCardLabel>
        </div>
      </div>
    );
  }
  if (
    status === "submitted" ||
    status === "manual_review" ||
    status === "processing"
  ) {
    return (
      <div className={className}>
        <div>
          <FeatureCardLabel>
            Payment processing will be available soon. Your information is being
            processed and is typically approved within 3-5 business days.
          </FeatureCardLabel>
        </div>
      </div>
    );
  }
  if (status === "locked") {
    return (
      <div className={className}>
        <div>
          <FeatureCardLabel>
            Your account is currently under review and transactions cannot be
            completed at this time. Our team will reach out to you through email
            with more information.
          </FeatureCardLabel>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${className} flex justify-center`}>
        <FeatureCardLabel>
          <CircularProgress />
        </FeatureCardLabel>
      </div>
    );
  }
};

const RenderKYB = () => {
  const history = useHistory();
  const { userRole } = useAuth();

  if (userRole === "admin") {
    return (
      <CardWrapper>
        <CardTitle>Processing account pending.</CardTitle>
        <CardSubtitle>
          Your processing account has not been set up yet. This action must be
          started by the account owner.
        </CardSubtitle>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <CardTitle>Start Receiving Payments!</CardTitle>
      <CardSubtitle>
        Set up your processing account in just minutes.
      </CardSubtitle>

      <Button variant="green" onClick={() => history.push("/kyb")}>
        Begin
      </Button>
    </CardWrapper>
  );
};

export const DashboardBalanceCard = ({ className }) => {
  const {
    data: tenancy,
    isLoading: isTenancyLoading,
    error: tenancyError,
  } = useTenancy();
  const { data: accountBalance, isLoading: isBalanceLoading } =
    useAccountBalance();

  if (isTenancyLoading || isBalanceLoading)
    return (
      <div className={`${className} flex justify-center`}>
        <FeatureCardLabel>
          <CircularProgress />
        </FeatureCardLabel>
      </div>
    );

  if (tenancyError) {
    return (
      <div className={`${className} flex justify-center`}>
        <FeatureCardLabel>Error loading account info</FeatureCardLabel>
      </div>
    );
  }

  return RenderBalanceCard(
    className,
    accountBalance?.available_balance,
    tenancy?.baas_status
  );
};

const CardTitle = styled.div`
  ${textStyles.body.b3}
`;

const CardSubtitle = styled.div`
  ${textStyles.body.b2}
`;

const CardWrapper = styled.div`
  display: grid;
  gap: 20px;
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 20px 24px;

  @media (${breakpoints.sm}) {
    max-width: 375px;
    min-width: 375px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 20px;
`;
