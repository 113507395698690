import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import checked_icon from "assets/images/checkbox-checked.png";
import check_base from "assets/images/checkbox-base-1.png";
import { subscription_options } from "pages/demo";
import { updateProfile } from "../actions";
import {
  CardBody,
  SelectedCardHeader,
  CardHeader,
  CardHeaderLabel,
  MainDescription,
  MainTitle,
  PlanInfo,
  PlanInfoSubText,
  SelectPlanCard,
  SelectPlanSection,
  AutoPayLabel,
  SubmitBtn,
  BottomTextSection,
  Setup2MainSection,
  Setup2Wrapper,
  SelectedPlanCard,
  BackBtn,
  BackBtnLabel,
} from "../styled";

const AccountSetup2 = ({ step, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState(subscription_options);

  const handleSelectFeature = () => {
    const updatedList = featureList.map((feature) => {
      return {
        ...feature,
        checked: !feature.checked,
      };
    });
    setFeatureList(updatedList);
  };

  const handleNextSetup = async () => {
    setLoading(true);
    const selectedFeature = featureList.find((feature) => feature.checked);
    const payload = {
      account_setup_step: 3,
      subscription_plan: selectedFeature.value,
    };
    await updateProfile(payload).then((res) => {
      if (res && res.subscription_plan !== "") {
        setLoading(false);
        setStep(res.account_setup_step);
      }
    });
  };

  return (
    <Setup2Wrapper>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup2MainSection>
        <MainTitle>Choose Your Subscription</MainTitle>
        <MainDescription>
          Pocketbook keeps things simple. We offer one easy subscription that
          gives you access to all our features. All you need to do is select
          monthly or annual billing. Plus, your first 14-days are free!
        </MainDescription>
        <SelectPlanSection>
          {featureList.map((feature, index) =>
            feature.checked ? (
              <SelectedPlanCard key={feature.type}>
                <SelectedCardHeader>
                  <CardHeaderLabel className="white-color">
                    {feature.type}{" "}
                    <AutoPayLabel className="white-color">
                      (Autopay)
                    </AutoPayLabel>
                  </CardHeaderLabel>
                  <img
                    src={checked_icon}
                    alt="checked"
                    width="16px"
                    height="16px"
                  />
                </SelectedCardHeader>
                <CardBody>
                  <PlanInfo>${feature.price} / month</PlanInfo>
                  <PlanInfoSubText>{feature.description}</PlanInfoSubText>
                </CardBody>
              </SelectedPlanCard>
            ) : (
              <SelectPlanCard
                key={feature.type}
                onClick={() => handleSelectFeature()}
              >
                <CardHeader>
                  <CardHeaderLabel className="black-color">
                    {feature.type}{" "}
                    <AutoPayLabel className="black-color">
                      (Autopay)
                    </AutoPayLabel>
                  </CardHeaderLabel>
                  <img
                    src={check_base}
                    alt="check"
                    width="16px"
                    height="16px"
                  />
                </CardHeader>
                <CardBody>
                  <PlanInfo>${feature.price} / month</PlanInfo>
                  <PlanInfoSubText>{feature.description}</PlanInfoSubText>
                </CardBody>
              </SelectPlanCard>
            )
          )}
        </SelectPlanSection>
        <div>
          <SubmitBtn onClick={handleNextSetup}>
            Securely connect your bank to pay
            {loading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </SubmitBtn>
        </div>
      </Setup2MainSection>
      <BottomTextSection>
        <PlanInfoSubText className="font-medium pb-10">
          Why Pay-by-Bank?
        </PlanInfoSubText>
        <PlanInfoSubText>
          Pocketbook offers a cost-effective payment method, pay-by-bank, which
          saves your business money compared to the up to 3% plus transaction
          fees charged by credit card processors.
        </PlanInfoSubText>
      </BottomTextSection>
    </Setup2Wrapper>
  );
};

export default AccountSetup2;
