import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import upload_icon from "assets/images/dashboard/upload.png";
import {
  FormField,
  FormLabel,
  UploadeLogoSection,
  UploadTitle,
  UploadSubTitle,
} from "../settings/styled";
import { cn } from "utils/classnames";

const StyledImageUploader = ({
  formLabel,
  formSubLabel,
  handleChangeImage,
  previewImg,
  uploadedImg,
  size,
}) => {
  // const [cnt, setCnt] = useState(0);

  // useEffect(() => {
  //   if (!previewImg) setCnt((preCnt) => preCnt + 1);
  // }, [previewImg]);
  return (
    <FormField className="pb-20">
      <div>
        <FormLabel className="pb-10">{formLabel}</FormLabel>
        <FormLabel className="font-normal">{formSubLabel}</FormLabel>
      </div>
      <FileUploader
        multiple={true}
        handleChange={handleChangeImage}
        name="file"
        types={["PNG", "JPG", "JPEG", "SVG"]}
        children={
          <UploadeLogoSection>
            {previewImg !== null ? (
              <div>
                <img
                  src={URL.createObjectURL(previewImg)}
                  alt="preview-logo"
                  width="auto"
                  height="90px"
                  className={cn("aspect-[4/3]", {
                    "aspect-[19/9]": size === "1920x800px",
                  })}
                />
                <UploadSubTitle>Preview Mode</UploadSubTitle>
              </div>
            ) : (
              <div>
                {uploadedImg !== null && uploadedImg !== "" ? (
                  <div>
                    <img
                      src={`https://cdn.pocketbook.tech/${uploadedImg}`}
                      alt="uploaded logo"
                      width="auto"
                      height="130px"
                      className={cn("aspect-[4/3]", {
                        "aspect-[19/9]": size === "1920x800px",
                      })}
                    />
                    <UploadSubTitle>Uploaded Image</UploadSubTitle>
                  </div>
                ) : (
                  <div>
                    <img
                      src={upload_icon}
                      width="40px"
                      height="40px"
                      alt="upload"
                    />
                    <UploadTitle>
                      <span className="font-medium">Click to upload</span> or
                      drag and drop
                    </UploadTitle>
                    <UploadSubTitle>
                      PNG, JPG or SVG (max. {size})
                    </UploadSubTitle>
                  </div>
                )}
              </div>
            )}
          </UploadeLogoSection>
        }
      />
    </FormField>
  );
};

export default StyledImageUploader;
