import { useState, useCallback, useRef, useEffect } from "react";

function useWebSocket(url) {
  const [isConnected, setIsConnected] = useState(false);
  const [contract, setContract] = useState("");
  const [isButtonAvailable, setIsButtonAvailable] = useState(false);
  const socketRef = useRef(null);
  const disconnectTimerRef = useRef(null);

  const resetDisconnectTimer = useCallback(() => {
    if (disconnectTimerRef.current) {
      clearTimeout(disconnectTimerRef.current);
    }
    disconnectTimerRef.current = setTimeout(() => {
      console.log("No message received for 2 seconds, disconnecting...");
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
        setIsConnected(false);
        setIsButtonAvailable(true);
      }
    }, 2000);
  }, []);

  const connect = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (socketRef.current) {
        console.log("WebSocket is already open.");
        resolve();
        return;
      }

      socketRef.current = new WebSocket(url);
      socketRef.current.onopen = () => {
        console.log("WebSocket connected");
        setIsConnected(true);
        resolve();
      };
      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(error);
      };
      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(JSON.parse(event.data).body).data;
        if (message) {
          setContract((prev) => prev + message);
          resetDisconnectTimer();
        }
        console.log(event);
      };
      socketRef.current.onclose = () => {
        console.log("WebSocket disconnected");
        setIsConnected(false);
        setIsButtonAvailable(true);
      };
    });
  }, [url, resetDisconnectTimer]);

  const sendMessage = useCallback(
    async (message) => {
      if (
        !socketRef.current ||
        socketRef.current.readyState !== WebSocket.OPEN
      ) {
        console.log("WebSocket is not connected. Waiting for connection...");
        await connect()
          .then(() => {
            console.log("Connection established, sending message.");
            socketRef.current.send(JSON.stringify(message));
          })
          .catch((error) => {
            console.error("Failed to connect WebSocket:", error);
          });
      } else {
        console.log("WebSocket is connected, sending message.");
        socketRef.current.send(JSON.stringify(message));
      }
    },
    [connect]
  );

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setIsConnected(false);
      setIsButtonAvailable(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      disconnect();
      if (disconnectTimerRef.current) {
        clearTimeout(disconnectTimerRef.current);
      }
    };
  }, [disconnect]);

  return {
    connect,
    sendMessage,
    disconnect,
    isConnected,
    contract,
    setContract,
    isButtonAvailable,
  };
}

export default useWebSocket;
