import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import paypal_logo from "assets/images/paypal-logo.png";
import venmo_logo from "assets/images/venmo-logo1.png";
import pocketbook_logo from "assets/images/pocketbook-logo-black.jpg";
import { convertThousandSeparator } from "scripts/functions";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useProfile } from "hooks/profile/use-profile";
import { formatPhoneUS } from "utils/functions";

const styles = StyleSheet.create({
  primaryLabelSection: {
    padding: "20px 10px 16px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  primaryLabel: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#000000",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  secondaryLabelSection: {
    gap: "20px",
    padding: "2px 18px",
    display: "flex",
    flexDirection: "row",
  },
  secondaryLabel: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#ffffff",
    width: "100%",
  },
  headerTitle: {
    fontSize: "33px",
    fontWeight: 400,
    textTransform: "uppercase",
    color: "#000000",
    paddingBottom: 26,
    textAlign: "right",
  },
  detailRow: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  invoiceTable: {
    width: "100%",
    display: "flex",
    padding: "2px",
    marginTop: "50px",
    flexDirection: "column",
  },
  tableLabel: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "6px 10px",
    fontWeight: 400,
    fontSize: "12px",
    color: "#ffffff",
    width: "100%",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  tableItem: {
    display: "flex",
    alignContent: "center",
    position: "relative",
    backgroundColor: "#ffffff",
    padding: "6px 10px",
    fontWeight: 400,
    fontSize: 12,
    color: "#000000",
    borderBottom: "1px solid #000000",
    borderRight: "1px solid #000000",
    width: "100%",
  },
  teamInfo: {
    position: "absolute",
    left: "40px",
    bottom: "100px",
  },
  bottomText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000000",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    bottom: "20px",
  },
  paymentIcon1: {
    position: "absolute",
    bottom: "20px",
    right: "16px",
  },
  paymentIcon2: {
    position: "absolute",
    bottom: "20px",
    right: "60px",
  },
});

export const InvoicePreview = () => {
  const { settingData } = useSelector((state) => state.account);
  const { data: profile } = useProfile();
  const { data: tenancy } = useTenancy();
  const { values: invoice } = useFormikContext();

  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page size="A4">
          <View style={{ padding: 26 }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <View style={{ width: "100%", marginRight: 10 }}>
                <View style={{ marginLeft: 0, marginRight: "auto" }}>
                  <Image
                    src={
                      settingData?.logo
                        ? `https://cdn.pocketbook.tech/${settingData?.logo}`
                        : pocketbook_logo
                    }
                    style={{ height: "40px" }}
                  />
                </View>
                <View style={styles.primaryLabelSection}>
                  <Text style={styles.primaryLabel}>
                    {profile?.business_name}
                  </Text>
                  <Text style={styles.primaryLabel}>
                    {profile?.business_email}
                  </Text>
                  <Text style={styles.primaryLabel}>
                    {formatPhoneUS(profile?.business_phone)}
                  </Text>
                </View>
                <View
                  style={[
                    styles.secondaryLabelSection,
                    {
                      backgroundColor: settingData?.primary_color,
                      marginBottom: "10px",
                    },
                  ]}
                >
                  <Text style={styles.secondaryLabel}>Bill To:</Text>
                </View>
                <Text style={styles.primaryLabel}>{invoice.businessName}</Text>
                <Text style={styles.primaryLabel}>
                  {invoice.firstName + " " + invoice.lastName}
                </Text>
                <Text style={styles.primaryLabel}>
                  {invoice?.mailingAddr && invoice?.mailingAddr + ", "}
                  {invoice.city && invoice?.city + ", "}
                  {invoice?.state && invoice?.state}
                  {invoice?.zipCode && " " + invoice?.zipCode}
                </Text>
              </View>
              <View style={{ width: "100%" }}>
                <Text style={styles.headerTitle}>Invoice</Text>
                <View
                  style={[
                    styles.secondaryLabelSection,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  <Text style={styles.secondaryLabel}>Invoice No.</Text>
                </View>
                <View style={styles.detailRow}>
                  <Text style={styles.primaryLabel}>
                    {/* {invoice?.invoice_info.invoice_number} */}
                  </Text>
                </View>
                <View
                  style={[
                    styles.secondaryLabelSection,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  <Text style={[styles.secondaryLabel, { width: 300 }]}>
                    Customer ID.
                  </Text>
                </View>
                <View style={styles.detailRow}>
                  <Text
                    style={[
                      styles.primaryLabel,
                      { color: `${invoice?.contact_id ? "black" : "red"}` },
                    ]}
                  >
                    {invoice?.contact_id ? invoice.contact_id : "Not selected"}
                  </Text>
                </View>

                <View
                  style={[
                    styles.secondaryLabelSection,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  <Text style={styles.secondaryLabel}>Date</Text>
                  <Text style={styles.secondaryLabel}>Due Date</Text>
                </View>
                <View style={styles.detailRow}>
                  <Text
                    style={[
                      styles.primaryLabel,
                      {
                        width: "100%",
                        color: `${invoice?.sendDate ? "black" : "red"}`,
                      },
                    ]}
                  >
                    {invoice?.sendDate
                      ? moment(invoice?.sendDate).format("MM/DD/YYYY")
                      : "Not selected"}
                  </Text>
                  <Text
                    style={[
                      styles.primaryLabel,
                      {
                        width: "100%",
                        color: `${invoice?.dueDate ? "black" : "red"}`,
                      },
                    ]}
                  >
                    {invoice?.dueDate
                      ? moment(invoice?.dueDate.$d).format("MM/DD/YYYY")
                      : "Not selected"}
                  </Text>
                </View>

                <View
                  style={[
                    styles.secondaryLabelSection,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  <Text style={styles.secondaryLabel}>Client Note</Text>
                </View>
                <View style={styles.detailRow}>
                  <Text style={styles.primaryLabel}>{invoice?.notes}</Text>
                </View>
              </View>
            </View>
            <View style={styles.invoiceTable}>
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableLabel,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  Description
                </Text>
                <Text
                  style={[
                    styles.tableLabel,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    styles.tableLabel,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  Price
                </Text>
                <Text
                  style={[
                    styles.tableLabel,
                    {
                      backgroundColor: settingData?.primary_color,
                    },
                  ]}
                >
                  Amount
                </Text>
              </View>
              {invoice?.lineItems.map((item, index) => (
                <View style={styles.tableRow} key={"invoice" + index}>
                  <Text
                    style={[
                      styles.tableItem,
                      {
                        borderLeft: "1px solid #000000",
                      },
                    ]}
                  >
                    {item.description}
                  </Text>
                  <Text style={styles.tableItem}>{item.quantity}</Text>
                  <Text style={styles.tableItem}>
                    {" "}
                    ${convertThousandSeparator(item.unit_price)}
                  </Text>
                  <Text style={styles.tableItem}>
                    ${convertThousandSeparator(item.item_total)}
                  </Text>
                </View>
              ))}
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderRight: "0px",
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text style={styles.tableItem}>SubTotal</Text>
                <Text style={styles.tableItem}>
                  ${convertThousandSeparator(invoice?.subTotal)}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderRight: "0px",
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text style={styles.tableItem}>Tax</Text>
                <Text style={styles.tableItem}>
                  ${convertThousandSeparator(invoice?.invoiceTax)}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderRight: "0px",
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text style={styles.tableItem}>Fee</Text>
                <Text style={styles.tableItem}>
                  ${convertThousandSeparator(invoice?.transactionFeeAmount)}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderRight: "0px",
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text
                  style={[
                    styles.tableItem,
                    {
                      borderBottom: "0px",
                    },
                  ]}
                ></Text>
                <Text style={styles.tableItem}>Total</Text>
                <Text style={styles.tableItem}>
                  ${convertThousandSeparator(invoice?.invoiceTotal)}
                </Text>
              </View>
            </View>
          </View>
          <Text style={styles.bottomText}>{tenancy?.subdomain}</Text>
          <View style={styles.paymentIcon1}>
            <Image
              src={paypal_logo}
              style={{
                height: "30px",
                width: "30px",
                marginRight: 4,
              }}
            />
          </View>
          <View style={styles.paymentIcon2}>
            <Image src={venmo_logo} style={{ height: "30px", width: "30px" }} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
