import { login, logout } from "api/auth/auth";
import { useLocalStorage } from "hooks/use-local-storage";
import { createContext, useContext, useState } from "react";

// Initial state something like it
const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

const authContext = createContext();
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useTenancyinfo() {
  const { user } = useContext(authContext);
  return user.client_tenancy_info;
}

function useProvideAuth() {
  // While no api route for ME exists, keep user info in local storage
  // Duplicate values to no break things
  const [token, setToken] = useLocalStorage("AuthToken", null);
  const [accessToken, setAccessToken] = useLocalStorage("AccessToken", null);
  const [cognitoId, setCognitoId] = useLocalStorage("CognitoId", null);
  const [userRole, setUserRole] = useLocalStorage("UserRole", null);
  const [user, setUser] = useLocalStorage("pb_user", null);
  const [tenancyInfo, setTenancyInfo] = useLocalStorage("tenancy_info", null);

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState(null);

  const [isSigningOut, setIsSigningOut] = useState(false);
  const [signOutError, setSignOutError] = useState(null);

  const signin = async (payload, config) => {
    if (user) {
      console.log("[SIGNIN FUNC] User already signed in. Logout first.");
      return;
    }
    if (isSigningIn || isSigningOut) {
      console.log(
        "[SIGNIN FUNC] Auth action taking place. Wait until it is complete"
      );
      return;
    }

    setIsSigningIn(true);

    try {
      const data = await login(payload);
      setCognitoId(data.client_tenancy_info.user_id);
      setToken(data.cognito_auth_info.RefreshToken);
      setAccessToken(data.cognito_auth_info.AccessToken);
      setUserRole(data.client_tenancy_info.user_type);
      setTenancyInfo(data.client_tenancy_info);
      setUser(data);

      if (config && config.onSuccess) {
        config.onSuccess(data, payload);
      }
    } catch (error) {
      setSignInError(error, payload);
      if (config && config.onError) {
        config.onError(error);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const signout = async (config) => {
    if (!user) {
      console.log("[SIGNOUT FUNC] User already signed out. Login first.");
      return;
    }
    if (isSigningIn || isSigningOut) {
      console.log(
        "[SIGNIN FUNC] Auth action taking place. Wait until it is complete"
      );
      return;
    }

    setIsSigningOut(true);
    try {
      const data = await logout({ cognitoId, token });
      setCognitoId(null);
      setToken(null);
      setAccessToken(null);
      setUserRole(null);
      setTenancyInfo(null);
      setUser(null);
      localStorage.setItem("BAAS_WARNING_CLOSED", JSON.stringify(false));

      if (config && config.onSuccess) {
        config.onSuccess(data);
      }
    } catch (error) {
      setSignOutError(error);
      if (config && config.onError) {
        config.onError(error);
      }
    } finally {
      setIsSigningOut(false);
    }
  };

  return {
    token,
    accessToken,
    cognitoId,
    userRole,
    user,
    signin,
    isSigningIn,
    signInError,
    signout,
    isSigningOut,
    signOutError,
  };
}
