import { useState, useEffect } from "react";
import styled from "styled-components";
import { BsArrowLeft } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import { updateProfile } from "../actions";
import {
  Setup1MainSection,
  MainDescription,
  MainTitle,
  SubmitBtn,
  BackBtn,
  BackBtnLabel,
} from "../styled";

const Setup5Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
`;

const AccountSetup5 = ({ step, setStep }) => {
  const [loading, setLoading] = useState(false);

  const handleNextSetup = async () => {
    setLoading(true);
    const payload = {
      account_setup_step: 6,
    };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
    <Setup1MainSection>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup5Wrapper>
        <MainTitle>Success! Your subscription is active.</MainTitle>
        <MainDescription>
          The final step is to set up Your Pocketbook so that you can start
          accepting payments.
        </MainDescription>
        <SubmitBtn onClick={handleNextSetup}>
          Setup
          {loading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </SubmitBtn>
      </Setup5Wrapper>
    </Setup1MainSection>
  );
};

export default AccountSetup5;
