import { useMemo } from "react";
import pocketbook_logo from "assets/images/pocketbook-logo.png";
import { shortcut_list } from "pages/demo";
import { MainTitle, MainSubTitle, OptionLabel } from "pages/styled";
import { Button } from "components/ui/button-tw";
import { ChevronDown } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from "providers/auth";
import { BiLock } from "react-icons/bi";

const ClientMainHeader = ({ title, subTitle, description }) => {
  return (
    <div className="space-y-4 mb-6">
      <div className="hidden md:flex justify-end">
        <img src={pocketbook_logo} alt="logo" width="156px" height="26px" />
      </div>
      <div className=" space-y-4">
        <div className="flex justify-between items-center mb-3">
          <MainTitle>{title}</MainTitle>
          <Shortcuts />
        </div>
        {subTitle && <MainSubTitle>{subTitle}</MainSubTitle>}
        {description && (
          <OptionLabel style={{ width: "100%", maxWidth: 550 }}>
            {description}
          </OptionLabel>
        )}
      </div>
    </div>
  );
};

function Shortcuts() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const handleChange = (value) => history.push(`/${value}`);

  const selected = useMemo(
    () => shortcut_list.find((v) => `/${v.value}` === location.pathname),
    [location]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="default"
          className="bg-skin-primary hover:bg-skin-primary/90"
        >
          Shortcuts <ChevronDown className="w-5 h-5 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Shortcuts</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={selected?.value}
          onValueChange={handleChange}
        >
          {shortcut_list.map((v) => (
            <DropdownMenuRadioItem
              key={v.value}
              value={v.value}
              className="py-2"
              disabled={
                v.value === "move-money" &&
                user?.cognito_user_info?.UserRole === "admin"
              }
            >
              <span>{v.label}</span>
              {v.value === "move-money" &&
                user?.cognito_user_info?.UserRole === "admin" && (
                  <BiLock className="w-5 h-5 ml-2" />
                )}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ClientMainHeader;
