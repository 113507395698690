import { useState } from "react";
import { Checkbox, CircularProgress } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import { updateProfile } from "../actions";
import {
  Setup1MainSection,
  MainDescription,
  MainTitle,
  SubmitBtn,
  BackBtn,
  BackBtnLabel,
} from "../styled";

const Setup8Wrapper = styled.div`
  width: 100%;
  max-width: 750px;
`;
const Setup8Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  padding-bottom: 24px;
`;
const CheckboxSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 30px;
`;
const CheckboxLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  line-height: 16px;
`;
const BottomText = styled.div`
  position: absolute;
  left: 100px;
  bottom: 47px;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;

  @media (max-width: 420px) {
    position: initial;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  @media (max-height: 670px) {
    position: initial;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

const AccountSetup8 = ({ step, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState({
    electornic_communication_consent: false,
    business_account_agreement: false,
    privacy_policy: false,
    pocketbook_privacy_policy: false,
  });

  const handleCheckboxChange = (name, checked) => {
    setCheckbox((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleNextSetup = async () => {
    setLoading(true);
    const payload = {
      account_setup_step: 9,
      evolve_account_agreement: checkbox.business_account_agreement,
      evolve_e_consent: checkbox.electornic_communication_consent,
      evolve_privacy_policy: checkbox.privacy_policy,
      pb_privacy_policy: checkbox.pocketbook_privacy_policy,
    };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
    <Setup1MainSection style={{ flexWrap: "wrap" }}>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>

      <Setup8Wrapper>
        <MainTitle>Set Up Your Pocketbook</MainTitle>
        <MainDescription>
          Please review and acknowledge the following documents.
        </MainDescription>
        <Setup8Description>
          By checking the box beside each document and pressing “Submit”, you
          acknowledge that you are applying for a deposit account with Evolve
          Bank & Trust, member FDIC, and that you agree to the documents
          below. You are also authorizing us to share your personal information
          with Evolve Bank & Trust. You also consent to Evolve Bank & Trust
          sharing information about you and your deposit account with
          Pocketbook. Pocketbook will use and process that information in
          accordance with its Privacy Policy linked below. In all other
          instances, your information will be treated in accordance with the
          Evolve Bank & Trust Privacy Policy, which is also linked below.
        </Setup8Description>
        <CheckboxSection>
          {Object.entries(checkbox).map(([name, checked]) => (
            <div className="flex align-items-center" key={name}>
              <Checkbox
                size="small"
                inputProps={{ "aria-label": "controlled" }}
                checked={checked}
                onChange={(event) =>
                  handleCheckboxChange(name, event.target.checked)
                }
              />
              <CheckboxLabel style={{ color: checked ? "#13b50a" : "#000000" }}>
                {name === "electornic_communication_consent" && (
                  <span>
                    Evolve Bank & Trust's{" "}
                    <span
                      className="text-underline cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://cdn.pocketbook.tech/Evolve/Evolve_Bond_E_Sign_Consent.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Electronic Communication Consent
                    </span>
                  </span>
                )}
                {name === "business_account_agreement" && (
                  <span>
                    Evolve Bank & Trust's{" "}
                    <span
                      className="text-underline cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://cdn.pocketbook.tech/Evolve/Evolve_Bond_Customer_Agreement_Business.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Business Account Agreement
                    </span>
                  </span>
                )}
                {name === "privacy_policy" && (
                  <span>
                    Evolve Bank & Trust's{" "}
                    <span
                      className="text-underline cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://www.getevolved.com/privacy-policy/",
                          "_blank"
                        );
                      }}
                    >
                      Privacy Policy
                    </span>
                  </span>
                )}
                {name === "pocketbook_privacy_policy" && (
                  <span>
                    Pocketbook's{" "}
                    <span
                      className="text-underline cursor-pointer"
                      onClick={() => {
                        window.open(
                          "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Privacy_Policy.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Privacy Policy
                    </span>
                  </span>
                )}
              </CheckboxLabel>
            </div>
          ))}
        </CheckboxSection>
        <SubmitBtn onClick={handleNextSetup}>
          Submit
          {loading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </SubmitBtn>
      </Setup8Wrapper>
      <BottomText>
        Pocketbook is not a bank. Banking services are provided by Evolve Bank &
        Trust, Member FDIC
      </BottomText>
    </Setup1MainSection>
  );
};

export default AccountSetup8;
