import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { StyledInput, StyledTextInput } from "pages/utils/styled-input";
import { validateEmail } from "scripts/functions";
import { getContactById, updateContact } from "./actions";
import { MainWrapper } from "pages/styled";
import { FormBtnSection, FormLabel, FormSubLabel, OptionLabel } from "./styled";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { Button } from "components/ui/button-tw";
import { toast } from "react-toastify";
import { customTimeout } from "utils/functions";
import { useQueryClient } from "@tanstack/react-query";

const UpdateContact = ({ match }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = match.params;
  const { settingData } = useSelector((state) => state.account);

  const [shortCut, setShortCut] = useState({
    label: "Create contact",
    value: "new-contact",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [tag, setTag] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [note, setNote] = useState("");
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [validate, setValidate] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
  });
  const [currentWidth, setCurrentWidth] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getContactInfo(id);
    }
  }, [id]);

  const validateFields = () => {
    if (firstName === "") {
      setValidate((prevState) => ({ ...prevState, firstName: false }));
    } else {
      setValidate((prevState) => ({ ...prevState, firstName: true }));
    }
    if (lastName === "") {
      setValidate((prevState) => ({ ...prevState, lastName: false }));
    } else {
      setValidate((prevState) => ({ ...prevState, lastName: true }));
    }
    if (email === "") {
      setValidate((prevState) => ({ ...prevState, email: false }));
    } else {
      if (validateEmail(email)) {
        setValidate((prevState) => ({ ...prevState, email: true }));
      } else {
        setValidate((prevState) => ({ ...prevState, email: false }));
      }
    }
    if (phone === "") {
      setValidate((prevState) => ({ ...prevState, phone: false }));
    } else {
      if (phone?.length < 10) {
        setValidate((prevState) => ({ ...prevState, phone: false }));
      } else {
        setValidate((prevState) => ({ ...prevState, phone: true }));
      }
    }
  };

  useEffect(() => {
    if (id) {
      validateFields();
    }
  }, [firstName, lastName, email, phone]);

  const getContactInfo = async (id) => {
    await getContactById(id).then((res) => {
      if (res && res?.length > 0) {
        setFirstName(res[0].contact_info.first_name);
        setLastName(res[0].contact_info.last_name);
        setEmail(res[0].contact_info.email);
        setPhone(res[0].contact_info.phone);
        setBusinessName(res[0].contact_info.business_name);
        setDbaName(res[0].contact_info.business_dba);
        setTag(res[0].contact_info.tags);
        setAddress(res[0].contact_info.business_address);
        setCity(res[0].contact_info.business_city);
        setState(res[0].contact_info.business_state);
        setZipCode(res[0].contact_info.business_zip);
        setNote(res[0].contact_info.notes);
      }
    });
  };

  const handleUpdateContact = async () => {
    const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
    if (!clientCognitoId) {
      throw new Error("It was not possible to identify the platform.");
    }

    const payload = {
      cognito_id: clientCognitoId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      tags: tag,
      business_name: businessName,
      business_dba: dbaName,
      business_address: address,
      business_city: city,
      business_state: state,
      business_zip: zipCode,
      business_country: "",
      notes: note,
      integrations: {},
    };
    if (
      (validate.firstName, validate.lastName, validate.email, validate.phone)
    ) {
      await updateContact(payload, id)
        .then((res) => {
          if (res && res.status === "success") {
            queryClient.invalidateQueries(["contacts"]);
            toast.success("Your contact has been updated");
            setSubmitLoading(false);
            customTimeout(() => {
              history.push("/contacts");
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setSubmitLoading(false);
        });
    } else {
      toast.error("Please fill the missing information.");
      validateFields();
    }
  };

  return (
    <div>
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <MainWrapper>
        <ClientMainHeader
          title="Contacts"
          subTitle="Create a contact"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <div className="pt-24 pb-25">
          <OptionLabel>Primary contact information</OptionLabel>
          <div className="flex mb-20 align-items-center">
            <FormLabel>*Name</FormLabel>
            <StyledInput
              value={firstName}
              setValue={setFirstName}
              placeholder={"First Name"}
              width="244px"
              mRight="24px"
              validation={validate.firstName}
            />
            <StyledInput
              value={lastName}
              setValue={setLastName}
              placeholder={"Last Name"}
              width="244px"
              validation={validate.lastName}
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <div>
              <FormLabel>*Email Address</FormLabel>
              <FormSubLabel>
                Invoices will be sent to this email address.
              </FormSubLabel>
            </div>
            <StyledInput
              value={email}
              setValue={setEmail}
              placeholder={"email"}
              width="512px"
              validation={validate.email}
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>*Phone number</FormLabel>
            <div style={{ width: "512px" }}>
              <PhoneInput
                placeholder="Phone number"
                country={"us"}
                onlyCountries={["us"]}
                value={phone}
                style={{ border: !validate.phone && "1px solid #ff0000" }}
                onChange={(phone) => {
                  setPhone(phone);
                }}
                disableCountryCode
              />
            </div>
          </div>
        </div>
        <div className="pt-24 pb-25">
          <OptionLabel>Business contact information</OptionLabel>
          <div className="flex mb-20 align-items-center">
            <FormLabel>Business name</FormLabel>
            <StyledInput
              value={businessName}
              setValue={setBusinessName}
              placeholder={"Business Name"}
              width="512px"
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>DBA</FormLabel>
            <StyledInput
              value={dbaName}
              setValue={setDbaName}
              placeholder={"Title"}
              width="512px"
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>Tags</FormLabel>
            <StyledInput
              value={tag}
              setValue={(value) => {
                setTag([value]);
              }}
              placeholder={"Create a tag"}
              width="512px"
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>Physical Address</FormLabel>
            <StyledInput
              value={address}
              setValue={setAddress}
              placeholder={"Physical Address"}
              width="512px"
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>City</FormLabel>
            <StyledInput
              value={city}
              setValue={setCity}
              placeholder={"City"}
              width="512px"
            />
          </div>
          <div className="flex mb-20 align-items-center">
            <FormLabel>State / Province</FormLabel>
            <StyledInput
              value={state}
              setValue={setState}
              placeholder={"State"}
              width="148px"
              mRight="24px"
            />
            <StyledInput
              value={zipCode}
              setValue={setZipCode}
              placeholder={"Zip Code"}
              width="148px"
            />
          </div>
          <div className="pt-24">
            <OptionLabel>Other</OptionLabel>
            <div className="flex">
              <FormLabel>Notes</FormLabel>
              <StyledTextInput value={note} setValue={setNote} width="512px" />
            </div>
          </div>
        </div>
        <FormBtnSection>
          <Button variant="outline" onClick={() => history.push("/contacts")}>
            Cancel
          </Button>
          <Button
            className="bg-skin-primary hover:bg-skin-primary/90 ml-2"
            onClick={() => handleUpdateContact()}
          >
            Update
            {submitLoading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </Button>
        </FormBtnSection>
      </MainWrapper>
    </div>
  );
};

export default UpdateContact;
