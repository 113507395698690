import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import ContactTable from "./contacts-table";
import ClientMainHeader from "pages/header";
import { StyledInput } from "pages/utils/styled-input";
import { MainWrapper } from "pages/styled";
import { CreateBtn, HeadSection } from "./styled";
import { useDebounce } from "hooks/use-debounce";
import { LimitedAccountWarning } from "components/limited-account-warning";

const Contacts = () => {
  const history = useHistory();
  const [shortCut, setShortCut] = useState("");
  const [search, setSearch] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    setCurrentWidth(width);
  }

  return (
    <div>
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />
        <MainWrapper>
          <ClientMainHeader
            title="Contacts"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <HeadSection>
            <CreateBtn onClick={() => history.push("/new-contact")}>
              Create a contact
            </CreateBtn>
            <StyledInput
              value={search}
              setValue={setSearch}
              placeholder={"Search contacts"}
              width={currentWidth > 500 ? "320px" : "500px"}
            />
          </HeadSection>
          <ContactTable search={debounceSearch} setSearch={setSearch} />
        </MainWrapper>
      </>
    </div>
  );
};

export default Contacts;
