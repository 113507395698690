import styled from "styled-components";

const PaymentMethodButton = ({
  className,
  status,
  handleDisconnect,
  handleConnect,
}) => {
  if (status === "active") {
    return (
      <div className={className} onClick={() => handleDisconnect()}>
        Disconnect
      </div>
    );
  }
  if (status === "pending_manual_verification") {
    return (
      <div className={className} onClick={() => handleConnect()}>
        Verify
      </div>
    );
  }
  return (
    <div className={className} onClick={() => handleConnect()}>
      Connect
    </div>
  );
};

const PaymentMethodButtonStyled = styled(PaymentMethodButton)`
  width: 142px;
  background: #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) =>
    props.status != "pending_manual_verification"
      ? props.primary_color
      : "#FFA500"};
`;

export default PaymentMethodButtonStyled;
