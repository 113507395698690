import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dialog } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
} from "pages/styled";
import { DialogSubmitBtn } from "./styled";

const ContractComfirmDialog = ({ dialog, setDialog, submitFunc }) => {
  const history = useHistory();
  const [step, setStep] = useState(0);

  const handleDialogClose = () => {
    setStep(0);
    setDialog(false);
  };

  const handleCancel = () => {
    handleDialogClose();
    history.push("/quotes");
  };

  const handleDialogSubmit = () => {
    if (submitFunc) {
      submitFunc();
    } else {
      handleDialogClose();
      // history.push("/smart-contracts");
    }
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>
            {step === 0 ? "Disclosure:" : "Smart quotes help you"}
          </DialogTitle>
          <BsXLg onClick={handleCancel} />
        </DialogHeader>
        <DialogDescription className="text-start">
          A contract has legally binding consequences. The smart contract
          service is provided for general informational purposes intended to
          assist you in the development of a suitable contract. It should not be
          relied upon as legal advice and should not serve as a substitute for
          professional legal counsel. Laws pertaining to contracts are varied,
          can change over time and each situation is different. It is always
          advisable to consult with a qualified attorney or legal professional
          who can provide guidance tailored to your particular circumstances.
        </DialogDescription>
        {step === 0 ? (
          <div className="flex">
            <DialogCancelBtn
              onClick={() => {
                submitFunc ? setDialog(false) : history.push("/quotes");
                // setStep(1);
              }}
            >
              I disagree
            </DialogCancelBtn>
            <DialogSubmitBtn onClick={() => handleDialogSubmit()}>
              Yes, I agree
            </DialogSubmitBtn>
          </div>
        ) : (
          <DialogSubmitBtn onClick={() => handleDialogSubmit()}>
            Start
          </DialogSubmitBtn>
        )}
      </DialogSection>
    </Dialog>
  );
};

export default ContractComfirmDialog;
