import { useFormikContext } from "formik";
import { useEffect } from "react";

const transactionFeeCalculation = (subTotal) => {
  const standardTransactionFeePct = 0.005;
  const largeTransactionFeePct = 0.0025;
  const standardFeeTransactionSizeCutoff = 10000;
  const minimumFee = 0.95;

  let invoiceFee = Math.max(subTotal * standardTransactionFeePct, minimumFee);

  if (subTotal > standardFeeTransactionSizeCutoff) {
    invoiceFee =
      standardFeeTransactionSizeCutoff * standardTransactionFeePct +
      (subTotal - standardFeeTransactionSizeCutoff) * largeTransactionFeePct;
  }

  return parseFloat(invoiceFee.toFixed(2));

  // let transactionFeePercentage = 0.005;
  // let minTransactionFee = 0.95;

  // if (subTotal >= 10000) {
  //   transactionFeePercentage = 0.0025;
  // }

  // let transactionFee = subTotal * transactionFeePercentage;

  // if (transactionFee < minTransactionFee) {
  //   transactionFee = minTransactionFee;
  // }

  // return transactionFee;
};

const useLineItemActions = () => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    const subTotal = values.lineItems.reduce((sum, item) => {
      return sum + item.quantity * item.unit_price;
    }, 0);

    const invoiceTotal =
      subTotal +
      subTotal * (values.invoiceTax / 100) +
      (values.transactionFee ? transactionFeeCalculation(subTotal) : 0);

    setFieldValue("subTotal", subTotal);
    setFieldValue("invoiceTotal", invoiceTotal);
    setFieldValue("transactionFeeAmount", transactionFeeCalculation(subTotal));
  }, [
    values.lineItems,
    values.invoiceTax,
    values.transactionFee,
    setFieldValue,
  ]);

  const modifyItemProperty = (index, property, value) => {
    const updatedLineItems = [...values.lineItems];
    updatedLineItems[index][property] = value;
    updatedLineItems[index].item_total =
      updatedLineItems[index].quantity * updatedLineItems[index].unit_price;
    setFieldValue("lineItems", updatedLineItems);
  };

  const deleteLineItem = (index) => {
    const updatedLineItems = [...values.lineItems];
    updatedLineItems.splice(index, 1);
    setFieldValue("lineItems", updatedLineItems);
  };

  const addLineItem = () => {
    const updatedLineItems = [...values.lineItems];
    updatedLineItems.push({
      description: "",
      quantity: 0,
      unit_price: 0,
      item_total: 0,
    });
    setFieldValue("lineItems", updatedLineItems);
  };

  const modifyInvoiceTax = (invoiceTax) => {
    if (invoiceTax < 0) {
      invoiceTax = 0;
    }
    setFieldValue("invoiceTax", invoiceTax);
  };

  const modifyTransactionFee = (transactionFee) => {
    setFieldValue("transactionFee", transactionFee);
  };

  const clearLineItems = () => {
    const updatedLineItems = [];
    updatedLineItems.push({
      description: "",
      quantity: 0,
      unit_price: 0,
      item_total: 0,
    });
    setFieldValue("lineItems", updatedLineItems);
  };

  return {
    modifyItemProperty,
    modifyInvoiceTax,
    deleteLineItem,
    addLineItem,
    modifyTransactionFee,
    clearLineItems,
  };
};

export { useLineItemActions };
