import { useEffect, useState } from "react";
import req from "api/config";
import { useSelector } from "react-redux";
import api from "api/v1-jwt/config/api";
import { useQueryClient } from "@tanstack/react-query";

export const useStep = (step) => {
  const queryClient = useQueryClient();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [postError, setPostError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("CognitoId"));
  const { profileData } = useSelector((state) => state.profile);
  const baseURL = `/platform/application/submitted/${userId}/step/${step}/${profileData.application_id}`;

  const handlePost = async (payload, callback) => {
    try {
      setIsPostLoading(true);
      await api.post(baseURL, payload);
      queryClient.invalidateQueries([
        "application",
        profileData?.application_id,
      ]);
      if (callback) {
        callback();
      }
    } catch (err) {
      setPostError(err);
    } finally {
      setIsPostLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(baseURL);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [step, baseURL]);

  return {
    data,
    error,
    isLoading,
    handlePost,
    isPostLoading,
    postError,
  };
};
