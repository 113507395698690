import { useState } from "react";
import { Dialog, CircularProgress } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import { deleteInvoice } from "../actions";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogOrangeBtn,
} from "../../styled";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const InvoiceDeleteDialog = ({
  dialog,
  setDialog,
  deleteItem,
  setDeleteItem,
  dateOption,
  getInvoiceKpis,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleDeleteInvoice = async () => {
    setLoading(true);
    await deleteInvoice(deleteItem.invoice_id).then((res) => {
      if (res && res.status === "success") {
        setDeleteItem(null);
        setLoading(false);
        setDialog(false);
        getInvoiceKpis(dateOption.value);
        queryClient.invalidateQueries({ queryKey: ["invoices"] });
        toast.success("Your invoice has been deleted.");
      }
    });
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription className="text-start">
          Are you sure you'd like to delete this invoice?
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            No! Keep it
          </DialogCancelBtn>
          <DialogOrangeBtn onClick={handleDeleteInvoice}>
            Yes, delete
            {loading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </DialogOrangeBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default InvoiceDeleteDialog;
