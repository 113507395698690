import req from "api/config";
import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const fetchContracts = async ({ id = "", type = "client" }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const isCustomer = type === "customer";

    const url = `/platform/contracts/${
      isCustomer
        ? `customer/${clientCognitoId}|${id || clientCognitoId}`
        : id || clientCognitoId
    }`;

    const { data, status } = await api.get(encodeURI(url));

    if (status === 200) {
      return data;
    } else {
      toast.error("There was an error fetching the data. Contact support.");
      return [];
    }
  } catch (error) {
    toast.error("An error occurred while fetching contracts. Contact support.");
    return [];
  }
};

export const updateContract = async (contractId, payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  if (!contractId) throw new Error("[API::CONTRACT::PATCH] Id is required.");

  const { data } = await api.patch(
    `/platform/contracts/${clientCognitoId}/${contractId}`,
    payload
  );

  return data;
};
