import moment from "moment";
import dayjs from "dayjs";

const invoiceToForm = (data) => {
  return {
    invoiceName: `${data.invoice_info.invoice_to.first_name} ${data.invoice_info.invoice_to.last_name}`,
    invoiceType: {
      label: "One time",
      value: data.invoice_info.invoice_frequency,
    },
    dueDate: dayjs(data.invoice_info.invoice_due_date),
    sendDate: dayjs(data.invoice_info.invoice_send_date),
    firstName: data.invoice_info.invoice_to.first_name,
    lastName: data.invoice_info.invoice_to.last_name,
    email: data.invoice_info.invoice_to.email,
    phone: data.invoice_info.invoice_to.phone,
    businessName: data.invoice_info.invoice_to.business_name,
    dbaName: data.invoice_info.invoice_to.business_dba,
    mailingAddr: data.invoice_info.invoice_to.business_address,
    city: data.invoice_info.invoice_to.business_city,
    state: data.invoice_info.invoice_to.business_state,
    zipCode: data.invoice_info.invoice_to.business_zip,
    invoiceText: data.invoice_info.invoice_notes,
    invoiceTax: data.invoice_info.invoice_tax_percentage,
    invoiceAddPaypal: data.invoice_info.invoice_add_paypal,
    paymentOption: { label: "Credit card", value: "credit-card" },
    subTotal: data.invoice_info.invoice_subtotal,
    invoiceTotal: data.invoice_info.invoice_total,
    invoice_add_paypal: data.invoice_info.invoice_add_paypal,
    lineItems: data.invoice_info.invoice_line_items.map((item) => ({
      description: item.description,
      quantity: item.quantity,
      unit_price: item.unit_price,
      item_total: item.item_total,
    })),
    transactionFee: data.invoice_info.invoice_pass_processing_fee,
    transactionFeeAmount: data.invoice_info.invoice_processing_fee,
  };
};
export default invoiceToForm;
