import React from 'react';
import { StyledTextField } from '../FormLabelStyles';

export const SSNInput = ({ssn, setSSN}) => {

  const formatSSN = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Limit to 9 characters
    const limitedValue = numericValue.slice(0, 9);

    // Format with dashes
    let formattedSSN = '';
    for (let i = 0; i < limitedValue.length; i++) {
      if (i === 3 || i === 5) {
        formattedSSN += '-';
      }
      formattedSSN += limitedValue[i];
    }

    return formattedSSN;
  };

  const handleSSNChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatSSN(inputValue);
    setSSN(formattedValue);
  };

  return (
    <StyledTextField
      id="ssnInput"
      value={ssn}
      onChange={handleSSNChange}
    />
  );
};

