/**
 * Convert a string from camel case to snake case.
 * @util
 * @param {string} str - A string in camel case.
 */
export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/**
 * Capitalize first letter of a string.
 * @util
 * @param {string} str - A string with lower case first letter.
 */
export function capitalizeFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export const removePlusOne=(inputString)=>{
  if (inputString.startsWith('+1')) {
    return inputString.slice(2).trim();
  }
  if (inputString.length === 11 && inputString.startsWith('1')) {
    return inputString.slice(1).trim();
  }

  // If the string does not start with '+1', return the original string
  return inputString;
}