import styled from "styled-components";
import { colors } from "ui";

export const MobileDashboardHeader = styled.div`
  padding: 10px 32px 0px;
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  font-weight: 590;
  font-family: Inter;
  color: ${colors.black};
  padding-bottom: 10px;
  line-height: 38px;
`;

export const StyledSubTitle = styled.div`
  font-size: 22px;
  font-weight: 590;
  font-family: Inter;
  color: ${colors.black};
  padding-bottom: 18px;
`;

export const BalanceLabel = styled.div`
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  color: ${colors.black};
  padding-bottom: 6px;
  line-height: 16px;
`;

export const HeaderBalanceAmount = styled.div`
  font-size: 22px;
  font-weight: 600;
  font-family: Inter;
  color: ${colors.black};
  line-height: 38px;
`;

export const StyledBody = styled.div`
  padding: 32px;
`;

export const MainLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: ${colors.black};
  padding-bottom: 16px;
`;

export const MainBalance = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  padding-bottom: 16px;
`;

export const LeftOptionBtn = styled.div`
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  height: 37px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CenterOptionBtn = styled.div`
  background-color: #ffffff;
  border-top: 1px solid #d0d5dd;
  border-bottom: 1px solid #d0d5dd;
  height: 37px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  cursor: pointer;
`;

export const RightOptionBtn = styled.div`
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  height: 37px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ShortcutBtn = styled.div`
  background-color: #325161;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 590;
  font-family: Inter;
  color: #ffffff;
  line-height: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 32px;
`;
