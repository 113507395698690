import styled from "styled-components";

export const PocketbookLogo = styled.img`
  width: 156px;
  height: 26px;
`;

export const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const LeftSection = styled.div`
  background-color: #ffffff;
  flex-basis: 50%;
  position: relative;
  overflow: auto;
  // align-items: center;
  // display: flex;
  @media (max-width: 600px) {
    display: block;
    height: 100%;
    flex-basis: 100%;
    padding: 0px 20px;
  }
`;

export const LeftHeader = styled.div`
  padding: 50px 60px;
`;

export const LoginFormSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px 100px 35px 50px;
  max-width: 580px;
  height: calc(100% - 275px);
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 30px 50px 20px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 28px;
    padding: 30px 50px 20px 20px;
  }
`;

export const LoginFormTitle = styled.div`
  font-size: 40px;
  font-weight: 590;
  font-family: Inter;
  line-height: 38px;
  font-style: normal;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export const LoginFormDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 22px;
`;

export const FormGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
  margin-top: 50px;
`;

export const FormSubmitBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
  background-color: #000000;
  cursor: pointer;
  width: fit-content;
  margin-top: 20px;
`;

export const LoginLeftFooter = styled.div`
  padding: 50px 60px;
`;

export const ContactBtn = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #939393;

  @media (max-width: 600px) {
    position: initial;
    // margin-top: 30px;
  }
`;

export const SignUpBtn = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  margin-top: 10px;

  @media (max-width: 600px) {
    position: initial;
    // margin-top: 30px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  // background: #000000;
  background: #f15a25;
  position: relative;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const RightImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const FeatureSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const CheckboxText = styled.div`
  color: #000000;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;
