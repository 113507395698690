import styled from "styled-components";

// Define your styled component before using it in other components
const GenerateBtn = styled.div`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 12px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : "#007BFF"}; // Default color added
`;

const CustomButton = ({ onClick, text, backgroundColor }) => {
  return (
    <GenerateBtn onClick={onClick} backgroundColor={backgroundColor}>
      {text}
    </GenerateBtn>
  );
};

const QuotesButton = ({ stage, handleFunctions, texts, settingData }) => {
  const buttonProps = [
    { text: texts.next, onClick: handleFunctions.moveToNextStage },
    { text: texts.generate, onClick: handleFunctions.handleGenerate },
    { text: texts.save, onClick: handleFunctions.handleSave },
  ];

  if (stage < 0 || stage >= buttonProps.length) {
    return null; // Handling for undefined or out-of-range stages
  }

  return (
    <CustomButton
      {...buttonProps[stage]}
      backgroundColor={settingData?.primary_color}
    />
  );
};

export default QuotesButton;
