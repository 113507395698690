import { useContract } from "hooks/contract/use-contract";
import Sidebar from "pages/sidebar/sidebar";
import { MainWrapper } from "pages/styled";
import { useState, useEffect } from "react";
import ClientMainHeader from "pages/header";
import { ActionCard, NotesCard } from "ui/DetailHelpers";
import styled from "styled-components";
import { ChatGptSection } from "./styled";
import { downloadFile } from "ui/files";
import { PdfViewer } from "ui/PDF";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import { useProfile } from "hooks/profile/use-profile";
import moment from "moment";
import {
  formatPhoneNumber,
  formatPhoneUS,
  getAddressFromContact,
  getAddressFromProfile,
} from "utils/functions";
import QuoteDetailsTable from "./components/QuoteDetailsTable";

const ContractDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ContractDetails = ({ match, customer = false }) => {
  const { id } = match.params;
  const { data: contractData } = useContract(id);
  const { data: profileInfo } = useProfile();
  const [filePath, setFilePath] = useState();

  const [currentWidth, setCurrentWidth] = useState();
  const [shortCut, setShortCut] = useState("");

  const handlefilePath = async () => {
    const downloadPath = await downloadFile({
      file_location: contractData.contract_info.contract_full_location,
    });

    setFilePath(downloadPath.downloadURLData);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (contractData?.contract_info.contract_full_location) {
      handlefilePath();
    }
  }, [contractData]);
  function handleResize() {
    const width = window.innerWidth;
    setCurrentWidth(width);
  }
  return (
    <div className="h-100">
      {!customer ? (
        <Sidebar
          currentWidth={currentWidth}
          setCurrentWidth={setCurrentWidth}
        />
      ) : (
        <CustomerSidebar
          currentWidth={currentWidth}
          setCurrentWidth={setCurrentWidth}
        />
      )}
      <MainWrapper>
        <ClientMainHeader
          title="Quotes"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        {!contractData ? (
          <div>loading</div>
        ) : (
          <ContractDetailsWrapper>
            <ChatGptSection style={{ margin: "0" }}>
              {contractData &&
                (contractData.contract_info.is_quote ? (
                  // Render something specific for a quote here
                  <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                    <h2 className="text-base font-semibold leading-6 text-gray-900">
                      Quote
                    </h2>
                    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                      <div>
                        <div className="sm:pr-4">
                          <dt className="inline text-gray-500">Sent on</dt>{" "}
                          <dd className="inline text-gray-700">
                            <time>
                              {moment(contractData?.created_at).format("LL")}
                            </time>
                          </dd>
                        </div>
                        <div className="mt-2 sm:mt-0 ">
                          {contractData?.contract_info?.expiration_date && (
                            <>
                              <dt className="inline text-gray-500">
                                Expiration date
                              </dt>{" "}
                              <dd className="inline text-gray-700">
                                <time>
                                  {moment(
                                    contractData?.contract_info?.expiration_date
                                  ).format("LL")}
                                </time>
                              </dd>
                            </>
                          )}
                        </div>
                      </div>
                      <div></div>
                      <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                        <dt className="font-semibold text-gray-900">From</dt>
                        <dd className="mt-2 text-gray-500">
                          <span className="font-medium text-gray-900">
                            {profileInfo?.business_name}
                          </span>
                          <br />
                          {profileInfo?.business_email}
                          <br />
                          {formatPhoneUS(profileInfo?.business_phone)}
                          <br />
                          {getAddressFromProfile(profileInfo?.physical_address)}
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                        <dt className="font-semibold text-gray-900">To</dt>
                        <dd className="mt-2 text-gray-500">
                          {contractData.contact_info?.business_name ? (
                            <>
                              <span className={`font-medium text-gray-900`}>
                                {contractData.contact_info?.business_name}
                              </span>
                              <br />
                            </>
                          ) : null}
                          <span
                            className={
                              contractData.contact_info?.business_name != ""
                                ? null
                                : `font-medium text-gray-900`
                            }
                          >
                            {`${contractData?.contact_info?.first_name} ${contractData?.contact_info?.last_name}`}
                          </span>
                          <br />
                          {contractData?.contact_info?.email}
                          <br />
                          {formatPhoneNumber(
                            contractData?.contact_info?.phone_number
                          )}
                          <br />
                          {getAddressFromContact(contractData?.contact_info)}
                        </dd>
                      </div>
                    </dl>
                    <QuoteDetailsTable
                      prompt={""}
                      quoteItems={contractData.contract_info?.quote_items}
                      setQuoteItems={() => {}}
                      display
                    />
                  </div>
                ) : !contractData.contract_info.is_smart_contract ? (
                  <PdfViewer filePath={filePath} /> // Render PdfViewer for non-smart contracts
                ) : (
                  contractData.contract_info.contract_text // Display contract text for smart contracts
                ))}
            </ChatGptSection>
            <div>
              <ActionCard
                item={contractData}
                status={contractData?.contract_info.contract_status}
                deliveryOption={contractData?.contract_info.delivery_option}
                clientEmail={contractData?.contact_info.email}
                customer={customer}
              />
              <NotesCard
                notes={""}
                element={
                  contractData?.contract_info.is_quote ? "quote" : "contract"
                }
              />
            </div>
          </ContractDetailsWrapper>
        )}
      </MainWrapper>
    </div>
  );
};

export default ContractDetails;
