import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "api/v1-jwt/config/api";

export const useFetchKYBData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const userId = JSON.parse(localStorage.getItem("CognitoId"));
  const { profileData } = useSelector((state) => state.profile);
  const [isLoading, setIsLoading] = useState(true);
  const baseURL = `/platform/application/submitted/${userId}/${profileData?.application_id}`;

  const fetchData = async () => {
    try {
      const response = await api.get(baseURL);
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (profileData?.application_id) {
      fetchData();
    }
  }, [baseURL, profileData]);

  const refetchData = () => {
    setIsLoading(true);
    fetchData();
  };

  return { data, error, isLoading, refetchData };
};
