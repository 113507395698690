import { useQuery } from "@tanstack/react-query";
import { getTenancyInfo } from "api/v1-jwt/tenancy/tenancy";

export const useTenancy = ({ userId, queryKey, queryFn, ...config } = {}) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const id = userId || clientCognitoId;
  return useQuery({
    queryKey: ["tenancy", id],
    queryFn: async () => getTenancyInfo(id),
    staleTime: 5 * 60 * 1000,
    ...config,
  });
};
