import { useState, useLayoutEffect, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import * as invoiceActionTypes from "pages/invoices/actionsTypes";
import * as quoteActionTypes from "pages/contracts/actionTypes";

import * as actionTypes from "./actionTypes";
import {
  StyledAuthInput,
  StyledAuthPhone,
  StyledAuthSelector,
} from "pages/utils/styled-auth-input";
import { getSettings } from "pages/settings/actions";
import { LoadingWrapper } from "pages/styled";
import { account_options } from "pages/demo";
import {
  ContactBtn,
  FormGroupSection,
  FormSubmitBtn,
  LeftHeader,
  LeftSection,
  SignupFormDescription,
  SignupFormSection,
  SignupFormTitle,
  SignupLeftFooter,
  SignupWrapper,
  PocketbookLogo,
  RightImage,
  RightSection,
  TermsText,
} from "./styled";
import useQueryParams from "utils/useQueryParams";

const CustomerSignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountInfo, settingData } = useSelector((state) => state.account);

  const [accountType, setAccountType] = useState("Business");
  const [businessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [receiveMsgChecked, setReceiveMsgChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState({
    businessName: null,
    phone: null,
    email: null,
    firstName: null,
    lastName: null,
  });

  const [errMessage, setErrMessage] = useState({
    businessName: "Business Name is required!",
    firstName: "First Name is required!",
    lastName: "Last Name is required!",
    phone:
      accountType === "Business"
        ? "Business Phone is required!"
        : "Phone number is required!",
    email:
      accountType === "Business"
        ? "Business Email is required!"
        : "Email address is required!",
  });

  useLayoutEffect(() => {
    if (!settingData) {
      setLoading(true);
      getSettings().then((res) => {
        if (res)
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: res,
          });
        setLoading(false);
      });
    }
  }, [dispatch]);

  const params = useQueryParams();

  useEffect(() => {
    const { invoice, contact, quote } = params;

    const invoiceParam = invoice;
    const contactParam = contact;
    localStorage.setItem("contactId", contactParam);
    localStorage.setItem("invoiceId", invoiceParam);
    localStorage.setItem("quoteId", quote);
    if (invoice) {
      dispatch({
        type: invoiceActionTypes.SELECTED_INVOICE,
        payload: invoiceParam,
      });
    }
    if (quote) {
      dispatch({
        type: quoteActionTypes.SELECTED_QUOTE,
        payload: quote,
      });
    }
  }, [params]);

  useEffect(() => {
    if (accountInfo && accountInfo.accountType) {
      setAccountType(accountInfo.accountType);
    }
    if (accountInfo && accountInfo.businessName) {
      setBusinessName(accountInfo.businessName);
      validateBusinessName(accountInfo.businessName);
    }
    if (accountInfo && accountInfo.phone) {
      setPhone(accountInfo.phone);
      validatePhone(accountInfo.phone);
    }
    if (accountInfo && accountInfo.email) {
      setEmail(accountInfo.email);
      validateEmail(accountInfo.email);
    }
    if (accountInfo && accountInfo.firstName) {
      setFirstName(accountInfo.firstName);
      validateFirstName(accountInfo.firstName);
    }
    if (accountInfo && accountInfo.lastName) {
      setLastName(accountInfo.lastName);
      validateLastName(accountInfo.lastName);
    }
  }, [accountInfo]);

  const handleLinkTOS = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Terms_of_Service.pdf",
      "_blank"
    );
  };

  const handleLinkPrivacyPolicy = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Privacy_Policy.pdf",
      "_blank"
    );
  };

  const validateBusinessName = (value) => {
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, businessName: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        businessName: "Business Name is required!",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, businessName: true }));
    }
  };

  const validateFirstName = (value) => {
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, firstName: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        firstName: "First Name is required!",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, firstName: true }));
    }
  };

  const validateLastName = (value) => {
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, lastName: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        lastName: "Last Name is required!",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, lastName: true }));
    }
  };

  const validatePhone = (value) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const numValidation = value.match(phoneno);
    if (value === "") {
      setErrMessage((prevState) => ({
        ...prevState,
        phone:
          accountType === "Business"
            ? "Business Phone is required!"
            : "Phone number is required!",
      }));
      setValidated((prevState) => ({ ...prevState, phone: false }));
    } else if (!numValidation) {
      setErrMessage((prevState) => ({
        ...prevState,
        phone:
          accountType === "Business"
            ? "Business Phone must be valid"
            : "Phone number must be valid",
      }));
      setValidated((prevState) => ({ ...prevState, phone: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, phone: true }));
    }
  };

  const validateEmail = (value) => {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email:
          accountType === "Business"
            ? "Business Email is required!"
            : "Email address is required!",
      }));
    } else if (!validEmailRegex.test(value)) {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email:
          accountType === "Business"
            ? "Business Email must be valid"
            : "Email address must be valid",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, email: true }));
    }
  };

  const handleAccountChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleBusinessNameChange = (event) => {
    setIsSubmitted(false);
    validateBusinessName(event.target.value);
    setBusinessName(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setIsSubmitted(false);
    validateFirstName(event.target.value);
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setIsSubmitted(false);
    validateLastName(event.target.value);
    setLastName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setIsSubmitted(false);
    validatePhone(event.target.value);
    setPhone(event.target.value);
  };

  const handleEmailChange = (event) => {
    setIsSubmitted(false);
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  const handleTermChange = (event) => {
    setTermChecked(event.target.checked);
  };

  const handleReceiveMsgChange = (event) => {
    setReceiveMsgChecked(event.target.checked);
  };

  const onSubmit = () => {
    const contactId = localStorage.getItem("contactId");
    console.log("SIGNUP CONTACT ID::", contactId);
    if (!contactId || contactId === "") {
      toast.error("Contact ID is not exist.");
    } else {
      if (termChecked) {
        setIsSubmitted(true);
        setValidated({
          businessName:
            validated.businessName === null ? false : validated.businessName,
          phone: validated.phone === null ? false : validated.phone,
          email: validated.email === null ? false : validated.email,
          firstName: validated.firstName === null ? false : validated.firstName,
          lastName: validated.lastName === null ? false : validated.lastName,
        });

        if (
          accountType === "Business" &&
          validated.businessName &&
          validated.phone &&
          validated.email &&
          validated.firstName &&
          validated.lastName
        ) {
          dispatch({
            type: actionTypes.ACCOUNT_INFO,
            payload: {
              accountType: accountType,
              businessName: businessName,
              phone: phone,
              email: email,
              firstName: firstName,
              lastName: lastName,
            },
          });
          history.push("/set-password");
        } else if (
          accountType === "Individual" &&
          validated.phone &&
          validated.email &&
          validated.firstName &&
          validated.lastName
        ) {
          dispatch({
            type: actionTypes.ACCOUNT_INFO,
            payload: {
              accountType: accountType,
              phone: phone,
              email: email,
              firstName: firstName,
              lastName: lastName,
              optInTextMessages: receiveMsgChecked,
            },
          });
          history.push("/set-password");
        }
      }
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <SignupWrapper>
      <LeftSection>
        <LeftHeader>
          <PocketbookLogo src={logo} alt="logo" />
        </LeftHeader>
        <SignupFormSection>
          <div>
            <SignupFormTitle>
              Create your free Pocketbook account
            </SignupFormTitle>
            <SignupFormDescription>
              Have an account?{" "}
              <span
                className="text-underline cursor-pointer"
                onClick={() => history.push("/login")}
              >
                Log in.
              </span>
            </SignupFormDescription>
            <FormGroupSection>
              <StyledAuthSelector
                label="Account"
                name="accountType"
                value={accountType}
                handleChange={handleAccountChange}
                selectList={account_options}
              />
              {accountType === "Business" ? (
                <>
                  <StyledAuthInput
                    name="businessName"
                    value={businessName}
                    placeholder="Business Name"
                    validation={validated.businessName}
                    errMsg={errMessage.businessName}
                    isSubmitted={isSubmitted}
                    handleChange={handleBusinessNameChange}
                  />
                  <StyledAuthPhone
                    name="businessPhone"
                    value={phone}
                    placeholder="Business Phone"
                    validation={validated.phone}
                    errMsg={errMessage.phone}
                    isSubmitted={isSubmitted}
                    handleChange={handlePhoneChange}
                  />
                  <StyledAuthInput
                    name="businessEmail"
                    value={email}
                    placeholder="Business Email"
                    validation={validated.email}
                    errMsg={errMessage.email}
                    isSubmitted={isSubmitted}
                    handleChange={handleEmailChange}
                  />
                  <StyledAuthInput
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    validation={validated.firstName}
                    errMsg={errMessage.firstName}
                    isSubmitted={isSubmitted}
                    handleChange={handleFirstNameChange}
                  />
                  <StyledAuthInput
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                    validation={validated.lastName}
                    errMsg={errMessage.lastName}
                    isSubmitted={isSubmitted}
                    handleChange={handleLastNameChange}
                  />
                </>
              ) : (
                <>
                  <StyledAuthInput
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    validation={validated.firstName}
                    errMsg={errMessage.firstName}
                    isSubmitted={isSubmitted}
                    handleChange={handleFirstNameChange}
                  />
                  <StyledAuthInput
                    name="lastName"
                    value={lastName}
                    placeholder="Last Name"
                    validation={validated.lastName}
                    errMsg={errMessage.lastName}
                    isSubmitted={isSubmitted}
                    handleChange={handleLastNameChange}
                  />
                  <StyledAuthInput
                    name="Email"
                    value={email}
                    placeholder="Email Address"
                    validation={validated.email}
                    errMsg={errMessage.email}
                    isSubmitted={isSubmitted}
                    handleChange={handleEmailChange}
                  />
                  <StyledAuthPhone
                    name="phone"
                    value={phone}
                    placeholder="Phone Number"
                    validation={validated.phone}
                    errMsg={errMessage.phone}
                    isSubmitted={isSubmitted}
                    handleChange={handlePhoneChange}
                  />
                </>
              )}

              <div className="flex align-items-center pb-10">
                <Checkbox
                  checked={termChecked}
                  onChange={handleTermChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <TermsText
                  style={{ color: termChecked ? "#000000" : "#f15a25" }}
                >
                  I agree to Pocketbook's{" "}
                  <span
                    className="text-underline cursor-pointer"
                    onClick={handleLinkTOS}
                  >
                    Terms of Service
                  </span>{" "}
                  and{" "}
                  <span
                    className="text-underline cursor-pointer"
                    onClick={handleLinkPrivacyPolicy}
                  >
                    Privacy Policy
                  </span>
                </TermsText>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  checked={receiveMsgChecked}
                  onChange={handleReceiveMsgChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <TermsText
                  style={{ color: receiveMsgChecked ? "#000000" : "#f15a25" }}
                >
                  I agree to receive text message invoices from{" "}
                  {settingData?.client_name}
                </TermsText>
              </div>
              <FormSubmitBtn onClick={onSubmit}>Submit</FormSubmitBtn>
            </FormGroupSection>
          </div>
        </SignupFormSection>
        <SignupLeftFooter>
          <ContactBtn>
            Questions? Feel free to{" "}
            <a
              href="mailto:support@pocketbook.tech"
              style={{ color: "#939393" }}
            >
              contact our team.
            </a>
          </ContactBtn>
        </SignupLeftFooter>
      </LeftSection>
      <RightSection style={{ background: `${settingData?.primary_color}` }}>
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="login-image"
        />
      </RightSection>
    </SignupWrapper>
  );
};

export default CustomerSignup;
