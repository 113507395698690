import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import pageReducer from "./pageReducer";
import authReducer from "./authReducer";
import billsReducer from "./billsReducer";
import profileReducer from "./profileReducer";


const rootReducer = combineReducers({
  account: accountReducer,
  page: pageReducer,
  auth: authReducer,
  bills: billsReducer,
  profile: profileReducer
});

export default rootReducer;
