import { useEffect, useState } from "react";

/**
 *
 * @param value The value to be debounced
 * @param delay The delay (in ms)
 * @returns The debounced value
 */

export function useDebounce(value, delay = 1000) {
  const [oldValue, setOldValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOldValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return oldValue;
}
