import { CircularProgress } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import { LoadingWrapper } from "pages/styled";
import {
  RightSection,
  PocketbookLogo,
  RightImage,
  SignupFormTitle,
} from "./styled";
import { useHistory } from "react-router-dom";
import { useSettings } from "hooks/setting/use-settings";

export const AdminNoAccess = () => {
  const { data: settings, isLoading: loadingSettings } = useSettings();

  if (loadingSettings) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="bg-white w-full sm:w-1/2 relative overflow-auto h-full p-6 sm:p-8 lg:p-12 flex flex-col justify-between">
        <div className="lg:mt-2">
          <PocketbookLogo src={logo} alt="logo" />
        </div>

        <div className="max-w-[600px] lg:pr-20 lg:pl-12 pb-24">
          <SignupFormTitle>
            Oops! You don't have access to this platform.
          </SignupFormTitle>
          <div className="font-medium text-gray-500 mt-1">
            Please contact the account owner for more details.
          </div>
        </div>
        <div className="flex justify-start items-center">
          <span className="text-sm font-medium text-gray-500">
            Questions? Feel free to
          </span>
          <a
            href="mailto:support@pocketbook.tech"
            className="text-sm font-medium text-skin-primary underline-offset-4 hover:underline ml-1"
          >
            contact our team.
          </a>
        </div>
      </div>
      <RightSection
        style={{
          backgroundColor: settings.primary_color
            ? settings.primary_color
            : "#000",
        }}
      >
        <RightImage
          src={
            settings?.logo
              ? `https://cdn.pocketbook.tech/${settings?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
    </div>
  );
};

export default AdminNoAccess;
