import axios from "axios";
import { popProp, validStatus } from "utils/helpers";
import api from "../config/api";
import { camelToSnakeCase } from "utils/string-manipulation";

const baseUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/platform/invoices`;

export const fetchInvoices = async ({ id = "", type = "client", filters }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const isCustomer = type === "customer";

  const url = `/platform/invoices/${
    isCustomer
      ? `customer/${clientCognitoId}|${id || clientCognitoId}`
      : id || clientCognitoId
  }${filters?.contactId ? `/contact/${filters?.contactId}` : ""}`;

  const { data } = await api.get(encodeURI(url));
  return data;
};

export const fetchContactInvoices = async (contactId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const url = `/platform/invoices/${clientCognitoId}/contact/${contactId}`;
  const { data } = await api.get(encodeURI(url));

  return data;
};

export const fetchInvoicesKPIs = async (params) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  let query = [];
  for (const [key, value] of Object.entries(params)) {
    let k = camelToSnakeCase(key);
    query.push(`${k}=${value}`);
  }

  query = query.join("&");
  const { data } = await api.get(
    encodeURI(
      `/platform/invoices/${clientCognitoId}/kpis?${
        query.includes("days_back") ? "" : "days_back=30"
      }&${query}`
    )
  );

  return data;
};

export const getInvoiceById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/invoices/${clientCognitoId}/${id}`);

  return data;
};

export const getInvoicesByContact = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/invoices/${clientCognitoId}/contact/${id}`
  );

  return data;
};

export const createInvoice = async (invoiceData) => {
  try {
    let accessToken = popProp(invoiceData, "accessToken");
    let userId = popProp(invoiceData, "userId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
      },
      validateStatus: validStatus,
    };

    const response = await api.post(
      `/platform/invoices/${userId}`,
      invoiceData,
      config
    );

    // You can handle specific status codes here after the request has been made
    if (response.status !== 201) {
      throw new Error(
        "The request was made and the server responded, but status code is not 201"
      );
    }

    return response;
  } catch (error) {
    console.log("Error creating invoice: ", error);
    let error_message;
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      switch (error.response.status) {
        case 401:
          error_message =
            "Your session has expired. Please log out and log in again.";
          break;
        case 403:
          error_message = "You do not have permission to create an invoice.";
          break;
        case 409:
          error_message = error.detail;
          break;
        case 422:
          if (error.detail && error.detail.length > 0) {
            error.detail.map((item) => {
              if (item.loc[1] === "invoice_due_date")
                error_message = "Due date is required";
              if (item.loc[1] === "invoice_send_date")
                error_message = "Send date is required";
              if (
                item.loc[1] === "invoice_line_items" &&
                item.loc[3] === "description"
              )
                error_message = "Line items description is required";
              if (
                item.loc[1] === "invoice_line_items" &&
                item.loc[3] === "quantity"
              )
                error_message = "Line items quantity is required";
              if (
                item.loc[1] === "invoice_line_items" &&
                item.loc[3] === "unit_price"
              )
                error_message = "Line items price is required";
              if (item.loc[1] === "invoice_tax_percentage")
                error_message = "Tax is required";
            });
          }
          break;
        default:
          error_message = `Error creating invoice: ${error}`;
          break;
      }
      throw new Error(error_message);
    }

    // if (error.request) { // The request was made but no response was received
    //     // This could be a network error, or some other reason why the request was not fulfilled
    //     error_message = "The request was made but no response was received.";
    //     throw new Error(error_message);
    // }

    // Something happened in setting up the request that triggered an Error
    // throw error;
  }
};

export const deleteInvoice = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/invoices/${clientCognitoId}/${id}`
  );

  return data;
};

export const updateInvoice = async (id, item) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/invoices/${clientCognitoId}/${id}`,
    item
  );

  return data;
};

export const payInvoice = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/invoices/${clientCognitoId}/pay_invoice`,
    payload
  );

  return data;
};

export const uploadInvoicePDF = async (params, pdfBlob) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    // Get URL
    const { data: url } = await api.get(
      `/settings/upload_url/${clientCognitoId}?${params}`
    );
    // Upload New way
    const presignedData = url.uploadURLData;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", pdfBlob, "invoice.pdf");
    const uploadResponse = await fetch(presignedData.url, {
      method: "POST",
      body: formData,
      redirect: "follow",
    });
    return { url: url, response: uploadResponse };
  } catch (err) {
    console.error(err);
  }
};

export const sendInvoiceToCustomer = async (invoiceId, reminder = false) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/invoices/${clientCognitoId}/send/${invoiceId}?reminder=${reminder}`,
    {}
  );

  return data;
};

export const downloadInvoice = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/settings/download_url/${clientCognitoId}`,
    payload
  );

  return data;
};

export const processInvoiceImage = async (file) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  let base64Image;
  try {
    const base64Content = await toBase64(file);
    base64Image = base64Content.split(",")[1];
  } catch (error) {
    console.log("INVOICE_IMAGE::FILE_READ::ERROR::", error);
    throw new Error("Error reading file content");
  }

  const fileType = file.type === "application/pdf" ? "pdf" : "image";
  const payload = {
    file_type: fileType,
    content: base64Image,
  };

  const { data } = await api.post(
    `/platform/invoices/process_invoice_image`,
    payload
  );
  return data;
};
