import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import CustomerHeader from "./header";
import CustomerInvoiceTable from "components/invoices/customer-invoices-table";

const invoiceStatus = [
  { label: "Unpaid & Overdue", value: "unpaid_and_overdue" },
  { label: "Unpaid", value: "unpaid" },
  { label: "Paid", value: "paid" },
  { label: "Draft", value: "draft" },
  { label: "Pending", value: "pending" },
];

export const MainWrapper = styled("div")`
  background-color: #f8f8f8;
  position: relative;
  margin-left: 315px;
  height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    margin-left: 0px;
    height: calc(100% - 101px);
  }

  @media (max-width: 400px) {
    margin-left: 0px;
  }
`;

export const HeaderContainer = styled("div")`
  padding: 47px 50px;

  @media (max-width: 768px) {
    padding: 30px 40px;
  }

  @media (max-width: 400px) {
    padding: 20px 24px 0;
  }
`;

export const TableContainer = styled("div")(({ theme }) => {
  return {
    padding: `0px 50px`,

    [theme.breakpoints.down("lg")]: {
      padding: `0px 0px`,
    },
  };
});

const CustomerInvoices = () => {
  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);

  return (
    <div className="h-100">
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        <HeaderContainer>
          <CustomerHeader
            title="Invoices"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
        </HeaderContainer>
        <TableContainer>
          <CustomerInvoiceTable />
        </TableContainer>
      </MainWrapper>
    </div>
  );
};

export default CustomerInvoices;
