import { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import darkenColor from "utils/colors";
const formatData = (inputData) => {
  if (inputData) {
    const createdDates = inputData.count.graph_data.created_dates;
    const createdCounts = inputData.count.graph_data.created;
    const paidAmounts = inputData.dollars.graph_data.paid;
    const unpaidAmounts = inputData.dollars.graph_data.unpaid;
    const pendingAmounts = inputData.dollars.graph_data.pending;

    const groupedData = {};

    for (let i = 0; i < createdDates.length; i++) {
      const date = new Date(createdDates[i]);
      const dateString = date.toISOString().split("T")[0];

      if (!groupedData[dateString]) {
        groupedData[dateString] = {
          count: 0,
          paid: 0,
          unpaid: 0,
          pending: 0,
        };
      }
      groupedData[dateString].count += createdCounts[i];
      groupedData[dateString].paid += paidAmounts[i];
      groupedData[dateString].unpaid += unpaidAmounts[i];
      groupedData[dateString].pending += pendingAmounts[i];
    }

    const formattedData = [];
    let cumulativeCount = 0;
    let cumulativePaid = 0;
    let cumulativeUnpaid = 0;
    let cumulativePending = 0;

    const sortedDates = Object.keys(groupedData).sort();

    for (const date of sortedDates) {
      cumulativeCount += groupedData[date].count;
      cumulativePaid += groupedData[date].paid;
      cumulativeUnpaid += groupedData[date].unpaid;
      cumulativePending += groupedData[date].pending;

      formattedData.push({
        name: date,
        count: cumulativeCount,
        paid: cumulativePaid,
        pending: cumulativePending,
        unpaid: cumulativeUnpaid,
        pendingAndPaid: cumulativePaid + cumulativePending,
        total: cumulativePaid + cumulativeUnpaid + cumulativePending,
      });
    }
    return formattedData;
  }
};

const InvoiceChart = ({ invoiceData = false, primaryColor }) => {
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    if (invoiceData) {
      const processedData = formatData(invoiceData);
      setGraphData(processedData);
    }
  }, [invoiceData]);
  if (!graphData) return <></>;

  return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
            width={300}
            height={200}
            data={graphData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis
              tickFormatter={(value) =>
                  value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
              }
          />
          <Tooltip
              formatter={(value, name, props) => [
                name +
                " " +
                value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              ]}
          />
          <Area
              type="monotone"
              dataKey="paid"
              stackId="1"
              stroke="black"
              fill={primaryColor}
          />
          <Area
              type="monotone"
              dataKey="pendingandpaid"
              stackId="1"
              stroke="black"
              fill={darkenColor(primaryColor, 0.1)}
          />
          <Area
              type="monotone"
              dataKey="unpaid"
              stackId="1"
              stroke="black"
              fill="#FEF29A"
          />
          <Area
              type="monotone"
              dataKey="total"
              stackId="2"
              stroke="black"
              fill="none"
          />
        </AreaChart>
      </ResponsiveContainer>
  );
};

export default InvoiceChart;
