export function getContactName({ contact_info }) {
  if (!contact_info)
    throw new Error("[GET_CONTACT_NAME] You must pass a valid contact object.");

  const { first_name, last_name, business_name } = contact_info;
  return business_name ? business_name : `${first_name} ${last_name}`.trim();
}

export function getContactSearchValue({ contact_info }) {
  if (!contact_info)
    throw new Error("[GET_CONTACT_NAME] You must pass a valid contact object.");

  const { first_name, last_name, business_name, email } = contact_info;
  return `${email} ${business_name} ${first_name} ${last_name}`.trim();
}
