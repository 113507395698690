import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Button } from "components/ui/button-tw";

export function ConfirmDialog({
  open,
  onOpenChange,
  title,
  description,
  onConfirm,
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl">{title}</DialogTitle>
          <DialogDescription style={{ whiteSpace: "pre-line" }}>
            {description}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="w-full flex mt-2">
          <Button
            variant="outline"
            className="grow mr-1"
            onClick={() => onOpenChange(false)}
          >
            Back
          </Button>
          <Button
            className="grow bg-[#1DB45A] hover:bg-[#1DB45A]/80"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
