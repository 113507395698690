import { useState } from "react";
import { Dialog, MobileStepper } from "@mui/material";
import { updateProfile } from "pages/setup/actions";
import {
  DialogBtn,
  DialogTitle,
  DialogDescription,
  DialogSection,
} from "./styled";

const tempData = [
  {
    title: "Welcome to your Dashboard",
    description:
      "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
  },
  {
    title: "Custom branding",
    description:
      "Add your business logo and choose a color for great looking invoices.",
  },
  {
    title: "Add a contact",
    description:
      "Organizing your contacts is easy. Manually create contacts or link your Quickbooks or Xero to import existing.",
  },
  {
    title: "Create an invoice",
    description:
      "Create clear, customized invoices. Get paid faster and more cheaply with pay by bank or add PayPal/Venmo for more payment options.",
  },
  {
    title: "Create a quote",
    description:
      "Upload your quotes or create a new one with our smart quotes tool.",
  },
];

const WelcomeDialog = ({ dialog, setDialog }) => {
  const [step, setStep] = useState(0);

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleConfirm = async () => {
    const payload = { tutorial_completed: true };
    await updateProfile(payload).then((res) => {
      if (res) setDialog(false);
    });
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-welcome-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px", // Set your width here
          },
        },
      }}
    >
      <DialogSection className="welcome-dialog">
        <DialogTitle>{tempData[step].title}</DialogTitle>
        <DialogDescription>{tempData[step].description}</DialogDescription>
        <MobileStepper
          steps={5}
          position="static"
          activeStep={step}
          sx={{ justifyContent: "center" }}
        />
        <DialogBtn
          onClick={() => {
            if (step === 4) {
              handleConfirm();
            } else {
              setStep(step + 1);
            }
          }}
        >
          Next
        </DialogBtn>
      </DialogSection>
    </Dialog>
  );
};

export default WelcomeDialog;
