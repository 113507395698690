import { OptionLabel } from "pages/styled";
import StyledDatePicker from "pages/utils/styled-date-picker";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { StyledError } from ".";
import { forwardRef } from "react";

const DatesSection = forwardRef(({ ...props }, ref) => {
  const { values } = useFormikContext();

  return (
    <div className="flex gap-x-4 mb-4 w-full" ref={ref}>
      <div className="grow">
        <label
          htmlFor="dueDate"
          className="block text-base font-medium leading-6 text-gray-900 mb-2"
        >
          Due date
        </label>
        <div>
          <Field name="dueDate" id="dueDate">
            {({ field, form: { touched, errors, setFieldValue } }) => (
              <StyledDatePicker
                value={field.value}
                setValue={(value) => setFieldValue("dueDate", value)}
                fullwidth
                style={{
                  border:
                    touched.dueDate && errors.dueDate
                      ? "2px solid red"
                      : undefined,
                }}
              />
            )}
          </Field>
        </div>
        <ErrorMessage
          name="dueDate"
          component={StyledError}
          className="error"
        />
      </div>
      {values.invoiceType.value === "scheduled" && (
        <div className="grow">
          <label
            htmlFor="sendDate"
            className="block text-base font-medium leading-6 text-gray-900 mb-2"
          >
            Send on
          </label>
          <Field name="sendDate">
            {({ field, form: { touched, errors, setFieldValue } }) => (
              <StyledDatePicker
                value={field.value}
                setValue={(value) => setFieldValue("sendDate", value)}
                fullwidth
                style={{
                  border:
                    touched.sendDate && errors.sendDate
                      ? "2px solid red"
                      : undefined,
                }}
              />
            )}
          </Field>
          <ErrorMessage
            name="sendDate"
            component={StyledError}
            className="error"
          />
        </div>
      )}
    </div>
  );
});

export default DatesSection;
