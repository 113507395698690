import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export const LabelStyle = {
  display: "flex",
  fontWeight: 590,
  fontFamily: "Inter",
  color: "#000",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

export const SpecialLabelStyle = {
  display: "flex",
  fontWeight: 590,
  fontFamily: "Inter",
  color: "#000",
  height: "100%",
  alignItems: "flex-start",
  fontSize: "14px",
  flexDirection: "column",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  gap: "5px",
};

export const StyledTextField = styled(TextField)`
  background-color: white;

  .MuiOutlinedInput-notchedOutline {
    outline: none !important;
    border-color: transparent; /* Remove the border color */
  }

  &:focus .MuiOutlinedInput-notchedOutline {
    border-color: transparent; /* Remove the border color on focus */
    outline: none !important; /* Remove the focus ring */
  }

  .MuiInputBase-input.MuiOutlinedInput-input:focus {
    box-shadow: none;
  }
`;

export const StyledSelect = styled(Select)`
  background-color: white;

  .MuiOutlinedInput-notchedOutline {
    outline: none !important;
    border-color: transparent; /* Remove the border color */
  }

  &:focus .MuiOutlinedInput-notchedOutline {
    border-color: transparent; /* Remove the border color on focus */
    outline: none !important; /* Remove the focus ring */
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  &.react-tel-input {
    border-radius: 0px !important;
    border: none;
  }

  &.react-tel-input .form-control {
    border-radius: 2px;
    border: none;
    height: 38px;
  }

  &.react-tel-input .form-control:focus {
    box-shadow: none;
  }

  &.react-tel-input .form-control:hover {
  }

  &.react-tel-input .flag-dropdown {
    border: none;
  }
`;
