import { useLayoutEffect, useState } from "react";
import { getClientInfo } from "pages/setup/actions";

const useGetClientInfo = () => {
  const [clientInfo, setClientInfo] = useState({});

  useLayoutEffect(() => {
    let isMounted = true;

    const getClientInformation = async () => {
      try {
        const res = await getClientInfo();
        if (isMounted && res) {
          setClientInfo(res);
        }
      } catch (e) {
        if (isMounted) {
          console.log(e);
        }
      }
    };

    getClientInformation();

    return () => {
      isMounted = false;
    };
  }, []);

  return clientInfo;
};

export default useGetClientInfo;
