import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import pocketbook_logo from "assets/images/pocketbook-logo.png";
import email_icon from "assets/images/mail.png";
import call_icon from "assets/images/call.png";
import chat_icon from "assets/images/chat.png";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import { CustomSelector } from "pages/utils/styled-select";
import { shortcut_list } from "pages/demo";
import { MainWrapper, MainHeader, MainTitle, OptionLabel } from "pages/styled";
import {
  CustomerCardHeader,
  CustomerSupportBtn,
  SupportCard,
  SupportLabel,
  SupportSection,
  SupportValue,
} from "pages/support/styled";

const CustomerSupport = () => {
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState("");
  const [hide, setHide] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    if (width < 700) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  window.addEventListener("resize", handleResize);

  const CardHeader = styled(CustomerCardHeader)`
    background-color: ${settingData?.primary_color};
  `;

  const SupportBtn = styled(CustomerSupportBtn)`
    background-color: ${settingData?.primary_color};
  `;

  return (
    <div className="h-100">
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        <MainHeader>
          <div>
            <MainTitle>Support</MainTitle>
            <OptionLabel>Questions? We're here to help</OptionLabel>
          </div>
          {hide ? (
            <></>
          ) : (
            <div className="text-end">
              <img
                src={pocketbook_logo}
                alt="logo"
                width="156px"
                height="26px"
              />
              <CustomSelector
                value={shortCut}
                setValue={setShortCut}
                selectList={shortcut_list}
                width={188}
                menuWidth={320}
                mTop={17}
                // linkUrl
                customer
                placeholder={"Quick links"}
              />
            </div>
          )}
        </MainHeader>
        <SupportSection>
          <SupportCard>
            <CardHeader />
            <div className="p-32-34">
              <img
                src={email_icon}
                alt="email"
                width={"40px"}
                height={"32px"}
              />
              <SupportLabel>Email</SupportLabel>
              <SupportValue>support@pocketbook.tech</SupportValue>
              <SupportBtn>Email</SupportBtn>
            </div>
          </SupportCard>
          <SupportCard>
            <CardHeader />
            <div className="p-32-34">
              <img src={call_icon} alt="call" width={"32px"} height={"32px"} />
              <SupportLabel>Phone</SupportLabel>
              <SupportValue>1-888-555-5552</SupportValue>
              <SupportBtn>Call</SupportBtn>
            </div>
          </SupportCard>
          <SupportCard className="opacity-2">
            <CardHeader />
            <div className="p-32-34">
              <img src={chat_icon} alt="chat" width={"29px"} height={"29px"} />
              <SupportLabel>Chat</SupportLabel>
              <SupportValue>coming soon</SupportValue>
              <SupportBtn>Chat</SupportBtn>
            </div>
          </SupportCard>
        </SupportSection>
      </MainWrapper>
    </div>
  );
};

export default CustomerSupport;
