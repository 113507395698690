import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

let SITE_ENDPOINT = "https://signup.pocketbook.tech";

export const getIntegrationInfo = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/integrations/${clientCognitoId}/integration`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const connectQuickBook = async (subdomain) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  window.location.href = `${SITE_ENDPOINT}/integrations/quickbooks?apiKey=${process.env.REACT_APP_PBTECH_API_KEY}&cognitoId=${clientCognitoId}&REDIRECT_URI=https://${subdomain}`;
};
export const connectXero = async (subdomain) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  window.location.href = `${SITE_ENDPOINT}/integrations/xero?apiKey=${process.env.REACT_APP_PBTECH_API_KEY}&cognitoId=${clientCognitoId}&REDIRECT_URI=https://${subdomain}`;
};

export const connectFunc = async (integrationId, subdomain) => {
  if (integrationId === "Quickbooks") {
    connectQuickBook(subdomain);
  }
  if (integrationId === "Xero") {
    connectXero(subdomain);
  }
};

export const deleteQBIntegration = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(`/integrations/delete_integration`, {
      cognito_id: clientCognitoId,
      integration_name: "quickbooks",
    });

    return data;
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.detail);
  }
};

export const revokeQBIntegration = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(`/integrations/revoke_qb_integration`, {
      cognito_id: clientCognitoId,
      integration_name: "quickbooks",
    });
    return data;
  } catch (error) {
    if (error.response?.status === 403) {
      toast.error(error.response?.data?.detail);
    }
    return error.response.data;
  }
};

export const syncContacts = async (integrationName, integrationData) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  if (integrationName.toLowerCase() === "quickbooks") {
    const { realm_id } = integrationData.integration_data;
    return syncQBContacts({
      cognito_id: clientCognitoId,
      integration_name: "QUICKBOOKS",
      integration_id: realm_id,
    });
  }
};

export const syncInvoices = async (integrationName, integrationData) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  if (integrationName === "quickbooks") {
    const { realm_id } = integrationData.integration_data;
    return syncQBInvoices({
      cognito_id: clientCognitoId,
      integration_name: "QUICKBOOKS",
      integration_id: realm_id,
    });
  }
};

const syncQBContacts = async (payload) => {
  try {
    const { data } = await api.post(
      `/integrations/qb_contact_consolidate`,
      payload
    );
    return data;
  } catch (error) {
    console.log(error);
    if (error.response?.status === 403) {
      toast.error(error.response?.data);
    }
    return error.response?.data;
  }
};

const syncQBInvoices = async (payload) => {
  try {
    const { data } = await api.post(
      `/integrations/qb_invoice_consolidate`,
      payload
    );

    return data;
  } catch (err) {
    console.log(err);
    toast.error(err.response?.data?.detail);
  }
};
