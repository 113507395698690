import styled from "styled-components";

export const OptionLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 24px;
`;

export const ToolBtn = styled.div`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
  height: fit-content;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: fit-content;
  min-width: 121px;
  justify-content: center;
  // margin-right: 10px;
  cursor: pointer;

  // @media (max-width: 1400px) {
  //   margin-left: auto;
  //   margin-right: 0px;
  // }
`;

export const FilterIcon = styled.img`
  width: 15px;
  height: 10px;
  margin-right: 10px;
`;

export const ToolSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 550px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 25px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const BalanceCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 24px;
  width: 360px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const MoneyCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 24px;
  width: 250px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CardLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 8px;
`;

export const BalanceLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
`;

export const StyledTable = styled.div`
  white-space: nowrap;
  width: 100%;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  overflow-x: auto;
`;

export const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 6;
`;

export const TableLabel = styled.div`
  min-height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  @media (max-width: 1000px) {
    padding: 13px 12px;
  }
`;

export const TableItem = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  padding: 16px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border-bottom: 1px solid #eaecf0;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

export const PaginationSection = styled.div`
  padding: 18px 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
`;

export const ColumnDetailSection = styled.div`
  grid-column: span 6;
  padding: 33px 46px;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  position: relative;
`;

export const DetailTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-right: 30px;
`;

export const DetailGrid = styled.div`
  max-width: 300px;
  white-space: nowrap;
  width: 100%;
  gap: 20px;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
`;

export const DetailLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const TableToolBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &:hover {
    background-color: #e5e2e2;
  }
`;
