import styled from "styled-components";

// Invoices
export const CustomerInvoiceTable = styled.div`
  white-space: nowrap;
  width: 100%;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin-top: 30px;
  overflow-x: auto;
`;

export const TableProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 4;
`;

export const TableOutlinedBtn = styled.div`
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  width: 154px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
`;

export const TableContainedBtn = styled.button`
  background: ${({ disabled }) => (disabled ? "#ccc" : "#000")};
  border: 1px solid ${({ disabled }) => (disabled ? "#ccc" : "#000")};
  border-radius: 12px;
  width: 154px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const InvoiceDetailSection = styled.div`
  width: 100%;
  max-width: 825px;
  padding-top: 35px;
  border-top: 1px solid #e1e1e1;
`;

export const DetailInfo = styled.div`
  display: flex;
  padding-bottom: 10px;

  @media (max-width: 700px) {
    display: grid;
  }
`;

export const DetailTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-right: 30px;
  min-width: 160px;

  @media (max-width: 700px) {
    padding-bottom: 10px;
  }
`;

export const DetailLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px;
`;

export const DetailTotalInvoiceSection = styled.div`
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaecf0;
  align-items: center;

  @media (max-width: 700px) {
    display: grid;
    gap: 18px;
    margin-top: 18px;
  }
`;

export const InvoiceAmountBox = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  max-width: 260px;
  padding: 10px 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;

  @media (max-width: 700px) {
    display: grid;
    max-width: 500px;
  }
`;

export const QuantityTagBox = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  width: 100px;
  height: 42px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  @media (max-width: 500px) {
    width: 50px;
  }
`;

export const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 20px;
  justify-content: end;
`;

export const LineItemSection = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const NewPaymentSubmitBtn = styled.div`
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: 311px;
  margin: 0;
  padding: 48px;
  margin-bottom: 28px;
  border-radius: 16px;
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "none"};
  background: radial-gradient(
    circle 957px at 8.7% 50.5%,
    rgba(246, 191, 13, 1) 0%,
    rgba(249, 47, 47, 1) 90%
  );
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BannerWelcome = styled.h2`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;
