import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "api/profile/profile";

export const useProfile = ({ id, queryKey, queryFn, ...config } = {}) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const userId = id || clientCognitoId;

  return useQuery({
    queryKey: ["profile", userId],
    queryFn: async () => fetchProfile(userId),
    ...config,
  });
};
