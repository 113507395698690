import { useQuery } from "@tanstack/react-query";
import { fetchInvoicesKPIs } from "api/v1-jwt/invoice/invoice";

export const useInvoicesKPIs = (
  params,
  { queryFn, queryKey, ...rest } = {}
) => {
  return useQuery({
    queryKey: ["invoices", "kpis", params],
    queryFn: async () => {
      return await fetchInvoicesKPIs(params);
    },
    staleTime: 60 * 1000,
    ...rest,
  });
};
