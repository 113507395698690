import { Drawer } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import styled from "styled-components";

const DrawerText = styled.div`
  position: relative;
  padding: 10px 20px;
  text-align: center;
  background-color: ${(props) => (props.hasError ? "#b41d2d" : "#1db45a")};
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const StyledDrawer = ({ state, setState, text, error = false }) => {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <Drawer
      anchor={"top"}
      open={state["top"]}
      onClose={toggleDrawer("top", false)}
    >
      <DrawerText hasError={error}>
        <span>{text}</span>
        <BsXLg
          style={{ position: "absolute", top: 12, right: 22 }}
          onClick={toggleDrawer("top", false)}
        />
      </DrawerText>
    </Drawer>
  );
};
