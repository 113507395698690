import { useQuery } from "@tanstack/react-query";
import { fetchContactById } from "api/contact/contact";

export const useContact = (id, { queryKey, queryFn, ...config } = {}) => {
  let { data, ...rest } = useQuery({
    queryKey: ["contacts", id],
    queryFn: async () => await fetchContactById(id),
    staleTime: Infinity,
    ...config,
  });

  if (Array.isArray(data)) {
    data = data[0];
  }

  return { ...rest, data };
};
