import { useLayoutEffect, useState } from "react";
import { getBondBalance } from "pages/setup/actions";

const useBondBalance = () => {
  const [bondBalance, setBondBalance] = useState({});

  useLayoutEffect(() => {
    let isMounted = true;

    const getBondBalanceForDashboard = async () => {
      try {
        const res = await getBondBalance();
        if (isMounted && res) {
          setBondBalance(res);
        }
      } catch (e) {
        if (isMounted) {
          console.log(e);
        }
      }
    };

    getBondBalanceForDashboard();

    return () => {
      isMounted = false;
    };
  }, []);

  return bondBalance;
};

export default useBondBalance;
