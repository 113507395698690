export const convertThousandSeparator = (value) => {
  const numberWithCommas = value?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return numberWithCommas;
};

export const convertPhoneNumber = (value) => {
  const cleanedNumber = value.replace(/\D/g, "");
  const convertedNumber = `+1${cleanedNumber}`;
  return convertedNumber;
};

export const paginator = (items, current_page, per_page_items) => {
  let page = current_page || 1,
    per_page = per_page_items || 1,
    offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items.length,
    total_pages: total_pages,
    data: paginatedItems,
  };
};

export const copyTextToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((error) => {
      console.error("Failed to copy text: ", error);
    });
};

export const validatePhoneNumber = (number) => {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return number.match(phoneno);
};

export const validateEmail = (email) => {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegex.test(email);
};

// Password Validation
export const isValidPasswordLength = (password) => {
  // Minimum length is 12 characters
  if (password.length < 12) {
    return false;
  }
  return true;
};

export const isValidNumberInPassword = (password) => {
  // Contains at least 1 number
  if (!/\d/.test(password)) {
    return false;
  }
  return true;
};

export const isValidSpecialCharacterInPassword = (password) => {
  // Contains at least 1 special character
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return false;
  }
  return true;
};

export const isValidUppercaseInPassword = (password) => {
  // Contains at least 1 uppercase letter
  if (!/[A-Z]/.test(password)) {
    return false;
  }
  return true;
};

export const isValidLowercaseInPassword = (password) => {
  // Contains at least 1 lowercase letter
  if (!/[a-z]/.test(password)) {
    return false;
  }
  return true;
};
