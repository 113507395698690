import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { updateProfile } from "../actions";
import {
  Setup1MainSection,
  MainDescription,
  MainTitle,
  SubmitBtn,
  BackBtn,
  BackBtnLabel,
} from "../styled";

const Setup9Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
`;

const AccountSetup9 = ({ step, setStep }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleNextSetup = async () => {
    setLoading(true);
    const payload = { account_setup_completed: true };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      history.push("/dashboard");
    });
  };

  return (
    <Setup1MainSection>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup9Wrapper>
        <MainTitle>
          It’s time to start using your Pocketbook account!{" "}
        </MainTitle>
        <MainDescription>
          We're working hard on activating your payment processing
          functionality (Your Pocketbook). In the meantime, start using Pocketbook's other
          features
        </MainDescription>
        <SubmitBtn onClick={handleNextSetup}>
          Take me to my Dashboard
          {loading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </SubmitBtn>
      </Setup9Wrapper>
    </Setup1MainSection>
  );
};

export default AccountSetup9;
