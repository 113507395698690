import { useQuery } from "@tanstack/react-query";
import { getPaymentMethods } from "api/v1-jwt/payment-methods/payment-methods";

export const useGetPaymentMethods = ({ queryKey, queryFn, ...config } = {}) => {
  return useQuery({
    queryKey: ["payment_methods"],
    queryFn: async () => await getPaymentMethods({ status: false }),
    ...config,
  });
};
