import { useMemo } from "react";
import { useContacts } from "./use-contacts";

export const useFilteredContacts = (search = "", sortBy = "first_name") => {
  const { data: contacts, ...rest } = useContacts();

  const filteredContacts = useMemo(() => {
    const keys = [
      "business_dba",
      "business_name",
      "first_name",
      "last_name",
      "email",
      "phone",
    ];

    const filtered = (contacts || []).filter(({ contact_info: info }) =>
      search
        ? keys.some(
            (key) =>
              info[key] &&
              info[key].toLowerCase().includes(search.toLowerCase())
          )
        : true
    );

    // Sort the filtered contacts alphabetically based on the specified property
    return filtered.sort((a, b) =>
      a.contact_info[sortBy].localeCompare(b.contact_info[sortBy])
    );
  }, [contacts, search, sortBy]);

  return {
    data: filteredContacts,
    ...rest,
  };
};
