import styled from "styled-components";
import { sendContractEmail } from "pages/contracts/actions";
export const ApproveRejectButton = styled.button`
  border: 2px solid;
  border-color: ${({ approve }) => (approve ? "#1DB45A" : "#FF0000")};
  border-radius: 12px;
  min-width: 154px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${({ approve }) => (approve ? "#34d472" : "#ff4d4d")};
    border-color: ${({ approve }) => (approve ? "#34d472" : "#ff4d4d")};
  }
`;

export const ApproveContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Actions = ({
  customer,
  clientEmail,
  setMenuOption,
  handleDownload,
  status,
  contract_id,
  toastNotify,
  isQuote,
}) => {
  const handleSendEmail = async () => {
    await sendContractEmail(contract_id).then((res) => {
      if (res && res.status === "sent") {
        toastNotify("Contract email sent successfully.", "success");
      }
    });
  };
  if (!customer) {
    return (
      <div className="flex flex-col gap-3 mt-3">
        <div className="text-sm font-medium leading-6 text-white">
          <button onClick={() => handleSendEmail()}>
            Send {isQuote ? "Quote" : "Contract"}
          </button>
        </div>
        {/* <div className="text-sm font-medium leading-6 text-white">
          <a href={`mailto:${clientEmail}`}>
            <button onClick={() => console.log("Email customer")}>
              Email Customer
            </button>
          </a>
        </div> */}
        <div className="text-sm font-medium leading-6 text-white">
          <button onClick={() => console.log("Create invoice")}>
            Create Invoice
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-3 mt-3">
        {status === "pending approval" ? (
          <div className="text-sm font-medium leading-6 text-white">
            <button onClick={() => setMenuOption("approve")}>
              Approve / Reject {isQuote ? "Quote" : "Contract"}
            </button>
          </div>
        ) : null}
        {/* <div className="text-sm font-medium leading-6 text-white">
          <a href={`mailto:${clientEmail}`}>
            <button onClick={() => console.log("Email customer")}>
              Email Business
            </button>
          </a>
        </div> */}
        {/* <div className="text-sm font-medium leading-6 text-white">
          <button onClick={() => handleDownload()}>
            Download {isQuote ? "Quote" : "Contract"}
          </button>
        </div> */}
      </div>
    );
  }
};
