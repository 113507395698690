import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BiLock } from "react-icons/bi";
import { Drawer } from "@mui/material";
import styled from "styled-components";
import pocketbook_logo from "assets/images/logo.png";
import support_icon from "assets/images/question.png";
import setting_icon from "assets/images/setting.png";
import back_icon from "assets/images/back.png";
import menu_icon from "assets/images/hamburger-menu.png";
import dashboard_icon from "assets/images/dashboard/dashboard.png";
import contact_icon from "assets/images/dashboard/contacts.png";
import invoice_icon from "assets/images/dashboard/invoices.png";
import contract_icon from "assets/images/dashboard/contracts.png";
import integration_icon from "assets/images/dashboard/integrations.png";
import payment_icon from "assets/images/dashboard/payment-methods.png";
import transaction_icon from "assets/images/dashboard/transactions.png";
import { setUserLoggedOut } from "pages/customer/login/actions";
import {
  SidebarLabel,
  SidebarToolSection,
  StepIcon,
  StepSection,
  CustomerSidebarWrapper,
  MobileNavWrapper,
  MobileNavHeader,
  DrawerSection,
  MobileNavSection,
} from "./styled";
import { useAuth } from "providers/auth";
import { toast } from "react-toastify";

const sidebar_list = [
  { name: "Dashboard", value: "dashboard", icon: dashboard_icon },
  { name: "Contacts", value: "", icon: contact_icon, status: "lock" },
  { name: "Invoices", value: "invoices", icon: invoice_icon },
  { name: "Quotes", value: "quotes", icon: contract_icon },
  {
    name: "Integrations",
    value: "",
    icon: integration_icon,
    status: "lock",
  },
  { name: "Payment Methods", value: "payment-methods", icon: payment_icon },
  {
    name: "Transactions",
    value: "",
    icon: transaction_icon,
    status: "lock",
  },
];

const CustomerSidebar = ({ currentWidth, setCurrentWidth }) => {
  const history = useHistory();
  const { signout, isSigningOut, signOutError } = useAuth();
  const { settingData } = useSelector((state) => state.account);

  const [drawer, setDrawer] = useState({
    right: false,
  });

  const currentUrl = window.location.href;
  const currentPath = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    setCurrentWidth(width);
  }

  window.addEventListener("resize", handleResize);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleRedirect = (path) => {
    history.push(`/${path}`);
  };

  const handleLogout = async () => {
    await signout({
      onSuccess: (data) => {
        if (!data.is_authenticated) {
          history.push("/login");
        }
      },
      onError: (error) => {
        toast.error("Signout failed. Please try again in a moment.");
      },
    });
    // await setUserLoggedOut().then((res) => {
    //   console.log("logout: ", res);
    //   if (!res.is_authenticated) history.push("/login");
    // });
  };

  const SidebarWrapper = styled(CustomerSidebarWrapper)`
    background-color: ${settingData?.primary_color};
  `;

  return (
    <>
      {currentWidth > 768 ? (
        <SidebarWrapper>
          <div style={{ cursor: "pointer" }}>
            <img
              onClick={() => handleRedirect("dashboard")}
              alt="dashboard"
              src={
                settingData && settingData.logo !== ""
                  ? `https://cdn.pocketbook.tech/${settingData.logo}`
                  : pocketbook_logo
              }
              className="h-[65px] w-auto"
            />
          </div>

          <StepSection className="align-items-start">
            {sidebar_list.map((item, index) => (
              <SidebarLabel
                key={index}
                className={`${
                  currentPath === item.value && "opacity-10"
                } justify-between`}
                onClick={() => {
                  if (item.value !== "") handleRedirect(item.value);
                }}
              >
                <div className="flex align-items-center">
                  <StepIcon src={item.icon} alt={item.value} />
                  <span className="pl-25">{item.name}</span>
                </div>

                {item.status === "lock" && (
                  <BiLock style={{ marginLeft: 14 }} />
                )}
              </SidebarLabel>
            ))}
          </StepSection>
          <div style={{ flexGrow: 1 }}></div>
          <SidebarToolSection className="align-items-start">
            <SidebarLabel
              className={currentPath === "settings" && "opacity-10"}
              onClick={() => handleRedirect("settings")}
            >
              <StepIcon src={setting_icon} alt="setting" />
              <span className="pl-25">Settings</span>
            </SidebarLabel>
            <SidebarLabel
              className={currentPath === "support" && "opacity-10"}
              onClick={() => {
                handleRedirect("support");
              }}
            >
              <StepIcon src={support_icon} alt="support" />
              <span className="pl-25">Support</span>
            </SidebarLabel>
            <SidebarLabel onClick={handleLogout}>
              <StepIcon src={back_icon} alt="back" />
              <span className="pl-25">Logout</span>
            </SidebarLabel>
          </SidebarToolSection>
        </SidebarWrapper>
      ) : (
        <MobileNavWrapper
          style={{ backgroundColor: settingData?.primary_color }}
        >
          <MobileNavHeader>
            <img
              onClick={() => handleRedirect("dashboard")}
              src={
                settingData && settingData.logo !== ""
                  ? `https://cdn.pocketbook.tech/${settingData.logo}`
                  : pocketbook_logo
              }
              alt="dba-logo"
              className="h-[37px] w-auto"
            />
            <img
              src={menu_icon}
              alt="menu"
              width={"32px"}
              height="auto"
              className="cursor-pointer"
              onClick={toggleDrawer("right", true)}
            />
          </MobileNavHeader>
          <Drawer
            anchor={"right"}
            open={drawer["right"]}
            onClose={toggleDrawer("right", false)}
            PaperProps={{
              sx: {
                backgroundColor: settingData?.primary_color,
              },
            }}
          >
            <DrawerSection>
              <MobileNavSection className="align-items-start">
                {sidebar_list.map((item, index) => (
                  <SidebarLabel
                    key={index}
                    className={`${
                      currentPath === item.value && "opacity-10"
                    } justify-between`}
                    onClick={() => {
                      if (item.value !== "") handleRedirect(item.value);
                    }}
                  >
                    <div className="flex align-items-center">
                      <StepIcon src={item.icon} alt={item.value} />
                      <span className="pl-25">{item.name}</span>
                    </div>

                    {item.status === "lock" && (
                      <BiLock style={{ marginLeft: 14 }} />
                    )}
                  </SidebarLabel>
                ))}
              </MobileNavSection>
              <div style={{ flexGrow: 1 }}></div>
              <SidebarToolSection>
                <SidebarLabel
                  className={currentPath === "settings" && "opacity-10"}
                  onClick={() => handleRedirect("settings")}
                >
                  <StepIcon src={setting_icon} alt="setting" />
                  <span className="pl-25">Settings</span>
                </SidebarLabel>
                <SidebarLabel
                  className={currentPath === "support" && "opacity-10"}
                  onClick={() => handleRedirect("support")}
                >
                  <StepIcon src={support_icon} alt="support" />
                  <span className="pl-25">Support</span>
                </SidebarLabel>
                <SidebarLabel onClick={handleLogout}>
                  <StepIcon src={back_icon} alt="back" />
                  <span className="pl-25">Logout</span>
                </SidebarLabel>
              </SidebarToolSection>
            </DrawerSection>
          </Drawer>
        </MobileNavWrapper>
      )}
    </>
  );
};

export default CustomerSidebar;
