import { useQuery } from "@tanstack/react-query";
import { getSubmittedApplication } from "api/v1-jwt/platform-application/platform-application";
import { useProfile } from "hooks/profile/use-profile";
import { useAuth } from "providers/auth";

export const useKYBData = ({ queryFn, queryKey, ...rest } = {}) => {
  const { cognitoId } = useAuth();
  const { data: profile } = useProfile();

  return useQuery({
    queryKey: ["application", profile?.application_id],
    queryFn: async () =>
      await getSubmittedApplication(cognitoId, profile?.application_id),
    // staleTime: Infinity,
    ...rest,
  });
};
