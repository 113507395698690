import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "api/auth/auth";

/**
 * I considered putting this hook in the auth provider together with
 * login and logout, but since this mutation won't change token nor
 * any other auth info related to the provider, I thought it best
 * to keep this here as a normal mutation hook.
 *
 */

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};
