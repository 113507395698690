import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";

import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";

import InvoiceConfirmDialog from "./components/invoice-confirm-dialog";
import { getInvoiceById, updateInvoice, uploadInvoicePDF } from "./actions";
import { InvoicePDFDocument } from "./components/invoice-pdf";
import { MainWrapper } from "pages/styled";

import {
  LineItemsList,
  PrimaryContactInformation,
  BusinessInformation,
  ToType,
  DatesSection,
  SubtotalAndOptions,
  InvoiceTotal,
  InvoiceSubmit,
} from "./components";
import { Form, Formik } from "formik";
import { invoiceToForm, getInvoicePayload } from "./utils";
import { customTimeout } from "utils/functions";
import { toast } from "react-toastify";
import { useProfileInfo } from "pages/dashboard/hooks";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { sendInvoiceToCustomer } from "api/v1-jwt/invoice/invoice";
import { useQueryClient } from "@tanstack/react-query";

const EditInvoice = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { settingData } = useSelector((state) => state.account);
  const { id } = useParams();
  const [shortCut, setShortCut] = useState({
    label: "Create an invoice",
    value: "new-invoice",
  });
  const [currentWidth, setCurrentWidth] = useState(0);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [initialValues, setInitalValues] = useState(null);
  const [invoiceUserId, setInvoiceUserId] = useState(null);
  const { profileInfo } = useProfileInfo();
  const { data: tenancy } = useTenancy();

  useEffect(() => {
    getInvoiceInfo(id);
  }, [id]);

  const getInvoiceInfo = async (id) => {
    const invoiceInfo = await getInvoiceById(id);
    setInitalValues(invoiceToForm(invoiceInfo));
    setInvoiceUserId(invoiceInfo?.invoice_info?.invoice_to?.contact_id);
  };

  const getInvoiceinfoById = async (id, invoice_number) => {
    const res = await getInvoiceById(id);

    const pdfFile = await pdf(
      <InvoicePDFDocument
        settingData={settingData}
        profile={profileInfo}
        invoice={res}
        tenancy={tenancy}
      />
    ).toBlob();
    if (pdfFile) {
      const folderName = `invoices/${id}`;
      const params = `folder_name=${folderName}&file_name=invoice_${invoice_number}.pdf`;
      await uploadInvoicePDF(params, pdfFile);
    }
  };

  const updateInvoiceInfo = async (values, status) => {
    const payload = getInvoicePayload(values);
    if (status === "send") {
      payload.invoice_status = "unpaid";
    }
    const res = await updateInvoice(id, payload);
    if (status === "send") {
      await getInvoiceinfoById(id, res.invoice_number);
      await sendInvoiceToCustomer(id);
      toast.success(`Your invoice has been sent.`);
    } else {
      toast.success("Your invoice has been updated.");
    }

    queryClient.invalidateQueries({ queryKey: ["invoices"] });
    customTimeout(() => {
      history.push("/invoices");
    });
  };

  const handleFormSubmit = async (values) => {
    if (values.status === "draft") {
      updateInvoiceInfo(values);
    } else {
      setConfirmData(values);
      setConfirmDialog(true);
    }
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <MainWrapper>
        {initialValues ? (
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleFormSubmit(values);
              //   resetForm();
            }}
          >
            <Form>
              <ClientMainHeader
                title="Invoices"
                subTitle="Create an invoice"
                shortCut={shortCut}
                setShortCut={setShortCut}
              />
              <ToType contact_id={invoiceUserId} />
              <DatesSection />
              <PrimaryContactInformation />
              <BusinessInformation />
              <LineItemsList />
              <SubtotalAndOptions />
              <InvoiceTotal />
              <InvoiceSubmit edit />
            </Form>
          </Formik>
        ) : null}
        {/*
         */}
      </MainWrapper>
      <InvoiceConfirmDialog
        dialog={confirmDialog}
        values={confirmData}
        setDialog={setConfirmDialog}
        createNewInvoice={updateInvoiceInfo}
      />
    </div>
  );
};

export default EditInvoice;
