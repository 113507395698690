import React, { useState } from "react";
import { Layout } from "pages/kyb";
import styled from "styled-components";
import { Button, colors, textStyles } from "ui";
import ClientMainHeader from "pages/header";
import { Search } from "lucide-react";
import { BillsTable } from "./components/BillsTable";
import { BillsModal } from "./components";
import { useBondBalance } from "pages/dashboard/hooks";
import { useFetchKYBData } from "pages/dashboard/hooks/useFetchKYBData";
import useGetClientInfo from "pages/dashboard/hooks/useGetClientInfo";
import { FeatureBalanceCard } from "pages/dashboard/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomSelector } from "pages/utils/styled-select";
import { chart_date_options, money_list } from "pages/demo";
import { breakpoints } from "ui/breakpoints";

export const Bills = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const bondBalance = useBondBalance();
  const { data, isLoading, error } = useFetchKYBData();
  const clientInfo = useGetClientInfo();
  const history = useHistory();
  const [dateOption, setDateOption] = useState({ label: "30 days", value: 30 });
  const [kpiOption, setKpiOption] = useState({
    label: "Dollars",
    value: "dollars",
  });

  return (
    <Layout>
      <ClientMainHeader
        title="Bills"
        subTitle={
          <SpecialSubtitle>
            Pay your contacts and vendors from your Pocketbook balance by
            uploading their invoices or taking a picture and creating a payable.
          </SpecialSubtitle>
        }
      />
      <TopOptions>
        <Buttons>
          <Button variant="green" onClick={() => setOpen(true)}>
            Upload a Bill
          </Button>
          <Button variant="white" onClick={() => history.push("/bills/create")}>
            Manually pay a Bill
          </Button>
        </Buttons>
        <SearchBar>
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full rounded-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-skin-primary sm:text-sm sm:leading-6"
              placeholder="Search Bills"
            />
          </div>
        </SearchBar>
      </TopOptions>
      <Balance>
        <FeatureBalanceCard
          status={clientInfo?.baas_status}
          balance={bondBalance?.available_balance}
          data={data}
          isLoading={isLoading}
          error={error}
        />
        {/* <SummaryOptions>
                <SummaryHeader>Summary</SummaryHeader>
                <SummaryDropdowns>
                    <CustomSelector
                        value={dateOption}
                        setValue={setDateOption}
                        selectList={chart_date_options}
                        width={140}
                        menuWidth={140}
                    />
                    <CustomSelector
                        value={kpiOption}
                        setValue={setKpiOption}
                        selectList={money_list}
                        width={140}
                        menuWidth={140}
                    />
                </SummaryDropdowns>
            </SummaryOptions> */}
      </Balance>
      <BillsText>All Bills</BillsText>
      <BillsTable search={search} setSearch={setSearch} />
      <BillsModal open={open} handleClose={() => setOpen(false)} />
    </Layout>
  );
};

const SpecialSubtitle = styled.div`
  max-width: 550px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (${breakpoints.md}) {
    flex-wrap: nowrap;
  }
`;

const SearchBar = styled.div`
  margin-top: 20px;
  max-width: 500px;
  width: 100%;

  @media (${breakpoints.md}) {
    margin-top: 0;
    width: auto;
  }
`;

const BillsText = styled.div`
  ${textStyles.title.h3};
  margin-top: 15px;
`;

const Balance = styled.div`
  display: flex;
  max-width: 550px;
  margin-top: 20px;
  gap: 20px;
`;

const SummaryOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-basis: auto;
`;

const SummaryHeader = styled.div`
  ${textStyles.title.h3}
`;

const SummaryDropdowns = styled.div`
  display: flex;
  gap: 20px;
`;
