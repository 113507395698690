import styled from "styled-components";
/**
 * SortIcon component to display sort direction using Unicode arrows.
 * @param {Object} props - The component props.
 * @param {string} props.direction - The direction of the sorting ('asc', 'desc', or null).
 */
const SortIcon = ({ className, direction, display = false }) => {
  if (!display) return null;
  let icon = "↕️";
  if (direction === "asc") {
    icon = "↑";
  } else if (direction === "desc") {
    icon = "↓";
  }

  return <span className={className}>{icon}</span>;
};

const SortIconStyled = styled(SortIcon)``;

export default SortIconStyled;
