import styled from "styled-components";

export const UploadBtn = styled.div`
  background: #1db45a;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  max-width: 212px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 550px) {
    max-width: initial;
  }
`;

export const SmartContractBtn = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  min-width: 205px;
  max-width: 205px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  cursor: pointer;

  @media (max-width: 550px) {
    max-width: initial;
  }
`;

export const StyledContractTable = styled.div`
  white-space: nowrap;
  width: 100%;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: ${({ customer }) =>
    customer ? "repeat(3, 1fr) auto" : "repeat(4, 1fr) auto"};
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin-top: 30px;
  overflow-x: auto;

  @media (max-width: 550px) {
    margin-top: 0px;
  }
`;

export const ToolSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;

export const ToolBtnSection = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const TableOutlinedBtn = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  width: 154px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

export const TableContainedBtn = styled.div`
  background: #000000;
  border: 1px solid #000000;
  border-radius: 12px;
  width: 154px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
`;

export const SmartContractSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  width: 100%;
  max-width: 872px;
  padding: 24px;
`;

export const SmartContractWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FinalizeDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 35rem;
  max-width: 25rem;
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  h2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  h3 {
    margin: 1rem 0 1rem 0;
  }
  input {
    color: black;
  }
`;
export const DetailsOptions = styled.select`
  color: black;
  margin-bottom: 20px;
  background-color: white;
  white-space: normal; /* Ensure text wraps */
  text-align: left; /* Align text to the left */
  border-radius: 0.5rem;
`;
export const DetailsInfoText = styled.p`
  font-size: 0.9em;
  color: white;
  margin-bottom: 20px;
`;

export const Option = styled.option`
  display: block;
  background: transparent;
  border: none;
  text-align: left;
  padding: 10px;
  width: 100%;
  font-size: 1em;
  color: black;
  cursor: pointer;
  ${(props) =>
    props.selected ? "background-color: #f0f0f0;border-radius: 0.5rem;" : null}
  &:hover {
    background-color: #f0f0f0;
    border-radius: 0.5rem;
  }
`;

export const SmartOptionsContainer = styled.div`
  display: flex;
`;

export const DetailsButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
`;

export const DetailsButton = styled.button`
  background-color: white;
  border: 2px solid transparent; /* Added for hover effect */
  border-radius: 0.5rem;
  color: black;
  padding: 1rem;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease; /* Smooth transition for hover effects */

  &:hover {
    background-color: #333333; /* Slightly darker or different color on hover */
    color: white; /* Slightly darker text color for better contrast */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-color: #ddd; /* Optional: Add a border color on hover */
  }
  &:active {
    transform: translateY(-2px); /* Less raise on active to simulate press */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Less shadow on active */
  }
`;
export const SmartContractTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  padding-bottom: 4px;
`;

export const SmartContractDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

export const CopyText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const GenerateBtn = styled.div`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 12px;
`;

export const CancelBtn = styled.div`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
`;

export const DialogSubmitBtn = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #1db45a;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 6px;
`;

export const ChatGptSection = styled.div`
  margin-bottom: 30px;
  margin-right: initial;
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
  font-family: Inter;
  border: 1px solid #d0d5dd;
  color: #000000;
  font-size: 14px;
  font-weight: 590;
  padding: 10px 14px;
  white-space: pre-wrap;
`;

export const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
`;

// Customer
export const CustomerContractTable = styled.div`
  white-space: nowrap;
  width: 100%;
  // position: absolute;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  /* grid-template-columns: repeat(2, 1fr); */
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin-top: 30px;
  overflow-x: auto;

  @media (max-width: 550px) {
    margin-top: 10px;
  }
`;

export const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 20px;
`;

export const TableProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 6;
`;

export const TableToolBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &:hover {
    background-color: #e5e2e2;
  }
`;
