import { useEffect, useState } from "react";
import { convertThousandSeparator } from "scripts/functions";
import { getLineItemsFromPrompt } from "../actions";
import { LineItemSection } from "pages/customer/styled";

const QuoteDetailsTable = ({
  prompt,
  quoteItems,
  setQuoteItems,
  display = false,
}) => {
  const [loading, setLoading] = useState(true);

  const fetchItems = async () => {
    if (!display) {
      const { data } = await getLineItemsFromPrompt(prompt);
      setQuoteItems(data.line_items);
      setLoading(false);
    }
  };
  console.log(quoteItems);

  useEffect(() => {
    if (prompt) {
      fetchItems();
    }
  }, [prompt]);

  if (loading && !display) {
    return (
      <div className="mt-16 w-full">Generating quote data from prompt</div>
    );
  }
  console.log(quoteItems, prompt);

  return (
    <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Product or Service
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Units
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Rate
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {(quoteItems || []).map((item, i) => (
          <tr key={i} className="border-b border-gray-100">
            <td className="max-w-0 px-0 py-5 align-top">
              <div className="truncate font-medium text-gray-900">
                {item?.product_or_service}
              </div>
              {/* <div className="truncate text-gray-500">
                          {item.description}
                        </div> */}
            </td>
            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              {item.units || item.quantity}
            </td>
            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              ${convertThousandSeparator(Number(item.rate || item.unit_price))}
            </td>
            <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
              ${convertThousandSeparator(Number(item.price || item.item_total))}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        {/* <tr>
          <th
            scope="row"
            className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
          >
            Discounts
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
          >
            Discounts
          </th>
          <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
            ($
            {convertThousandSeparator(
              Number(
                quoteItems.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.discount;
                }, 0)
              )
            )}
            )
          </td>
        </tr> */}
        <tr>
          <th
            scope="row"
            className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
          >
            Subtotal
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
          >
            Subtotal
          </th>
          <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
            $
            {convertThousandSeparator(
              Number(
                quoteItems.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.price;
                }, 0)
              )
            )}
          </td>
        </tr>
        {/* <tr>
          <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
            Tax
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
          >
            Tax
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
            ${convertThousandSeparator(Number(0))}
          </td>
        </tr> */}
        {/* {invoice?.invoice_info.invoice_pass_processing_fee && (
          <tr>
            <th
              scope="row"
              className="pt-4 font-normal text-gray-700 sm:hidden"
            >
              Fee
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
            >
              Fee
            </th>
            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
              $
              {convertThousandSeparator(
                Number(invoice?.invoice_info.invoice_processing_fee)
              )}
            </td>
          </tr>
        )} */}
        <tr>
          <th
            scope="row"
            className="pt-4 font-semibold text-gray-900 sm:hidden"
          >
            Total
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
          >
            Total
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
            $
            {convertThousandSeparator(
              Number(
                quoteItems.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.price;
                }, 0)
              )
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default QuoteDetailsTable;
