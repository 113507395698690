import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import CustomerHeader from "./header";
import { CustomSelector } from "pages/utils/styled-select";
import { paginator } from "scripts/functions";
import {
  BtnGroup,
  CustomerContractTable,
  TableContainedBtn,
  TableOutlinedBtn,
} from "pages/contracts/styled";
import {
  MainWrapper,
  PaginationSection,
  TableLabel,
  TableItem,
} from "pages/styled";
import { useContracts } from "hooks/contract/use-contracts";
import { useProfile } from "hooks/profile/use-profile";
import { downloadContract } from "pages/contracts/actions";
import ContractTable from "pages/contracts/contract-table";

const customer_contract_demo = [
  {
    dateSent: "August 20, 2024",
    status: { label: "Unsigned", value: "Unsigned" },
  },
  { dateSent: "August 1, 2024", status: { label: "Signed", value: "Signed" } },
];

const status_option = [
  { label: "Unsigned", value: "Unsigned" },
  { label: "Signed", value: "Signed" },
];

const StyledTableLabel = styled(TableLabel)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const CustomerContracts = () => {
  const [shortCut, setShortCut] = useState("");
  const [page, setPage] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);

  const { data: profile, isLoading: isProfileLoading } = useProfile({
    id: JSON.parse(localStorage.getItem("CognitoId")),
  });

  const { data: contracts, loading } = useContracts({
    fromContactId: profile && profile.from_contact_id,
    enabled: !isProfileLoading && !!profile,
  });

  const count = contracts ? Math.ceil(contracts.length / 10) : 0;

  const handlePaginate = (event, value) => {
    setPage(paginator(contracts, value, 1).page);
  };

  const handleDownload = async (contract_info) => {
    const payload = {
      file_location: contract_info.contract_full_location,
    };
    await downloadContract(payload).then((res) => {
      if (res && res.downloadURLData) {
        window.open(res.downloadURLData, "_blank");
      }
    });
  };

  const contractsPaginated = paginator(contracts || [], page, 10).data?.sort(
    (a, b) => {
      return a?.contract_info?.contract_name?.localeCompare(
        b?.contract_info?.contract_name
      );
    }
  );

  console.log(contracts);
  return (
    <div className="h-100">
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        <CustomerHeader
          title="Quotes"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <ContractTable
          filteredList={contractsPaginated}
          loading={loading}
          customer
        />
        {/* <CustomerContractTable style={{ overflow: "visible" }}>
          <StyledTableLabel>Document Name</StyledTableLabel>
          <StyledTableLabel></StyledTableLabel>
          {contractsPaginated.map(({ contract_id, contract_info }, index) => (
            <React.Fragment key={contract_id}>
              <TableItem>{contract_info.contract_name}</TableItem>
              <TableItem>
                <div className="w-full flex justify-end">
                  <TableContainedBtn
                    onClick={() => handleDownload(contract_info)}
                  >
                    Download
                  </TableContainedBtn>
                </div>
              </TableItem>
            </React.Fragment>
          ))}
          <PaginationSection
            style={{
              gridColumn: "span 2",
            }}
            className="transaction-pagination"
          >
            <Pagination
              count={count}
              page={page}
              onChange={handlePaginate}
              color="success"
            />
          </PaginationSection>
        </CustomerContractTable> */}
      </MainWrapper>
    </div>
  );
};

export default CustomerContracts;
