export const UNPAID_INVOICE_STATUS = "unpaid";
export const UNPAID_AND_OVERDUE_INVOICE_STATUS = "unpaid_and_overdue";
export const PAID_INVOICE_STATUS = "paid";
export const DRAFT_INVOICE_STATUS = "draft";
export const PENDING_INVOICE_STATUS = "pending";
export const PROCESSING_INVOICE_STATUS = "processing";
export const PAST_DUE_INVOICE_STATUS = "past_due";

export const invoiceStatus = {
  [UNPAID_AND_OVERDUE_INVOICE_STATUS]: {
    label: "Unpaid & Overdue",
    value: UNPAID_AND_OVERDUE_INVOICE_STATUS,
  },
  [UNPAID_INVOICE_STATUS]: { label: "Unpaid", value: UNPAID_INVOICE_STATUS },
  [PAID_INVOICE_STATUS]: { label: "Paid", value: PAID_INVOICE_STATUS },
  [DRAFT_INVOICE_STATUS]: { label: "Draft", value: DRAFT_INVOICE_STATUS },
  [PENDING_INVOICE_STATUS]: { label: "Pending", value: PENDING_INVOICE_STATUS },
  [PROCESSING_INVOICE_STATUS]: {
    label: "Processing",
    value: PROCESSING_INVOICE_STATUS,
  },
  [PAST_DUE_INVOICE_STATUS]: {
    label: "Past Due",
    value: PAST_DUE_INVOICE_STATUS,
  },
};

export const invoiceStatusList = Object.values(invoiceStatus);

export const invoiceFrequencyList = [
  { label: "One-time", value: "one-time" },
  // { label: "Recurring", value: "recurring" },
  { label: "Scheduled", value: "scheduled" },
];
