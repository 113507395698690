import { columns } from "./columns";
import { DataTable } from "./data-table";
import CustomerMobileList from "./mobile-list";
import { useCustomerInvoices } from "hooks/invoice/use-customer-invoices";
import { Loader2 } from "lucide-react";

export default function CustomerInvoiceTable() {
  const { data, isLoading } = useCustomerInvoices();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-24">
        <Loader2 className="mr-2 h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className=" ">
      <CustomerMobileList data={data || []} />
      <DataTable columns={columns} data={data} />
    </div>
  );
}
