import api from "../config/api";

export const checkPayPalIntegration = async (cognitoId) => {
  const { data } = await api.post(`/paypal/has_paypal`, {
    cognito_id: cognitoId,
  });
  return data;
};

export const connectPayPalIntegration = async ({ cognito_id }) => {
  const payload = {
    cognito_id,
    redirect_uri: window.location.href.split("?")[0],
  };

  const { data } = await api.post(`/paypal/initiate_integration`, payload);
  return data;
};

export const validatePaypalIntegration = async (payload) => {
  const { data } = await api.post(`/paypal/validate_integration`, payload);
  return data;
};

export const disconnectPaypalIntegration = async (payment_method_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const payload = {
    cognito_id: clientCognitoId,
    payment_method_id,
  };

  const { data } = await api.post(`/paypal/disconnect_paypal`, payload);
  return data;
};
