import { BottomText, NewInvoiceBtn } from "../styled";
import moment from "moment";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const StyledButton = styled(NewInvoiceBtn)`
  background-color: ${(props) => props.color};
  ${(props) => props.savebutton && "margin-top:20px;"}
  ${(props) =>
    props.disabled
      ? "opacity:0.6; pointer-events:none; cursor:not-allowed"
      : ""}
`;

const InvoiceSubmit = ({ edit, updateInvoiceInfo, submitLoading }) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const { settingData } = useSelector((state) => state.account);

  return (
    <>
      <div className="flex space-x-2">
        <StyledButton
          className="disable"
          color={settingData?.primary_color}
          onClick={() => {
            setFieldValue("submit_type", "text");
            submitForm();
          }}
          disabled={submitLoading}
        >
          Send by text
        </StyledButton>
        <StyledButton
          color={settingData?.primary_color}
          onClick={() => {
            setFieldValue("submit_type", "email");
            submitForm();
          }}
          disabled={submitLoading}
        >
          Send by email
        </StyledButton>
        <StyledButton
          className="disable"
          color={settingData?.primary_color}
          onClick={() => {
            setFieldValue("submit_type", "all");
            submitForm();
          }}
          disabled={submitLoading}
        >
          Send by text and email
        </StyledButton>
      </div>
      {edit ? (
        <StyledButton
          color="black"
          style={{ marginTop: "1em" }}
          onClick={() => {
            setFieldValue("status", "draft");
            submitForm();
          }}
          disabled={submitLoading}
        >
          Save Changes
        </StyledButton>
      ) : (
        <BottomText>
          Not ready to send this invoice?{" "}
          <span
            className="text-underline cursor-pointer"
            onClick={() => {
              setFieldValue("status", "draft");
              submitForm();
            }}
          >
            Save as a draft.
          </span>
          {submitLoading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </BottomText>
      )}
    </>
  );
};

export default InvoiceSubmit;
