const sizes = {
  sm: 768,
  md: 1024,
  lg: 1280,
  xlg: 1440,
}

// All the breakpoints here can be use for both the hook and the css media queries
// however, based on suitability the have been separated.

export const breakpoints = {
  // FOR CSS MEDIA QUERIES (MORE SUITABLE)
  sm: `(min-width: ${sizes.sm}px)`,
  md: `(min-width: ${sizes.md}px)`,
  lg: `(min-width: ${sizes.lg}px)`,
  xlg: `(min-width: ${sizes.xlg}px)`,
  // FOR useMediaQuery HOOK
  smallerThan1440: '(max-width: 1440px)',
  smallerThan1200: '(max-width: 1200px)',
  smallerThan1024: '(max-width: 1024px)',
  smallerThan768: '(max-width: 767px)',
  smallerThan575: '(max-width: 575px)',
  smallerThan512: '(max-width: 512px)',
  smallerThan375: '(max-width: 375px)',
  smallerThanWidth: (width) => `(max-width: ${width})`
}