import {
  ColumnDetailSection,
  DetailBtn,
  DetailToolSection,
  TableToolBtn,
} from "pages/invoices/styled";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";

import { Button } from "components/ui/button-tw";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { BsList, BsXLg } from "react-icons/bs";
import styled from "styled-components";

const DetailTool = ({
  styled,
  item,
  handleDownload,
  handleDelete,
  customer,
  setSelectedContract,
  setConcentDialogOpen,
}) => {
  const history = useHistory();

  const handleSend = () => {
    setSelectedContract(item);
    setConcentDialogOpen(true);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        {!customer && item.contract_info.contract_status === "draft" ? (
          <>
            <DropdownMenuItem onClick={() => handleSend()}>
              Send
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuItem
          onClick={() => history.push(`/quotes/${item.contract_id}`)}
        >
          View details
        </DropdownMenuItem>
        {!customer ? (
          <DropdownMenuItem
            onClick={() =>
              history.push(`/invoices/new?contract=${item.contract_id}`)
            }
          >
            Create Invoice
          </DropdownMenuItem>
        ) : null}
        {/* {handleDownload ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDownload(item)}>
              Download
            </DropdownMenuItem>
          </>
        ) : null} */}
        {handleDelete && !customer ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(item)}>
              Delete
            </DropdownMenuItem>
          </>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
const DetailToolStyled = styled(DetailTool)``;

export default DetailToolStyled;
