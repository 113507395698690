import React from "react";
import { FormControl, TextField, InputBase, styled } from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import styledComponent from "styled-components";
import InputAdornment from "@mui/material/InputAdornment";

export const CustomLabel = styledComponent.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 6px;
`;

export const DollarIcon = styledComponent.div`
  position: absolute;
  top: 11px;
  left: 12px;
`;

export const ShowHideIcon = styledComponent.div`
  position: absolute;
  top: 13px;
  right: 12px;
  opacity: 0.7
`;

export const DomainText = styledComponent.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  position: absolute;
  right: 12px;
  top: 11px;
`;

const CustomInput = styled(InputBase)(({ theme, validation }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    position: "relative",
    fontFamily: "Inter",
    border: validation === "true" ? "1px solid #D0D5DD" : "1px solid #ff0000",
    color: "#000000",
    fontSize: 14,
    fontWeight: 590,
    padding: "10px 14px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80BDFF",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
CustomInput.propTypes = {
  validation: PropTypes.string,
};

const DomainInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    position: "relative",
    fontFamily: "Inter",
    border: "1px solid #D0D5DD",
    color: "#000000",
    fontSize: 14,
    fontWeight: 590,
    padding: "10px 130px 10px 14px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80BDFF",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const PriceInput = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    position: "relative",
    fontFamily: "Inter",
    color: "#000000",
    fontSize: 14,
    fontWeight: 590,
    overflow: "hidden",
    borderRadius: 8,
    backgroundColor: "#ffffff",
    border: "1px solid #D0D5DD",
    padding: "10px 14px 10px 28px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "&:before": {
      display: "none",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&.Mui-focused": {
      borderRadius: 8,
      borderColor: "#80BDFF",
    },
  },
  "& .MuiFilledInput-input": {
    padding: "0px",
  },
}));

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const StyledInput = ({
  type,
  label,
  value,
  setValue,
  placeholder,
  mBottom,
  mRight,
  mLeft,
  mTop,
  width,
  disabled,
  validation,
  readOnly = false,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        maxWidth: width ? width : "initial",
        width: "100%",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <CustomInput
        type={type ? type : "text"}
        value={value}
        id="bootstrap-input"
        placeholder={placeholder}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        readOnly={readOnly}
        disabled={disabled ? true : false}
        validation={validation === undefined ? "true" : validation.toString()}
      />
    </FormControl>
  );
};

export const StyledPriceInput = ({
  label,
  value,
  setValue,
  placeholder,
  mLeft,
  mBottom,
  mRight,
  mTop,
  width,
  readOnly = false,
  icon = `$`,
  balance = 0,
  moneyOut,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        width: width ? width : "auto",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <PriceInput
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        placeholder={placeholder}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          readOnly: readOnly,
          inputComponent: NumericFormatCustom,
        }}
        variant="filled"
      />
      <DollarIcon>{icon}</DollarIcon>
      {moneyOut && parseFloat(value) > parseFloat(balance) ? (
        <p style={{ color: "red" }}>
          The amount entered exceeds your current balance
        </p>
      ) : null}
    </FormControl>
  );
};

export const StyledDomainInput = ({
  label,
  value,
  setValue,
  placeholder,
  mLeft,
  mBottom,
  mRight,
  mTop,
  width,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        maxWidth: width ? width : "initial",
        width: "100%",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <DomainInput
        type="text"
        value={value}
        id="bootstrap-input"
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      <DomainText>.pocketbook.tech</DomainText>
    </FormControl>
  );
};

export const StyledPasswordInput = ({
  type,
  label,
  value,
  setValue,
  placeholder,
  mBottom,
  mRight,
  mLeft,
  mTop,
  width,
  disabled,
  validation,
  readOnly = false,
  showPassword,
  handleShowPassword,
}) => {
  return (
    <FormControl
      style={{
        position: "relative",
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        maxWidth: width ? width : "initial",
        width: "100%",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <CustomInput
        type={showPassword ? "text" : "password"}
        value={value}
        id="bootstrap-input"
        placeholder={placeholder}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        readOnly={readOnly}
        disabled={disabled ? true : false}
        validation={validation === undefined ? "true" : validation.toString()}
      />
      <ShowHideIcon onClick={handleShowPassword}>
        {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
      </ShowHideIcon>
    </FormControl>
  );
};

export const StyledTextInput = ({
  label,
  value,
  setValue,
  mTop,
  mBottom,
  mRight,
  width,
  rows,
  placeholder,
  readOnly = false,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginTop: mTop ? mTop : "initial",
        maxWidth: width ? width : "initial",
        width: "100%",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <CustomInput
        type="text"
        value={value}
        rows={rows ? rows : 6}
        multiline
        id="bootstrap-input"
        placeholder={placeholder ? placeholder : ""}
        validation="true"
        readOnly={readOnly}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </FormControl>
  );
};

export const StyledPercentageInput = ({
  label,
  value,
  setValue,
  placeholder,
  mLeft,
  mBottom,
  mRight,
  mTop,
  width,
}) => {
  return (
    <FormControl
      style={{
        marginBottom: mBottom ? mBottom : "initial",
        marginRight: mRight ? mRight : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginTop: mTop ? mTop : "initial",
        width: width ? width : "auto",
      }}
    >
      {label && <CustomLabel>{label}</CustomLabel>}
      <PriceInput
        value={value <= 0 ? null : value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        placeholder={placeholder}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        variant="filled"
      />
    </FormControl>
  );
};
