import * as actionTypes from "pages/customer/login/actionTypes";

const initialState = () => ({
  profileData: {}
});

const profileReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROFILE_DATA:
      return {
        ...state,
        profileData: payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
