import { mapStatusColor } from "components/invoices/customer-invoices-table/columns";
import styled from "styled-components";
import { cn } from "utils/classnames";
import { capitalizeFirstLetter } from "utils/string-manipulation";

const Status = ({ className, status }) => {
  return (
    <span className={`${className} flex items-center space-x-1`}>
      <span
        className={cn(
          mapStatusColor(status).accent,
          "h-4 w-4 flex items-center justify-center rounded-full"
        )}
        aria-hidden="true"
      >
        <span
          className={cn(mapStatusColor(status).main, "h-2 w-2 rounded-full")}
        />
      </span>
      <p className={cn("text-sm font-medium text-slate-900")}>
        {capitalizeFirstLetter(status)}
      </p>
    </span>
  );
};

const StatusStyled = styled(Status)`
  display: flex;
  column-gap: 1rem;
  p {
    color: ${(props) => (props.white ? "white" : null)};
    margin-left: 6px;
  }
`;

export default StatusStyled;
