import api from "api/v1-jwt/config/api";

export const fetchProfile = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/profile/${userId || clientCognitoId}`
  );

  return data;
};
