import { useState } from "react";
import { Dialog, CircularProgress } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import { deleteContact } from "./actions";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogSubmitBtn,
} from "./styled";
import { customTimeout } from "utils/functions";
import { toast } from "react-toastify";

const DeleteContactDialog = ({
  dialog,
  setDialog,
  selectedContact,
  setSelectedContact,
  getContactList,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleDeleteContact = async () => {
    setLoading(true);
    await deleteContact(selectedContact.contact_id).then(async (res) => {
      if (res && res.status === "success") {
        await getContactList();
        setSelectedContact(null);
        setLoading(false);
        setDialog(false);
        toast.success("Your contact has been deleted.")
        customTimeout(()=>{
          window.location.reload()
        })
      }
    });
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection className="welcome-dialog">
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription>
          Are you sure you'd like to delete this contact?
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            No! Keep it
          </DialogCancelBtn>
          <DialogSubmitBtn onClick={handleDeleteContact}>
            Yes, delete
            {loading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </DialogSubmitBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default DeleteContactDialog;
