import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { LabelStyle, StyledSelect, StyledTextField } from "../FormLabelStyles";
import { BasicModal, Button, colors, textStyles } from "ui";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersLayout } from "@mui/x-date-pickers";
import { Formik, Form, Field } from "formik";
import { useStep } from "../hooks/useStep";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import { platformEndpoints } from "../utils/PlatformEndpoints";
import { useHistory } from "react-router-dom";
import { EINInput } from "./EINInput";

export const BusinessInformationForm = ({ handleNext }) => {
  const [openModal, setOpenModal] = useState(false);
  const { isLoading, data, handlePost, isPostLoading, postError } = useStep(1);
  const [newDate, setNewDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [businessTypes, setBusinessTypes] = useState([]);
  const history = useHistory();
  const [ein, setEin] = useState("");
  const [einError, setEinError] = useState("");
  const [dateError, setDateError] = useState("");

  // POPOVER LOGIC
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const getData = async () => {
      try {
        const businessTypes = await platformEndpoints.fetchBusinessTypes();
        setBusinessTypes(businessTypes);
      } catch (error) {
        toast.error("Error fetching business types. Contact support.");
      }
    };

    getData();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validation = () => {
    let isValid = true;

    if (!ein) {
      isValid = false;
      setEinError(
        "Please provide a valid EIN entry. No spaces and 9 digits are required, ex. 91-1234567."
      );
    } else {
      setEinError("");
    }

    if (!newDate) {
      isValid = false;
      setDateError("Please input a valid date.");
    } else {
      setDateError("");
    }

    return isValid;
  };

  return (
    <Formik
      initialValues={{
        ...data,
        business_type: data?.business_type,
        date_established: null,
      }}
      enableReinitialize
      onSubmit={(values) => {
        if (validation()) {
          handlePost(
            {
              ...values,
              date_established: newDate ? newDate : data?.date_established,
              ein: ein.split("-").join(""),
            },
            handleNext
          );
        }

        if (postError) {
          toast.error(postError);
        }
      }}
    >
      {({ handleSubmit, values }) =>
        isLoading ? (
          <LoaderContainer>
            <CircularProgress color="inherit" size={24} />
          </LoaderContainer>
        ) : (
          <Form onSubmit={handleSubmit}>
            <div className="mt-8 space-y-6  border-gray-900/10 pb-12 sm:space-y-0  sm:divide-gray-900/10 sm:pb-0 max-w-[872px]">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Business Type
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormControl size="small" style={{ width: "100%" }}>
                    <Field
                      as={StyledSelect}
                      labelId="business_type_label"
                      name="business_type"
                      required
                    >
                      {businessTypes.map((item) => (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Legal Business Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    required
                    id="legal_business_name"
                    name="legal_business_name"
                    fullWidth
                    size="small"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  DBA
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    id="business_dba"
                    name="business_dba"
                    fullWidth
                    size="small"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *EIN/TIN
                  <InfoOutlinedIcon
                    sx={{ marginLeft: "5px" }}
                    aria-describedby={id}
                    onClick={handleClick}
                    color="disabled"
                    fontSize="12"
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{
                      maxWidth: "650px",
                    }}
                  >
                    <PopoverText>
                      This is your business tax ID number. You can find this on
                      your SS4 or other IRS documents.
                    </PopoverText>
                  </Popover>
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <EINInput ein={ein} setEin={setEin} />
                  <ErrorMessage>{einError}</ErrorMessage>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Date Established
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    name="date_established"
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ padding: 0 }}
                          components={["DatePicker"]}
                        >
                          <DatePicker
                            {...field}
                            sx={{
                              backgroundColor: "#fff",
                              fontFamily: "Inter",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            slots={{ layout: StyledPickersLayout }}
                            slotProps={{ textField: { size: "small" } }}
                            onChange={(date) => {
                              const dateObject = `${date.$M + 1}/${date.$D}/${
                                date.$y
                              }`;
                              setNewDate(dateObject);
                            }}
                            required
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                  />
                  <ErrorMessage>{dateError}</ErrorMessage>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Business Description
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    required
                    id="business_description"
                    name="business_description"
                    fullWidth
                    multiline
                    rows={2}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-x-4 lg:mt-4">
              <Button
                variant="white"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}
              >
                Cancel
              </Button>
              <Button variant="green" type="submit">
                {isPostLoading ? (
                  <CircularProgress style={{ color: "#FFFFFF" }} size={12} />
                ) : (
                  "Next"
                )}
              </Button>
            </div>
            <BasicModal
              title={"Are you sure you want to cancel setup?"}
              text={"If you cancel, you’ll need to start over."}
              open={openModal}
              buttonText={"Yes, cancel setup"}
              onButtonClick={() => history.push("/dashboard")}
              handleClose={handleCloseModal}
            />
          </Form>
        )
      }
    </Formik>
  );
};

const FormContent = styled.div`
  margin-top: 34px;
`;

const LightText = styled.span`
  font-weight: 400;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
`;

const StyledPickersLayout = styled(PickersLayout)({
  ".MuiDateCalendar-root": {
    color: "#000",
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
});

const LoaderContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const PopoverText = styled.div`
  padding: 10px;
  ${textStyles.body.b2}
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  ${textStyles.body.b2}
  color: ${colors.error};
`;
