import { getProfileInfo } from "pages/setup/actions";
import { useState, useEffect } from 'react';

const useUserProfile = () => {
    const [profileInfo, setProfileInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const getUserProfile = async () => {
        setLoading(true);
        try {
            const res = await getProfileInfo();
            if (res) {
                setProfileInfo(res);
            }
        } catch (error) {
            console.error("Error fetching profile info:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUserProfile();
    }, []);

    return { profileInfo, loading };
}

export default useUserProfile;