import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { Formik, Form, Field, useField, useFormikContext } from "formik";
import { LabelStyle, StyledTextField } from "../FormLabelStyles";
import { BasicModal, Button, textStyles } from "ui";
import styled from "styled-components";
import { Modal } from "@mui/material";
import { CustomSelector } from "pages/utils/styled-select";
import Box from "@mui/material/Box";
import StyledDatePicker from "pages/utils/styled-date-picker";
import * as Yup from "yup";
import { createBill } from "../actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../utils/actionTypes";
import { breakpoints } from "ui/breakpoints";
import useToast from "hooks/notifications/use-toast";
import dayjs from "dayjs";

import { Button as ButtonTw } from "components/ui/button-tw";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { saveBankInfo } from "api/v1-jwt/contact/contact";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getContactById } from "pages/contacts/actions";

import { Check, ChevronsUpDown, Plus } from "lucide-react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn } from "utils/classnames";
import { useContacts } from "hooks/contact/use-contacts";
import AddContactModal from "components/contact/add-contact-modal";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import { TextArea } from "components/form/textarea";
import { PhoneInput as TelInput } from "components/form/phone-input";

// TODO: Clean up this mess of a page
// TODO: Make form inputs consistent
// TODO: Make better form layout
// TODO: Recipient contact info shouldn't be editable

function getContactName({ contact_info }) {
  if (!contact_info)
    throw new Error("[GET_CONTACT_NAME] You must pass a valid contact object.");

  const { first_name, last_name, business_name } = contact_info;
  return business_name ? business_name : `${first_name} ${last_name}`.trim();
}

function getContactSearchValue({ contact_info }) {
  if (!contact_info)
    throw new Error("[GET_CONTACT_NAME] You must pass a valid contact object.");

  const { first_name, last_name, business_name, email } = contact_info;
  return `${email} ${business_name} ${first_name} ${last_name}`.trim();
}

export function ContactsComboBoxField({ label, ...props }) {
  const [open, setOpen] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [field] = useField(props);
  const context = useFormikContext();
  const [ocrExtracted, setOcrExtracter] = useState(false);
  const { file } = useSelector((state) => state.bills);
  const { data: contacts } = useContacts({
    onSuccess: (data) => {
      if (file && !ocrExtracted) {
        const { vendor_email, vendor_first_name, vendor_last_name, vendor } =
          file;

        const contact = data?.find((c) => {
          const { email, first_name, last_name, business_name } =
            c.contact_info;
          if (vendor_email.toLowerCase() === email.toLowerCase()) return true;
          if (
            business_name.toLowerCase() === vendor.toLowerCase() ||
            business_name.toLowerCase() ===
              `${vendor_first_name} ${vendor_last_name}`.toLowerCase()
          ) {
            return true;
          }
          if (
            `${first_name} ${last_name}`.toLowerCase() ===
              vendor.toLowerCase() ||
            `${first_name} ${last_name}`.toLowerCase() ===
              `${vendor_first_name} ${vendor_last_name}`.toLowerCase()
          ) {
            return true;
          }

          return false;
        });

        if (contact) {
          handleSelection(contact);
        }

        setOcrExtracter(true);
      }
    },
  });

  const handleSelection = (contact) => {
    context.setFieldValue("contact", contact);

    context.setFieldValue("selected_contact", contact);
    context.setFieldValue("last_name", contact.contact_info.last_name || "");
    context.setFieldValue("first_name", contact.contact_info.first_name || "");
    context.setFieldValue("email", contact.contact_info.email || "");
    context.setFieldValue("phone", contact.contact_info.phone || "");
    context.setFieldValue(
      "business_name",
      contact.contact_info.business_name || ""
    );

    if (context.values.account !== null) {
      context.setFieldValue("account", null);
    }

    setTimeout(() => context.setFieldTouched("contact", true));
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <ButtonTw
          variant="outline"
          role="combobox"
          className={cn(
            "w-full justify-between",
            !field.value && "text-muted-foreground"
          )}
        >
          {field.value ? getContactName(field.value) : "Select contact"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </ButtonTw>
      </PopoverTrigger>
      <PopoverContent className="popover-content-width-same-as-its-trigger max-h-[300px] overflow-y-auto overflow-x-hidden p-0">
        <Command className="overflow-y-hidden pb-16">
          <CommandInput
            placeholder="Search contact..."
            className="focus:shadow-none focus:ring-transparent outline-none border-none"
          />
          <CommandEmpty>No contact found.</CommandEmpty>
          <CommandGroup>
            {contacts?.map((contact) => (
              <CommandItem
                value={getContactSearchValue(contact)}
                key={contact.contact_id}
                onSelect={(currValue) => {
                  handleSelection(contact);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    contact.contact_id === field.value?.contact_id
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {getContactName(contact)}
                <span className="text-xs text-gray-500 ml-3">
                  {contact?.contact_info?.email &&
                    ` ${contact?.contact_info?.email}`}
                </span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
        <div className="px-2 py-2 fixed bottom-0 right-0 left-0">
          <ButtonTw
            className="w-full"
            onClick={() => setAddContact(true)}
            type="button"
          >
            <Plus className="mr-2 h-3 w-3 shrink-0" />
            Add contact
          </ButtonTw>
        </div>
      </PopoverContent>
      <AddContactModal
        open={addContact}
        onOpenChange={setAddContact}
        onCreateSuccess={(contact) => {
          getContactById(contact.contact_id)
            .then((c) => {
              handleSelection(c[0]);
            })
            .catch((error) => console.log(error));
        }}
      />
    </Popover>
  );
}

export default function AccountSelect({
  openModal,
  accounts,
  setAccounts,
  ...props
}) {
  const [field] = useField(props);
  const context = useFormikContext();

  useEffect(() => {
    const contact = context.values.contact;
    if (contact) {
      let accs = contact.contact_info.accounts.map(
        ({ attribute_values: acc }) => ({
          ...acc,
          label: `${acc.bank_name} ****${acc.account_num_last_4}`,
          value: acc.counter_party_id,
        })
      );
      setAccounts(accs);

      if (accs.length) {
        context.setFieldValue("account", accs[0]);
      }
    }
  }, [
    context.values.contact?.contact_id,
    context.values.contact?.contact_info?.accounts?.length,
  ]);

  return (
    <div>
      {/* <h2 className="text-base font-semibold text-gray-900">Transfer funds</h2>
      <p className="mt-1 text-sm text-gray-500">
        Transfer your balance to your bank account.
      </p> */}
      {accounts.length === 0 ? (
        <div className="text-center border border-dashed rounded-lg p-5">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No account found
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Add your contact’s bank information to send a payment.
          </p>
          <div className="mt-6">
            <ButtonTw
              variant="outline"
              type="button"
              onClick={() => openModal(true)}
            >
              <Plus className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              New Account
            </ButtonTw>
          </div>
        </div>
      ) : (
        <>
          <fieldset className="mt-2">
            <legend className="sr-only">Bank account</legend>
            <div>
              {accounts.map((account, accountIdx) => (
                <div
                  key={account.counter_party_id}
                  className="relative flex items-start pb-4 pt-3.5 border-b divide-gray-200"
                >
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label
                      htmlFor={`account-${account.counter_party_id}`}
                      className="font-medium text-gray-900"
                    >
                      {capitalizeFirstLetter(account.account_type)}
                    </label>
                    <p
                      id={`account-${account.counter_party_id}-description`}
                      className="text-gray-500"
                    >
                      {account.label}
                    </p>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      id={`${account.counter_party_id}`}
                      aria-describedby={`account-${account.counter_party_id}-description`}
                      name="account"
                      type="radio"
                      checked={
                        field.value?.counter_party_id ===
                        account.counter_party_id
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          const acc = accounts.find(
                            (acc) => acc.counter_party_id === e.target.id
                          );
                          context.setFieldValue("account", acc);
                        }
                      }}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
          <ButtonTw
            variant="link"
            type="button"
            onClick={() => openModal(true)}
          >
            <Plus className="w-4 h-4 mr-1" /> Add account
          </ButtonTw>
        </>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 5,
};

const NewBillFormSchema = Yup.object().shape({
  contact: Yup.object().required("Please select a Contact."),
  account: Yup.object().required("Please select an account."),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  business_name: Yup.string(),
  amount: Yup.number()
    .positive("Amount must be positive")
    .required("Amount is required"),
  invoice_number: Yup.string().nullable(),
});

export const NewBillForm = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { file } = useSelector((state) => state.bills);
  const dispatch = useDispatch();
  const history = useHistory();
  const { toastNotify } = useToast();

  const handlePayment = async ({
    account,
    contact,
    due_date,
    bill_date,
    ...values
  }) => {
    const payload = { ...values };
    if (due_date !== null && typeof due_date !== "string") {
      payload.due_date = due_date.toISOString();
    }

    if (bill_date !== null && typeof bill_date !== "string") {
      payload.bill_date = bill_date.toISOString();
    }

    payload.selected_account = account;
    payload.selected_contact = contact;

    try {
      await createBill(payload);
      toastNotify("Bill payment sent successfully", "success");
      history.push("/bills");
    } catch (e) {
      toastNotify("There was an error creating the bill", "error");
    }
  };

  const createFieldSetter =
    ({ setFieldValue }) =>
    (fieldName) =>
    (value) => {
      setFieldValue(fieldName, value);
    };

  // Cleanup function (unmount)
  useEffect(() => {
    return function cleanup() {
      setOpenModal(false);
      dispatch({
        type: actionTypes.SET_FILE,
        payload: null,
      });
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          phone: null,
          amount: file?.amount || 0,
          due_date: file?.due_date ? dayjs(file?.due_date) : null,
          bill_date: file?.bill_date ? dayjs(file?.bill_date) : null,
          invoice_number: file?.invoice_number || null,
          memo: "",
          contact: null,
          account: null,
        }}
        validateOnBlur
        validateOnChange
        validationSchema={NewBillFormSchema}
        onSubmit={async (values) => {
          setOpenConfirmationModal(true);
        }}
      >
        {({ handleSubmit, errors, touched, setFieldValue, values }) => {
          const setField = createFieldSetter({ setFieldValue });

          return (
            <>
              <Form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
                <FormGroup>
                  <HeaderText>To</HeaderText>
                  <ContactsComboBoxField name="contact" />
                  {errors.contact && (
                    <div style={{ color: "red" }}>{errors.contact}</div>
                  )}
                  {/* <ContactsCombobox onSelect={handleContactSelect} /> */}
                </FormGroup>
                <FormGroup>
                  <HeaderText>Recipient Contact Information</HeaderText>
                  <InputContainer>
                    <InputGroup>
                      <Field
                        as={Input}
                        name={`first_name`}
                        placeholder="First Name"
                        fullWidth
                        size="small"
                        autoComplete="off"
                      />
                      <Field
                        as={Input}
                        name={`last_name`}
                        placeholder="Last Name"
                        fullWidth
                        size="small"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {errors.first_name && touched.first_name && (
                      <div style={{ color: "red" }}>{errors.first_name}</div>
                    )}
                    {errors.last_name && touched.last_name && (
                      <div style={{ color: "red" }}>{errors.last_name}</div>
                    )}
                    <Field
                      as={Input}
                      name={`email`}
                      placeholder="Email"
                      autoComplete="off"
                    />
                    {errors.email && touched.email && (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    )}

                    <Field
                      as={TelInput}
                      name="phone"
                      autoComplete="off"
                      value={values.phone}
                      onChange={setField("phone")}
                    />
                    {!values.phone && touched.phone && (
                      <div style={{ color: "red" }}>{errors.phone}</div>
                    )}
                    <Field
                      as={Input}
                      name={`business_name`}
                      placeholder="Business Name (if applicable)"
                      fullWidth
                      size="small"
                      autoComplete="off"
                    />
                    {errors.business_name && touched.business_name && (
                      <div style={{ color: "red" }}>{errors.business_name}</div>
                    )}
                  </InputContainer>
                </FormGroup>
                <FormGroup>
                  {
                    !!values.contact && (
                      <>
                        <HeaderText>Select Destination Account</HeaderText>
                        <AccountSelect
                          name="account"
                          openModal={setOpenModal}
                          accounts={paymentList}
                          setAccounts={setPaymentList}
                        />
                        {errors.account && touched.account && (
                          <div style={{ color: "red" }}>{errors.account}</div>
                        )}
                      </>
                    )
                    // <CustomSelector
                    //   bank={true}
                    //   value={values.selected_account}
                    //   setValue={setField("selected_account")}
                    //   selectList={paymentList?.map((acc) => ({
                    //     label: formatAccountInfo(acc),
                    //     value: acc,
                    //   }))}
                    //   width={"310px"}
                    //   menuWidth={310}
                    //   placeholder="Select Account"
                    //   menuLeft
                    //   bottomButton={
                    //     <Button
                    //       type="button"
                    //       variant="black"
                    //       fullWidth
                    //       onClick={(e) => {
                    //         e.preventDefault();
                    //         if (values.selected_contact) {
                    //           setOpenModal(true);
                    //         } else {
                    //           toastNotify("Please select a contact.", "error");
                    //         }
                    //       }}
                    //     >
                    //       Add new account
                    //     </Button>
                    //   }
                    // />
                  }
                </FormGroup>
                <FormGroup>
                  <HeaderText>Amount</HeaderText>

                  <Field as={AmountInput} name="amount" />
                  {errors.amount && touched.amount && (
                    <div style={{ color: "red" }}>{errors.amount}</div>
                  )}
                </FormGroup>
                <FormGroupDetails>
                  <HeaderText>Bills Details</HeaderText>
                  <InputContainer>
                    <InputColumns>
                      <InputLabel sx={LabelStyle}>
                        Due Date (Optional)
                      </InputLabel>
                      <Field
                        as={StyledDatePicker}
                        size="small"
                        name="due_date"
                        value={values.due_date}
                        setValue={setField("due_date")}
                      />
                    </InputColumns>
                    <InputColumns>
                      <InputLabel sx={LabelStyle}>
                        Bill Date (Optional)
                      </InputLabel>
                      <Field
                        as={StyledDatePicker}
                        name={`bill_date`}
                        size="small"
                        value={values.bill_date}
                        setValue={setField("bill_date")}
                      />
                    </InputColumns>
                    <InputColumns>
                      <InputLabel sx={LabelStyle}>
                        Invoice Number (Optional)
                      </InputLabel>
                      <Field
                        as={StyledTextField}
                        name={`invoice_number`}
                        value={values.invoice_number}
                        setValue={setField("invoice_number")}
                        size="small"
                        autoComplete="off"
                      />
                    </InputColumns>
                    <InputColumns>
                      <InputLabel sx={LabelStyle}>Memo (Optional)</InputLabel>
                      <Field
                        as={TextArea}
                        name={`memo`}
                        value={values.memo}
                        onChange={setField("memo")}
                        placeholder="Internal notes for this payment. Only you can see this."
                        className="max-w-[300px]"
                      />
                    </InputColumns>
                  </InputContainer>
                </FormGroupDetails>
                <ActionButtons>
                  <Button
                    variant="white"
                    onClick={() => history.push("/bills")}
                  >
                    Cancel
                  </Button>
                  <Button variant="green" type="submit">
                    Send Payment
                  </Button>
                </ActionButtons>
              </Form>
              <AddAccountModal
                recipient={`${values.contact?.contact_info?.first_name} ${values.contact?.contact_info?.last_name}`.trim()}
                open={openModal}
                handleClose={() => setOpenModal(false)}
                setSelectedAccount={({ bank_info }) => {
                  const { account_entity_type, ...bankInfo } = bank_info;
                  const acc = {
                    ...bankInfo,
                    label: `${bankInfo?.bank_name} ****${bankInfo?.account_num_last_4}`,
                    value: bankInfo?.counter_party_id,
                  };

                  setFieldValue("account", acc);
                  setPaymentList((curr) => [...curr, acc]);
                }}
                recipientId={values.contact?.contact_id}
              />

              <BasicModal
                title={"Confirm your payment"}
                text={`The following payment will be made to ${
                  values.first_name
                } ${values.last_name} on ${
                  values.due_date ? values.due_date.format("LL") + ":" : ""
                } Bank Name: ${
                  values.account?.bank_name || values.account?.value?.bank_name
                } Routing #: ***${
                  values.account?.routing_num_last_4 ||
                  values.account?.value?.routing_num_last_4
                } Account #: ${
                  values.account?.account_num_last_4 ||
                  values.account?.value?.account_num_last_4
                } Amount: $${
                  values.amount
                } Estimated availability of funds will be 2-3 business days.`}
                open={openConfirmationModal}
                buttonText={"Confirm Payment"}
                onButtonClick={() => {
                  handlePayment(values);
                }}
                handleClose={() => setOpenConfirmationModal(false)}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

function AmountInput(props) {
  return (
    <div>
      <div className="relative mt-2 rounded-lg ">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="number"
          name="price"
          id="price"
          {...props}
          className=" pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6
        flex h-10 w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50
        "
          placeholder="0.00"
          aria-describedby="price-currency"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
    </div>
  );
}

const routingValidation = {
  required: "Routing number is required",
  validate: (value) => {
    if (!/^\d+$/.test(value)) {
      return "Routing number must be numeric";
    } else if (value.length !== 9) {
      return "Routing number must be 9 digits";
    }
    return undefined; // Validation passed
  },
};

const accountValidation = {
  required: "Account number is required",
  validate: (value) =>
    /^\d{5,17}$/.test(value) || "Account number must be between 5-17 digits",
};

function AddAccountModal({
  open,
  handleClose,
  recipient,
  setSelectedAccount,
  recipientId,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
    reset,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      accountType: { label: "Individual Checking", value: "checking" },
      account: "",
      routing: "",
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      const res = await saveBankInfo(
        recipient,
        { ...values, accountType: values.accountType.value },
        recipientId
      );

      if (res?.error) {
        toast.error(`Error with ${res?.error?.field} number`);
      } else {
        setSelectedAccount(res);
        reset();
        handleClose();
      }
    } catch (e) {
      reset();
      toast.error(e);
    }
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        reset();
        handleClose();
      }}
    >
      <form onSubmit={onSubmit}>
        <Box sx={style}>
          <Title>Add Account Details</Title>
          <Content>
            <Recipient>Recipient: {recipient || ""}</Recipient>
            {/* TODO: We should get the recipient's name from the form */}
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="type">Account type</Label>
              <Controller
                control={control}
                name="accountType"
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelector
                      value={field.value}
                      setValue={field.onChange}
                      selectList={[
                        { label: "Individual Checking", value: "checking" },
                        { label: "Individual Savings", value: "savings" },
                        {
                          label: "Business Checking",
                          value: "business_checking",
                        },
                        {
                          label: "Business Savings",
                          value: "business_savings",
                        },
                      ]}
                      width={"100%"}
                      menuWidth={"100%"}
                      placeholder="Account Type"
                      menuLeft
                    />
                  );
                }}
              />
              {errors.accountType && (
                <p role="alert" className="text-sm text-red-600">
                  {errors.accountType.message}
                </p>
              )}
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="routing">Routing #</Label>
              <Input
                id="routing"
                name="routing"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                placeholder=""
                maxLength={9}
                aria-invalid={errors.routing ? "true" : "false"}
                {...register("routing", routingValidation)}
              />
              {errors.routing && (
                <p role="alert" className="text-sm text-red-600">
                  {errors.routing.message}
                </p>
              )}
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="account">Account #</Label>
              <Input
                id="account"
                placeholder=""
                type="number"
                aria-invalid={errors.account ? "true" : "false"}
                {...register("account", accountValidation)}
              />
              {errors.account && (
                <p role="alert" className="text-sm text-red-600">
                  {errors.account.message}
                </p>
              )}
            </div>
          </Content>
          <Button
            variant="green"
            type="button"
            disabled={isSubmitting || !isValid}
            onClick={() => onSubmit()}
          >
            Confirm
          </Button>
        </Box>
      </form>
    </Modal>
  );
}

const ActionButtons = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
`;

const HeaderText = styled.div`
  ${textStyles.title.h3}
`;

const FormGroup = styled.div`
  margin-bottom: 40px;
`;

const FormGroupDetails = styled.div`
  margin-bottom: 40px;

  @media (${breakpoints.sm}) {
  }
`;

const InputGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const InputColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media (${breakpoints.md}) {
    flex-wrap: nowrap;
    gap: 0;
  }
`;

const Title = styled.div`
  ${textStyles.title.h3}
`;

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

const Recipient = styled.div`
  ${textStyles.body.b3}
`;
