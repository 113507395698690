import React from "react";
import { cn } from "utils/classnames";

const InputBase = React.forwardRef(
  (
    {
      className,
      type,
      error,
      IconLeft,
      IconRight,
      siblingLeft,
      siblingRight,
      ...props
    },
    ref
  ) => {
    return (
      <div className="relative w-full">
        {IconLeft && (
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <IconLeft className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          </div>
        )}
        <input
          type={type}
          className={cn(
            "bg-white flex h-10 w-full rounded-lg border border-input px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:bg-gray-100",
            {
              "border-red-500 bg-red-50 text-red-900 placeholder-red-700":
                error,
              "ps-10": IconLeft,
              "pe-10": IconRight,
              "rounded-l-none": siblingLeft,
              "rounded-r-none": siblingRight,
            },
            className
          )}
          ref={ref}
          {...props}
        />
        {IconRight && (
          <div class="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
            <IconRight className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          </div>
        )}
      </div>
    );
  }
);
InputBase.displayName = "InputBase";

export { InputBase };
