// {
//     "logo": "",
//     "marketing_logo": "",
//     "favicon": "",
//     "primary_color": "#000000",
//     "secondary_color": "",
//     "accent_color": "",
//     "client_name": "",
//     "platform_id": "string"

import api from "../config/api";

//   }
export const fetchPlatformData = async (subdomain) => {
  const { data } = await api.get(`platform/settings/subdomain/${subdomain}`);
  return data;
};
