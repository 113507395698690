import React, { useState } from "react";
import {
  Layout,
  BusinessInformationForm,
  KYBStepper,
  BusinessContactForm,
  OwnerInformation,
  SubmitForm,
  SetupPocketbook,
  ConfirmForm,
} from "./components";
import styled from "styled-components";
import { ControlPersonInfo } from "./components/ControlPersonInfo";

const steps = [
  "Business Information",
  "Business Contact",
  "Control Person",
  "Owner Information",
  "Confirm",
  "Submit",
];

const renderForm = (step, handleNext) => {
  switch (step) {
    case "Business Information":
      return (
        <>
          <FormTitle>Business Information</FormTitle>
          <BusinessInformationForm handleNext={handleNext} />
        </>
      );
    case "Business Contact":
      return (
        <>
          <FormTitle>Business Contact</FormTitle>
          <BusinessContactForm handleNext={handleNext} />
        </>
      );
    case "Control Person":
      return (
        <>
          <FormTitle>Control Person Information</FormTitle>
          <ControlPersonInfo handleNext={handleNext} />
        </>
      );
    case "Owner Information":
      return (
        <>
          <FormTitle>Other Beneficial Owner Information</FormTitle>
          <OwnerInformation handleNext={handleNext} />
        </>
      );
    case "Confirm":
      return (
        <>
          <FormTitle>Please confirm your information</FormTitle>
          <ConfirmForm handleNext={handleNext} />
        </>
      );
    case "Submit":
      return (
        <>
          <FormTitle>
            Please review and acknowledge the following documents.{" "}
          </FormTitle>
          <SubmitForm />
        </>
      );
    default:
      return <div>YOU HAVE COMPLETED THE KYB PROCESS</div>;
  }
};

export const KYBLanding = () => {
  const [hasStarted, setHasStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState("Business Information");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setCurrentStep(steps[activeStep + 1]);
  };

  return (
    <Layout>
      <ContentContainer>
        {!hasStarted ? (
          <SetupPocketbook setHasStarted={setHasStarted} />
        ) : (
          <>
            <TopContent>
              <div className="max-w-2xl">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Start Receiving Payments
                </h1>
                <p className="mt-2 text-lg leading-8 text-gray-700">
                  Set up your processing account in just minutes
                </p>
              </div>
            </TopContent>
            <Form>
              <KYBStepper
                steps={steps}
                setCurrentStep={setCurrentStep}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              {renderForm(currentStep, handleNext)}
            </Form>
          </>
        )}
      </ContentContainer>
    </Layout>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  font-size: 48px;
  font-family: "Inter";
  font-weight: 590;
  line-height: normal;
`;

const Subtitle = styled.div`
  font-size: 22px;
  font-family: "Inter";
  font-weight: 590;
  line-height: normal;
`;

const Form = styled.div`
  margin-top: 37px;
`;

function FormTitle({ children }) {
  return (
    <h2 className="text-base lg:text-lg font-semibold leading-7 text-gray-900 mt-8">
      {children}
    </h2>
  );
}
