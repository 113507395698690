import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import ConfirmTransferDialog from "./confirm-dialog";
import { getAllContacts } from "pages/contacts/actions";
import { getAccountBalance, getPaymentMethods } from "actions/payment-methods";
import { MainWrapper } from "pages/styled";
import { getAllAccounts } from "./actions";
import { CardSection, ViewTransactionText } from "./styled";
import PaySomeoneModal from "components/move-money/pay-someone-modal";
import { PaymentCard } from "./components/StyledCard";
import AddAccountModal from "components/move-money/add-account-modal";
import { useBondBalance } from "pages/dashboard/hooks";
import { useFetchKYBData } from "pages/dashboard/hooks/useFetchKYBData";
import useGetClientInfo from "pages/dashboard/hooks/useGetClientInfo";
import { FeatureBalanceCard } from "pages/dashboard/styled";
import { LimitedAccountWarning } from "components/limited-account-warning";

const MoveMoney = () => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);

  const [shortCut, setShortCut] = useState({
    label: "Move money",
    value: "move-money",
  });
  const [addMoneyOption, setAddMoneyOption] = useState(null);
  const [addMoneyAmount, setAddMoneyAmount] = useState("");
  const [paySomeOneOption, setPaySomeOneOption] = useState("");

  const [withdrawMoneyOption, setWithdrawMoneyOption] = useState(null);
  const [withdrawMoneyAmount, setWithdrawMoneyAmount] = useState("");

  const [selectedContact, setSelectedContact] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [contactList, setContactList] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentAccount, setPaymentAccount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");

  const [addBankAccountDetails, setAddBankAccountDetails] = useState("");

  const [currentWidth, setCurrentWidth] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [balance, setBalance] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [accountInfo, setAccountInfo] = useState(null);

  const [confirmAccountDetails, setConfirmAccountDetails] = useState(false);
  const [addAccountDetails, setAddAccountDetails] = useState(false);
  const bondBalance = useBondBalance();
  const { data, isLoading, error } = useFetchKYBData();
  const clientInfo = useGetClientInfo();

  useEffect(() => {
    getAllContactInfo();
    getPaymentMethodList();
    getBalanceInfo();
    getAccountInfo();
  }, []);

  const checkBalance = (value) => {
    if (value > bondBalance.available_balance) {
      alert("you don't have suficient balance");
      return false;
    }
    return true;
  };

  const getAllContactInfo = async () => {
    await getAllContacts().then((res) => {
      if (res && res.length > 0) {
        const filteredContacts = res.map((item) => {
          return {
            id: item.contact_id,
            label:
              item.contact_info.first_name + " " + item.contact_info.last_name,
            value:
              item.contact_info.first_name + " " + item.contact_info.last_name,
          };
        });
        setContactList(filteredContacts);
      }
    });
  };

  const getAccountInfo = async () => {
    await getAllAccounts().then((res) => {
      if (res) setAccountInfo(res);
    });
  };

  const getPaymentMethodList = async () => {
    await getPaymentMethods({ status: true }).then((res) => {
      if (res && res.length > 0) {
        const updatedList = res.map((item) => {
          console.log(item);
          return {
            ...item,
            label: item.payment_method_id,
            value: item.payment_method_id,
          };
        });
        setPaymentList(updatedList);
      }
    });
  };

  const getBalanceInfo = async () => {
    setBalanceLoading(true);
    await getAccountBalance().then((res) => {
      setBalanceLoading(false);
      setBalance(res);
    });
  };

  const handleAddMoney = () => {
    setPaymentType("add_money");
    setPaymentAccount(addMoneyOption);
    setPaymentAmount(addMoneyAmount);
    if (addMoneyAmount && addMoneyOption) setConfirmDialog(true);
  };

  const handleWithdrawMoney = () => {
    if (!checkBalance(withdrawMoneyAmount)) {
      return;
    }
    setPaymentType("withdraw_money");
    setPaymentAccount(withdrawMoneyOption);
    setPaymentAmount(withdrawMoneyAmount);
    if (withdrawMoneyOption && withdrawMoneyAmount !== "")
      setConfirmDialog(true);
  };

  const handlePaySomeone = () => {
    if (!checkBalance(payAmount)) {
      return;
    }
    setPaymentType("pay_someone");
    setPaymentAmount(payAmount);
    if (selectedContact !== "" && payAmount !== "") setConfirmDialog(true);
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />
        <MainWrapper>
          <ClientMainHeader
            title="Move money"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <FeatureBalanceCard
            status={clientInfo?.baas_status}
            balance={bondBalance?.available_balance}
            data={data}
            isLoading={isLoading}
            error={error}
          />
          <CardSection>
            <PaymentCard
              customSelectValue={addMoneyOption}
              customSelectorSetValue={setAddMoneyOption}
              customSelectorSelectList={paymentList}
              customSelectorPlaceholder="Select account"
              priceInputValue={addMoneyAmount}
              priceInputSetValue={setAddMoneyAmount}
              priceInputPlaceholder="Amount"
              cardTitle="Add money to your Pocketbook"
              primary_color={settingData?.primary_color}
              accountInfo={accountInfo}
              handleClick={handleAddMoney}
              arrowDirection="down"
              buttonText={"Submit"}
              moneyOut={false}
              has_amount
            />
            <PaymentCard
              customSelectValue={withdrawMoneyOption}
              customSelectorSetValue={setWithdrawMoneyOption}
              customSelectorSelectList={paymentList}
              customSelectorPlaceholder="Select account"
              priceInputValue={withdrawMoneyAmount}
              priceInputSetValue={setWithdrawMoneyAmount}
              priceInputPlaceholder="Amount"
              cardTitle="Withdraw money from Your Pocketbook"
              primary_color={settingData?.primary_color}
              accountInfo={accountInfo}
              handleClick={handleWithdrawMoney}
              arrowDirection="up"
              buttonText={"Submit"}
              balance={bondBalance}
              moneyOut={true}
              has_amount
            />
            <PaymentCard
              customSelectValue={paySomeOneOption}
              customSelectorSetValue={setPaySomeOneOption}
              customSelectorSelectList={selectedContact.accounts}
              buttonText={"Continue"}
              customSelectorPlaceholder="Select account"
              cardTitle="Pay someone from Your Pocketbook"
              priceInputValue={payAmount}
              priceInputSetValue={setPayAmount}
              priceInputPlaceholder="Amount"
              primary_color={settingData?.primary_color}
              accountInfo={accountInfo}
              setSelectedContact={setSelectedContact}
              setAccountInfo={setAccountInfo}
              selectedContact={selectedContact}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              handleClick={() => setAddAccountDetails(true)}
              arrowDirection="right"
              balance={bondBalance}
              moneyOut={true}
              contacts
            />
          </CardSection>
          <ViewTransactionText onClick={() => history.push("/transactions")}>
            View past transactions
          </ViewTransactionText>
        </MainWrapper>
      </>
      <ConfirmTransferDialog
        dialog={confirmDialog}
        setDialog={setConfirmDialog}
        type={paymentType}
        amount={paymentAmount}
        selectedAccount={selectedAccount}
        paymentAccount={paymentAccount}
        contact={selectedContact}
        account={accountInfo}
      />
      <PaySomeoneModal
        open={confirmAccountDetails}
        onOpenChange={setConfirmAccountDetails}
        contactId={selectedContact.contact_id}
      />
      <AddAccountModal
        open={addAccountDetails}
        onOpenChange={setAddAccountDetails}
        contactId={selectedContact.contact_id}
        selectedContact={selectedContact}
        setPaymentType={setPaymentType}
        selectedAccount={
          selectedContact?.contact_info?.accounts[0]?.attribute_values || null
        }
        setSelectedAccount={setSelectedAccount}
        user_account={accountInfo}
        balance={balance}
      />
      {/* <addAccountDetails
        open={addBankAccountDetails}
        onOpenChange={setAddBankAccountDetails}
      /> */}
    </div>
  );
};

export default MoveMoney;
