import React, { forwardRef, useMemo } from "react";
import { useFormikContext } from "formik";
import { CustomSelector } from "pages/utils/styled-select";
import { invoice_type_options } from "pages/demo";
import { useContacts } from "hooks/contact/use-contacts";
import { useState } from "react";
import { getContactName, getContactSearchValue } from "utils/contact";
import { Button } from "components/ui/button-tw";
import { Check, ChevronsUpDown, Plus } from "lucide-react";
import AddContactModal from "components/contact/add-contact-modal";
import { getContactById } from "pages/contacts/actions";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn } from "utils/classnames";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";

const transformContactValues = (contact) => ({
  contact_id: contact?.contact_id || "",
  invoiceName:
    (contact?.contact_info?.first_name || "") +
    " " +
    (contact?.contact_info?.last_name || ""),
  firstName: contact?.contact_info?.first_name || "",
  lastName: contact?.contact_info?.last_name || "",
  email: contact?.contact_info?.email || "",
  phone: contact?.contact_info?.phone || "",
  businessName: contact?.contact_info?.business_name || "",
  dbaName: contact?.contact_info?.business_dba || "",
  mailingAddr: contact?.contact_info?.business_address || "",
  city: contact?.contact_info?.business_city || "",
  state: contact?.contact_info?.business_state || "",
  zipCode: contact?.contact_info?.business_zip || "",
});

export function ContactsComboboxField() {
  const { values, setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [addContact, setAddContact] = useState();
  const [ocrExtracted, setOcrExtracted] = useState();
  const { data: contacts } = useContacts({
    onSuccess: (data) => {
      const processed = JSON.parse(localStorage.getItem("PROCESSED_INVOICE"));
      if (processed && !ocrExtracted) {
        const ocr = processed.invoice_to;

        const contact = data?.find((c) => {
          const { first_name, last_name, business_name } = c.contact_info;
          if (
            business_name.toLowerCase() === ocr.business_name?.toLowerCase() ||
            business_name.toLowerCase() ===
              `${ocr.first_name} ${ocr.last_name}`.toLowerCase()
          ) {
            return true;
          }
          if (
            `${first_name} ${last_name}`.toLowerCase() ===
              ocr.business_name?.toLowerCase() ||
            `${first_name} ${last_name}`.toLowerCase() ===
              `${ocr.first_name} ${ocr.last_name}`.toLowerCase()
          ) {
            return true;
          }

          return false;
        });

        if (contact) {
          handleSelect(contact);
        }

        setOcrExtracted(true);
      }
    },
  });

  const handleSelect = (selectedContact) => {
    setValues((values) => ({
      ...values,
      ...transformContactValues(selectedContact),
    }));
  };

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            className={cn(
              "w-full justify-between",
              !values.contact_id && "text-muted-foreground"
            )}
          >
            {values.contact_id
              ? getContactName(
                  contacts.find((item) => item.contact_id === values.contact_id)
                )
              : "Select contact"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="popover-content-width-same-as-its-trigger max-h-[300px] overflow-y-auto overflow-x-hidden p-0">
          <Command className="overflow-y-hidden pb-16">
            <CommandInput
              placeholder="Search ..."
              className="focus:shadow-none focus:ring-transparent outline-none border-none"
            />
            <CommandEmpty>No results.</CommandEmpty>
            <CommandGroup>
              {contacts.map((contact) => (
                <CommandItem
                  value={getContactSearchValue(contact)}
                  key={contact.contact_id}
                  onSelect={(curr) => {
                    console.log("CURRENT::", curr);
                    handleSelect(contact);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      values.contact_id === contact.contact_id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  <span className="text-nowrap">{getContactName(contact)}</span>
                  <span className="text-xs text-gray-500 ml-3 truncate">
                    {` ${contact.contact_info?.email || ""}`}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
          <div className="px-2 py-2 fixed bottom-0 right-0 left-0 ">
            <Button
              className="w-full"
              onClick={(e) => {
                e.preventDefault();
                setAddContact(true);
              }}
              type="button"
            >
              <Plus className="mr-2 h-3 w-3 shrink-0" />
              Add contact
            </Button>
          </div>
        </PopoverContent>
      </Popover>
      <AddContactModal
        open={addContact}
        onOpenChange={setAddContact}
        onCreateSuccess={(contact) => {
          getContactById(contact.contact_id)
            .then((c) => {
              const contact = Array.isArray(c) ? c[0] : c;
              handleSelect(contact);
            })
            .catch((error) => console.log(error));
        }}
      />
    </>
  );
}

const ToType = forwardRef(({ contact_id }, ref) => {
  const { values, setValues, setFieldValue, errors, touched } =
    useFormikContext();

  return (
    <div className="flex flex-col" ref={ref}>
      <div className=" mb-4">
        <label
          htmlFor="contact"
          className="block text-base font-medium leading-6 text-gray-900 mb-2"
        >
          To
        </label>
        <ContactsComboboxField />
        {errors.contact_id ? (
          <div className="text-red-600 font-normal text-xs">
            {errors.contact_id}
          </div>
        ) : null}
      </div>

      <div>
        <label
          htmlFor="type"
          className="block text-base font-medium leading-6 text-gray-900 mb-2"
        >
          Type
        </label>
        <CustomSelector
          //   {...invoiceTypeField}
          setValue={(option) => setFieldValue("invoiceType", option)}
          selectList={invoice_type_options}
          menuWidth={320}
          mBottom={16}
          menuLeft={-20}
          placeholder={"One-time"}
          isInvalid={errors.invoiceType && touched.invoiceType}
        />
        {errors.invoiceType && touched.invoiceType ? (
          <div style={{ color: "red" }}>{errors.invoiceType}</div>
        ) : null}
      </div>
    </div>
  );
});

export default ToType;
