import styled from "styled-components";

export const CreateBtn = styled.div`
  background: #1db45a;
  border-radius: 12px;
  width: 100%;
  max-width: 200px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    max-width: 500px;
    margin-right: 0px;
  }
`;

export const StyledContactTable = styled.div`
  white-space: nowrap;
  width: 100%;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin-top: 30px;
  overflow-x: auto;

  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

export const TableLabel = styled.div`
  min-height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  @media (max-width: 1000px) {
    padding: 13px 12px;
  }
`;

export const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const TableItem = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  padding: 16px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border-bottom: 1px solid #eaecf0;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

export const PaginationSection = styled.div`
  padding: 18px 24px;
  width: 100%;
  // max-width: 900px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
`;

export const ColumnDetailSection = styled.div`
  grid-column: span 4;
  padding: 33px 46px;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  position: relative;
`;

export const DetailTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-right: 30px;
`;

export const DetailLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px;
  width: 250px;
  white-space: normal;
`;

export const DetailGrid = styled.div`
  // max-width: 300px;
  white-space: nowrap;
  width: 100%;
  gap: 10px;
  display: grid;
  box-sizing: border-box;
  padding: 0.1rem;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
`;

export const DetailBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 20px;
  text-align: end;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export const DialogSection = styled.div`
  padding: 24px;
  background-color: #ffffff;
  text-align: center;
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const DialogTitle = styled.div`
  font-family: Inter;
  font-size: 22px;
  font-weight: 590;
  line-height: 26px;
  color: #000000;
  padding-bottom: 4px;
`;

export const DialogDescription = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #000000;
  padding-bottom: 20px;
`;

export const DialogCancelBtn = styled.div`
  width: 100%;
  max-width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
  margin-right: 6px;
`;

export const DialogSubmitBtn = styled.div`
  width: 100%;
  max-width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f15a25;
  border: 1px solid #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 34px;
`;

export const FormLabel = styled.div`
  width: 280px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding-right: 32px;
`;

export const FormSubLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
  // padding-top: 2px;
`;

export const NewContactCancelBtn = styled.div`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  cursor: pointer;
  margin-right: 12px;
  width: fit-content;
`;

export const NewContactSubmitBtn = styled.div`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormBtnSection = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 790px;
  justify-content: end;
`;

export const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 4;
`;

export const DetailSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1380px) {
    display: grid;
  }
`;

export const DetailBtnSection = styled.div`
  padding-top: 24px;

  @media (max-width: 1380px) {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  @media (max-width: 1250px) {
    gap: 20px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;

  @media (max-width: 1120px) {
    display: grid;
  }
`;

export const TableToolBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &:hover {
    background-color: #e5e2e2;
  }
`;
