import { useState, useLayoutEffect, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Drawer } from "@mui/material";
import styled from "styled-components";
import support_icon from "assets/images/question.png";
import setting_icon from "assets/images/setting.png";
import back_icon from "assets/images/back.png";
import menu_icon from "assets/images/hamburger-menu.png";
import dashboard_icon from "assets/images/dashboard/dashboard.png";
import contact_icon from "assets/images/dashboard/contacts.png";
import invoice_icon from "assets/images/dashboard/invoices.png";
import contract_icon from "assets/images/dashboard/contracts.png";
import integration_icon from "assets/images/dashboard/integrations.png";
import payment_icon from "assets/images/dashboard/payment-methods.png";
import transaction_icon from "assets/images/dashboard/transactions.png";
import receipt_text_icon from "assets/images/dashboard/receipt-text.png";
import pocketbook_logo from "assets/images/logo.png";
import {
  SidebarLabel,
  SidebarToolSection,
  StepIcon,
  StepSection,
  MobileNavWrapper,
  MobileNavHeader,
  MobileNavSection,
  DrawerSection,
  // SidebarMainNav,
} from "./styled";
import { useAuth } from "providers/auth";
import { toast } from "react-toastify";
import { UploadCloud } from "lucide-react";
import { useSettings } from "hooks/setting/use-settings";
import { LogoUploadModal } from "components/upload-logo-modal";
import { useSelector } from "react-redux";

const useRouteAccess = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [canAccess, setCanAccess] = useState(true);

  useEffect(() => {
    const role = user?.cognito_user_info?.UserRole;
    if (
      role === "admin" &&
      [
        "/bills",
        "/bills/create",
        "/payment-methods",
        "/plaid",
        "/move-money",
        "/settings",
      ].includes(location.pathname)
    ) {
      setCanAccess(false);
    }
  }, [user?.cognito_user_info?.UserRole]);

  return canAccess;
};

const useNavigationList = () => {
  const { user } = useAuth();
  const [navigation, setNavigation] = useState([
    { name: "Dashboard", value: "dashboard", icon: dashboard_icon },
    { name: "Contacts", value: "contacts", icon: contact_icon },
    { name: "Invoices", value: "invoices", icon: invoice_icon },
    { name: "Quotes", value: "quotes", icon: contract_icon },
    { name: "Integrations", value: "integrations", icon: integration_icon },
    { name: "Payment Methods", value: "payment-methods", icon: payment_icon },
    { name: "Transactions", value: "transactions", icon: transaction_icon },
    {
      name: "Bills",
      value: "bills",
      icon: receipt_text_icon,
    },
  ]);

  useEffect(() => {
    if (user?.cognito_user_info?.UserRole === "admin") {
      setNavigation((prev) =>
        prev.filter(
          (item) =>
            !["payment-methods", "bills", "settings"].includes(item.value)
        )
      );
    }
  }, [user?.cognito_user_info?.UserRole]);

  return navigation;
};

function UploadLogoButton({ showButton, onClick }) {
  if (!showButton) return null;
  return (
    <button
      onClick={onClick}
      type="button"
      className="group relative flex items-center bg-white lg:block lg:w-full rounded-lg border lg:border-2  border-slate-100 p-3 lg:p-6 text-center hover:border-slate-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
    >
      <UploadCloud className="w-4 h-4 lg:w-8 lg:h-8 text-slate-400 mx-auto group-hover:text-slate-700 transition-all" />

      <span className="ml-2 lg:ml-0 lg:mt-2 block text-sm font-semibold text-slate-700 group-hover:text-slate-900 transition-all">
        Upload your logo
      </span>
    </button>
  );
}
const SidebarWrapper = styled.div`
  width: 100%;
  max-width: 314px;
  min-height: 800px;
  background-color: ${(props) => props.color};
  padding: 38px;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-height: 800px) {
    min-height: 0px;
  }
`;

const Sidebar = ({ currentWidth, setCurrentWidth }) => {
  const history = useHistory();
  const { signout, user } = useAuth();
  const canAccess = useRouteAccess();
  const navigation = useNavigationList();
  const { data: settings } = useSettings();
  const logo = useSelector((state) => state.account.settingData.logo);
  const primaryColor = useSelector(
    (state) => state.account.settingData.primary_color
  );
  const [logoUploadOpen, setLogoUploadOpen] = useState(false);
  const [drawer, setDrawer] = useState({
    right: false,
  });
  const [logoError, setLogoError] = useState(false);

  const currentUrl = window.location.href;
  const currentPath = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);

  if (!canAccess) {
    history.replace("/");
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    setCurrentWidth(width);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleRedirect = (path) => {
    history.push(`/${path}`);
  };

  const handleLogout = async () => {
    await signout({
      onSuccess: (data) => {
        if (!data.is_authenticated) {
          history.push("/login");
        }
      },
      onError: (error) => {
        toast.error("Signout failed. Please try again in a moment.");
      },
    });
  };

  return (
    <>
      <LogoUploadModal
        setLogoError={setLogoError}
        open={logoUploadOpen}
        onOpenChange={setLogoUploadOpen}
      />
      {currentWidth > 768 ? (
        <SidebarWrapper color={primaryColor}>
          <div>
            {logo ? (
              <LogoImage
                onClick={() => handleRedirect("dashboard")}
                src={`https://cdn.pocketbook.tech/${logo}`}
                alt="dba-logo"
                onError={() => setLogoError(true)}
              />
            ) : (
              <UploadLogoButton
                showButton={!logo}
                onClick={() => setLogoUploadOpen(true)}
              />
            )}
          </div>
          <StepSection className="align-items-start">
            {navigation.map((item) => (
              <SidebarLabel
                key={item.value}
                className={currentPath === item.value && "opacity-10"}
                onClick={() => handleRedirect(item.value)}
              >
                <StepIcon src={item.icon} alt={item.value} />
                <span className="pl-25">{item.name}</span>
              </SidebarLabel>
            ))}
          </StepSection>
          <div style={{ flexGrow: 1 }}></div>
          <SidebarToolSection>
            {user?.cognito_user_info?.UserRole === "owner" && (
              <SidebarLabel
                className={currentPath === "settings" && "opacity-10"}
                onClick={() => handleRedirect("settings")}
              >
                <StepIcon src={setting_icon} alt="setting" />
                <span className="pl-25">Settings</span>
              </SidebarLabel>
            )}

            <SidebarLabel
              className={currentPath === "support" && "opacity-10"}
              onClick={() => handleRedirect("support")}
            >
              <StepIcon src={support_icon} alt="support" />
              <span className="pl-25">Support</span>
            </SidebarLabel>
            <SidebarLabel onClick={handleLogout}>
              <StepIcon src={back_icon} alt="back" />
              <span className="pl-25">Logout</span>
            </SidebarLabel>
          </SidebarToolSection>
        </SidebarWrapper>
      ) : (
        <MobileNavWrapper style={{ backgroundColor: primaryColor }}>
          <MobileNavHeader>
            {settings && logo ? (
              <img
                onClick={() => handleRedirect("dashboard")}
                src={`https://cdn.pocketbook.tech/${logo}`}
                alt="dba-logo"
                className="h-[37px] w-auto"
              />
            ) : (
              <UploadLogoButton onClick={() => setLogoUploadOpen(true)} />
            )}
            <img
              src={menu_icon}
              alt="menu"
              className="cursor-pointer w-[25px] h-auto"
              onClick={toggleDrawer("right", true)}
            />
          </MobileNavHeader>
          <Drawer
            anchor={"right"}
            open={drawer["right"]}
            onClose={toggleDrawer("right", false)}
            PaperProps={{
              sx: {
                backgroundColor: primaryColor,
              },
            }}
          >
            <DrawerSection>
              <MobileNavSection className="align-items-start">
                {navigation.map((item) => (
                  <SidebarLabel
                    key={item.value}
                    className={currentPath === item.value && "opacity-10"}
                    onClick={() => handleRedirect(item.value)}
                  >
                    <StepIcon src={item.icon} alt={item.value} />
                    <span className="pl-25">{item.name}</span>
                  </SidebarLabel>
                ))}
              </MobileNavSection>
              <div style={{ flexGrow: 1 }}></div>
              <SidebarToolSection>
                {user?.cognito_user_info?.UserRole === "owner" && (
                  <SidebarLabel
                    className={currentPath === "settings" && "opacity-10"}
                    onClick={() => handleRedirect("settings")}
                  >
                    <StepIcon src={setting_icon} alt="setting" />
                    <span className="pl-25">Settings</span>
                  </SidebarLabel>
                )}
                <SidebarLabel
                  className={currentPath === "support" && "opacity-10"}
                  onClick={() => handleRedirect("support")}
                >
                  <StepIcon src={support_icon} alt="support" />
                  <span className="pl-25">Support</span>
                </SidebarLabel>
                <SidebarLabel onClick={handleLogout}>
                  <StepIcon src={back_icon} alt="back" />
                  <span className="pl-25">Logout</span>
                </SidebarLabel>
              </SidebarToolSection>
            </DrawerSection>
          </Drawer>
        </MobileNavWrapper>
      )}
    </>
  );
};

const LogoImage = styled.img`
  height: 65px;
  width: auto;
  object-fit: contain;
`;

export default Sidebar;
