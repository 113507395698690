import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

export const LabelStyle = {
    display: "flex",
    fontWeight: 590,
    fontFamily: "Inter",
    color: '#000',
    height: "100%",
    alignItems: "center",
    fontSize: "14px",
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  };
  
export const SpecialLabelStyle = {
    display: "flex",
    fontWeight: 590,
    fontFamily: "Inter",
    color: '#000',
    height: "100%",
    alignItems: "flex-start",
    fontSize: "14px",
    flexDirection: "column",
    wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      gap: "5px"
  };

export const StyledTextField = styled(TextField)`
  background-color: white;
  `;

export const StyledSelect = styled(Select)`
  background-color: white;
`;

export const StyledPhoneInput=styled(PhoneInput)`
  &.react-tel-input{
  border-radius: 0px !important;
  /* border: none; */
  }
`