import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, CircularProgress, Select, MenuItem } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { BsXLg } from "react-icons/bs";
import moment from "moment";
import styled from "styled-components";
import upload_icon from "assets/images/dashboard/upload.png";
import { CustomSelector } from "pages/utils/styled-select";
import { getAllContacts } from "pages/contacts/actions";
import { createContract, uploadUrl } from "./actions";
import { toast } from "react-toastify";

const DialogSection = styled.div`
  padding: 24px;
  position: relative;
`;
const DialogTitle = styled.div`
  font-size: 22px;
  font-weight: 590;
  font-family: Inter;
  color: #000000;
  padding-bottom: 4px;
  text-align: center;
`;
const DialogBody = styled.div`
  padding: 20px 0px;
`;
const UploadFileSection = styled.div`
  width: 100%;
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
`;
const UploadTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
`;
const UploadSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #939393;
`;

const ContractUploadDialog = ({
  dialog,
  setDialog,
  getAllContracts,
}) => {
  const { settingData } = useSelector((state) => state.account);
  const [selectedContact, setSelectedContact] = useState("");
  const [contactList, setContactList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContactList();
  }, [dialog, setDialog]);

  console.log(contactList)

  const getContactList = async () => {
    await getAllContacts().then((res) => {
      if (res && res.length > 0) {
        const filteredContacts = res.map((item) => {
          return {
            ...item,
            label:
              item.contact_info.first_name + " " + item.contact_info.last_name,
            value:
              item.contact_info.first_name + " " + item.contact_info.last_name,
          };
        });
        setContactList(filteredContacts);
      }
    });
  };

  const handleUploadFile = async (file) => {
    setUploadedFile(file[0]);
  };

  const handleUploadContract = async () => {
    if (uploadedFile) {
      let folderName;
      let params;
      if (selectedContact !== "") {
        folderName = `contracts/${selectedContact.contact_id}`;
        params = `folder_name=${folderName}&file_name=${uploadedFile.name}`;
      } else {
        folderName = `contracts/${uploadedFile.name}`;
        params = `file_name=${uploadedFile.name}`;
      }
      setLoading(true);
      await uploadUrl(params, uploadedFile).then((res) => {
        handleCreateContact(res.url);
      });
    }
  };

  const handleCreateContact = async (params) => {
    const payload = {
      uploaded_date: moment(
        params.uploadURLData.fields["x-amz-date"],
        "YYYYMMDDTHHmmssZ"
      ).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      contact_id: selectedContact !== "" ? selectedContact.contact_id : "",
      contract_full_location: params.key,
    };

    await createContract(payload).then((res) => {
      if (res && res.status === "success") {
        setLoading(false);
        toast.success("New contract created successfully.")
        getAllContracts();
        handleDialogClose();
      }
    });
  };

  const handleDialogClose = () => {
    setSelectedContact([]);
    setUploadedFile(null);
    setDialog(false);
  };

  const UploadBtn = styled.div`
    background-color: ${settingData?.primary_color};
    width: 100%;
    height: 37px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 590;
    font-family: Inter;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
            overflow: "initial",
          },
        },
      }}
    >
      <DialogSection>
        <BsXLg
          onClick={handleDialogClose}
          style={{ position: "absolute", top: 16, right: 16 }}
        />
        <DialogTitle>Upload a contract</DialogTitle>
        <DialogBody>
          {/* <CustomSelector
            value={selectedContact}
            setValue={setSelectedContact}
            selectList={contactList}
            width={"100%"}
            menuWidth={"100%"}
            mBottom={25}
            placeholder={"Assign to a contact"}
            menuLeft
          /> */}
          <Select
            name="contact"
            displayEmpty={true}
            renderValue={(selected) => (selected ? selected?.label : 'Assign to a contact')}
            sx={{
              marginBottom: "15px",
              width: "100%",
              borderRadius: "8px"
            }}
            onChange={(e)=>setSelectedContact(e.target.value)}
          >
          {contactList?contactList?.map((contact) => (
              <MenuItem value={contact} key={contact.label}>
                {contact.label}
              </MenuItem>
            )): <MenuItem disabled>Loading</MenuItem>}
          </Select>
          <FileUploader
            multiple={true}
            handleChange={handleUploadFile}
            name="file"
            // types={["PNG", "JPG", "GIF", "SVG"]}
            children={
              <UploadFileSection
                style={{
                  border: uploadedFile
                    ? "1px solid #bbadad"
                    : "1px solid #dfdfdf",
                }}
              >
                <img
                  src={upload_icon}
                  width="40px"
                  height="40px"
                  alt="upload"
                />
                <UploadTitle>
                  <span className="font-medium">Click to upload</span> or drag
                  and drop
                </UploadTitle>
                {uploadedFile && (
                  <UploadSubTitle>
                    File uploaded: {uploadedFile.name}
                  </UploadSubTitle>
                )}
              </UploadFileSection>
            }
          />
        </DialogBody>
        <UploadBtn onClick={handleUploadContract}>
          Upload
          {loading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </UploadBtn>
      </DialogSection>
    </Dialog>
  );
};

export default ContractUploadDialog;
