import { useForm, Controller } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import { StyledAuthPassword } from "pages/utils/styled-auth-input";
import { LoadingWrapper } from "pages/styled";
import {
  FormGroupSection,
  RightSection,
  PocketbookLogo,
  RightImage,
  SignupFormTitle,
} from "./styled";
import { Button } from "components/ui/button-tw";
import { Loader2 } from "lucide-react";
import { useState, useLayoutEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAdminSignup } from "hooks/auth/use-admin-signup";
import { useSettings } from "hooks/setting/use-settings";
import { Checkbox } from "components/ui/check-box";
import { useAuth } from "providers/auth";
import { useDispatch } from "react-redux";
import * as actionTypes from "pages/customer/signup/actionTypes";
import { usePlatformUser } from "hooks/tenancy/use-platform-user";

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(12, "Password length should be at least 12 characters")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Passwors must contain at least one special character"
    )
    .matches(/[A-Z]/, "Passwors must contain at least one uppercase letter"),
  confirm: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  tos: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms of service and privacy policy must be accepted."),
});

export const AdminSignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { signin, isSigningIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { data: settings, isLoading: loadingSettings } = useSettings();

  const { mutate: signup } = useAdminSignup();
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues: { password: "", confirm: "" },
    resolver: yupResolver(formSchema),
  });

  const search = new URLSearchParams(window.location.search);
  const { isLoading: loadingUserInfo } = usePlatformUser({
    userId: search.get("user_id"),
    onSuccess: (data) => {
      if (data?.record_status === "inactive") {
        history.replace("/admin-no-access");
      }
    },
    onError: () => {
      history.replace("/login");
      toast.error("Something went wrong. Try the email link again.");
    },
  });

  useLayoutEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const email = search.get("email");
    const firstName = search.get("first_name");
    const lastName = search.get("last_name");
    const apiKey = search.get("api_key");
    const userId = search.get("user_id");

    if (!apiKey || !email || !firstName || !lastName || !userId) {
      history.push("/login");
      toast.error("The link to set password expired or is invalid.", {
        autoClose: 15000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }, [window.location.search]);

  const onSubmit = async ({ password, tos }) => {
    const search = new URLSearchParams(window.location.search);
    const email = search.get("email");
    const firstName = search.get("first_name");
    const lastName = search.get("last_name");
    const apiKey = search.get("api_key");
    const userId = search.get("user_id");

    const payload = {
      password,
      email,
      apiKey,
      first_name: firstName,
      last_name: lastName,
      accepted_pb_tos_pp: tos,
      account_type: "admin",
      user_type: "admin",
      user_id: userId,
    };

    signup(payload, {
      onSuccess: async () => {
        handleSignIn({
          username: email,
          password: password,
        });
        toast.success("Password set successfully. We are logging you in...", {
          autoClose: 15000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onError: (error) => {
        toast.error("Something went wrong while setting the password.", {
          autoClose: 15000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        console.log("PASSWORD RESET ERROR::", error);
      },
    });
  };

  const handleSignIn = async (payload) => {
    await signin(payload, {
      onSuccess: async (data) => {
        dispatch({
          type: actionTypes.SET_PROFILE_DATA,
          payload: data.user_profile,
        });
        history.push("/dashboard");
      },

      onError: (error) => {
        console.log("[LOGIN ERROR]::::::: ", error);
        toast.error("Login failed. Please, check your credentials");
      },
    });
  };

  const handleLinkTOS = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Terms_of_Service.pdf",
      "_blank"
    );
  };

  const handleLinkPrivacyPolicy = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Privacy_Policy.pdf",
      "_blank"
    );
  };

  if (loadingSettings || loadingUserInfo) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="bg-white w-full sm:w-1/2 relative overflow-auto h-full p-6 sm:p-8 lg:p-12 flex flex-col justify-between">
        <div className="lg:mt-2">
          <PocketbookLogo src={logo} alt="logo" />
        </div>

        <div className="max-w-[600px] lg:pr-20 lg:pl-12 pb-24">
          <div>
            <SignupFormTitle>Set your password</SignupFormTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroupSection>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState, formState }) => {
                    const { onChange, ref, ...rest } = field;
                    return (
                      <StyledAuthPassword
                        {...rest}
                        placeholder="Password"
                        isSubmitted={formState.isSubmitted}
                        handleChange={onChange}
                        showPassword={showPassword}
                        handleShowPassword={() =>
                          setShowPassword((show) => !show)
                        }
                        errMsg={fieldState.error?.message}
                        validation={
                          fieldState.isDirty //&& formState.isSubmitted
                            ? !fieldState.invalid
                            : undefined
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="confirm"
                  control={control}
                  render={({ field, fieldState, formState }) => {
                    const { onChange, ref, ...rest } = field;
                    return (
                      <StyledAuthPassword
                        {...rest}
                        placeholder="Retype your password to confirm"
                        validation={
                          fieldState.isDirty //&& formState.isSubmitted
                            ? !fieldState.invalid
                            : undefined
                        }
                        errMsg={fieldState.error?.message}
                        isSubmitted={formState.isSubmitted}
                        handleChange={onChange}
                        showPassword={showConfirmPassword}
                        handleShowPassword={() =>
                          setShowConfirmPassword((show) => !show)
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="tos"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center space-x-2 mb-8">
                      <Checkbox
                        id="tos"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <label
                        htmlFor="tos"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        I agree to Pocketbook's{" "}
                        <span
                          className="text-underline cursor-pointer"
                          onClick={handleLinkTOS}
                        >
                          Terms of Service
                        </span>{" "}
                        and{" "}
                        <span
                          className="text-underline cursor-pointer"
                          onClick={handleLinkPrivacyPolicy}
                        >
                          Privacy Policy
                        </span>
                      </label>
                    </div>
                  )}
                />
                <Button
                  disabled={
                    !formState.isValid || formState.isSubmitting || isSigningIn
                  }
                  className="w-24"
                >
                  {formState.isSubmitting ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </FormGroupSection>
            </form>
          </div>
        </div>
        <div className="flex justify-start ">
          {/* <Button
            variant="link"
            className="text-slate-600 pl-0"
            onClick={backToLogin}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Button> */}
        </div>
      </div>
      <RightSection
        style={{
          backgroundColor: settings.primary_color
            ? settings.primary_color
            : "#000",
        }}
      >
        <RightImage
          src={
            settings?.logo
              ? `https://cdn.pocketbook.tech/${settings?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
    </div>
  );
};

export default AdminSignup;
