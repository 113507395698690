import api from "api/v1-jwt/config/api";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllContacts = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(`/platform/contacts/${clientCognitoId}`);

    return data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getContactById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/contacts/${clientCognitoId}?contact_id=${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getContactByName = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/platform/contacts/contact/by_name`, {
    ...payload,
    cognito_id: clientCognitoId,
  });

  return data;
};

export const createContact = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contacts/${clientCognitoId}`,
    payload
  );

  return data;
};

export const updateContact = async (payload, id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/contacts/${clientCognitoId}/${id}`,
    payload
  );

  return data;
};

export const deleteContact = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/contacts/${clientCognitoId}/${id}`
  );

  return data;
};

export const saveBankInfo = async (name_on_account, values, contact_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const bank_data = {
    name_on_account,
    ach: {
      account_number: values.account,
      routing_number: values.routing,
      account_type: values.accountType,
    },
  };

  const { data } = await api.post(
    `/platform/contacts/add_bank/${clientCognitoId}/${contact_id}`,
    bank_data
  );
  return data.bank_info;
};
