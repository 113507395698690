import React, { useState } from "react";
import { CircularProgress, Pagination } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import { convertThousandSeparator, paginator } from "scripts/functions";
import { useBills } from "hooks/bill/use-bills";
import { useMemo } from "react";
import { Menu } from "lucide-react";
import { useBillDrawer } from "hooks/bill/use-bill-drawer";

export const BillsTable = ({ search }) => {
  const [page, setPage] = useState(0);
  const { handleOpen } = useBillDrawer();
  const { data, isLoading } = useBills();
  const bills = useMemo(() => {
    return (data || []).filter((item) => {
      const name = `${item.first_name || ""} ${item.last_name || ""}`
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "");
      const businessName = item.business_name
        ? item.business_name.toString().toLowerCase().replace(/\s+/g, "")
        : "";
      const vendorName = item.vendor_name
        ? item.vendor_name.toString().toLowerCase().replace(/\s+/g, "")
        : "";

      const matchesSearch =
        (name.includes(search.toLowerCase().replace(/\s+/g, "")) && name) ||
        (businessName.includes(search.toLowerCase().replace(/\s+/g, "")) &&
          businessName) ||
        (vendorName.includes(search.toLowerCase().replace(/\s+/g, "")) &&
          vendorName);

      return matchesSearch;
    });
  }, [data, search]);

  const count = Math.ceil(bills?.length ? bills?.length / 10 : 0);

  const handlePaginate = (event, value) => {
    setPage(paginator(bills, value, 1).page);
  };

  return (
    <div>
      <div className="overflow-auto border-x grid grid-cols-5 whitespace-nowrap">
        <TableLabel className="bg-skin-primary">Due Date</TableLabel>
        <TableLabel className="bg-skin-primary">Amount</TableLabel>
        <TableLabel className="bg-skin-primary">To</TableLabel>
        <TableLabel className="bg-skin-primary">Status</TableLabel>
        <TableLabel className="bg-skin-primary justify-center">View</TableLabel>
        {isLoading && (
          <ProgressSection>
            <CircularProgress />
          </ProgressSection>
        )}
        {paginator(bills, page, 10).data.map((item, index) => (
          <React.Fragment key={"bill" + index}>
            <TableItem>
              {item.due_date ? moment(item.due_date).format("MM/DD/YYYY") : ""}
            </TableItem>
            <TableItem>
              {Number(item.amount) > 0
                ? `$${convertThousandSeparator(Number(item.amount))}`
                : `-$${convertThousandSeparator(
                    Math.abs(Number(item.amount))
                  )}`}
            </TableItem>
            <TableItem>{item.vendor_name}</TableItem>
            <TableItem>{item.status}</TableItem>
            <TableItem className="justify-center">
              <TableToolBtn
                onClick={() => {
                  handleOpen(item.bill_id);
                }}
              >
                <Menu className="w-4 h-4" />
              </TableToolBtn>
            </TableItem>
          </React.Fragment>
        ))}
      </div>
      <PaginationSection className="transaction-pagination">
        <Pagination
          count={count}
          page={page}
          onChange={handlePaginate}
          color="success"
        />
      </PaginationSection>
    </div>
  );
};

const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  grid-column: span 6;
`;

const TableLabel = styled.div`
  min-height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  @media (max-width: 1000px) {
    padding: 13px 12px;
  }
`;

const TableItem = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  padding: 16px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border-bottom: 1px solid #eaecf0;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

const PaginationSection = styled.div`
  padding: 18px 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
`;

const TableToolBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &:hover {
    background-color: #e5e2e2;
  }
`;
