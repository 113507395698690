import styled from "styled-components";

const SmartContractDescription = ({ stage, prompt }) => {
  if (stage == 0) {
    <SmartContractDescriptionStyled>
      Please select a Contact
    </SmartContractDescriptionStyled>;
  }
  if (stage == 1) {
    return (
      <SmartContractDescriptionStyled>
        Try typing something like, “Write a contract for me. I’m selling 30 //
        tons of steel for $10,000 to Mike Smith. Payment is due within 30 //
        days or will incur a 10% interest charge.”
      </SmartContractDescriptionStyled>
    );
  }
  if (stage == 2) {
    <SmartContractDescriptionStyled>
      Your prompt: ${prompt}
    </SmartContractDescriptionStyled>;
  }
};

export const SmartContractDescriptionStyled = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

export default SmartContractDescription;
