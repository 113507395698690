export const account_options = [
  { label: "Business", value: "Business" },
  { label: "Individual", value: "Individual" },
];

export const subscription_options = [
  {
    type: "Monthly",
    value: "monthly",
    price: 69,
    description: "Pay monthly",
    checked: true,
  },
  {
    type: "Annual, Save $10 per month",
    value: "yearly",
    price: 59,
    description: "Annual total due today ($708)",
    checked: false,
  },
];

export const shortcut_list = [
  { label: "Create contact", value: "new-contact" },
  { label: "Create an invoice", value: "invoices/new" },
  { label: "Create a quote", value: "smart-quotes" },
  { label: "Move money", value: "move-money" },
];

export const type_list = [
  { label: "Invoices", value: "invoices" },
  { label: "Contracts", value: "contracts" },
  { label: "Transactions", value: "transactions" },
];

export const money_list = [
  { label: "Dollars", value: "dollars" },
  { label: "Count", value: "count" },
];

export const customer_list = [{ label: "Customer", value: "Customer" }];

export const chart_date_options = [
  { label: "Past 7 days", value: 7 },
  { label: "30 days", value: 30 },
  { label: "90 days", value: 90 },
];

export const payment_options = [
  { label: "Created", value: "created" },
  { label: "Paid", value: "paid" },
  { label: "Unpaid", value: "unpaid" },
];

export const sort_date_options = [
  { label: "Due date", value: "due_date" },
  { label: "Status", value: "status" },
  { label: "Amount", value: "amount" },
];

export const invoice_type_options = [
  { label: "One time", value: "one-time" },
  // { label: "Recurring", value: "recurring" },
  { label: "Scheduled", value: "scheduled" },
];

export const invoice_payment_options = [
  { label: "Paid", value: "paid" },
  { label: "Unpaid", value: "unpaid" },
  { label: "Draft", value: "draft" },
  { label: "Pending", value: "pending" },
];

export const timezone_options = [
  { label: "PST", value: "PST" },
  { label: "CST", value: "CST" },
  { label: "EST", value: "EST" },
  { label: "UTC", value: "UTC" },
];

export const country_options = [
  { label: "USA", value: "USA" },
  { label: "Canada", value: "Canada" },
  { label: "France", value: "France" },
  { label: "Germany", value: "Germany" },
];

export const color_options = [
  { label: "Default (Black)", value: "#000000" },
  { label: "Blue 1", value: "#1b3b61ff" },
  { label: "Blue 2", value: "#4B98CF" },
  { label: "Red 1", value: "#FF0000" },
  { label: "Red 2", value: "#A01111" },
  { label: "Brown 1", value: "#AF8900" },
  { label: "Brown 2", value: "#745B01" },
  { label: "Green 1", value: "#5FBB33" },
  { label: "Green 2", value: "#116C42" },
  { label: "Orange", value: "#ED9C00" },
  { label: "Pink", value: "#C20080" },
  { label: "Purple", value: "#7600D2" },
  { label: "Gold", value: "#D8B500" },
];

export const auth_options = [
  { label: "Email", value: "email" },
  { label: "Text Message", value: "text" },
];

export const cookie_options = [
  { label: "Allowed", value: "allowed" },
  { label: "Not allowed", value: "notAllowed" },
];

export const month_options = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const year_options = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];
