import { Button } from "components/ui/button-tw";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { useFilteredContacts } from "hooks/contact/use-filtered-contacts";
import { Check, ChevronDown, Plus, Search } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import AddContactModal from "./add-contact-modal";
import { cn } from "utils/classnames";
import { getContactById } from "pages/contacts/actions";

export function ContactsCombobox({ className, onSelect, selected }) {
  const [open, setOpen] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [search, setSearch] = useState("");
  const { data: contacts } = useFilteredContacts(search);
  const contact = useMemo(
    () =>
      contacts
        ? contacts.find((c) => c.contact_id === selectedContact?.contact_id)
        : undefined,
    [selectedContact?.contact_id, contacts]
  );

  useEffect(() => {
    if (selected) {
      setSelectedContact(selected);
      onSelect(selected);
    }
  }, [selected]);

  return (
    <div className={cn("flex items-center space-x-4", className)}>
      <Popover
        open={open}
        onOpenChange={(open) => {
          if (!open) setSearch("");
          setOpen(open);
        }}
      >
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="justify-between w-full pr-2 border-gray-200 hover:bg-transparent hover:cursor-pointer hover:border-gray-400 rounded-lg font-normal"
          >
            <div className="flex justify-between w-full items-center">
              {contact ? (
                <span>{`${contact.contact_info?.first_name} ${contact.contact_info?.last_name}`}</span>
              ) : (
                <span>Select contact...</span>
              )}
              <ChevronDown className=" h-5 w-5 shrink-0 text-gray-300 font-bold" />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 min-w-80" align="start">
          <div className="">
            <div
              className="flex items-center border-b px-3"
              cmdk-input-wrapper=""
            >
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <input
                onChange={(e) => setSearch(e.target.value)}
                className={
                  "flex h-11 w-full rounded-md bg-transparent py-3 border-none text-sm focus:shadow-none focus:ring-transparent outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                }
              />
            </div>
            <ul className="max-h-[300px] overflow-y-auto overflow-x-hidden p-3">
              {contacts.map((contact) => (
                <li
                  onClick={() => {
                    setSelectedContact(contact);
                    onSelect(contact);
                    setSearch("");
                    setOpen(false);
                  }}
                  key={contact.contact_id}
                  className="relative flex justify-between items-center cursor-default select-none rounded-md px-3 py-2 text-sm hover:bg-gray-50 hover:cursor-pointer outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                >
                  <div>
                    <div className="text-sm font-normal">{`${contact.contact_info?.first_name} ${contact.contact_info?.last_name}`}</div>
                    <div className="text-xs text-gray-500">
                      {contact.contact_info?.email}
                    </div>
                  </div>
                  {selectedContact &&
                  selectedContact.contact_id === contact.contact_id ? (
                    <Check className="w-4 h-4 text-skin-primary" />
                  ) : undefined}
                </li>
              ))}
            </ul>
            <div className="p-3">
              <Button className="w-full" onClick={() => setAddContact(true)}>
                <Plus className="mr-2 h-3 w-3 shrink-0" />
                Add contact
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <AddContactModal
        open={addContact}
        onOpenChange={setAddContact}
        onCreateSuccess={(contact) => {
          getContactById(contact.contact_id)
            .then((c) => {
              const contact = Array.isArray(c) ? c[0] : c;
              setSelectedContact(contact);
              onSelect(contact);
            })
            .catch((error) => console.log(error));
        }}
      />
    </div>
  );
}
