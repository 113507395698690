import { useQuery } from "@tanstack/react-query";
import { getSubscription } from "api/v1-jwt/subscription/subscription";

export const useSubscription = ({ queryKey, queryFn, ...config } = {}) => {
  return useQuery({
    queryKey: ["subscription"],
    queryFn: getSubscription,
    ...config,
  });
};
