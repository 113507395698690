import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "utils/classnames";
import { Checkbox } from "components/ui/check-box";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useProfile } from "hooks/profile/use-profile";
import { formatPhoneNumber, formatPhoneUS } from "utils/functions";
import { convertThousandSeparator } from "scripts/functions";
import { CreditCard, XCircle } from "lucide-react";
import { useBillDrawer } from "hooks/bill/use-bill-drawer";
import { BsBank2 } from "react-icons/bs";

function Loading() {
  return (
    <div>
      <div role="status" className="px-6 py-8 animate-pulse">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className=" p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className="absolute bottom-0 inset-x-0 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      ></div>
    </div>
  );
}

function Error() {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There was an error loading the bill details.
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>Make sure your internet connection is stable</li>
              <li>Try closing and opening the drawer again</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const bill = {
  bill_id: "haudhusdg82ubeb892",
  selected_contact: {
    cognito_id: "string",
    contact_id: "string",
    contact_info: {
      accounts: ["string"],
      active: true,
      business_country: "string",
      business_address: "string",
      business_city: "string",
      business_dba: "string",
      business_name: "string",
      business_state: "string",
      business_zip: "string",
      email: "string",
      first_name: "string",
      last_name: "string",
      notes: "string",
      phone: "string",
      integrations: {},
      tags: ["string"],
    },
    created_at: "string",
  },
  selected_account: {
    counter_party_id: "string",
    routing_num_last_4: "4672",
    account_num_last_4: "3682",
    account_type: "string",
    bank_name: "string",
    label: "string",
    value: "string",
  },
  amount: 12683.83,
  phone: "16289373399",
  due_date: "2024-04-30T12:21:14.658Z",
  bill_date: "2024-04-30T12:21:14.658Z",
  last_name: "Diego",
  first_name: "Gasques",
  email: "diego@gmail.com",
  business_name: "DidiGaGa",
};

export default function BillDrawer() {
  const {
    drawerData: bill,
    open,
    handleClose,
    isLoading,
    isError,
  } = useBillDrawer();
  const { data: profile } = useProfile({
    enabled: open,
  });

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative pointer-events-auto w-screen max-w-2xl">
                    <div className=" pb-[120px] flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6 bg-skin-primary">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Bill
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-gray-50 hover:text-gray-300 focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      {isLoading ? (
                        <Loading />
                      ) : isError || bill === undefined ? (
                        <Error />
                      ) : (
                        <div className=" divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="flex-col space-y-4 px-4 sm:px-6 min-h-full">
                              <div className="flex justify-between items-center mt-6 sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      $
                                      {convertThousandSeparator(
                                        Number(bill?.amount)
                                      )}
                                    </h3>
                                    {/* <InvoiceStatusBadge
                                      status={
                                        bill?.status || DRAFT_INVOICE_STATUS
                                      }
                                    /> */}
                                  </div>
                                  {/* <p className="text-sm text-gray-500">Total</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-5 sm:px-6 sm:py-0 sm:pb-8">
                            {/* Bill */}
                            <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                              <div className="sm:pr-4">
                                <dt className="inline text-gray-500">
                                  Bill date
                                </dt>{" "}
                                <dd className="inline text-gray-700">
                                  <time>
                                    {moment(bill?.bill_date).format("LL")}
                                  </time>
                                </dd>
                              </div>
                              <div className="mt-2 sm:mt-0 sm:pl-4">
                                <dt className="inline text-gray-500">Due on</dt>{" "}
                                <dd className="inline text-gray-700">
                                  <time>
                                    {moment(bill?.due_date).format("LL")}
                                  </time>
                                </dd>
                              </div>
                              <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                                <dt className="font-semibold text-gray-900">
                                  From
                                </dt>
                                <dd className="mt-2 text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {profile?.business_name}
                                  </span>
                                  <br />
                                  {profile?.business_email}
                                  <br />
                                  {formatPhoneUS(profile?.business_phone)}
                                </dd>
                              </div>

                              <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                                <dt className="font-semibold text-gray-900">
                                  To
                                </dt>
                                <dd className="mt-2 text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {bill?.vendor_name ||
                                      bill?.business_name ||
                                      `${bill?.first_name} ${bill?.last_name}`.trim()}
                                  </span>
                                  <br />
                                  {bill?.recipient_email || ""}
                                  <br />
                                  {formatPhoneUS(bill?.phone)}
                                  <br />
                                </dd>
                              </div>
                            </dl>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 w-full">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                Account
                              </dt>
                              <dd className="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                <ul
                                  role="list"
                                  className="divide-y divide-gray-100 rounded-md border border-gray-200"
                                >
                                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                      <CreditCard
                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">
                                          Account number
                                        </span>
                                      </div>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 text-gray-400">
                                      ****
                                      <span className="flex-shrink-0 ">
                                        {bill?.account_num_last_4}
                                      </span>
                                    </div>
                                  </li>
                                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                      <BsBank2
                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">
                                          Routing number
                                        </span>
                                      </div>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 text-gray-400">
                                      ****
                                      <span className="flex-shrink-0 ">
                                        {bill?.routing_num_last_4}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </dd>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
