import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const getAllInvoices = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(`/platform/invoices/${clientCognitoId}`);

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const getInvoiceById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/invoices/${clientCognitoId}/${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const getInvoicesByContact = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/invoices/${clientCognitoId}/contact/${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const createInvoice = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/invoices/${clientCognitoId}`,
      payload
    );
    return data;
  } catch (error) {
    if (error.response?.status === 422) {
      if (
        error.response?.data?.detail &&
        error.response?.data?.detail.length > 0
      ) {
        error.response?.data?.detail.map((item) => {
          if (item.loc[1] === "invoice_due_date")
            toast.error("Due date is required");
          if (item.loc[1] === "invoice_send_date")
            toast.error("Send date is required");
          if (
            item.loc[1] === "invoice_line_items" &&
            item.loc[3] === "description"
          )
            toast.error("Line items description is required");
          if (
            item.loc[1] === "invoice_line_items" &&
            item.loc[3] === "quantity"
          )
            toast.error("Line items quantity is required");
          if (
            item.loc[1] === "invoice_line_items" &&
            item.loc[3] === "unit_price"
          )
            toast.error("Line items price is required");
          if (item.loc[1] === "invoice_tax_percentage")
            toast.error("Tax is required");
        });
      }
    } else if (error.response?.status === 409) {
      toast.error("The amount entered exceeds your current balance.");
    }

    return error.response?.data;
  }
};

export const deleteInvoice = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/invoices/${clientCognitoId}/${id}`
  );

  return data;
};

export const updateInvoice = async (id, item) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/invoices/${clientCognitoId}/${id}`,
    item
  );

  return data;
};

export const getAllInvoiceKpis = async (day) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/invoices/${clientCognitoId}/kpis?days_back=${day}`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const uploadInvoicePDF = async (params, pdfBlob) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    // Get URL
    const { data: url } = await api.get(
      `/settings/upload_url/${clientCognitoId}?${params}`
    );

    // Upload New way
    const presignedData = url.uploadURLData;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", pdfBlob, "invoice.pdf");
    const uploadResponse = await fetch(presignedData.url, {
      method: "POST",
      body: formData,
      redirect: "follow",
    });
    return { url: url, response: uploadResponse };
  } catch (err) {
    console.error(err);
  }
};

export const payInvoice = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.post(
      `/platform/invoices/${clientCognitoId}/pay_invoice`,
      payload
    );

    return data;
  } catch (error) {
    if (error.response?.status === 422) {
      if (
        error.response?.data?.detail &&
        error.response?.data?.detail.length > 0
      ) {
        console.log(error.response?.data?.detail);
      }
    }
    console.log(error);
  }
};

export const sendEmailReminder = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/invoices/${clientCognitoId}/send_email/${id}?reminder=true`,
    {}
  );

  return data;
};

export const downloadInvoice = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/settings/download_url/${clientCognitoId}`,
    payload
  );

  return data;
};
