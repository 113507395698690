import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import {
  LabelStyle,
  SpecialLabelStyle,
  StyledPhoneInput,
  StyledSelect,
  StyledTextField,
} from "../FormLabelStyles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik"; // Import Formik components
import { useStep } from "../hooks/useStep";
import { BasicModal, Button, colors, textStyles } from "ui";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersLayout } from "@mui/x-date-pickers";
import { CircularProgress, InputAdornment } from "@mui/material";
import { platformEndpoints } from "../utils/PlatformEndpoints";
import { toast } from "react-toastify";
import { useFetchStates } from "hooks/useFetchStates";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { SSNInput } from "./SSNInput";

const validationSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      control_person_ownership_pct: Yup.number()
        .integer("Please enter a whole number")
        .min(
          25,
          "Only individuals with 25% or more ownership should be listed here."
        )
        .max(100, "The percentage cannot be over 100%")
        .test(
          "ownershipPercentage",
          "Only individuals with 25% or more ownership should be listed here.",
          (value) => value >= 25
        )
        .required("Please list the ownership % for this individual."),
    })
  ),
});

export const OwnerInformation = ({ handleNext }) => {
  const { isLoading, data, handlePost, postError, isPostLoading } = useStep(4);
  const [listOfDates, setListOfDates] = useState([]);
  const [listOfPhones, setListOfPhones] = useState([]);
  const [roles, setRoles] = useState([]);
  const { data: states, isLoading: statesLoading } = useFetchStates("US");
  const [hasAddedAllOwners, setHasAddedAllOwners] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [initialValue, setInitialValue] = useState([
    {
      control_person_first_name: "",
      control_person_middle_name: "",
      control_person_last_name: "",
      control_person_physical_address: {
        street_line_1: "",
        city: "",
        state: "",
        postal_code: "",
      },
      control_person_ssn: "",
      control_person_email: "",
      control_person_phone: "",
      control_person_dob: "",
      control_person_title: "",
      control_person_ownership_pct: 25,
    },
  ]);
  const [usersSSN, setUsersSSN] = useState([
    { id: 0, ssn: "" },
    { id: 1, ssn: "" },
    { id: 2, ssn: "" },
    { id: 3, ssn: "" },
  ]);

  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const roles = await platformEndpoints.fetchRoleTitle();
        setRoles(roles);
      } catch (error) {
        toast.error("Error fetching data. Contact support.");
      }
    };

    getData();
  }, []);

  console.log(data);

  useEffect(() => {
    if (data?.beneficial_owners) {
      const modifiedData = data?.beneficial_owners?.map((owner) => {
        const {
          control_person_physical_address,
          control_person_dob,
          control_person_phone,
          ...rest
        } = owner;

        setListOfPhones((prev) => [...prev, control_person_phone]);

        return {
          ...rest,
          ...control_person_physical_address,
          control_person_dob: null,
        };
      });
      setInitialValue(modifiedData);
    }
  }, [data]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSSNChange = (userId, newSSN) => {
    setUsersSSN((prevUsersSSN) =>
      prevUsersSSN.map((user) =>
        user.id === userId ? { ...user, ssn: newSSN } : user
      )
    );
  };

  const validation = (index, phone, ssn, date, role, state) => {
    let isValid = true;
    const today = new Date();
    const dob = new Date(date);
    const age = today.getFullYear() - dob.getFullYear();

    const updateFieldError = (fieldName, errorValue) => {
      setFieldErrors((prev) => ({
        ...prev,
        [index]: {
          ...prev[index],
          [fieldName]: errorValue,
        },
      }));
    };

    if (!phone || phone.length !== 10) {
      isValid = false;
      updateFieldError("phoneError", true);
    } else {
      updateFieldError("phoneError", false);
    }

    if (!ssn) {
      isValid = false;
      updateFieldError("SSNError", true);
    } else {
      updateFieldError("SSNError", false);
    }

    if (!date) {
      isValid = false;
      updateFieldError("dateOfBirthError", true);
    } else {
      updateFieldError("dateOfBirthError", false);
    }

    if (age < 18) {
      isValid = false;
      updateFieldError("ageError", true);
    } else {
      updateFieldError("ageError", false);
    }

    if (!role) {
      isValid = false;
      updateFieldError("roleError", true);
    } else {
      updateFieldError("roleError", false);
    }

    if (!state) {
      isValid = false;
      updateFieldError("stateError", true);
    } else {
      updateFieldError("stateError", false);
    }

    return isValid;
  };

  return isLoading ? (
    <LoaderContainer>
      <CircularProgress color="inherit" size={24} />
    </LoaderContainer>
  ) : (
    <>
      <Subtext>
        Any other individuals owning 25% or more of the company should be listed
        here. You may list up to 4 additional Beneficial Owners.
      </Subtext>
      <Formik
        initialValues={{
          users: initialValue,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const isUsersValid = values.users.every((user, index) => {
            const { control_person_title, state } = user;
            return validation(
              index,
              listOfPhones[index],
              usersSSN[index].ssn,
              listOfDates[index],
              control_person_title,
              state
            );
          });

          if (!isUsersValid) {
            toast.error("Please fill in all required fields correctly.");
            return;
          }

          const usersArray = values.users.map((user, index) => {
            const { street_line_1, city, postal_code, state, ...rest } = user;

            return {
              ...rest,
              control_person_physical_address: {
                street_line_1: user?.street_line_1,
                city: user?.city,
                postal_code: user?.postal_code,
                state: user?.state,
                country: "US",
              },
              control_person_dob: listOfDates[index],
              control_person_phone: listOfPhones[index],
              control_person_ssn: usersSSN[index].ssn.split("-").join(""),
            };
          });

          if (hasAddedAllOwners) {
            handlePost({ beneficial_owners: usersArray }, handleNext);
          } else {
            toast.error("Please confirm you have added all owners.");
          }

          if (postError) {
            toast.error(postError);
          }
        }}
      >
        {({ handleSubmit, values }) =>
          isLoading ? (
            <LoaderContainer>
              <CircularProgress color="inherit" size={24} />
            </LoaderContainer>
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="mt-8 space-y-6  border-gray-900/10 sm:space-y-0  sm:divide-gray-900/10 sm:pb-0 max-w-[872px]">
                <FieldArray name="users">
                  {({ push, remove }) => (
                    <>
                      {values.users.map((user, index) => (
                        <>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Full Name*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <div className="flex flex-col gap-y-3 lg:flex-row lg:gap-x-3">
                                <Field
                                  as={StyledTextField}
                                  name={`users[${index}].control_person_first_name`}
                                  fullWidth
                                  size="small"
                                  placeholder="First"
                                  autoComplete="off"
                                  required
                                />
                                <Field
                                  as={StyledTextField}
                                  name={`users[${index}].control_person_middle_name`}
                                  fullWidth
                                  size="small"
                                  placeholder="Middle (If Applicable)"
                                  autoComplete="off"
                                />
                                <Field
                                  as={StyledTextField}
                                  name={`users[${index}].control_person_last_name`}
                                  fullWidth
                                  size="small"
                                  placeholder="Last"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <div>
                              <label
                                htmlFor="username"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Ownership Percentage*
                              </label>
                              <p className=" max-w-2xl text-sm leading-6 text-gray-500">
                                Must be your personal residential address. No
                                P.O. Box.
                              </p>
                            </div>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <Field
                                as={StyledTextField}
                                name={`users[${index}].control_person_ownership_pct`}
                                size="small"
                                type="number"
                                autoComplete="off"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                style={{ maxWidth: 190 }}
                                inputProps={{
                                  maxLength: 3,
                                }}
                              />
                              <ErrorMessage
                                name={`users[${index}].control_person_ownership_pct`}
                                component="div"
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "128.336%",
                                  marginTop: "5px",
                                  color: colors.error,
                                }}
                              />
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <div>
                              <label
                                htmlFor="username"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Residential Address*
                              </label>
                              <p className=" max-w-2xl text-sm leading-6 text-gray-500">
                                Must be their personal residential address. No
                                P.O. Box.
                              </p>
                            </div>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <Field
                                as={StyledTextField}
                                name={`users[${index}].street_line_1`}
                                placeholder="Street"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                required
                              />
                              <BottomFieldWrapper>
                                <Field
                                  as={StyledTextField}
                                  name={`users[${index}].city`}
                                  fullWidth
                                  placeholder="City"
                                  size="small"
                                  autoComplete="off"
                                  required
                                />
                                <FormControl size="small" fullWidth>
                                  <Field
                                    as={StyledSelect}
                                    name={`users[${index}].state`}
                                    displayEmpty={true}
                                    renderValue={(selected) =>
                                      selected ? selected : "State"
                                    }
                                  >
                                    {states?.map((item) => (
                                      <MenuItem
                                        value={item.value}
                                        key={item.label}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormControl>
                                <Field
                                  as={StyledTextField}
                                  name={`users[${index}].postal_code`}
                                  fullWidth
                                  size="small"
                                  placeholder="Zip Code"
                                  autoComplete="off"
                                  inputProps={{ maxLength: 5 }}
                                  required
                                />
                              </BottomFieldWrapper>
                              {fieldErrors[index]?.stateError && (
                                <ErrorMessageContainer>
                                  Please input select a State.
                                </ErrorMessageContainer>
                              )}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              SSN*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <SSNInput
                                ssn={usersSSN[index].ssn}
                                setSSN={(newSSN) =>
                                  handleSSNChange(index, newSSN)
                                }
                              />
                              {fieldErrors[index]?.SSNError && (
                                <ErrorMessageContainer>
                                  Please input a valid SSN.
                                </ErrorMessageContainer>
                              )}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Email Address*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <Field
                                as={StyledTextField}
                                name={`users[${index}].control_person_email`}
                                size="small"
                                autoComplete="off"
                                type="email"
                                required
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Phone Number*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <Field
                                as={StyledPhoneInput}
                                required
                                name={`users[${index}].control_person_phone`}
                                size="small"
                                autoComplete="off"
                                country={"us"}
                                onlyCountries={["us"]}
                                value={listOfPhones[index]}
                                disableCountryCode
                                onChange={(phone) => {
                                  const updatedPhones = [...listOfPhones];
                                  updatedPhones[index] = phone;
                                  setListOfPhones(updatedPhones);
                                }}
                                placeholder={"(123) 456-7890"}
                              />
                              {fieldErrors[index]?.phoneError && (
                                <ErrorMessageContainer>
                                  Please input a valid phone.
                                </ErrorMessageContainer>
                              )}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Date of Birth*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  sx={{ padding: 0 }}
                                  components={["DatePicker"]}
                                >
                                  <DatePicker
                                    sx={{ backgroundColor: "#FFF" }}
                                    slots={{ layout: StyledPickersLayout }}
                                    slotProps={{ textField: { size: "small" } }}
                                    name={`users[${index}].control_person_dob`}
                                    onChange={(date) => {
                                      const dateObject = `${date.$M + 1}/${
                                        date.$D
                                      }/${date.$y}`;
                                      const tempListOfDates = listOfDates;
                                      tempListOfDates[index] = dateObject;
                                      setListOfDates(tempListOfDates);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              {fieldErrors[index]?.dateOfBirthError && (
                                <ErrorMessageContainer>
                                  Please provide a Date Of Birth.
                                </ErrorMessageContainer>
                              )}
                              {fieldErrors[index]?.ageError && (
                                <ErrorMessageContainer>
                                  Beneficial Owners must be 18+.
                                </ErrorMessageContainer>
                              )}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Role or Title*
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <FormControl
                                size="small"
                                style={{ minWidth: 235 }}
                                fullWidth
                              >
                                <Field
                                  as={StyledSelect}
                                  name={`users[${index}].control_person_title`}
                                  labelId={`role_label`}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                >
                                  {roles?.map((item) => (
                                    <MenuItem
                                      value={item.value}
                                      key={item.label}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                              {fieldErrors[index]?.roleError && (
                                <ErrorMessageContainer>
                                  Please select a role.
                                </ErrorMessageContainer>
                              )}
                            </div>
                            {values.users.length >= 1 && (
                              <Button
                                type="button"
                                onClick={() => remove(index)}
                                variant="black"
                                marginBottom="20"
                                marginTop={32}
                              >
                                Remove Owner
                              </Button>
                            )}
                          </div>
                        </>
                      ))}
                      {values.users.length <= 3 && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              control_person_first_name: "",
                              control_person_middle_name: "",
                              control_person_last_name: "",
                              street_line_1: "",
                              city: "",
                              state: "",
                              postal_code: "",
                              control_person_ssn: "",
                              control_person_email: "",
                              control_person_phone: "",
                              control_person_dob: "",
                              control_person_title: "",
                              control_person_ownership_pct: 25, // Set the initial value here
                            });
                          }}
                          variant="white"
                          marginTop={30}
                          type="button"
                        >
                          + Add Beneficial Owners
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </div>
              <CheckboxContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_primary_control_person"
                      value={hasAddedAllOwners}
                      onChange={() => {
                        setHasAddedAllOwners(!hasAddedAllOwners);
                      }}
                    />
                  }
                  label="All beneficial owners have been added."
                />
              </CheckboxContainer>
              <ActionButtons>
                <Button
                  variant="white"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="green" type="submit">
                  {isPostLoading ? (
                    <CircularProgress style={{ color: "#FFFFFF" }} size={12} />
                  ) : (
                    "Next"
                  )}
                </Button>
              </ActionButtons>
              <BasicModal
                title={"Are you sure you want to cancel setup?"}
                text={"If you cancel, you’ll need to start over."}
                open={openModal}
                buttonText={"Yes, cancel setup"}
                onButtonClick={() => history.push("/dashboard")}
                handleClose={handleCloseModal}
              />
            </Form>
          )
        }
      </Formik>
    </>
  );
};

const FormContent = styled.div`
  margin-top: 34px;
`;

const LightText = styled.span`
  font-weight: 400;
  color: #939393;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
`;

const CheckboxContainer = styled.div`
  margin-top: 40px;
`;

const BottomFieldWrapper = styled.div`
  display: flex;
  margin-top: ${({ $noMargin }) => ($noMargin ? "0" : "20px")};
  gap: 12px;
`;

const StyledPickersLayout = styled(PickersLayout)({
  ".MuiDateCalendar-root": {
    color: "#000",
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
});

const LoaderContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Subtext = styled.div`
  ${textStyles.body.b2};
  color: ${colors.textGray};
  margin-top: 10px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 5px;
  ${textStyles.body.b2}
  color: ${colors.error};
`;
