import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import email_icon from "assets/images/mail.png";
import call_icon from "assets/images/call.png";
import chat_icon from "assets/images/chat.png";
import { MainWrapper } from "pages/styled";
import {
  SupportBtn,
  SupportCard,
  SupportLabel,
  SupportSection,
  SupportValue,
} from "./styled";

const Support = () => {
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);

  const SupportCardHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 23px;
    width: 100%;
    background-color: ${settingData?.primary_color};
  `;

  const StyledSupportBtn = styled(SupportBtn)`
    background-color: ${settingData?.primary_color};
  `;

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <MainWrapper>
        <ClientMainHeader
          title="Support"
          subTitle="Questions? We're here to help"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <SupportSection>
          <SupportCard>
            <SupportCardHeader />
            <div className="p-32-34">
              <img
                src={email_icon}
                alt="email"
                width={"40px"}
                height={"32px"}
              />
              <SupportLabel>Email</SupportLabel>
              <SupportValue>support@pocketbook.tech</SupportValue>
              <StyledSupportBtn>
                <a
                  href="mailto:support@pocketbook.tech"
                  style={{ color: "white", textDecoration: "initial" }}
                >
                  Email
                </a>
              </StyledSupportBtn>
            </div>
          </SupportCard>
          <SupportCard>
            <SupportCardHeader />
            <div className="p-32-34">
              <img src={call_icon} alt="call" width={"32px"} height={"32px"} />
              <SupportLabel>Phone</SupportLabel>
              <SupportValue>1-877-888-5105</SupportValue>
              <StyledSupportBtn>Call</StyledSupportBtn>
            </div>
          </SupportCard>
          <SupportCard className="opacity-2">
            <SupportCardHeader />
            <div className="p-32-34">
              <img src={chat_icon} alt="chat" width={"29px"} height={"29px"} />
              <SupportLabel>Chat</SupportLabel>
              <SupportValue>coming soon</SupportValue>
              <StyledSupportBtn>Chat</StyledSupportBtn>
            </div>
          </SupportCard>
        </SupportSection>
      </MainWrapper>
    </div>
  );
};

export default Support;
