import api from "../config/api";

export const getPaymentMethods = async ({ status, user_id }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const id = user_id || clientCognitoId;
  const { data } = await api.get(
    `/platform/payment_methods/${id}?move_money=${status}`
  );

  return data;
};

export const setAccessToken = async (publicToken, metadata, cognitoId) => {
  const { data } = await api.post(
    `/platform/payment_methods/plaid/set_access_token`,
    { public_token: publicToken, metadata: metadata, cognito_id: cognitoId }
  );

  return data;
};

export const createPlaidLinkToken = async (cognitoId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/payment_methods/plaid/link-token`,
    {
      user_id: cognitoId || clientCognitoId,
    }
  );

  return data;
};

export const setDefaultPayment = async ({ userId, paymentId }) => {
  if (!userId || !paymentId) {
    console.log(
      "SET_DEFAULT_PAYMENT::MISSING_PARAMS::",
      `USER_ID::${userId} - PAYMENT_ID::${paymentId}`
    );
    throw new Error("Missing params in setDefaultPayment");
  }
  const { data } = await api.patch(
    `/platform/payment_methods/set_default/${userId}`,
    {
      user_id: userId,
      payment_method_id: paymentId,
    }
  );

  return data;
};
