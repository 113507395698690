// import { useState } from "react";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import { BiLock } from "react-icons/bi";
import styled from "styled-components";

const CustomPlaceholder = styled.div`
  text-align: start;
  align-items: center;
  display: flex;
  height: 36px;
  margin-left: 4px;
  color: ${(props) => (props.shortcut ? "#ffffff" : "#000000")};
`;

const ButtonWrapper=styled.div`
  width: 100%;
  padding: 10px 15px;
`

const getKeyStyle = (key) => {
  if (key == 0) {
    return "font-size:12px;font-weight:bold;";
  } else if (key == 1) {
    return "font-size:12px;";
  } else {
    return "font-size:12px";
  }
};

const BankDataCard = styled.div`
  ${({ index }) => getKeyStyle(index)}
`;

export const CustomSelector = ({
  value,
  setValue,
  selectList,
  mLeft,
  mTop,
  mRight,
  mBottom,
  width,
  menuWidth,
  placeholder,
  menuLeft,
  linkUrl,
  customer,
  shortcut,
  color,
  selected,
  bank,
  disabled = false,
  bottomButton
}) => {
  const history = useHistory();
  // const [option, setOption] = useState(null);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex justify-between">
          {!props.bank ? (
            <>
              <div className="flex align-items-center">
                {customer && <BiLock style={{ marginRight: 8 }} />}
                {props.data.label}
              </div>
              {props.isSelected ? <BsCheck className="font-20" /> : <></>}
            </>
          ) : (
            <>
              <>
                <div className="flex align-items-center">
                  {customer && <BiLock style={{ marginRight: 8 }} />}
                  <div className="flex-col">
                    {props.data.label.split(" - ").map((data, index) => (
                      <BankDataCard key={index} index={index}>
                        {data}
                      </BankDataCard>
                    ))}
                  </div>
                </div>
                {props.value === props.data.value ? (
                  <BsCheck className="font-20" />
                ) : (
                  <></>
                )}
              </>
            </>
          )}
        </div>
      </components.Option>
    );
  };

  const MenuWithButton = (props) => (
    <components.Menu {...props}>
      {props.children}
      <ButtonWrapper>
      {bottomButton}
      </ButtonWrapper>
    </components.Menu>
  );

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const customStyle = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: width,
      // maxWidth: width,
      backgroundColor: shortcut ? color : "#ffffff",
      border: "1px solid #E1E1E1",
      borderRadius: "8px",
      fontSize: 14,
    }),
    valueContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: 30,
      alignItems: "left",
      padding: customer ? "0px 8px 0px 26px" : "0px 8px",
    }),
    singleValue: (base) => ({
      ...base,
      height: 28,
      marginTop: 10,
      color: shortcut ? "#fff" : "#000",
      textAlign: "start",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      alignItems: "center",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "inherit",
    }),
    menu: (base) => ({
      ...base,
      width: menuWidth,
      right: menuLeft ? "initial" : "1px",
    }),
    option: (base, state) => ({
      ...base,
      color: "#000000",
      padding: "14px",
      backgroundColor: state.isSelected ? "#F9FAFB" : "#ffffff",
      width: menuWidth - 20,
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "#F9FAFB",
      },
    }),
  };

  return (
    <div
      style={{
        marginTop: mTop ? mTop : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginRight: mRight ? mRight : "initial",
        marginBottom: mBottom ? mBottom : "initial",
        position: "relative",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Select
        isSearchable={false}
        value={value}
        onChange={handleChange}
        options={selectList}
        components={{
          Option: (props) => <CustomOption {...props} bank={bank} />,
          Menu: bottomButton ? MenuWithButton : components.Menu
        }}
        styles={customStyle}
        isDisabled={disabled}
        placeholder={
          <CustomPlaceholder shortcut={shortcut}>
            {placeholder}
          </CustomPlaceholder>
        }
      />
      {customer && (
        <BiLock
          style={{ position: "absolute", top: 11, left: 10, color: "#ffffff" }}
        />
      )}
    </div>
  );
};

export const CustomColorPicker = ({
  value,
  setValue,
  selectList,
  mLeft,
  mTop,
  mRight,
  mBottom,
  width,
  menuWidth,
  menuLeft,
  disabled = false,
}) => {
  const ColorSelector = styled.div`
    width: 10px;
    height: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    margin-right: 8px;
  `;

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex justify-between">
          <div className="flex align-items-center">
            <ColorSelector style={{ backgroundColor: props.data.value }} />
            {props.data.label}
          </div>
          {props.isSelected ? <BsCheck className="font-20" /> : <></>}
        </div>
      </components.Option>
    );
  };

  const customStyle = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: width,
      backgroundColor: "#ffffff",
      border: "1px solid #E1E1E1",
      borderRadius: "8px",
      fontSize: 14,
    }),
    valueContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: 30,
      alignItems: "left",
      padding: "0px 8px",
    }),
    singleValue: (base) => ({
      ...base,
      height: 28,
      marginTop: 10,
      color: "#000000",
      textAlign: "start",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      alignItems: "center",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "inherit",
    }),
    menu: (base) => ({
      ...base,
      width: menuWidth,
      right: menuLeft ? "initial" : "1px",
    }),
    option: (base, state) => ({
      ...base,
      color: "#000000",
      padding: "14px",
      backgroundColor: state.isSelected ? "#F9FAFB" : "#ffffff",
      width: menuWidth - 20,
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "#F9FAFB",
      },
    }),
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        marginTop: mTop ? mTop : "initial",
        marginLeft: mLeft ? mLeft : "initial",
        marginRight: mRight ? mRight : "initial",
        marginBottom: mBottom ? mBottom : "initial",
        position: "relative",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Select
        isSearchable={false}
        value={value}
        defaultValue={selectList[0]}
        onChange={handleChange}
        options={selectList}
        components={{ Option: CustomOption }}
        styles={customStyle}
        isDisabled={disabled}
        // placeholder={<CustomPlaceholder>{placeholder}</CustomPlaceholder>}
      />
    </div>
  );
};
