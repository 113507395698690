import { useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { LoadingWrapper, MainWrapper } from "pages/styled";
import { SubmitBtn } from "./styled";
import { usePlaidLink } from "react-plaid-link";
import useLinkToken from "../plaid/hooks/useLinkToken";
import setAccessToken from "../plaid/actions/setAccesToken";
import { useAuth } from "providers/auth";
import { useHistory } from "react-router-dom";

const ConnectPaymentMethod = () => {
  const history = useHistory();
  const { settingData, businessId } = useSelector((state) => state.account);

  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);
  const { cognitoId } = useAuth();

  const { linkToken, loading, error } = useLinkToken(cognitoId);
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      await setAccessToken(public_token, metadata, cognitoId);
      console.log("AccessToken set successfully");
      history.push("/payment-methods");
    },
  });

  const StyledButton = styled(SubmitBtn)`
    background-color: ${settingData?.primary_color};
  `;

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <MainWrapper>
        <ClientMainHeader
          title="Connect your payment method"
          description="Plaid makes it easy, safe and reliable for you to connect your
              financial data to Pocketbook."
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <StyledButton onClick={() => open()}>Connect</StyledButton>
      </MainWrapper>
    </div>
  );
};

export default ConnectPaymentMethod;
