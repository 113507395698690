import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import pocketbook_logo from "assets/images/pocketbook-logo.png";
import { CustomSelector } from "pages/utils/styled-select";
import { shortcut_list } from "pages/demo";
import {
  MainHeader,
  MainTitle,
  MainSubTitle,
  ShortcutSection,
  OptionLabel,
} from "pages/styled";

const CustomerHeader = ({
  title,
  subTitle,
  description,
  shortCut,
  setShortCut,
}) => {
  const { settingData } = useSelector((state) => state.account);

  const [hide, setHide] = useState(false);

  useEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    // setCurrentWidth(width);
    if (width < 700) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  window.addEventListener("resize", handleResize);

  return (
    <MainHeader>
      <div>
        <MainTitle>{title}</MainTitle>
        {subTitle && <MainSubTitle>{subTitle}</MainSubTitle>}
        {description && (
          <OptionLabel style={{ width: "100%", maxWidth: 550 }}>
            {description}
          </OptionLabel>
        )}
      </div>
      <ShortcutSection>
        {hide ? (
          <></>
        ) : (
          <div className="text-end">
            <img src={pocketbook_logo} alt="logo" width="156px" height="26px" />
            <CustomSelector
              value={shortCut}
              setValue={setShortCut}
              selectList={shortcut_list}
              width={188}
              menuWidth={320}
              mTop={17}
              color={settingData?.primary_color}
              customer
              shortcut
              placeholder={"Quick links"}
            />
          </div>
        )}
      </ShortcutSection>
    </MainHeader>
  );
};

export default CustomerHeader;
