import { Button } from "components/ui/button-tw";
import { Check, Loader2, UploadCloud, X } from "lucide-react";
import { FileUploader } from "react-drag-drop-files";
import { formatBytes } from "utils/numbers";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as actionTypes from "pages/customer/signup/actionTypes";
import { getSettings, updateSettings } from "pages/settings/actions";
import { uploadLogo } from "api/setting/setting";


export function LogoUploadModal({ open, onOpenChange, setLogoError }) {
  const [file, setFile] = useState(null);
  const {loading, setIsLoading} = useState(false);
  const dispatch=useDispatch()

  const handleUpload = async () => {
    if (file) {
      // setIsLoading(true);
      try {
        const resp = await uploadLogo(file);
        console.log(resp);
        const payload= {
            logo: resp.url,
        }
        await updateSettings(payload);
        getSettings().then(settings => {
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: {
              ...settings,
              logo: `${settings.logo}?${Date.now()}`
            }
          });
        });
        toast.success("Logo uploaded successfully");
      } catch (err) {
        toast.error("An error occurred while uploading the logo. Please try again.");
      }
    }
    // setIsLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Upload your logo</DialogTitle>
          <DialogDescription>
            For optimal performance and the best user experience, please upload
            a white PNG image of your logo.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <FileUploader
            handleChange={(file) => {
              setFile(file);
            }}
            name="logo"
            hoverTitle="Drop here!"
            types={["PNG"]}
            onTypeError={(err) => console.log(err)}
            maxSize={1}
            onSizeError={(err) => console.log(err)}
            children={
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:border-skin-primary dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-skin-primary/10 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <UploadCloud className="w-8 h-8 mb-4 text-gray-600" />
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold text-skin-primary">
                        Click to upload
                      </span>{" "}
                      or drag and drop
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">PNG</span> (MAX of 1MB)
                    </p>
                  </div>
                </label>
              </div>
            }
          />
          {file && (
            <div className="bg-white rounded-md px-4 py-4 space-y-1 flex max-w-full justify-between items-center border transition-all">
              <img
                src={URL.createObjectURL(file)}
                alt="Logo preview"
                className={`min-h-[35px] max-h-[45px] w-auto rounded-sm bg-black mr-2`}
              />
              <div className="flex justify-between items-center grow m-0">
                <div className="flex items-center space-x-2">
                  <div className="text-xs font-medium text-slate-500">
                    {` ${formatBytes(file.size)}`}
                  </div>
                  <Check className="w-4 h-4 text-green-600" />
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  className="hover:bg-red-100"
                  onClick={() => setFile(null)}
                >
                  <X className="w-4 h-4 " />
                </Button>
              </div>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            type="submit"
            disabled={!file || loading}
            onClick={handleUpload}
          >
            {loading ? (
              <Loader2 className="w-5 h-5 text-white mr-2 animate-spin" />
            ) : (
              <UploadCloud className="w-5 h-5 text-white mr-2" />
            )}
            Upload logo
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
