import { SELECTED_INVOICE } from "pages/invoices/actionsTypes";
import { SELECTED_QUOTE } from "pages/contracts/actionTypes";
import { UPLOADED_LOGO } from "../pages/dashboard/actionTypes";
import { CONNECTED_QUICKBOOK } from "../pages/integrations/actionTypes";

const initialState = {
  uploadedLogo: null,
  connectedQuickBook: null,
  selectedInvoice: null,
  selectedQuote: null,
};

const pageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPLOADED_LOGO:
      return {
        ...state,
        uploadedLogo: payload,
      };
    case CONNECTED_QUICKBOOK:
      return {
        ...state,
        connectedQuickBook: payload,
      };
    case SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoice: payload,
      };
    case SELECTED_QUOTE:
      return {
        ...state,
        selectedQuote: payload,
      };
    default:
      return state;
  }
};

export default pageReducer;
