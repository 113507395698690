import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pagination, CircularProgress } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import { paginator } from "scripts/functions";
import DeleteContactDialog from "./delete-dialog";
import { getAllContacts } from "./actions";
import {
  ColumnDetailSection,
  DetailBtn,
  DetailLabel,
  DetailTitle,
  PaginationSection,
  StyledContactTable,
  TableItem,
  ProgressSection,
  DetailSection,
  DetailBtnSection,
  ContactInfo,
  TableToolBtn,
} from "./styled";
import { useFilteredContacts } from "hooks/contact/use-filtered-contacts";
import { formatPhoneNumber } from "utils/functions";
import { Menu, Trash2 } from "lucide-react";
import { useContactDrawer } from "hooks/contact/use-contact-drawer";

const ContactTable = ({ search, setSearch }) => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const { handleOpen } = useContactDrawer();

  const { data: contacts } = useFilteredContacts(search);

  const count = Math.ceil(contacts.length / 10);

  // useEffect(() => {
  //   getContactList();
  // }, []);

  // useEffect(() => {
  //   const filteredArr = contactList.filter((item) => {
  //     const contact_name =
  //       item.contact_info.first_name + " " + item.contact_info.last_name;
  //     return (
  //       contact_name
  //         .toString()
  //         .toLowerCase()
  //         .replace(/\s+/g, "")
  //         .includes(search.toLowerCase().replace(/\s+/g, "")) ||
  //       item.contact_info.business_name
  //         .toString()
  //         .toLowerCase()
  //         .replace(/\s+/g, "")
  //         .includes(search.toLowerCase().replace(/\s+/g, ""))
  //     );
  //   });
  //   setFilteredList(filteredArr);
  // }, [search, setSearch, contactList]);

  const getContactList = async () => {
    setLoading(true);
    await getAllContacts().then((res) => {
      if (res && res.length > 0) {
        setContactList(res);
        setFilteredList(res);
        setLoading(false);
      } else if (res.length === 0) {
        setContactList([]);
        setFilteredList([]);
        setLoading(false);
      }
    });
  };

  const handlePaginate = (event, value) => {
    setSelectedContact(null);
    setPage(paginator(filteredList, value, 1).page);
  };

  const handleView = (item, index) => {
    const updatedItem = { ...item, index: index };
    console.log("CONTACT::", item);
    setSelectedContact(updatedItem);
  };

  const handleEditContact = () => {
    history.push(`contacts/update/${selectedContact.contact_id}`);
  };

  const handleViewContract = () => {
    history.push(`/quotes?contact_id=${selectedContact.contact_id}`);
  };

  const handleRedirectNewInvoice = () => {
    history.push(`/invoices/new?contact_id=${selectedContact.contact_id}`);
  };

  const handleViewInvoice = () => {
    history.push(`/invoices?contact_id=${selectedContact.contact_id}`);
  };

  const handleDelete = (item) => {
    setDeleteContact(item);
    setDeleteDialog(true);
  };

  const TableLabel = ({ children }) => (
    <div className="min-h-[44px] flex py-[13px] px-3 text-white text-sm leading-4 font-medium lg:px-6 relative items-center bg-skin-primary">
      {children}
    </div>
  );

  return (
    <div>
      <StyledContactTable>
        <TableLabel>Primary contact name</TableLabel>
        <TableLabel>Business name</TableLabel>
        <TableLabel>Tags</TableLabel>
        <TableLabel className="justify-center">View / Delete</TableLabel>
        {loading && (
          <ProgressSection>
            <CircularProgress />
          </ProgressSection>
        )}
        {paginator(contacts, page, 10).data.map((item, index) => (
          <React.Fragment key={"contact" + index}>
            <>
              <TableItem>
                {item.contact_info.first_name} {item.contact_info.last_name}
              </TableItem>
              <TableItem>{item.contact_info.business_name}</TableItem>
              <TableItem>{item.tags}</TableItem>
              <TableItem className="justify-center">
                <TableToolBtn
                  onClick={() => {
                    handleOpen(item.contact_id);
                  }}
                >
                  <Menu className="w-5 h-5" />
                </TableToolBtn>
                <TableToolBtn
                  className="ml-08"
                  onClick={() => {
                    handleDelete(item);
                  }}
                >
                  <Trash2 className="w-5 h-5" />
                </TableToolBtn>
              </TableItem>
            </>
            {selectedContact && index === selectedContact.index && (
              <ColumnDetailSection>
                <BsXLg
                  style={{ position: "absolute", top: 23, right: 42 }}
                  onClick={() => setSelectedContact(null)}
                />
                <DetailSection>
                  <div className="flex">
                    <DetailTitle>
                      {selectedContact.contact_info.first_name}{" "}
                      {selectedContact.contact_info.last_name}
                    </DetailTitle>
                    <div>
                      <ContactInfo>
                        <div>
                          <DetailLabel className="font-medium">
                            Primary Contact
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">Name:</span>{" "}
                            {selectedContact.contact_info.first_name}{" "}
                            {selectedContact.contact_info.last_name}
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">Email:</span>{" "}
                            {selectedContact.contact_info.email}
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">Phone:</span>{" "}
                            {formatPhoneNumber(
                              selectedContact.contact_info.phone
                            )}
                          </DetailLabel>
                        </div>
                        <div>
                          <DetailLabel className="font-medium">
                            Business Information
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">Business name:</span>{" "}
                            {selectedContact.contact_info.business_name}
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">DBA name:</span>{" "}
                            {selectedContact.contact_info.business_dba || "-"}
                          </DetailLabel>
                          <DetailLabel>
                            <span className="font-medium">
                              Business address:
                            </span>{" "}
                            <span>
                              {`${
                                selectedContact.contact_info.business_address
                                  ? selectedContact.contact_info
                                      .business_address + "."
                                  : ""
                              } ${
                                selectedContact?.contact_info?.business_city
                                  ? selectedContact?.contact_info
                                      ?.business_city + ","
                                  : ""
                              } ${
                                selectedContact?.contact_info?.business_state
                                  ? selectedContact?.contact_info
                                      ?.business_state + ","
                                  : ""
                              } ${
                                selectedContact?.contact_info?.business_zip ||
                                ""
                              }`}
                            </span>
                          </DetailLabel>
                        </div>
                      </ContactInfo>
                      <DetailLabel>
                        <span className="font-medium cursor-pointer">
                          Notes:
                        </span>
                      </DetailLabel>
                    </div>
                  </div>
                  <DetailBtnSection>
                    <DetailBtn onClick={handleEditContact}>
                      Edit contact
                    </DetailBtn>
                    <DetailBtn onClick={handleRedirectNewInvoice}>
                      Create a new invoice for contact
                    </DetailBtn>
                    <DetailBtn onClick={handleViewInvoice}>
                      View contact's invoices
                    </DetailBtn>
                    <DetailBtn onClick={handleViewContract}>
                      View contact's quotes
                    </DetailBtn>
                  </DetailBtnSection>
                </DetailSection>
              </ColumnDetailSection>
            )}
          </React.Fragment>
        ))}
      </StyledContactTable>
      {contacts.length > 0 && (
        <PaginationSection className="transaction-pagination">
          <Pagination
            count={count}
            page={page}
            onChange={handlePaginate}
            color="success"
          />
        </PaginationSection>
      )}
      <DeleteContactDialog
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        selectedContact={deleteContact}
        setSelectedContact={setDeleteContact}
        getContactList={getContactList}
      />
    </div>
  );
};

export default ContactTable;
