import { useQuery } from "@tanstack/react-query";
import { getSettings } from "api/setting/setting";

const placeholderData = {
  logo: "",
  favicon: "",
  primary_color: "#000000",
  secondary_color: "",
  accent_color: "",
  client_name: "",
};

export const useSettings = ({ queryFn, queryKey, ...rest } = {}) => {
  return useQuery({
    queryKey: ["settings"],
    queryFn: fetchSettings,
    placeholderData,
    staleTime: 5 * 60 * 1000,
    ...rest,
  });
};

const fetchSettings = async () => {
  const data = await getSettings();
  const newPlatformSettings = {
    ...JSON.parse(localStorage.getItem("platform_settings") || "{}"),
    pb_user: data,
  };
  localStorage.setItem(
    "platform_settings",
    JSON.stringify(newPlatformSettings)
  );
  return data;
};
