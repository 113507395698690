import { useState, useEffect } from "react";
import { Dialog, CircularProgress } from "@mui/material";
import styled from "styled-components";
import { useSyncContacts, useSyncInvoices } from "./hooks";
import { ConnectBtn } from "./styled";

const SyncDialog = ({
  syncName,
  integrationName,
  integrationData,
  setHistory,
  // clickedInputs,
  setStep,
  useDataHook,
}) => {
  const { loading, data, error } = useDataHook(
    integrationName,
    integrationData
  );
  let integrationStr =
    integrationName.charAt(0).toUpperCase() + integrationName.slice(1);
  useEffect(() => {
    if (data) {
      setHistory((prev) => {
        return {
          ...prev,
          [syncName]: data.message,
        };
      });
      setStep((prev) => prev + 1);
    }
  }, [data]);
  return (
    <TextWrapper>
      <SyncWrapper>
        {loading ? (
          <>
            <h2>
              Syncing {integrationStr} {syncName}
            </h2>
            <CircularProgress />
          </>
        ) : null}
        {data ? <div>{data.message}</div> : null}
      </SyncWrapper>
    </TextWrapper>
  );
};
const SettingsCheckbox = ({ className, label, checked, onChange }) => {
  return (
    <div className={className}>
      <input
        type="checkbox"
        name={label}
        checked={checked}
        onChange={onChange}
      ></input>
      <label>{label}</label>
    </div>
  );
};

const SettingsDialog = ({
  className,
  integrationName,
  integrations,
  isOpen,
  setIsOpen,
}) => {
  let integrationStr =
    integrationName.charAt(0).toUpperCase() + integrationName.slice(1);
  const [clickedInputs, setClickedInputs] = useState({
    Contacts: false,
    Invoices: false,
  });
  const [syncHistory, setSyncHistory] = useState({
    contacts: "",
    invoices: "",
  });
  const [step, setStep] = useState(1);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClickedInput = (e) => {
    setClickedInputs((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.checked,
      };
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <TextWrapper>
            <h2>Congratulations {integrationStr} is activated</h2>
            <h3>What would you like to sync?</h3>

            <SettingsCheckboxStyled
              checked={clickedInputs.Contacts}
              label={"Contacts"}
              onChange={(e) => handleClickedInput(e)}
            />
            <SettingsCheckboxStyled
              checked={clickedInputs.Invoices}
              label={"Invoices"}
              onChange={(e) => handleClickedInput(e)}
            />
            <ButtonWrapper>
              {clickedInputs.Contacts || clickedInputs.Invoices ? (
                <ConnectBtn
                  style={{ marginTop: "15px" }}
                  onClick={() => setStep((prev) => prev + 1)}
                >
                  Sync
                </ConnectBtn>
              ) : (
                <ConnectBtn style={{ marginTop: "15px" }} onClick={handleClose}>
                  Cancel
                </ConnectBtn>
              )}
            </ButtonWrapper>
          </TextWrapper>
        );
      case 2:
        if (clickedInputs.Contacts) {
          return (
            <SyncDialog
              key={"contacts"}
              syncName={"contacts"}
              setStep={setStep}
              setHistory={setSyncHistory}
              clickedInputs={clickedInputs}
              integrationName={integrationName}
              integrationData={integrations[integrationName]}
              useDataHook={useSyncContacts}
            />
          );
        } else {
          setStep(3);
        }
      case 3:
        if (clickedInputs.Invoices) {
          return (
            <SyncDialog
              key={"invoices"}
              syncName={"invoices"}
              setStep={setStep}
              setHistory={setSyncHistory}
              clickedInputs={clickedInputs}
              integrationName={integrationName}
              integrationData={integrations[integrationName]}
              useDataHook={useSyncInvoices}
            />
          );
        } else {
          setStep(4);
        }
      case 4:
        return null;
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      className={className}
      aria-labelledby="customized-welcome-dialog"
      open={isOpen}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "fit-content",
          },
        },
      }}
    >
      {getStepContent(step)}
      {syncHistory.contacts || syncHistory.invoices ? (
        <SyncHistoryWrapper>
          <h2>Sync History</h2>
          {syncHistory.contacts ? <div>{syncHistory.contacts}</div> : null}
          {syncHistory.invoices ? <div>{syncHistory.invoices}</div> : null}
        </SyncHistoryWrapper>
      ) : null}
      {step === 4 ? (
        <TextWrapper>
          <ConnectBtn
            style={{ marginTop: "15px" }}
            onClick={() => setIsOpen(false)}
          >
            Done
          </ConnectBtn>
        </TextWrapper>
      ) : null}
    </Dialog>
  );
};

const SyncHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  h2 {
    padding: 0;
    margin: 0 0 10px 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  h2 {
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
  h3 {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  button {
    margin-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
const SettingsCheckboxStyled = styled(SettingsCheckbox)`
  display: flex;
  align-items: center;
  gap: 10px;
  input {
    width: 20px;
    height: 20px;
  }
  label {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
`;
const SyncWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export { SettingsDialog as SettingsDialog };
