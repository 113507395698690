import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import styled from "styled-components";
import { LimitedAccountWarning } from "components/limited-account-warning";

export const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [currentWidth, setCurrentWidth] = useState(0);

  return (
    <>
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        {!pathname.includes("kyb") && <LimitedAccountWarning />}
        <ContentWrapper>{children}</ContentWrapper>
      </>
    </>
  );
};

export const ContentWrapper = styled.div`
  padding: 38px;
  background-color: #f8f8f8;
  position: relative;
  margin-left: 315px;
  height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    margin-left: 0px;
    height: calc(100% - 101px);
    padding: 30px;
  }

  @media (max-width: 400px) {
    margin-left: 0px;
    padding: 20px 16px;
  }
`;
