import { useState } from "react";
import { Dialog, CircularProgress } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import moment from "moment";
import { convertThousandSeparator } from "scripts/functions";
import { transferMoney, transferDeposit, transferWithdraw } from "./actions";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogSubmitBtn,
} from "../styled";
import { toast } from "react-toastify";
import { customTimeout } from "utils/functions";
import { useHistory } from "react-router-dom";

const ConfirmTransferDialog = ({
  type,
  dialog,
  setDialog,
  amount,
  paymentAccount,
  account,
  contact,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleDialogClose = () => {
    setDialog(false);
  };
  const handleConfirm = async () => {
    setLoading(true);
    const formattedAmount = parseFloat(amount).toFixed(2);
    if (type === "add_money") {
      console.log(paymentAccount);
      const payload = {
        payment_method_id: paymentAccount.value.payment_method_id,
        amount: formattedAmount,
      };
      await transferDeposit(payload).then((res) => {
        if (res && !res.detail) {
          setLoading(false);
          toast.success("Your transfer has been initiated.");
          setDialog(false);
          customTimeout(() => {
            history.push("/transactions");
          });
        }
      });
    } else if (type === "withdraw_money") {
      const payload = {
        payment_method_id: paymentAccount.value.payment_method_id,
        amount: formattedAmount,
      };
      await transferWithdraw(payload).then((res) => {
        if (res && !res.detail) {
          setLoading(false);
          toast.success("Your transfer has been initiated.");
          setDialog(false);
          customTimeout(() => {
            history.push("/transactions");
          });
        }
      });
    }
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>Confirm your transfer</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription className="text-start">
          {type === "add_money" &&
            `$${convertThousandSeparator(
              Number(amount)
            )} will be transferred from ${
              paymentAccount.value.account_name
            } to Your Pocketbook on ${moment().format(
              "MM/DD/YY"
            )}. Estimated availability of your funds will be ${moment()
              .add(3, "days")
              .format("MM/DD/YY")}`}
          {type === "withdraw_money" &&
            `$${convertThousandSeparator(
              Number(amount)
            )} will be transferred from your Pocketbook to ${
              paymentAccount.value.account_name
            } on ${moment().format(
              "MM/DD/YY"
            )}. Estimated availability of your funds will be ${moment()
              .add(3, "days")
              .format("MM/DD/YY")}`}
          {type === "pay_someone" &&
            `$${convertThousandSeparator(
              Number(amount)
            )} will be transferred from your Pocketbook to ${
              contact.label
            } on ${moment().format(
              "MM/DD/YY"
            )}. Estimated availability of your funds will be ${moment()
              .add(3, "days")
              .format("MM/DD/YY")}`}
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>Back</DialogCancelBtn>
          <DialogSubmitBtn onClick={handleConfirm}>
            Confirm
            {loading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </DialogSubmitBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default ConfirmTransferDialog;
