import { useQuery } from "@tanstack/react-query";
import { fetchContactInvoices } from "api/v1-jwt/invoice/invoice";

export const useContactInvoices = ({
  queryFn,
  queryKey,
  contactId,
  ...rest
} = {}) => {
  return useQuery({
    queryKey: ["invoices", "contact", contactId],
    queryFn: async () => {
      return await fetchContactInvoices(contactId);
    },
    staleTime: 5 * 60 * 1000,
    ...rest,
  });
};
