import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { BondExternalAccounts } from "bond-sdk-web";
import { CircularProgress } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";
import * as actionTypes from "../actionTypes";
import { getBondToken, accountLink, updateProfile } from "../actions";

import {
  MainDescription,
  MainTitle,
  PlanInfoSubText,
  BottomTextSection,
  Setup2Wrapper,
  Setup3MainSection,
  BackBtn,
  BackBtnLabel,
} from "../styled";
import { getStoredObj } from "utils/localStorage/localStorage";
import { TENANCY_INFO } from "utils/localStorage/storageKeys";

const SubmitBtn = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  min-width: 140px;
  max-width: 168px;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Create a BondExternalAccounts instance.
 * @constructor
 * @param {boolean} [live=true] Set to True to work with live data.
 * False for sandbox data
 */
// const bondExternalAccounts = new BondExternalAccounts({
//   live: process.env.REACT_APP_PLAID_LINK_LIVE,
// });

const AccountSetup3 = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [linkStatus, setLinkstatus] = useState(false);
  const businessId = useSelector((state) => state.account.businessId);

  // const linkBondAccount = async () => {
  //   setLoading(true);
  //   let userType = getStoredObj(TENANCY_INFO).account_type;
  //   let bond_res;
  //   const { Identity, Authorization } = await getBondToken({
  //     id: businessId,
  //     userType,
  //   });
  //
  //   try {
  //     bond_res = await bondExternalAccounts.linkAccount({
  //       [userType === "business" ? "businessId" : "customerId"]: businessId, // or business_id: BUSINESS_ID
  //       identity: Identity,
  //       authorization: Authorization,
  //     });
  //
  //     if (bond_res.status && bond_res.status === "interrupted") {
  //       throw new Error("[INTERRUPTED] The bond link account was canceled.");
  //     }
  //
  //     const { linkedAccountId } = bond_res;
  //     const { status } = await accountLink(
  //       userType,
  //       linkedAccountId,
  //       businessId
  //     );
  //     dispatch({
  //       type: actionTypes.BOND_LINKED_ACCOUNT,
  //       payload: {
  //         linkedAccountId,
  //         status,
  //       },
  //     });
  //     setLinkstatus(status);
  //   } catch (e) {
  //     console.log("[LINK_ACCOUNT] Error: ", e);
  //   } finally {
  //     if (linkStatus === "success") {
  //       handleNextStep();
  //     }
  //     setLoading(false);
  //   }
  // };

  const handleNextStep = async () => {
    const payload = { account_setup_step: 4 };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
    <Setup2Wrapper>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup3MainSection>
        <div>
          <MainTitle>Connect your Bank to pay</MainTitle>
          <MainDescription style={{ maxWidth: "600px" }}>
            Plaid makes it easy, safe and reliable for you to connect your
            financial data to Pocketbook.
          </MainDescription>
          {linkStatus === "success" ? (
            <SubmitBtn onClick={() => setStep(4)}>Continue</SubmitBtn>
          ) : (
            // <SubmitBtn onClick={() => linkBondAccount()}>
            <SubmitBtn onClick={() => handleNextStep()}>
              <span className="pr-10">Connect</span>
              {loading && <CircularProgress color="inherit" size={14} />}
            </SubmitBtn>
          )}
        </div>
      </Setup3MainSection>
      <BottomTextSection>
        <PlanInfoSubText>
          Plaid is the safer way to link financial accounts; trusted by today’s
          leading brands.{" "}
          <span className="cursor-pointer text-underline">
            <a
              href="https://plaid.com/safety/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </span>
        </PlanInfoSubText>
      </BottomTextSection>
    </Setup2Wrapper>
  );
};

export default AccountSetup3;
