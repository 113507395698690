import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pocketbook_white_logo from "assets/images/pocketbook-logo-white.png";
import logo from "assets/images/logo.png";
import support_icon from "assets/images/question.png";
// import setting_icon from "assets/images/setting.png";
import back_icon from "assets/images/back.png";
import checkbox_base_icon from "assets/images/checkbox-base.png";
import green_checkbox_icon from "assets/images/green-check.png";
import {
  setLoggedOutState,
  setUserLoggedOut,
} from "pages/customer/login/actions";
import {
  SetupPocketBookLogo,
  SidebarWrapper,
  SidebarLabel,
  SidebarToolSection,
  StepIcon,
  StepSection,
  SupportLabel,
} from "./styled";
import { useAuth } from "providers/auth";
import { toast } from "react-toastify";

const SetupSidebar = ({ step, setStep }) => {
  const history = useHistory();
  const { signout, isSigningOut, signOutError } = useAuth();
  const [draw, setDraw] = useState(false);
  // const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    // setCurrentWidth(width);
    if (width < 768) {
      setDraw(true);
    } else {
      setDraw(false);
    }
  }

  window.addEventListener("resize", handleResize);

  const handleDrawSidebar = () => {
    setDraw(true);
  };

  const handleOpenSidebar = () => {
    setDraw(false);
  };

  const handleLogout = async () => {
    await signout({
      onSuccess: (data) => {
        if (!data.is_authenticated) {
          history.push("/login");
        }
      },
      onError: (error) => {
        toast.error("Signout failed. Please try again in a moment.");
      },
    });
    // await setUserLoggedOut().then((res) => {
    //   if (!res.is_authenticated) {
    //     setLoggedOutState();
    //     history.push("/login");
    //   }
    // });
  };

  return (
    <SidebarWrapper className={draw && "draw-setup-sidebar"}>
      {!draw && (
        <SetupPocketBookLogo
          src={pocketbook_white_logo}
          alt="pocketbook-white-logo"
          onClick={() => handleDrawSidebar()}
        />
      )}
      {draw && (
        <SetupPocketBookLogo
          src={logo}
          alt="logo"
          onClick={() => handleOpenSidebar()}
        />
      )}
      <StepSection
        className={draw ? "align-items-center" : "align-items-start"}
      >
        <SidebarLabel>
          <StepIcon src={green_checkbox_icon} alt="green-checkbox" />
          <span className={draw ? "d-none" : "pl-25"}>Create Your Account</span>
        </SidebarLabel>
        <SidebarLabel>
          <StepIcon src={green_checkbox_icon} alt="green-checkbox" />
          <span className={draw ? "d-none" : "pl-25"}>
            Confirm Your Email Address
          </span>
        </SidebarLabel>
        <SidebarLabel>
          {Number(step) < 5 ? (
            <StepIcon src={checkbox_base_icon} alt="base-checkbox" />
          ) : (
            <StepIcon src={green_checkbox_icon} alt="green-checkbox" />
          )}
          <span className={draw ? "d-none" : "pl-25"}>
            Choose Your Subscription
          </span>
        </SidebarLabel>
        {Number(step) < 5 && (
          <SidebarLabel className="opacity-5">
            <StepIcon src={checkbox_base_icon} alt="base-checkbox" />
            <span className={draw ? "d-none" : "pl-25"}>
              Setup Payment Processing
            </span>
          </SidebarLabel>
        )}
        {Number(step) >= 5 && Number(step) < 9 && (
          <SidebarLabel>
            <StepIcon src={checkbox_base_icon} alt="base-checkbox" />
            <span className={draw ? "d-none" : "pl-25"}>
              Setup Payment Processing
            </span>
          </SidebarLabel>
        )}
        {Number(step) === 9 && (
          <SidebarLabel>
            <StepIcon src={green_checkbox_icon} alt="base-checkbox" />
            <span className={draw ? "d-none" : "pl-25"}>
              Setup Payment Processing
            </span>
          </SidebarLabel>
        )}
      </StepSection>
      <SidebarToolSection
        className={draw ? "align-items-center" : "align-items-start"}
      >
        <SupportLabel href="mailto:support@pocketbook.tech">
          <StepIcon src={support_icon} alt="support" />
          <span className={draw ? "d-none" : "pl-25"}>Support</span>
        </SupportLabel>
        <SidebarLabel onClick={handleLogout}>
          <StepIcon src={back_icon} alt="back" />
          <span className={draw ? "d-none" : "pl-25"}>Logout</span>
        </SidebarLabel>
      </SidebarToolSection>
    </SidebarWrapper>
  );
};

export default SetupSidebar;
