import styled from "styled-components";
import { DashboardBalanceCard } from "./balance-card";
import darkenColor from "utils/colors";
import { breakpoints } from "ui/breakpoints";

export const FeatureBalanceCard = styled(DashboardBalanceCard)`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;

  @media (${breakpoints.sm}) {
    max-width: 375px;
    min-width: 375px;
    display: grid;
    gap: 20px;
  }
`;

export const FeatureCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    display: grid;
    gap: 20px;
  }
`;

export const SmallCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 178px;
  height: 48px;
  min-width: 150px;
  cursor: pointer;
`;

export const ChartCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 746px;
  min-height: 400px;
  height: fit-content;
`;

export const FeatureCardLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 8px;
`;

export const FeatureCardBalance = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
`;

export const MainLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 16px;
`;

export const PaymentCardSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 16px;
  margin-bottom: 16px;
`;

export const InvoiceChartTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  padding-bottom: 16px;
`;

export const ExportBtn = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #101828;
`;

export const DateOptionBtn = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0px 14px;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #101828;
  margin-right: 14px;
  width: 120px;
  height: 40px;
  cursor: pointer;
`;

export const ChartRadioLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
`;

const FCardBalance = ({ className, paid, processing, kpiOption }) => {
  return (
    <div className={className}>
      <div>
        <p>Paid:</p>
        <span>
          {kpiOption
            ? paid?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : paid}
        </span>
      </div>
      <div>
        <p>Processing:</p>
        <span>
          {kpiOption
            ? processing?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : processing}
        </span>
      </div>
    </div>
  );
};

export const FeatureCardBalanceDetail = styled(FCardBalance)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  div {
    display: flex;
  }
  p {
    font-weight: 600;
    margin: 0;
  }
  span {
    margin-left: 8px;
  }
`;

export const StyledRadio = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  margin-right: 10px;
`;

const handleColor = (status, color) => {
  switch (status) {
    case "paid":
      return color;
    case "processingandpaid":
      return darkenColor(color, 0.1);
    case "unpaid":
      return "#FEF29A";
    default:
      return color;
  }
};

export const StyledSelectedRadio = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ status, primaryColor }) =>
    handleColor(status, primaryColor)};
  margin-right: 10px;
`;

export const RedirectBtnSection = styled.div`
  width: 373px;
  display: flex;
  gap: 16px;
  isolation: isolate;
  flex-wrap: wrap;
  margin-left: 16px;
`;

// Customer
export const MainInfoSection = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 40px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const PaidInfo = styled.div`
  padding-right: 38px;
  border-right: 1px solid #e1e1e1;
  margin-right: 38px;

  @media (max-width: 500px) {
    border-right: 0px;
  }
`;

export const CustomerViewBtn = styled.div`
  width: 122px;
  height: 40px;
  background-color: #000000;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
`;
