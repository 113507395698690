export const formatPhoneUS = (input) => {
  let cleaned = ("" + input).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

export const formatPhoneNumber = (input) => {
  if (input === null || input === undefined) {
    return "";
  }
  let numericString = input.replace(/\D/g, "");
  if (numericString.length > 10) {
    numericString = numericString
      .split("")
      .reverse()
      .slice(0, 10)
      .reverse()
      .join("");
  }

  const formattedNumber = numericString.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedNumber;
};

export const customTimeout = (callback, timeout = 3000) => {
  setTimeout(callback, timeout);
};

export const getAddressFromProfile = (addressObj) => {
  const { street_line_1, city, state, postal_code, country } = addressObj;
  let address = "";

  if (street_line_1) {
    address += street_line_1;
  }
  if (city) {
    address += address ? `, ${city}` : city;
  }
  if (state) {
    address += address ? `, ${state}` : state;
  }
  if (postal_code) {
    address += address ? `, ${postal_code}` : postal_code;
  }
  if (country) {
    address += address ? `, ${country}` : country;
  }

  return address.trim();
};

export const getAddressFromContact = (contact_info) => {
  const { business_address, business_state, business_zip } = contact_info;
  let address = "";

  if (business_address) {
    address += business_address;
  }
  if (business_state) {
    address += address ? `, ${business_state}` : business_state;
  }
  if (business_zip) {
    address += address ? ` ${business_zip}` : business_zip;
  }

  return address.trim();
};
