import { usePlaidLink } from "react-plaid-link";
import useLinkToken from "./hooks/useLinkToken";
import { useEffect } from "react";
import setAccessToken from "./actions/setAccesToken";
import getAuthInfo from "./actions/getAuthInfo";
import { useAuth } from "providers/auth";

const Plaid = () => {
  const { cognitoId } = useAuth();
  const { linkToken, loading, error } = useLinkToken(cognitoId);
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      await setAccessToken(public_token, metadata, cognitoId);
    },
  });
  useEffect(() => {
    if (linkToken) {
      open();
    }
  }, [linkToken]);

  return (
    <div>
      <h1>Plaid</h1>
      <button onClick={() => (linkToken ? open() : null)} disabled={!ready}>
        Connect a bank account
      </button>
      <button onClick={() => (linkToken ? getAuthInfo(cognitoId) : null)}>
        {" "}
        GET AUTH INFO{" "}
      </button>
    </div>
  );
};

export default Plaid;
