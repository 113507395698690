import { useState, useEffect } from "react";
import { CustomSelector } from "pages/utils/styled-select";
import statement_icon from "assets/images/line-chart-up.png";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { month_options, year_options } from "pages/demo";
import { MainWrapper, LoadingWrapper } from "pages/styled";
import { CardTitle, StyledCard, SubmitBtn } from "./styled";
import { getAccountStatement} from "api/v1-jwt/account/account";
import useToast from "hooks/notifications/use-toast";
import { useAuth } from "providers/auth";

const AccountStatement = () => {
  const [shortCut, setShortCut] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [hide, setHide] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const { toastNotify, toastNotify2 } = useToast();
  const { accessToken, cognitoId } = useAuth();

  useEffect(() => {
    handleResize();
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    if (width < 700) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  const submitHandler = async () => {
    const accountStatementData = {
      statementMonth: month.value,
      statementYear: year.value,
      accessToken: accessToken
    }
    // toastNotify(
    //     getAccountStatement(accountStatementData),
    //     'success',
    //     'Account statement created successfully'
    // );
    await toastNotify2(getAccountStatement(accountStatementData), 'success', 'Account statement created successfully')
  }

  window.addEventListener("resize", handleResize);

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <MainWrapper>
        <ClientMainHeader
          title="Settings"
          subTitle="Account statements"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <StyledCard>
          <div>
            <img
              src={statement_icon}
              alt="statement"
              width="50px"
              height="50px"
            />
            <CardTitle>Create an account statement (PDF)</CardTitle>
            <CustomSelector
              value={month}
              setValue={setMonth}
              selectList={month_options}
              width={hide ? "100%" : "233px"}
              menuWidth={250}
              mBottom={12}
              placeholder={"Month"}
            />
            <CustomSelector
              value={year}
              setValue={setYear}
              selectList={year_options}
              width={hide ? "100%" : "233px"}
              menuWidth={250}
              mBottom={12}
              placeholder={"Year"}
            />
            <SubmitBtn onClick={submitHandler}>Submit</SubmitBtn>
          </div>
        </StyledCard>
      </MainWrapper>
    </div>
  );
};

export default AccountStatement;
