import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import styled from "styled-components";
import CustomerHeader from "./header";
import { convertThousandSeparator } from "scripts/functions";
import { MainWrapper, DrawerText } from "pages/styled";
import {
  CustomerViewBtn,
  FeatureCard,
  FeatureCardBalance,
  FeatureCardLabel,
  MainInfoSection,
  MainLabel,
  PaidInfo,
} from "pages/dashboard/styled";
import { useProfile } from "hooks/profile/use-profile";
import { useInvoicesKPIs } from "hooks/invoice/use-invoices-kpis";
import { useAuth } from "providers/auth";
import { useContracts } from "hooks/contract/use-contracts";
import { Banner, BannerWelcome } from "./styled";
import { useSelector } from "react-redux";
import { useSettings } from "hooks/setting/use-settings";

const CustomerDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const { cognitoId } = useAuth();
  const { data: profile, isLoading: profileLoading } = useProfile({
    id: cognitoId,
    enabled: !!cognitoId,
  });
  const { data: settings, loading: settingsLoading } = useSettings({
    staleTime: 0,
  });

  const { data: invoicesKPI } = useInvoicesKPIs(
    {
      fromContactId: cognitoId,
    },
    {
      enabled: !profileLoading && !!profile,
    }
  );
  const { data: contracts } = useContracts({
    fromContactId: cognitoId,
    enabled: !profileLoading && !!profile,
  });

  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);
  const [drawer, setDrawer] = useState({
    top: false,
  });

  useEffect(() => {
    if (location.state && location.state.confirm === "true") {
      setDrawer({ ...drawer, top: true });
    }
  }, [location]);

  const togglePaymentDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  const welcomeName = () => {
    if (!profile) return "";

    return profile.business_dba !== ""
      ? profile.business_dba
      : profile.business_name
      ? profile.business_name
      : profile.first_name + " " + profile.last_name;
  };

  const unpaidInvoices = (kpis) => {
    if (typeof kpis !== "object") return 0;

    return kpis.count.total_number_invoices_unpaid;
  };

  const balanceDue = (kpis) => {
    if (typeof kpis !== "object") return 0;

    return kpis?.dollars?.total_amount_invoices_unpaid;
  };

  const unsignedContracts = (contracts) => {
    if (!Array.isArray(contracts)) return 0;

    return contracts.reduce(
      (acc, { contract_info }) =>
        acc +
        (contract_info.contract_status.toLowerCase() === "pending approval"
          ? 1
          : 0),
      0
    );
  };

  return (
    <div>
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        <CustomerHeader
          title="Dashboard"
          subTitle={""}
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        <UserGreeting>{`Welcome ${welcomeName()}`}</UserGreeting>
        <div>
          {!settingsLoading && !settings?.marketing_logo ? (
            <Banner>
              <BannerWelcome>
                <span
                  style={{
                    fontSize: "22px",
                    fontFamily: "Inter, sans-serif",
                    color: "white",
                    marginBottom: "12px",
                  }}
                >
                  Welcome
                </span>
                <span
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    fontFamily: "Inter, sans-serif",
                    color: "white",
                  }}
                >{`${welcomeName()}`}</span>
              </BannerWelcome>
            </Banner>
          ) : (
            <CustomerMainImgContainer>
              <CustomerMainImg
                src={`https://cdn.pocketbook.tech/${settings?.marketing_logo}`}
                alt="dashboard-img"
              />
            </CustomerMainImgContainer>
          )}
          <MainInfoSection>
            <div style={{ width: "100%" }}>
              <MainLabel>Invoices</MainLabel>
              <FeatureCard>
                <PaidInfo>
                  <FeatureCardLabel>Unpaid</FeatureCardLabel>
                  <FeatureCardBalance>
                    {unpaidInvoices(invoicesKPI)}
                  </FeatureCardBalance>
                </PaidInfo>
                <div>
                  <FeatureCardLabel>Balance due</FeatureCardLabel>
                  <FeatureCardBalance>
                    ${convertThousandSeparator(balanceDue(invoicesKPI))}
                  </FeatureCardBalance>
                </div>
              </FeatureCard>
              <CustomerViewBtn onClick={() => history.push("invoices")}>
                View
              </CustomerViewBtn>
            </div>
            <div style={{ width: "100%" }}>
              <MainLabel>Quotes</MainLabel>
              <FeatureCard>
                <div>
                  <FeatureCardLabel>Pending Approval</FeatureCardLabel>
                  <FeatureCardBalance>
                    {unsignedContracts(contracts)}
                  </FeatureCardBalance>
                </div>
              </FeatureCard>
              <CustomerViewBtn onClick={() => history.push("quotes")}>
                View
              </CustomerViewBtn>
            </div>
          </MainInfoSection>
        </div>
      </MainWrapper>
      <Drawer
        anchor={"top"}
        open={drawer["top"]}
        onClose={togglePaymentDrawer("top", false)}
      >
        <DrawerText>
          <span>Your payment has been made.</span>
          <BsXLg
            style={{ position: "absolute", top: 12, right: 22 }}
            onClick={togglePaymentDrawer("top", false)}
          />
        </DrawerText>
      </Drawer>
    </div>
  );
};

const UserGreeting = styled.div`
  color: #101828;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 16px;
`;

const CustomerMainImg = styled.img`
  object-fit: fill;
  width: 100%;
  max-height: 290px;
`;

const CustomerMainImgContainer = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 290px;
  border-radius: 20px;
  margin-bottom: 28px;
  overflow: hidden;
`;

export default CustomerDashboard;
