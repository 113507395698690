import React, { useState, useLayoutEffect } from "react";
import { CircularProgress } from "@mui/material";
import { updateProfile } from "../actions";
import {
  MainDescription,
  MainTitle,
  SubmitBtn,
  Setup1MainSection,
} from "../styled";

const AccountSetup1 = ({ setStep, profile: initialProfile }) => {  // added profile: initialProfile to accept profile prop and give it an alias
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(initialProfile);  // add this line to initialize profile state

  useLayoutEffect(() => {
    setProfile(initialProfile);
  }, [initialProfile]);  // need to add initialProfile to the dependency array

  const handleNextSetup = async () => {
    setLoading(true);
    const payload = { account_setup_step: 2 };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
      <Setup1MainSection>
        <div>
          <MainTitle>Welcome { (profile.business_dba === "") ? profile.business_name : profile.business_dba }</MainTitle>
          <MainDescription>
            It's time to choose your subscription and set up Your Pocketbook
            so that you can begin accepting and sending payments.
          </MainDescription>
          <SubmitBtn onClick={handleNextSetup}>
            Start
            {loading && (
                <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </SubmitBtn>
        </div>
      </Setup1MainSection>
  );
};

export default AccountSetup1;