import styled from "styled-components";
import { breakpoints } from "ui/breakpoints";

export const NewAccountBtn = styled.div`
  background: #1db45a;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  max-width: 204px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;
`;

export const PaymentSection = styled.div`
  /* justify-content: center;
    margin-top: 0px;
    gap: 0; */
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  gap: 20px;
  margin-top: 40px;

  justify-content: center;

  @media (${breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

export const CustomerPaymentCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  height: 380px;
  padding: 35px;
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

export const StyledCircle = styled.div`
  width: 32px;
  height: 32px;
  border: 2px solid #000000;
  border-radius: 50%;
`;

export const CardTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 18px;
`;

export const CardDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 28px;
  min-height: 34px;
`;

export const ContainedBtn = styled.div`
  width: 142px;
  background: #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
`;

export const OutlinedBtn = styled.div`
  width: 142px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #000000;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
  text-align: center;
  margin-top: 14px;
`;

export const PaymentCardIcon = styled.img`
  width: 38px;
  height: auto;
  margin-right: 6px;
`;
export const BankCardIcon = styled.img`
  width: 100px;
  height: auto;
  margin-right: 6px;
`;

export const SubmitBtn = styled.div`
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
