const { Checkbox } = require("@mui/material");
const { CheckboxLabel } = require("../styled");

const CheckBox = ({ checked, onChange, label }) => {
  return (
    <div className={`flex align-items-center`}>
      <Checkbox
        size="small"
        inputProps={{ "aria-label": "controlled" }}
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
      ></Checkbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </div>
  );
};

export default CheckBox;
