import { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { updateProfile } from "../actions";
import {
  Setup1MainSection,
  MainDescription,
  MainTitle,
  SubmitBtn,
  BackBtn,
  BackBtnLabel,
} from "../styled";

const Setup6Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
`;
const Setup6Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
`;
const Setup6List = styled.li`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;
  line-height: 16px;
`;
const BottomText = styled.div`
  position: absolute;
  left: 100px;
  bottom: 47px;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #000000;

  @media (max-width: 1100px) {
    position: initial;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  @media (max-height: 768px) {
    position: initial;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

const AccountSetup6 = ({ step, setStep }) => {
  const [loading, setLoading] = useState(false);

  const handleNextSetup = async () => {
    setLoading(true);
    const payload = { account_setup_step: 7 };
    await updateProfile(payload).then((res) => {
      setLoading(false);
      setStep(res.account_setup_step);
    });
  };

  return (
    <Setup1MainSection style={{ flexWrap: "wrap" }}>
      <BackBtn onClick={() => setStep(Number(step) - 1)}>
        <BsArrowLeft />
        <BackBtnLabel>Back</BackBtnLabel>
      </BackBtn>
      <Setup6Wrapper>
        <MainTitle>Set Up Your Pocketbook</MainTitle>
        <MainDescription>
          As you create and send invoices to your customers, they need a way to
          pay you. The final step in setting up your account is establishing
          Your Pocketbook – a deposit account that will be used to process
          payments.
          {<br />}
          {<br />}
          <Setup6Description className="font-medium">
            Your Pocketbook enables your business to save money and receive
            payments more quickly. Some documents that may be required:
          </Setup6Description>
          {<br />}
          <Setup6List>Articles of Incorporation / Organization</Setup6List>
          <Setup6List>Drivers License</Setup6List>
          {/* <Setup6List>Certificate of Good Standing</Setup6List> */}
          {<br />}
          <Setup6Description>
            <span className="font-medium">
              Important Information About Procedures for Opening a New Business
              Account:{" "}
            </span>
            To help the government fight the funding of terrorism and money
            laundering activities, Federal law requires us to obtain, verify,
            and record information that identifies each customer who opens an
            Account.  This means that when you open an Account, we will ask for
            your name, address, tax identification number, and other information
            that will allow us to identify you.  Federal regulations require
            that you provide us with information concerning the beneficial
            owners of a legal entity at Account opening.  We will request the
            name, address, date of birth, and taxpayer ID number or social
            security number of certain beneficial owners and a control person. 
            We may also ask to see other identifying documents, such as a
            driver’s license, taxpayer identification number; passport number
            and country of issuance; alien identification card number; or number
            and country of issuance of any government-issued document evidencing
            nationality or residence and bearing a photograph or similar
            safeguard.
          </Setup6Description>
        </MainDescription>
        <SubmitBtn onClick={handleNextSetup}>
          Start
          {loading && (
            <CircularProgress color="inherit" size={14} className="ml-08" />
          )}
        </SubmitBtn>
      </Setup6Wrapper>
      <BottomText>
        Pocketbook is not a bank. Banking services are provided by Evolve Bank &
        Trust, Member FDIC
      </BottomText>
    </Setup1MainSection>
  );
};

export default AccountSetup6;
