import styled from "styled-components";

export const SetupMainWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
    position: relative;
  }
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  min-height: 600px;
  background-color: #000000;
  padding: 38px;
  height: 100vh;
  position: fixed;

  @media (max-width: 1200px) {
    max-width: 350px;
    padding: 38px 20px;
  }

  @media (max-width: 768px) {
    padding: 38px 20px;
    width: 280px;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10;
  }
`;

export const SetupPocketBookLogo = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const SidebarLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SupportLabel = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: initial;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const StepSection = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  padding: 0px;
  gap: 32px;
  margin-top: 62px;
  height: calc(100% - 250px);
`;

export const SidebarToolSection = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  padding: 0px;
  gap: 23px;
`;

export const StepIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SetupMainSection = styled.div`
  width: 100%;
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;
`;

export const CloseIcon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 47px;
  right: 45px;
  cursor: pointer;
`;

export const MainTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 48px;
  line-height: 58px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const MainDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  color: #000000;
  padding-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const SubmitBtn = styled.div`
  background-color: #000000;
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChoosePlanLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 12px;
`;

export const PlanDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const SelectPlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 27px 0px;
  gap: 10px;
`;

export const SelectedPlanCard = styled.div`
  width: 100%;
  background: #ffffff;
  border: 2px solid #e1e1e1;
  border-radius: 12px;
`;

export const SelectPlanCard = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
`;

export const SelectedCardHeader = styled.div`
  background: #000000;
  // border: 1px solid #e1e1e1;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;

export const CardHeader = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;

export const CardHeaderLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
`;

export const AutoPayLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
`;

export const CardBody = styled.div`
  padding: 16px;
`;

export const PlanInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  padding: 4px;
`;

export const PlanInfoSubText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const BottomTextSection = styled.div`
  padding: 47px 90px 47px 100px;
  background-color: #f8f8f8;
`;

export const Setup1MainSection = styled.div`
  width: 100%;
  height: 100vh;
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  margin-left: 500px;

  @media (max-width: 1200px) {
    margin-left: 350px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    padding: 47px 60px 47px 90px;
  }
`;

export const Setup2Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: #f8f8f8;
  margin-left: 500px;

  @media (max-width: 1200px) {
    margin-left: 350px;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const Setup2MainSection = styled.div`
  width: 100%;
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;

  @media (min-width: 1600px) {
    height: calc(100% - 155px);
  }

  @media (max-width: 500px) {
    padding: 47px 60px 47px 90px;
  }
`;

export const Setup3MainSection = styled.div`
  width: 100%;
  height: calc(100% - 111px);
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    padding: 47px 60px 47px 90px;
  }
`;

export const Setup4MainSection = styled.div`
  width: 100%;
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;

  @media (max-width: 500px) {
    padding: 47px 60px 47px 90px;
  }
`;

export const Setup7MainSection = styled.div`
  width: 100%;
  padding: 47px 90px 47px 100px;
  position: relative;
  background-color: #f8f8f8;

  @media (max-width: 1000px) {
    padding: 47px 90px;
  }

  @media (max-width: 500px) {
    padding: 47px 40px 47px 90px;
  }
`;

export const BackBtn = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;
`;

export const BackBtnLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-left: 4px;
`;
