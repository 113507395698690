import styled from "styled-components";

// Main Header
export const MainWrapper = styled.div`
  padding: 38px;
  background-color: #f8f8f8;
  position: relative;
  margin-left: 315px;
  height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    margin-left: 0px;
    height: calc(100% - 101px);
    padding: 30px;
  }

  @media (max-width: 400px) {
    margin-left: 0px;
    padding: 20px 16px;
  }
`;

export const ShortcutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 40px;
`;

export const MainTitle = styled.div`
  font-size: 48px;
  font-weight: 590;
  font-family: Inter;
  color: #000000;
  line-height: 1;

  @media (max-width: 786px) {
    font-size: 32px;
  }

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

export const MainSubTitle = styled.div`
  font-size: 20px;
  font-weight: 590;
  font-family: Inter;
  color: #000000;
  line-height: 1.4;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    font-size: 18px;
  }

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const MainGreenBtn = styled.div`
  background: #1db45a;
  border-radius: 12px;
  width: 100%;
  min-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;
`;

export const MainWhiteBtn = styled.div`
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: fit-content;
  min-width: 140px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  margin-right: 24px;
`;

//
export const OptionLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

// Table
export const TableLabel = styled.div`
  min-height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding: 13px 12px;
  }
`;

export const TableItem = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  padding: 16px 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border-bottom: 1px solid #eaecf0;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

// Drawer
export const DrawerText = styled.div`
  position: relative;
  padding: 10px 20px;
  text-align: center;
  background: #1db45a;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

// Pagination
export const PaginationSection = styled.div`
  padding: 18px 24px;
  width: 100%;
  //max-width: 900px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
`;

// Dialog
export const DialogSection = styled.div`
  padding: 24px;
  background-color: #ffffff;
  text-align: center;
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const DialogTitle = styled.div`
  font-family: Inter;
  font-size: 22px;
  font-weight: 590;
  line-height: 26px;
  color: #000000;
  padding-bottom: 4px;
`;

export const DialogDescription = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #000000;
`;

export const DialogCancelBtn = styled.div`
  width: 100%;
  max-width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
  margin-right: 6px;
`;

export const DialogSubmitBtn = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #1db45a;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogOrangeBtn = styled.div`
  width: 100%;
  max-width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f15a25;
  border: 1px solid #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainLoadingWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
