import api from "../config/api";

export const getSubscription = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/subscription/${clientCognitoId}`);
  return data;
};
