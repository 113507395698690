import * as React from "react";
import { Button, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function InputField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    ownerState,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{
        background: "#FFFFFF",
        border: "1px solid #E1E1E1",
        borderRadius: "8px",
        padding: "6px 14px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        textTransform: "initial",
        color: "#101828",
        height: "40px",
        width: ownerState?.fullwidth? "100%":"200px",
        justifyContent: "left",
        "&:hover": {
          borderRadius: "8px",
          borderColor: "#E1E1E1",
          background: "#FFFFFF",
        },
      }}
    >
      {label ?? "Pick a date"}
    </Button>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <DatePicker
      slots={{ field: InputField, ...props.slots }}
      slotProps={{
        field: { setOpen },
        actionBar: {
          actions: ["today"],
        },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function StyledDatePicker({ value, setValue, fullwidth=false }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <ButtonDatePicker
          fullwidth={fullwidth}
          label={`${
            value == null ? "Select Date" : value.format("MM/DD/YYYY")
          }`}
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
      </Stack>
    </LocalizationProvider>
  );
}
