import { Button } from "components/ui/button-tw";
import { ArrowRight } from "lucide-react";
import React from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
import styled from "styled-components";
import { textStyles } from "ui";
import transactionsImage from "assets/images/preview-transactions.png";

export const SetupPocketbook = ({ setHasStarted }) => {
  return (
    <div className="">
      <div className="relative isolate overflow-hidden lg:overflow-visible ">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-6">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full  lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-2xl">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Start Receiving Payments
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  As you create and send invoices to your customers, they need a
                  way to pay you. The final step in setting up your account is
                  establishing a processing account – a deposit account that
                  will be used to process payments.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src={transactionsImage}
              alt="Sample transactions"
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full  lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-2xl text-base leading-7 text-gray-700 ">
                <p>
                  Your processing account enables your business to save money
                  and receive payments more quickly. Some information about your
                  business will be required, e.g.:
                </p>
                <ul role="list" className="mt-6 space-y-4 text-gray-600">
                  <li className="flex gap-x-3">
                    <BsChevronDoubleRight
                      className="mt-1.5 h-4 w-4 flex-none text-slate-600"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Standard Business Information
                      </strong>{" "}
                      - Business Name, Address, Phone Number, EIN, etc...
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <BsChevronDoubleRight
                      className="mt-1.5 h-4 w-4 flex-none text-slate-600"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Information on the Control Person
                      </strong>{" "}
                      - name, address, DOB, and SSN for the individual who has
                      the authority to open the account for the business
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <BsChevronDoubleRight
                      className="mt-1.5 h-4 w-4 flex-none text-slate-600"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Information on Beneficial Owners of the Business
                      </strong>{" "}
                      - name, address, DOB, and SSN for all other individuals
                      who own 25% or more of the business
                    </span>
                  </li>
                </ul>
                <h2 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
                  Important Information
                  {/* About Procedures for Opening a New
                  Business Account */}
                </h2>
                <p className="mt-6 text-sm">
                  To help the government fight the funding of terrorism and
                  money laundering activities, Federal law requires us to
                  obtain, verify, and record information that identifies each
                  customer who opens an Account.  This means that when you open
                  an Account, we will ask for your name, address, tax
                  identification number, and other information that will allow
                  us to identify you.  Federal regulations require that you
                  provide us with information concerning the beneficial owners
                  of a legal entity at Account opening.  We will request the
                  name, address, date of birth, and taxpayer ID number or social
                  security number of certain beneficial owners and a control
                  person.  We may also ask to see other identifying documents,
                  such as a driver’s license, taxpayer identification number;
                  passport number and country of issuance; alien identification
                  card number; or number and country of issuance of any
                  government-issued document evidencing nationality or residence
                  and bearing a photograph or similar safeguard.
                </p>
              </div>
              <div className="mt-8">
                <Button
                  onClick={() => setHasStarted(true)}
                  className="w-full lg:w-auto"
                >
                  Start now
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </div>
              <FooterText>
                Pocketbook is not a bank. Banking services provided by
                Grasshopper Bank, N.A., Member FDIC.
              </FooterText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterText = styled.div`
  margin-top: 32px;
  ${textStyles.body.b2};
`;
