import * as actionTypes from "pages/customer/login/actionTypes";

const initialState = () => ({
  token: localStorage.getItem("AuthToken"),
  role: localStorage.getItem("UserRole"),
  loggedInUserId: localStorage.getItem("CognitoId"),
});

const authReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case actionTypes.USER_ROLE:
      return {
        ...state,
        role: payload,
      };
    case actionTypes.LOGGED_IN_USER_ID:
        return {
            ...state,
            loggedInUserId: payload,
        }
    case actionTypes.LOG_OUT:
      return initialState();
    default:
      return state;
  }
};

export default authReducer;
