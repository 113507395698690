import axios from "axios";

const req = axios.create();

req.defaults.baseURL = process.env.REACT_APP_PBTECH_API_ENDPOINT;
req.defaults.headers.common["x-api-key"] = process.env.REACT_APP_PBTECH_API_KEY;
req.defaults.headers.post["Content-Type"] = "application/json";
req.defaults.withCredentials= true;

export { req };
export default req;
