import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CustomSelector } from "pages/utils/styled-select";
import { convertThousandSeparator } from "scripts/functions";
import { money_list, type_list } from "pages/demo";
import { InvoiceChart } from "pages/dashboard/components";
import {
  HeaderBalanceAmount,
  BalanceLabel,
  MainLabel,
  StyledBody,
  MobileDashboardHeader,
  StyledSubTitle,
  StyledTitle,
  MainBalance,
  LeftOptionBtn,
  CenterOptionBtn,
  RightOptionBtn,
  ShortcutBtn,
} from "./styled";
import { FeatureBalanceCard } from "pages/dashboard/styled";
import useGetClientInfo from "pages/dashboard/hooks/useGetClientInfo";
import { useKYBData } from "hooks/platform/use-kyb-data";
import { LinkBankStepper } from "pages/dashboard/dashboard";

const StyledHeader = styled(MobileDashboardHeader)`
  background-color: ${(props) => props.color};
`;
const MobileViewDashboard = ({
  type,
  setType,
  kpiOption,
  setKpiOption,
  dateOption,
  setDateOption,
  bondBalance,
  businessName,
  chartOptions,
  invoiceChartData,
}) => {
  const { settingData } = useSelector((state) => state.account);

  return (
    <div>
      <StyledHeader color={settingData?.primaryolor}>
        <div>
          <StyledTitle>Dashboard</StyledTitle>
          <StyledSubTitle>{businessName}</StyledSubTitle>
        </div>
        <div>
          <HeaderBalanceAmount>
            <LinkBankStepper />
            {/* <FeatureBalanceCard /> */}
          </HeaderBalanceAmount>
        </div>
      </StyledHeader>
      <StyledBody>
        <MainLabel>Summary</MainLabel>
        <div className="flex">
          <CustomSelector
            value={type}
            setValue={setType}
            selectList={type_list}
            width={150}
            menuWidth={230}
            mBottom={16}
            mRight={16}
            menuLeft
          />
          <CustomSelector
            value={kpiOption}
            setValue={setKpiOption}
            selectList={money_list}
            width={120}
            menuWidth={240}
            mBottom={16}
            menuLeft
          />
        </div>
        <MainBalance>
          {bondBalance?.available_balance &&
            `$${convertThousandSeparator(bondBalance?.available_balance)}`}
        </MainBalance>
        <div className="flex pb-25">
          {dateOption === 90 ? (
            <LeftOptionBtn
              style={{ background: "rgb(50, 81, 97, 0.65)", color: "#ffffff" }}
            >
              90 days
            </LeftOptionBtn>
          ) : (
            <LeftOptionBtn onClick={() => setDateOption(90)}>
              90 days
            </LeftOptionBtn>
          )}
          {dateOption === 30 ? (
            <CenterOptionBtn
              style={{ background: "rgb(50, 81, 97, 0.65)", color: "#ffffff" }}
            >
              30 days
            </CenterOptionBtn>
          ) : (
            <CenterOptionBtn onClick={() => setDateOption(30)}>
              30 days
            </CenterOptionBtn>
          )}
          {dateOption === 7 ? (
            <RightOptionBtn
              style={{ background: "rgb(50, 81, 97, 0.65)", color: "#ffffff" }}
            >
              7 days
            </RightOptionBtn>
          ) : (
            <RightOptionBtn onClick={() => setDateOption(7)}>
              7 days
            </RightOptionBtn>
          )}
        </div>
        <InvoiceChart
          primaryColor={settingData?.primary_color}
          invoiceData={invoiceChartData}
        />
      </StyledBody>
    </div>
  );
};

export default MobileViewDashboard;
