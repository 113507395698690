import { useDispatch } from "react-redux";
import { Dialog } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import { revokeQBIntegration } from "./actions";
import * as actionTypes from "./actionTypes";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogSubmitBtn,
} from "pages/contacts/styled";
import { toast } from "react-toastify";
import { customTimeout } from "utils/functions";

const DisconnectQuickBookDialog = ({ dialog, setDialog }) => {
  const dispatch = useDispatch();

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleDisconnect = () => {
    revokeQBIntegration().then((res) => {
      console.log("revoke integration", res);
      if (res && res.message === "integration quickbooks deleted") {
        dispatch({
          type: actionTypes.CONNECTED_QUICKBOOK,
          payload: false,
        });
        setDialog(false);
        toast.success("Quickbooks has been disconnected");
        customTimeout(() => {
          window.location.reload();
        });
      }
    });
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection className="welcome-dialog">
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription>
          Are you sure you'd like to disconnect your QuickBooks integration?
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            No! Keep it
          </DialogCancelBtn>
          <DialogSubmitBtn onClick={handleDisconnect}>
            Yes, disconnect
          </DialogSubmitBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default DisconnectQuickBookDialog;
