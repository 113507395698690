import styled from "styled-components";
import { breakpoints } from "ui/breakpoints";

export const SupportSection = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  gap: 20px;

  justify-content: center;

  @media (${breakpoints.sm}){
    justify-content: flex-start;
  }
`;

export const SupportCard = styled.div`
  width: 100%;
  max-width: 290px;
  min-width: 290px;
  height: 310px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
`;

export const SupportCardHeader = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 23px;
  width: 100%;
  background-color: #000000;
`;

export const SupportLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #101828;
  padding-top: 24px;
  padding-bottom: 10px;
`;

export const SupportValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 30px;
`;

export const SupportBtn = styled.div`
  width: 142px;
  height: 40px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// Customer

export const CustomerCardHeader = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 23px;
  width: 100%;
`;

export const CustomerSupportBtn = styled.div`
  width: 142px;
  height: 40px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
