import req from "api/config";
import api from "api/v1-jwt/config/api";

/**
 * @typedef {Object} Settings
 * @property {string} logo             - The logo url
 * @property {string} favicon          - The favicon url
 * @property {string} primary_color    - The primary color hex string
 * @property {string} secondary_color  - The secondary color hex string
 * @property {string} accent_color     - The accent color hex string
 * @property {string} marketing_logo     - The marketing image url

/**
 * @returns {Promise<Settings>} A promise to the settings object
 */
export const getSettings = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/settings/${clientCognitoId}`);
  return {
    ...data,
    logo: data.logo ? `${data.logo}?${Date.now()}` : "",
    marketing_logo: data.marketing_logo
      ? `${data.marketing_logo}?${Date.now()}`
      : "",
  };
};

/**
 * @param {Object} payload
 * @param {string} [payload.logo]             - The logo url
 * @param {string} [payload.marketing_logo]   - The marketing logo url
 * @param {string} [payload.favicon]          - The favicon url
 * @param {string} [payload.primary_color]    - The primary color hex string
 * @param {string} [payload.secondary_color]  - The secondary color hex string
 * @param {string} [payload.accent_color]     - The accent color hex string
 * @throws Will throw an error if status code is >= 400 and < 600
 * @returns {Promise<Settings>} A promise to the settings object
 */
export const updateSettings = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/settings/${clientCognitoId}`,
    payload
  );

  return data;
};

/**
 * @param {File} file       - The logo file to be uploaded
 * @throws
 * @returns {Promise<void>} - A promise
 */
export const uploadLogo = async (file) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/settings/upload_url/${clientCognitoId}?file_name=logo.png&folder_name=settings`
    );

    const { fields } = data.uploadURLData;

    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);

    await api.post(data.uploadURLData.url, formData);
    await updateSettings({ logo: data.key });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const uploadMarketingLogo = async (file) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    let fileType;
    fileType = file.type.split("image/")[1];
    const { data } = await api.get(
      `/settings/upload_url/${clientCognitoId}?file_name=ad1.${fileType}&folder_name=settings/marketing`
    );

    const { fields } = data.uploadURLData;

    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);

    await api.post(data.uploadURLData.url, formData);
    await updateSettings({ marketing_logo: data.key });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
