import { useQuery } from "@tanstack/react-query";
import { fetchPlatformData } from "api/v1-jwt/platform-data/platform-data";

export const usePlatformData = ({ queryFn, queryKey, ...rest } = {}) => {
  let host = window.location.host;
  let subdomain = host.startsWith("localhost")
    ? process.env.REACT_APP_DEV_SUBDOMAIN
    : host.split(".pocketbook")[0];

  return useQuery({
    queryKey: ["platform", subdomain],
    queryFn: () => fetchPlatformData(subdomain),
    // staleTime: Infinity,
    ...rest,
  });
};
