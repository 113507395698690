import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();

  // Memoize the result to prevent infinite re-renders
  const queryParams = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(location.search));
  }, [location.search]);

  return queryParams;
};

export default useQueryParams;
