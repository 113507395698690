import { useQuery } from "@tanstack/react-query";
import { getPlatformUser } from "api/v1-jwt/tenancy/tenancy";

export const usePlatformUser = ({
  userId,
  queryKey,
  queryFn,
  ...config
} = {}) => {
  return useQuery({
    queryKey: ["tenancy", "user", userId],
    queryFn: async () => getPlatformUser(userId),
    enabled: !!userId,
    ...config,
  });
};
