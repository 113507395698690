import * as React from "react";
import { cn } from "utils/classnames";
import styled, { css } from "styled-components";

const StyledInput = styled.input`
  ${(props) =>
    props.type === "number" &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    `}
`;

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <>
      <StyledInput
        type={type}
        className={cn(
          "flex h-10 w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    </>
  );
});
Input.displayName = "Input";

export { Input };
