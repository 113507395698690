import React, { useEffect, useState } from "react";
import req from "api/config";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { BasicModal, Button, textStyles } from "ui";
import Grid from "@mui/material/Grid";
import { LabelStyle } from "../FormLabelStyles";
import InputLabel from "@mui/material/InputLabel";
import { useStep } from "../hooks/useStep";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatPhoneNumber } from "utils/functions";
import { platformEndpoints } from "../utils/PlatformEndpoints";
import { toast } from "react-toastify";
import api from "api/v1-jwt/config/api";

export const ConfirmForm = ({ handleNext }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handlePost, isPostLoading } = useStep(5);
  const userId = JSON.parse(localStorage.getItem("CognitoId"));
  const { profileData } = useSelector((state) => state.profile);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [businessType, setBusinessType] = useState("");

  // GET BUSINESS TYPE LABEL
  const fetchBusinessType = async (currentType) => {
    try {
      const businessTypes = await platformEndpoints.fetchBusinessTypes();
      const selectedType = businessTypes.filter((type) => {
        return type.value === currentType;
      });
      setBusinessType(selectedType[0].label);
    } catch (error) {
      toast.error("Error fetching business types. Contact support.");
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get(
        `/platform/application/submitted/${userId}/${profileData.application_id}`
      );
      setData(response.data);
      fetchBusinessType(response?.data?.step1?.business_type);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {isLoading ? (
        <LoaderContainer>
          <CircularProgress color="inherit" size={24} />
        </LoaderContainer>
      ) : (
        <Container>
          <StepWrapper>
            <div className="sm:px-0">
              <h3 className="text-lg font-semibold leading-7 text-gray-900">
                Business Information
              </h3>
            </div>

            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Type
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {businessType}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Legal Business Name
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step1?.legal_business_name}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    DBA
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step1?.business_dba}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    EIN (or Social Security #)
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`*${data?.step1?.ein}`}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Date Established
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step1?.date_established}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Description
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step1?.business_description}
                  </dd>
                </div>
              </dl>
            </div>
          </StepWrapper>
          <StepWrapper>
            <div className="sm:px-0">
              <h3 className="text-lg font-semibold leading-7 text-gray-900">
                Business Contact
              </h3>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Phone
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {formatPhoneNumber(data?.step2?.business_phone)}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Email
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step2?.business_email}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Website
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step2?.business_website}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Address
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`${data?.step2?.business_physical_address?.street_line_1}, ${data?.step2?.business_physical_address?.city}, ${data?.step2?.business_physical_address?.state}. ${data?.step2?.business_physical_address?.postal_code}`}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Business Formation State
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step2?.business_formation_state}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Industry Classification
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step2?.business_naics_description}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Estimated Monthly Sales
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step2?.business_estimated_monthly_sales}
                  </dd>
                </div>
              </dl>
            </div>
          </StepWrapper>
          <StepWrapper>
            <div className="sm:px-0">
              <h3 className="text-lg font-semibold leading-7 text-gray-900">
                Control Person Information
              </h3>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Full Name
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`${
                      data?.step3?.control_person_info
                        ?.control_person_first_name
                    } ${
                      data?.step3?.control_person_info
                        ?.control_person_middle_name || " "
                    } ${
                      data?.step3?.control_person_info?.control_person_last_name
                    }`}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Residential Address
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {`${data?.step3?.control_person_info?.control_person_physical_address?.street_line_1}, ${data?.step3?.control_person_info?.control_person_physical_address?.city}, ${data?.step3?.control_person_info?.control_person_physical_address?.state}. ${data?.step3?.control_person_info?.control_person_physical_address?.postal_code}`}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Email Address
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step3?.control_person_info?.control_person_email}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {formatPhoneNumber(
                      data?.step3?.control_person_info?.control_person_phone
                    )}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    SSN
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step3?.control_person_info?.control_person_ssn}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Date of birth
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step3?.control_person_info?.control_person_dob}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Role or Title
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {data?.step3?.control_person_info?.control_person_title}
                  </dd>
                </div>
                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Ownership Percentage
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {
                      data?.step3?.control_person_info
                        ?.control_person_ownership_pct
                    }
                  </dd>
                </div>
              </dl>
            </div>
          </StepWrapper>
          {data?.step4?.beneficial_owners.map((owner, index) => {
            return (
              <StepWrapper>
                <div className="sm:px-0">
                  <h3 className="text-lg font-semibold leading-7 text-gray-900">
                    Beneficial Owner Information {index + 1}
                  </h3>
                </div>

                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Full Name
                      </dt>
                      <dd>
                        {`${owner.control_person_first_name} ${
                          owner?.control_person_middle_name || " "
                        } ${owner?.control_person_last_name}`}
                      </dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Residential Address
                      </dt>
                      <dd>
                        {`${owner?.control_person_physical_address?.street_line_1}, ${owner?.control_person_physical_address?.city}, ${owner?.control_person_physical_address?.state}. ${owner?.control_person_physical_address?.postal_code}`}
                      </dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        SSN
                      </dt>
                      <dd>{owner?.control_person_ssn}</dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Email Address
                      </dt>
                      <dd>{owner?.control_person_email}</dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Phone Number
                      </dt>
                      <dd>{formatPhoneNumber(owner?.control_person_phone)}</dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Date of birth
                      </dt>
                      <dd>{owner?.control_person_dob}</dd>
                    </div>
                    <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Role or Title
                      </dt>
                      <dd>{owner?.control_person_title}</dd>
                    </div>
                  </dl>
                </div>
              </StepWrapper>
            );
          })}
          <ActionButtons>
            <Button
              variant="white"
              onClick={(e) => {
                e.preventDefault();
                setOpenModal(true);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="green"
              onClick={() => {
                handlePost(
                  {
                    is_confirmed: true,
                  },
                  handleNext
                );
              }}
            >
              {isPostLoading ? (
                <CircularProgress style={{ color: "#FFFFFF" }} size={12} />
              ) : (
                "Next"
              )}
            </Button>
          </ActionButtons>
          <BasicModal
            title={"Are you sure you want to cancel setup?"}
            text={"If you cancel, you’ll need to start over."}
            open={openModal}
            buttonText={"Yes, cancel setup"}
            onButtonClick={() => history.push("/dashboard")}
            handleClose={handleCloseModal}
          />
        </Container>
      )}
    </>
  );
};

const LoaderContainer = styled.div`
  margin-top: 34px;
  text-align: center;
`;

const Container = styled.div`
  margin-top: 34px;
`;

const Title = styled.div`
  ${textStyles.title.h3}
`;

const StepWrapper = styled.div`
  margin-top: 50px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
`;
