import api from "api/v1-jwt/config/api";

export const getSettings = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/settings/${clientCognitoId}`);

  return data;
};

export const updateSettings = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/settings/${clientCognitoId}`,
    payload
  );

  return data;
};

export const uploadLogo = async (file) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    // Get URL
    const { data: url } = await api.get(
      `/settings/upload_url/${clientCognitoId}?file_name=logo.png&folder_name=settings`
    );

    // Upload New way
    const presignedData = url.uploadURLData;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", file);
    const uploadResponse = await fetch(presignedData.url, {
      method: "POST",
      body: formData,
      redirect: "follow",
    });
    return { response: uploadResponse, url: url.key };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const uploadMarketingImage = async (file) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    // Get file type
    let fileType;
    fileType = file.type.split("image/")[1];
    if (fileType === "svg+xml") fileType = "svg";
    // Get URL
    const { data: url } = await api.get(
      `/settings/upload_url/${clientCognitoId}?file_name=ad1.${fileType}&folder_name=settings`
    );

    // Upload New way
    const presignedData = url.uploadURLData;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", file);
    const uploadResponse = await fetch(presignedData.url, {
      method: "POST",
      body: formData,
      redirect: "follow",
    });
    return { response: uploadResponse, url: url.key };
  } catch (err) {
    console.error(err);
  }
};

export const jwtTest = async () => {
  const { data } = await api.get(`/jwt-test`);

  return data;
};
