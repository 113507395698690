import { Route, Switch } from "react-router-dom";
import {
  CustomerDashboard,
  CustomerContracts,
  CustomerInvoices,
  CustomerInvoiceDetail,
  CustomerPaymentMethods,
  CustomerNewPaymentMethod,
  CustomerSupport,
  CustomerSettings,
  ContractDetails,
} from "pages/index.js";
import { NoMatch } from "components/no-match-redirect";

const CustomerRoutes = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={CustomerDashboard} />
      <Route
        path="/quotes/:id"
        render={(props) => <ContractDetails {...props} customer />}
      />
      <Route path="/quotes" component={CustomerContracts} />
      <Route exact path="/invoices" component={CustomerInvoices} />
      <Route path="/invoices/detail/:id" component={CustomerInvoiceDetail} />
      <Route path="/invoices/pay/:id" component={CustomerPaymentMethods} />
      <Route exact path="/payment-methods" component={CustomerPaymentMethods} />
      <Route path="/payment-methods/new" component={CustomerNewPaymentMethod} />
      <Route path="/support" component={CustomerSupport} />
      <Route path="/settings" component={CustomerSettings} />
      <Route path="/" exact component={CustomerDashboard} />
      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default CustomerRoutes;
