import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CircularProgress } from "@mui/material";
import { BsCheck, BsX } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { CustomColorPicker, CustomSelector } from "pages/utils/styled-select";
import {
  StyledDomainInput,
  StyledInput,
  StyledPasswordInput,
} from "pages/utils/styled-input";
import { StyledDrawer } from "pages/utils/styled-drawer";
import StyledImageUploader from "../utils/styled-image-uploader";
import {
  isValidLowercaseInPassword,
  isValidNumberInPassword,
  isValidPasswordLength,
  isValidSpecialCharacterInPassword,
  isValidUppercaseInPassword,
} from "scripts/functions";
import {
  auth_options,
  color_options,
  cookie_options,
  country_options,
  timezone_options,
} from "pages/demo";
import {
  getSettings,
  updateSettings,
  uploadLogo,
  uploadMarketingImage,
} from "./actions";
import { getProfileInfo } from "pages/setup/actions";
import * as actionTypes from "pages/customer/signup/actionTypes";
import { MainWrapper, OptionLabel } from "pages/styled";
import {
  BtnSection,
  CheckboxLabel,
  ChecklistLabel,
  ChecklistSection,
  FormBtn,
  FormField,
  FormLabel,
  MFACheckboxSection,
  PhoneInputForm,
  ProfileSection,
  SettingDescription,
  ValidationText,
} from "./styled";
import {
  useUploadLogo,
  useUploadMarketingImage,
} from "hooks/setting/use-upload-logo";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useResetPassword } from "hooks/auth/use-reset-password";
import { useAuth } from "providers/auth";
import { Button } from "components/ui/button-tw";
import { Loader2 } from "lucide-react";
import { removePlusOne } from "utils/string-manipulation";
import { useTenancyinfo } from "providers/auth";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { useSettings } from "hooks/setting/use-settings";
import { usePlatformUsers } from "hooks/tenancy/use-platform-users";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { useAdminInvite } from "hooks/tenancy/use-admin-invite";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { useDeleteAdmin } from "hooks/tenancy/use-delete-admin";

const Settings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { accessToken, logout } = useAuth();
  const { subdomain } = useTenancyinfo();
  const { mutate: resetPassword, isLoading: resettingPassword } =
    useResetPassword();
  // const { data: settings } = useSettings({
  //   onSuccess: (data) => setUploadedImage(data.logo),
  // });
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [mailingAddr, setMailingAddr] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [timezone, setTimezone] = useState("PST");
  const [country, setCountry] = useState("USA");
  const [domain, setDomain] = useState("");
  const [subscription, setSubscription] = useState("");
  const [selectedColor, setSelectedColor] = useState({
    label: "Default (Black)",
    value: "#000000",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authOption, setAuthOption] = useState({
    label: "Email",
    value: "email",
  });
  const [cookie, setCookie] = useState({ label: "Allowed", value: "allowed" });
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [enableMFA, setEnableMFA] = useState(false);
  const [drawer, setDrawer] = useState({
    top: false,
  });
  const [hide, setHide] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  // Upload Image
  const [uploadedImage, setUploadedImage] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [marketingImage, setMarketingImage] = useState(null);
  const [previewMarketingImg, setPreviewMarketingImg] = useState(null);
  const [loading3, setLoading3] = useState(false);
  const { data: settings } = useSettings();
  const { mutate } = useUploadLogo();
  const { mutate: mutateMarketingImage } = useUploadMarketingImage();
  const client = useQueryClient();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    getProfile();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getPlatformSettings();
    setDomain(subdomain.split(".")[0]);
  }, []);

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  }, [newPassword, confirmPassword]);

  function handleResize() {
    const width = window.innerWidth;
    if (width < 700) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  const getProfile = async () => {
    await getProfileInfo()
      .then((res) => {
        if (res) {
          setFirstName(res.first_name);
          setLastName(res.last_name);
          setEmail(res.business_email);
          setBusinessName(res.business_name);
          setPhone(removePlusOne(res.business_phone));
          setDbaName(res.business_dba);
          setTimezone({ label: res.time_zone, value: res.time_zone });
          setSubscription(res.subscription_plan);
          setCity(res.physical_address.city);
          setState(res.physical_address.state);
          setCountry(res.physical_address.country);
          setMailingAddr(res.physical_address.street_line_1);
          setZipCode(res.physical_address.postal_code);
        }
      })
      .catch((error) => {
        console.log("SETTINGS::GET_PROFILE_INFO::ERROR::", error);
      });
  };

  const getPlatformSettings = async () => {
    await getSettings().then((res) => {
      if (res) {
        const filteredColor = color_options.filter(
          (color) => color.value === res.primary_color
        );
        if (filteredColor.length > 0) setSelectedColor(filteredColor[0]);
      }
    });
  };

  const handleChangeLogo = async (file) => {
    setPreviewLogo(file[0]);
  };

  const handleChangeMarketingImage = async (file) => {
    setPreviewMarketingImg(file[0]);
  };

  const handleSaveCustomBranding = async () => {
    const primaryColor = settingData?.primary_color;

    try {
      setLoading3(true);
      if (previewLogo) {
        try {
          const resp = await uploadLogo(previewLogo);
          setPreviewLogo(previewLogo);
          const payload = {
            logo: resp.url,
          };
          await updateSettings(payload);
          toast.success("Logo uploaded successfully");
        } catch (err) {
          toast.error(
            "An error occurred while uploading the logo. Please try again."
          );
        }
      }

      if (previewMarketingImg) {
        try {
          const resp = await uploadMarketingImage(previewMarketingImg);
          const payload = {
            marketing_logo: resp.url,
          };
          await updateSettings(payload);
          client.setQueryData(["settings"], (old) => ({
            ...old,
            marketing_logo: resp.url,
          }));
          setPreviewMarketingImg(null);
          toast.success("Marketing image uploaded successfully");
        } catch (error) {
          toast.error(
            "An error occurred while uploading the image. Please try again."
          );
        }
      }

      if (primaryColor !== selectedColor?.value) {
        const payload = {
          primary_color: selectedColor?.value,
        };
        await updateSettings(payload);
      }

      client.invalidateQueries(["settings"]);
      await getSettings().then((settings) => {
        dispatch({
          type: actionTypes.SETTING_DATA,
          payload: {
            ...settings,
            logo: settings.logo ? `${settings.logo}?${Date.now()}` : "",
          },
        });
      });
    } finally {
      setLoading3(false);
    }
  };

  const handleShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);

  const handleShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleResetPassword = () => {
    resetPassword(
      {
        previous_password: currentPassword,
        new_password: newPassword,
        access_token: accessToken,
      },
      {
        onSuccess: (data) => {
          toast.success(data.message);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        },
        onError: (error) => {
          console.log("[SETTINGS] Reset password:: ", error);
          toast.error("Was not possible to reset password. Please try again.");
        },
      }
    );
  };

  const isResetPasswordBtnDisabled = useMemo(() => {
    return (
      !currentPassword ||
      !newPassword ||
      !isValidLowercaseInPassword(newPassword) ||
      !isValidNumberInPassword(newPassword) ||
      !isValidPasswordLength(newPassword) ||
      !isValidSpecialCharacterInPassword(newPassword) ||
      !isValidUppercaseInPassword(newPassword) ||
      newPassword !== confirmPassword ||
      resettingPassword
    );
  }, [resettingPassword, newPassword, confirmPassword, currentPassword]);

  const handleSaveAuthSettings = () => {
    setDrawer({ ...drawer, top: true });
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Settings"
            subTitle="View your information, Add Admin(s), Custom Branding, and statements"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <div>
            <ProfileSection>
              <OptionLabel>Personal Information</OptionLabel>
              <FormField>
                <FormLabel>Name</FormLabel>
                <StyledInput
                  value={firstName}
                  setValue={setFirstName}
                  placeholder={"First Name"}
                  width={hide ? "512px" : "244px"}
                  mBottom={20}
                  mRight={24}
                  readOnly={true}
                  disabled={true}
                />
                <StyledInput
                  value={lastName}
                  setValue={setLastName}
                  placeholder={"Last Name"}
                  width={hide ? "512px" : "244px"}
                  mBottom={20}
                  readOnly={true}
                  disabled={true}
                />
              </FormField>
              <FormField>
                <FormLabel>Email address</FormLabel>
                <StyledInput
                  value={email}
                  setValue={setEmail}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled={true}
                />
              </FormField>
              <FormField>
                <FormLabel>Phone number</FormLabel>
                <PhoneInputForm>
                  <PhoneInput
                    // placeholder={"(123) 456-7890"}
                    country={"us"}
                    onlyCountries={["us"]}
                    disableCountryCode={true}
                    value={phone}
                    onChange={(phone) => {
                      setPhone(phone);
                    }}
                    disabled={true}
                    autoComplete="off"
                    style={{ border: "1px solid #ddd" }}
                  />
                </PhoneInputForm>
              </FormField>
            </ProfileSection>
            <ProfileSection>
              <OptionLabel>Business Information</OptionLabel>
              <FormField>
                <FormLabel>Business name</FormLabel>
                <StyledInput
                  value={businessName}
                  setValue={setBusinessName}
                  // placeholder={"Business name"}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>DBA</FormLabel>
                <StyledInput
                  value={dbaName}
                  setValue={setDbaName}
                  // placeholder={"DBA"}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>Mailing Address</FormLabel>
                <StyledInput
                  value={mailingAddr}
                  setValue={setMailingAddr}
                  // placeholder={"100 Smith Street"}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>City</FormLabel>
                <StyledInput
                  value={city}
                  setValue={setCity}
                  // placeholder={"Bend"}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>State / Province</FormLabel>
                <StyledInput
                  value={state}
                  setValue={setState}
                  // placeholder={"OR"}
                  width={hide ? "512px" : "148px"}
                  mBottom={20}
                  mRight={24}
                  readOnly={true}
                  disabled
                />
                <StyledInput
                  value={zipCode}
                  setValue={setZipCode}
                  // placeholder={"97001"}
                  width={hide ? "512px" : "148px"}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>Timezone</FormLabel>
                <CustomSelector
                  value={timezone}
                  setValue={setTimezone}
                  selectList={timezone_options}
                  width={hide ? "100%" : "148px"}
                  menuWidth={200}
                  mBottom={20}
                  // placeholder={"PST"}
                  disabled={true}
                />
              </FormField>
              <FormField>
                <FormLabel>Country</FormLabel>
                <CustomSelector
                  value={country}
                  setValue={setCountry}
                  selectList={country_options}
                  width={hide ? "100%" : "148px"}
                  menuWidth={200}
                  mBottom={20}
                  placeholder={"USA"}
                  disabled={true}
                />
              </FormField>
              <SettingDescription>
                To update your personal or business information, please contact
                us:{" "}
                <span>
                  <a href="mailto:support@pocketbook.tech">
                    support@pocketbook.tech
                  </a>
                </span>
              </SettingDescription>
            </ProfileSection>

            {/**
             *  ADMIN SECTION
             */}
            <AddAdminFormSection />

            <ProfileSection>
              <OptionLabel>Administrators</OptionLabel>
              <AdminTable />
            </ProfileSection>

            <ProfileSection>
              <OptionLabel>Custom Branding & Marketing</OptionLabel>
              <FormField>
                <FormLabel>Pocketbook domain</FormLabel>
                <StyledDomainInput
                  value={domain}
                  setValue={setDomain}
                  placeholder={"yourdomain"}
                  width={512}
                  mBottom={20}
                  readOnly={true}
                  disabled
                />
              </FormField>
              <FormField>
                <FormLabel>App color</FormLabel>
                <CustomColorPicker
                  value={selectedColor}
                  setValue={setSelectedColor}
                  selectList={color_options}
                  width={hide ? "100%" : "148px"}
                  menuWidth={"320px"}
                  mBottom={36}
                  menuLeft
                />
              </FormField>
              <StyledImageUploader
                formLabel="Logo"
                formSubLabel={`For optimal performance and the best user experience, please upload a white PNG image of your logo with a transparent background.`}
                handleChangeImage={handleChangeLogo}
                previewImg={previewLogo}
                uploadedImg={settings.logo}
                size="800x400px"
              />
              <StyledImageUploader
                formLabel="Marketing image"
                formSubLabel={`Your customers will see this image when paying an invoice.`}
                handleChangeImage={handleChangeMarketingImage}
                previewImg={previewMarketingImg}
                uploadedImg={settings.marketing_logo}
                size="1920x800px"
              />
              <BtnSection>
                <Button onClick={handleSaveCustomBranding}>
                  {loading3 ? (
                    <CircularProgress style={{ color: "white" }} size={14} />
                  ) : (
                    "Save changes"
                  )}
                </Button>
              </BtnSection>
            </ProfileSection>
            <ProfileSection>
              <OptionLabel>Account statements</OptionLabel>
              <FormField>
                <FormLabel>Statements</FormLabel>
                <FormBtn
                  style={{ maxWidth: 194 }}
                  onClick={() => history.push("/account-statement")}
                >
                  View all past statements
                </FormBtn>
              </FormField>
            </ProfileSection>
            <ProfileSection>
              <OptionLabel>Account details</OptionLabel>
              <FormField className="pb-25">
                <FormLabel>Subscription</FormLabel>
                {/* <FormBtn style={{ maxWidth: 512 }}> */}
                {/* Monthly (Autopay) - Next payment due 08/24/2023 */}
                <FormBtn>
                  {subscription === "monthly" ? "Monthly" : "Yearly"}
                </FormBtn>
              </FormField>
              <FormField className="pb-25">
                <FormLabel>Account / Routing #</FormLabel>
                <FormBtn className="mr-32">XXXXXXXXXX</FormBtn>
                <FormBtn>XXXXXXXXXX</FormBtn>
              </FormField>
              <SettingDescription>
                To update or close your Pocketbook account, please contact us at{" "}
                <span>
                  <a href="mailto:support@pocketbook.tech">
                    support@pocketbook.tech
                  </a>
                </span>
              </SettingDescription>
              <BtnSection>
                <Button href="mailto:support@pocketbook.tech">
                  Contact us
                </Button>
              </BtnSection>
            </ProfileSection>
            <ProfileSection>
              <OptionLabel>Privacy & security</OptionLabel>
              <FormField>
                <FormLabel>Current password</FormLabel>
                <StyledPasswordInput
                  value={currentPassword}
                  setValue={setCurrentPassword}
                  //placeholder={"Current Password"}
                  type="password"
                  width={512}
                  mBottom={20}
                  showPassword={showCurrentPassword}
                  handleShowPassword={handleShowCurrentPassword}
                />
              </FormField>
              <FormField>
                <FormLabel>New password</FormLabel>
                <div className="w-100">
                  <StyledPasswordInput
                    value={newPassword}
                    setValue={setNewPassword}
                    //placeholder={"New Password"}
                    type="password"
                    width={512}
                    showPassword={showNewPassword}
                    handleShowPassword={handleShowNewPassword}
                    mBottom={20}
                  />
                  <ChecklistSection>
                    {isValidPasswordLength(newPassword) ? (
                      <div className="flex">
                        <BsCheck style={{ color: "#00cd4c" }} />
                        <ChecklistLabel style={{ color: "#000000" }}>
                          Minimum length of 12 characters
                        </ChecklistLabel>
                      </div>
                    ) : (
                      <div className="flex">
                        <BsX />
                        <ChecklistLabel>
                          Minimum length of 12 characters
                        </ChecklistLabel>
                      </div>
                    )}
                    {isValidLowercaseInPassword(newPassword) ? (
                      <div className="flex">
                        <BsCheck style={{ color: "#00cd4c" }} />
                        <ChecklistLabel style={{ color: "#000000" }}>
                          Must contain a lowercase letter
                        </ChecklistLabel>
                      </div>
                    ) : (
                      <div className="flex">
                        <BsX />
                        <ChecklistLabel>
                          Must contain a lowercase letter
                        </ChecklistLabel>
                      </div>
                    )}
                    {isValidUppercaseInPassword(newPassword) ? (
                      <div className="flex">
                        <BsCheck style={{ color: "#00cd4c" }} />
                        <ChecklistLabel style={{ color: "#000000" }}>
                          Must contain an uppercase letter
                        </ChecklistLabel>
                      </div>
                    ) : (
                      <div className="flex">
                        <BsX />
                        <ChecklistLabel>
                          Must contain an uppercase letter
                        </ChecklistLabel>
                      </div>
                    )}
                    {isValidNumberInPassword(newPassword) ? (
                      <div className="flex">
                        <BsCheck style={{ color: "#00cd4c" }} />
                        <ChecklistLabel style={{ color: "#000000" }}>
                          Must contain a number
                        </ChecklistLabel>
                      </div>
                    ) : (
                      <div className="flex">
                        <BsX />
                        <ChecklistLabel>Must contain a number</ChecklistLabel>
                      </div>
                    )}
                    {isValidSpecialCharacterInPassword(newPassword) ? (
                      <div className="flex">
                        <BsCheck style={{ color: "#00cd4c" }} />
                        <ChecklistLabel style={{ color: "#000000" }}>
                          Must contain a special character
                        </ChecklistLabel>
                      </div>
                    ) : (
                      <div className="flex">
                        <BsX />
                        <ChecklistLabel>
                          Must contain a special character
                        </ChecklistLabel>
                      </div>
                    )}
                  </ChecklistSection>
                </div>
              </FormField>
              <FormField>
                <FormLabel>Confirm new password</FormLabel>
                <div className="w-100">
                  <StyledPasswordInput
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    // placeholder={"Confirm Your Password"}
                    type="password"
                    width={512}
                    mBottom={4}
                    validation={passwordValidation}
                    showPassword={showConfirmPassword}
                    handleShowPassword={handleShowConfirmPassword}
                  />
                  {!passwordValidation && (
                    <ValidationText>Does not match. Try again</ValidationText>
                  )}
                </div>
              </FormField>
              <BtnSection>
                <Button
                  onClick={handleResetPassword}
                  disabled={isResetPasswordBtnDisabled}
                >
                  Update password
                  {resettingPassword && (
                    <Loader2 className="w-4 h-4 text-white animate-spin" />
                  )}
                </Button>
              </BtnSection>
            </ProfileSection>
            {/* <ProfileSection>
              <MFACheckboxSection>
                <Checkbox
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    padding: "9px 9px 9px 0px",
                  }}
                  checked={enableMFA}
                  onChange={(event) => setEnableMFA(event.target.checked)}
                />
                <CheckboxLabel>
                  Enable Multi-Factor Authentication (MFA)
                </CheckboxLabel>
              </MFACheckboxSection>
              <FormField>
                <FormLabel className={!enableMFA && "opacity-5"}>
                  2-Factor Authentication
                </FormLabel>
                <CustomSelector
                  value={authOption}
                  setValue={setAuthOption}
                  selectList={auth_options}
                  width={hide ? "100%" : "120px"}
                  menuWidth={200}
                  mBottom={20}
                  disabled={enableMFA ? false : true}
                />
              </FormField>
              <FormField>
                <FormLabel>Cookies</FormLabel>
                <CustomSelector
                  value={cookie}
                  setValue={setCookie}
                  selectList={cookie_options}
                  width={hide ? "100%" : "140px"}
                  menuWidth={200}
                />
              </FormField>
              <BtnSection>
                <Button onClick={handleSaveAuthSettings}>Save changes</Button>
              </BtnSection>
            </ProfileSection> */}
          </div>
        </MainWrapper>
      </>
      <StyledDrawer
        state={drawer}
        setState={setDrawer}
        text="Your changes has been saved."
      />
    </div>
  );
};

export default Settings;

const schema = yup
  .object({
    firstName: yup.string().required("First name is a required field"),
    lastName: yup.string().required("Last name is a required field"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  })
  .required();

function AddAdminFormSection() {
  const queryClient = useQueryClient();
  const { data: tenancy } = useTenancy();
  const { mutate: sendInvite, isLoading } = useAdminInvite();
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const onSubmit = ({ firstName, lastName, email }) => {
    const payload = {
      email,
      first_name: firstName,
      last_name: lastName,
      subdomain: tenancy?.subdomain,
    };

    sendInvite(payload, {
      onSuccess: () => {
        reset();
        queryClient.invalidateQueries(["tenancy"]);
        toast.success(
          `Invitation to ${firstName} ${lastName} sent successfully!`
        );
      },
      onError: (error) => {
        if (error.response.status === 409) {
          toast.error(
            error?.response?.data?.detail ||
              "The maximum number of admin users (10) has been reached.",
            {
              autoClose: 15000,
              hideProgressBar: false,
            }
          );
        } else {
          toast.error(
            "Something went wrong sending the invite. Please try again.",
            {
              autoClose: 10000,
              hideProgressBar: false,
            }
          );
        }
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProfileSection>
          <OptionLabel style={{ paddingBottom: "4px" }}>
            Add Account Administrator
          </OptionLabel>
          <div className="pb-8 font-inter font-normal text-base text-gray-500 max-w-[830px]">
            Admins have access to send invoices, create quotes, manage
            integrations and contacts but are not allowed to move money or pay
            bills. You can add up to 10 administrators to your platform.
          </div>

          <FormField>
            <FormLabel>First / Last Name</FormLabel>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <StyledInput
                  {...field}
                  setValue={(value) => {
                    setValue("firstName", value);
                    clearErrors("firstName");
                  }}
                  width={"243.5px"}
                  mBottom={20}
                  mRight={24}
                  error={errors.firstName?.message}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <StyledInput
                  {...field}
                  setValue={(value) => {
                    setValue("lastName", value);
                    clearErrors("lastName");
                  }}
                  width={"243.5px"}
                  mBottom={20}
                />
              )}
            />
          </FormField>

          <FormField>
            <FormLabel>Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <StyledInput
                  {...field}
                  setValue={(value) => {
                    setValue("email", value);
                    clearErrors("email");
                  }}
                  width={512}
                  mBottom={20}
                />
              )}
            />
          </FormField>
          <div className="text-sm font-medium text-red-600">
            {errors.firstName?.message}
          </div>
          <div className="text-sm font-medium text-red-600">
            {errors.lastName?.message}
          </div>
          <div className="text-sm font-medium text-red-600">
            {errors.email?.message}
          </div>
          <BtnSection>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                "Send invite"
              )}
            </Button>
          </BtnSection>
        </ProfileSection>
      </form>
    </>
  );
}

function AdminTable() {
  const queryClient = useQueryClient();
  const { data } = usePlatformUsers({ userType: "admin" });
  const { mutate: deleteAdmin, isLoading: deleting } = useDeleteAdmin();

  const handleDelete = ({ email_address, user_id }) => {
    deleteAdmin(
      { email: email_address, userId: user_id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["tenancy"]);
          toast.success("Admin user deleted successfully!");
        },
        onError: () => {
          toast.error(
            "Something went wrong while deleting admin user. Please try again."
          );
        },
      }
    );
  };

  return (
    <div className="max-w-[823px]">
      <div className="flow-root">
        <div className="-mx-8 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((user) => {
                    if (user.user_type === "owner") return undefined;

                    return (
                      <tr key={user.email_address}>
                        <td className="whitespace-nowrap py-4 pl-8 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {`${user.first_name} ${user.last_name}`}
                          <dl className="font-normal lg:hidden">
                            <dt className="sr-only lg:hidden">Email</dt>
                            <dd className="mt-1 truncate text-gray-500 lg:hidden">
                              {user.email_address}
                            </dd>
                            <dt className="sr-only sm:hidden">Status</dt>
                            <dd className="mt-1 truncate text-gray-500 sm:hidden">
                              {user.record_status}
                            </dd>
                          </dl>
                        </td>

                        <td className="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.email_address}
                        </td>
                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.record_status}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {user.user_type === "admin" && (
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Button
                                  type="button"
                                  variant="destructive"
                                  size="sm"
                                >
                                  Delete
                                  <span className="sr-only">, {user.name}</span>
                                </Button>
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Are you absolutely sure?
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    {`Are you sure you want to delete ${user.first_name} ${user.last_name}? This action cannot be undone. If you change your mind you will have to send an invite again.`}
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel disabled={deleting}>
                                    Cancel
                                  </AlertDialogCancel>
                                  <AlertDialogAction
                                    disabled={deleting}
                                    onClick={() => handleDelete(user)}
                                    className="bg-red-600 hover:bg-red-700 disabled:bg-red-100"
                                  >
                                    Continue
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
