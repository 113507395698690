import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import {
  LabelStyle,
  SpecialLabelStyle,
  StyledTextField,
  StyledSelect,
  StyledPhoneInput,
} from "../FormLabelStyles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form, Field } from "formik";
import { useStep } from "../hooks/useStep";
import { CircularProgress } from "@mui/material";
import { useFetchStates } from "hooks/useFetchStates";
import { BasicModal, Button, colors, textStyles } from "ui";
import { platformEndpoints } from "../utils/PlatformEndpoints";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import { removePlusOne } from "utils/string-manipulation";

export const BusinessContactForm = ({ handleNext }) => {
  const { isLoading, data, handlePost, isPostLoading, postError } = useStep(2);
  const { data: statesData, isLoading: statesLoading } = useFetchStates("US");
  const [naicsList, setNaicsList] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [phone, setPhone] = useState(null);
  const [naic, setNaic] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [websiteValue, setWebsiteValue] = useState(null);
  let [errors, setErrors] = useState({
    websiteError: false,
    phoneError: false,
    addressError: false,
    formationStateError: false,
    classificationError: false,
    salesError: false,
  });

  console.log(data);

  useEffect(() => {
    const getData = async () => {
      try {
        const monthlySales =
          await platformEndpoints.fetchEstimatedMonthlySales();
        const naicsList = await platformEndpoints.fetchNaicsList();
        setMonthlySales(monthlySales);
        setNaicsList(naicsList);
      } catch (error) {
        toast.error("Error fetching data. Contact support.");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (data?.business_phone) {
      setPhone(removePlusOne(data?.business_phone));
    }
    if (data?.business_naics_code) {
      setNaic({
        label: data?.business_naics_description,
        value: data?.business_naics_code,
      });
    }
  }, [data]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isValidWebsite = (value) => {
    // Regular expression for basic URL validation
    const urlRegex =
      /^(?:(https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
    return urlRegex.test(value);
  };

  const validation = (state, formationState, sales) => {
    let isValid = true;

    if (websiteValue) {
      if (!isValidWebsite(websiteValue)) {
        isValid = false;
        setErrors((prev) => ({
          ...prev,
          websiteError: true,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          websiteError: false,
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        websiteError: false,
      }));
    }

    if (!phone || phone.length !== 10) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        phoneError: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        phoneError: false,
      }));
    }

    if (!state) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        addressError: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        addressError: false,
      }));
    }

    if (!formationState) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        formationStateError: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        formationStateError: false,
      }));
    }

    if (!naic?.value) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        classificationError: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        classificationError: false,
      }));
    }

    if (!sales) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        salesError: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        salesError: false,
      }));
    }

    return isValid;
  };

  return (
    <Formik
      initialValues={{
        ...data,
        street_line_1: data?.business_physical_address?.street_line_1
          ? data?.business_physical_address?.street_line_1
          : "",
        city: data?.business_physical_address?.city
          ? data?.business_physical_address?.city
          : "",
        postal_code: data?.business_physical_address?.postal_code
          ? data?.business_physical_address?.postal_code
          : "",
        state: data?.business_physical_address?.state
          ? data?.business_physical_address?.state
          : "",
      }}
      enableReinitialize
      onSubmit={(values) => {
        const { street_line_1, city, postal_code, state, ...rest } = values;

        if (
          validation(
            values?.state,
            values?.business_formation_state,
            values?.business_estimated_monthly_sales
          )
        ) {
          handlePost(
            {
              ...rest,
              business_physical_address: {
                street_line_1: values?.street_line_1,
                city: values?.city,
                postal_code: values?.postal_code,
                state: values?.state,
              },
              business_phone: phone,
              business_naics_code: naic.value,
              business_naics_description: naic.label,
            },
            handleNext
          );
        } else {
          toast.error("Please fill in the missing information");
        }

        if (postError) {
          toast.error(postError);
        }
      }}
    >
      {({ handleSubmit, values }) =>
        isLoading ? (
          <LoaderContainer>
            <CircularProgress color="inherit" size={24} />
          </LoaderContainer>
        ) : (
          <Form onSubmit={handleSubmit}>
            <div className="mt-8 space-y-6  border-gray-900/10 pb-12 sm:space-y-0  sm:divide-gray-900/10 sm:pb-0 max-w-[872px]">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Business Phone
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledPhoneInput}
                    required
                    id="business_phone"
                    name="business_phone"
                    size="small"
                    autoComplete="off"
                    country={"us"}
                    onlyCountries={["us"]}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    disableCountryCode
                    placeholder={"(123) 456-7890"}
                    fullWidth
                  />
                  {errors.phoneError && (
                    <ErrorMessage>Please input a valid Phone.</ErrorMessage>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Business Email
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    required
                    id="business_email"
                    name="business_email"
                    size="small"
                    autoComplete="off"
                    type="email"
                    fullWidth
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Business Website
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    id="business_website"
                    name="business_website"
                    size="small"
                    placeholder="https://mydomain.com"
                    autoComplete="off"
                    fullWidth
                    value={websiteValue}
                    onChange={(e) => setWebsiteValue(e.target.value)}
                  />
                  {errors.websiteError && (
                    <ErrorMessage>Please input a valid website.</ErrorMessage>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Business Address*
                  </label>
                  <p className=" max-w-2xl text-sm leading-6 text-gray-500">
                    Must be a physical address. No P.O. Box.
                  </p>
                </div>

                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Field
                    as={StyledTextField}
                    required
                    id="street"
                    name="street_line_1"
                    fullWidth
                    placeholder="Street"
                    size="small"
                    autoComplete="off"
                  />
                  <BottomFieldWrapper>
                    <Field
                      as={StyledTextField}
                      required
                      id="city"
                      name="city"
                      placeholder="City"
                      fullWidth
                      size="small"
                      autoComplete="off"
                    />
                    <FormControl size="small" fullWidth>
                      <Field
                        as={StyledSelect}
                        labelId="state_label"
                        name="state"
                        displayEmpty={true}
                        renderValue={(selected) =>
                          selected ? selected : "State"
                        }
                      >
                        {!statesLoading &&
                          statesData.map((item) => (
                            <MenuItem value={item.value} key={item.label}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                    <Field
                      as={StyledTextField}
                      id="postal_code"
                      name="postal_code"
                      placeholder="Zip Code"
                      fullWidth
                      size="small"
                      required
                      autoComplete="off"
                      inputProps={{ maxLength: 5 }}
                    />
                  </BottomFieldWrapper>
                  {errors.addressError && (
                    <ErrorMessage>Please select a State.</ErrorMessage>
                  )}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Business Formation State
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormControl size="small" fullWidth style={{ minWidth: 250 }}>
                    <Field
                      as={StyledSelect}
                      labelId="business_formation_state_label"
                      name="business_formation_state"
                    >
                      {!statesLoading &&
                        statesData.map((item) => (
                          <MenuItem value={item.value} key={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                  {errors.formationStateError && (
                    <ErrorMessage>
                      Please select a Formation State.
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Industry Classification
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormControl size="small" fullWidth style={{ minWidth: 250 }}>
                    <Autocomplete
                      disablePortal
                      options={naicsList}
                      name="business_naics_description"
                      renderInput={(params) => <StyledTextField {...params} />}
                      value={naic || null}
                      onChange={(event, value) => setNaic(value)}
                    />
                  </FormControl>
                  {errors.classificationError && (
                    <ErrorMessage>
                      Please select a Industry Classification.
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  *Estimated Monthly Sales
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <FormControl size="small" style={{ minWidth: 250 }}>
                    <Field
                      as={StyledSelect}
                      labelId="business_estimated_monthly_saleslabel"
                      name="business_estimated_monthly_sales"
                    >
                      {monthlySales.map((item) => (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  {errors.formationStateError && (
                    <ErrorMessage>
                      Please select your Monthly Sales.
                    </ErrorMessage>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-x-4 lg:mt-4">
              <Button
                variant="white"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}
              >
                Cancel
              </Button>
              <Button variant="green" type="submit">
                {isPostLoading ? (
                  <CircularProgress style={{ color: "#FFFFFF" }} size={12} />
                ) : (
                  "Next"
                )}
              </Button>
            </div>
            <BasicModal
              title={"Are you sure you want to cancel setup?"}
              text={"If you cancel, you’ll need to start over."}
              open={openModal}
              buttonText={"Yes, cancel setup"}
              onButtonClick={() => history.push("/dashboard")}
              handleClose={handleCloseModal}
            />
          </Form>
        )
      }
    </Formik>
  );
};

const BottomFieldWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 15px;
`;

const LoaderContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  ${textStyles.body.b2}
  color: ${colors.error};
`;
