import { Button } from "components/ui/button-tw";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import useInvoiceSelection from "hooks/invoice/use-invoice-selection";
import { MoreHorizontal } from "lucide-react";
import moment from "moment";
import { downloadInvoice } from "pages/invoices/actions";
import { cn } from "utils/classnames";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import SortIcon from "@mui/icons-material/Sort";
import { invoiceStatus } from "constants/invoice";

export function apiToTable({ invoice_info, invoice_id }) {
  return {
    id: invoice_id,
    status: invoice_info.invoice_status.toLowerCase(),
    sent: invoice_info.invoice_send_date,
    amount: invoice_info.invoice_total,
  };
}

export function mapStatusColor(status) {
  switch (status) {
    case "paid":
      return {
        main: "bg-green-400",
        accent: "bg-green-100",
        text: "text-green-400",
      };
    case "unpaid":
      return {
        main: "bg-yellow-400",
        accent: "bg-yellow-100",
        text: "text-yellow-400",
      };
    case "overdue":
      return {
        main: "bg-red-400",
        accent: "bg-red-100",
        text: "text-red-400",
      };
    case "pending":
      return {
        main: "bg-blue-400",
        accent: "bg-blue-100",
        text: "text-blue-400",
      };
    case "rejected":
      return {
        main: "bg-red-400",
        accent: "bg-red-100",
        text: "text-red-400",
      };
    case "approved":
      return {
        main: "bg-green-400",
        accent: "bg-green-100",
        text: "text-green-400",
      };
    default:
      return {
        main: "bg-slate-400",
        accent: "bg-slate-100",
        text: "text-slate-400",
      };
  }
}
function dateSortFn(rowA, rowB, columnId) {
  const dateA = new Date(rowA.values[columnId]);
  const dateB = new Date(rowB.values[columnId]);

  return dateA - dateB;
}

export const columns = [
  {
    accessorKey: "sent",
    accessorFn: ({ invoice_info }) => moment(invoice_info.invoice_send_date),
    header: (
      <div className="flex items-center space-x-2">
        <span>Date Sent</span>
        <SortIcon />
      </div>
    ),
    cell: ({ row }) => {
      const formatted = row.getValue("sent").format("MMMM Do, YYYY");

      return <div className="text-left font-medium">{formatted}</div>;
    },
    sortFn: dateSortFn,
    enableSorting: true,
  },
  {
    accessorKey: "status",
    accessorFn: ({ invoice_info }) =>
      invoiceStatus[invoice_info.invoice_status]?.label,
    header: (
      <div className="flex items-center space-x-2">
        <span>Status</span>
        <SortIcon />
      </div>
    ),
    enableSorting: true,
    cell: ({ row }) => {
      const status = row.getValue("status");
      return (
        <span className="flex items-center space-x-1">
          <span
            className={cn(
              mapStatusColor(status).accent,
              "h-4 w-4 flex items-center justify-center rounded-full"
            )}
            aria-hidden="true"
          >
            <span
              className={cn(
                mapStatusColor(status).main,
                "h-2 w-2 rounded-full"
              )}
            />
          </span>
          <p className={cn("text-sm font-medium text-slate-900")}>
            {capitalizeFirstLetter(status)}
          </p>
        </span>
      );
    },
  },
  {
    accessorKey: "amount",
    accessorFn: ({ invoice_info }) => invoice_info.invoice_total,
    header: (
      <div className="flex items-center space-x-2">
        <span>Amount</span>
        <SortIcon />
      </div>
    ),
    enableSorting: true,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("amount"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);

      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return <ActionsCell row={row} />;
    },
  },
];

function ActionsCell({ row }) {
  const onSelect = useInvoiceSelection();
  const invoice = row.original;

  const unpaid = invoice.invoice_info.invoice_status.toLowerCase() === "unpaid";
  // &&
  // invoice.invoice_info.invoice_status.toLowerCase() !== "pending";

  const handleDownload = async (item) => {
    const payload = {
      file_location: item.invoice_info.invoice_full_location,
    };
    await downloadInvoice(payload).then((res) => {
      if (res && res.downloadURLData) {
        window.open(res.downloadURLData, "_blank");
      } else {
        console.log("error");
      }
    });
  };

  return (
    <div className="flex justify-end items-center space-x-4">
      <Button
        variant="outline"
        className="hidden lg:block w-20"
        onClick={() => onSelect(invoice)}
      >
        {unpaid ? "Pay" : "View"}
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => onSelect(invoice)}>
            View details
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleDownload(invoice)}>
            Download
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() =>
              navigator.clipboard.writeText(invoice.invoice_info.invoice_number)
            }
          >
            Copy invoice #
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
