import { useState } from "react";
import { Dialog, CircularProgress } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import { deleteContract } from "./actions";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogOrangeBtn,
} from "pages/styled";
import { toast } from "react-toastify";

const ContractDeleteDialog = ({
  dialog,
  setDialog,
  selectedContract,
  setSelectedContract,
  getContractList,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleDeleteContract = async () => {
    setLoading(true);
    await deleteContract(selectedContract.contract_id).then((res) => {
      if (res && res.status === "success") {
        setSelectedContract(null);
        setLoading(false);
        setDialog(false);
        toast.success("Contract deleted successfully.")
        getContractList();
      }
    });
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription className="text-start">
          Are you sure you'd like to delete this contract?
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            No! Keep it
          </DialogCancelBtn>
          <DialogOrangeBtn onClick={handleDeleteContract}>
            Yes, delete
            {loading && (
              <CircularProgress color="inherit" size={14} className="ml-08" />
            )}
          </DialogOrangeBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default ContractDeleteDialog;
