import { useAuth } from "providers/auth";
import { Redirect } from "react-router-dom";

export function NoMatch({ children, redirectTo, ...rest }) {
  let { user } = useAuth();

  return (
    <Redirect
      to={{
        pathname: redirectTo || "/",
      }}
    />
  );
}
