import { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import api from "api/v1-jwt/config/api";
import setAccessToken from "pages/plaid/actions/setAccesToken";
import { toast } from "react-toastify";
import { useAuth } from "providers/auth";
import { useGetPaymentMethods } from "hooks/payment/use-get-payment-methods";
import { useQueryClient } from "@tanstack/react-query";
const usePlaidLinkWithStatus = ({
  payment_method_status,
  method_type,
  access_token,
  defaultPaymentMethod,
  onSuccess,
  onSettled,
  name_on_account,
}) => {
  const [linkToken, setLinkToken] = useState(null);
  const [loadingToken, setLoadingToken] = useState(false);
  const [error, setError] = useState(null);
  const { cognitoId } = useAuth();
  const queryClient = useQueryClient();

  const { data: paymentMethods, isLoading: paymentMethodsLoading } =
    useGetPaymentMethods();

  useEffect(() => {
    const fetchLinkToken = async () => {
      if (method_type === "PLAID" && payment_method_status !== "active") {
        setLoadingToken(true);
        try {
          let route = `/platform/payment_methods/plaid/link-token`;
          let payload = {
            user_id: cognitoId,
          };
          if (access_token != "") {
            payload.access_token = access_token;
          }
          const { data } = await api.post(route, payload);
          const linkToken = data.link_token;
          setLinkToken(linkToken);
        } catch (err) {
          console.error(err);
          setError(err);
        } finally {
          setLoadingToken(false);
        }
      }
    };

    fetchLinkToken();
  }, [cognitoId, payment_method_status, method_type]);

  const { open: openPlaidLink, ready: readyPlaidLink } = usePlaidLink({
    token: linkToken,
    onSuccess: async (access_token, metadata) => {
      try {
        const institutionId = metadata.institution.institution_id;
        const accounts = metadata.accounts;

        const isDuplicate = paymentMethods.some((method) => {
          return (
            method.payment_method_data.institution.institution_id ===
              institutionId &&
            method.payment_method_data.accounts.some((account) =>
              accounts.some(
                (acc) => acc.name === account.name && acc.mask === account.mask
              )
            )
          );
        });

        if (isDuplicate) {
          toast.error("This account has already been linked.");
          return;
        }
        const newPaymentMethod = await setAccessToken(
          access_token,
          metadata,
          cognitoId,
          name_on_account,
          defaultPaymentMethod
        );

        onSuccess(newPaymentMethod, access_token, metadata);
        if (onSettled) {
          onSettled();
        }
        queryClient.invalidateQueries(["payment_methods"]);
      } catch (err) {
        console.error(err);
        toast.error(`Failed to connect your bank account.`);
      }
    },
    onExit: (error, metadata) => {
      if (error != null && error.error_code === "INVALID_LINK_TOKEN") {
        toast.error(
          "Something went wrong linking your account. Please try connecting again."
        );
      }

      if (error != null) {
        toast.error(error.display_message);

        console.log(
          `USER ${cognitoId} EXITED PLAID WITH ERROR TYPE ${error.error_type} :: CODE ${error.error_code}`
        );
        console.log(
          "================================================================="
        );
        console.log("ERROR MESSAGE::", error.error_message);
        console.log(
          "================================================================="
        );
        console.log("ERROR OBJECT::", error);
      }

      if (onSettled) {
        onSettled();
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
    },

    onEvent: async (eventName, metadata) => {
      if (eventName === "ERROR" && metadata.error_code != null) {
        console.log(
          `PLAID EVENT WITH ERROR TYPE ${metadata.error_type} :: CODE ${metadata.error_code}`
        );
        console.log(
          "================================================================="
        );
        console.log("ERROR MESSAGE::", metadata.error_message);
        console.log(
          "================================================================="
        );
        console.log("EVENT METADATA OBJECT::", metadata);

        if (onSettled) {
          onSettled();
        }
      }
    },
  });

  return { linkToken, loadingToken, error, openPlaidLink, readyPlaidLink };
};

export default usePlaidLinkWithStatus;
