export function addresseInfoExists(invoice) {
  return !!invoice?.invoice_info?.invoice_to;
}

export function canInvoiceBePaid(invoice) {
  const status = invoice?.invoice_info?.invoice_status;
  if (!status || !(typeof status === "string")) {
    return false;
  }

  return !["paid", "pending", "processing"].includes(status.toLowerCase());
}

export function addresseeEmail(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return invoice.invoice_info.invoice_to.email;
}

export function addresseePhone(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return invoice.invoice_info.invoice_to.phone;
}

export function addresseeFullName(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return `${invoice.invoice_info.invoice_to.first_name} ${invoice.invoice_info.invoice_to.last_name}`;
}

export function addresseeBusinessName(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return invoice.invoice_info.invoice_to.business_name;
}

export function addresseeDBA(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return invoice.invoice_info.invoice_to.business_dba;
}

export function addresseeNamePriority(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return (
    addresseeDBA(invoice) ||
    addresseeBusinessName(invoice) ||
    addresseeFullName(invoice)
  );
}

export function addresseeBusinessAddr(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return invoice.invoice_info.invoice_to.business_address;
}

export function addresseeArea(invoice) {
  if (!addresseInfoExists(invoice)) {
    return undefined;
  }

  return `${invoice?.invoice_info.invoice_to.business_city}, ${invoice?.invoice_info.invoice_to.business_state} ${invoice?.invoice_info.invoice_to.business_zip}`;
}
