import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import {
  LabelStyle,
  SpecialLabelStyle,
  StyledPhoneInput,
  StyledSelect,
  StyledTextField,
} from "../FormLabelStyles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BasicModal, Button, colors, textStyles } from "ui";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersLayout } from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useStep } from "../hooks/useStep";
import { useFetchStates } from "hooks/useFetchStates";
import { CircularProgress, InputAdornment } from "@mui/material";
import { platformEndpoints } from "../utils/PlatformEndpoints";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { SSNInput } from "./SSNInput";
import { removePlusOne } from "utils/string-manipulation";

const validationSchema = Yup.object().shape({
  control_person_ownership_pct: Yup.number()
    .integer("Please enter a whole number")
    .min(0, "Please enter a positive number")
    .max(100, "The percentage cannot be over 100%")
    .required("Please list the ownership %")
    .nullable(false),
});

export const ControlPersonInfo = ({ handleNext }) => {
  const [authority, setAuthority] = useState(false);
  const { isLoading, data, handlePost, postError, isPostLoading } = useStep(3);
  const [newDate, setNewDate] = useState(null);
  const { data: statesData, isLoading: statesLoading } = useFetchStates("US");
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [ssn, setSSN] = useState("");
  let [fieldErrors, setFieldErrors] = useState({
    SSNError: false,
    phoneError: false,
    dateOfBirthError: false,
    ageError: false,
    roleError: false,
    stateError: false,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const roles = await platformEndpoints.fetchRoleTitle();
        setRoles(roles);
      } catch (error) {
        toast.error("Error fetching data. Contact support.");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (data?.control_person_info?.control_person_phone) {
      setPhone(removePlusOne(data?.control_person_info?.control_person_phone));
    }
  }, [data]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validation = (role, state) => {
    const today = new Date();
    const dob = new Date(newDate);
    const age = today.getFullYear() - dob.getFullYear();
    let isValid = true;

    if (!phone || phone.length !== 10) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        phoneError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        phoneError: false,
      }));
    }

    if (!ssn) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        SSNError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        SSNError: false,
      }));
    }

    if (!newDate) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        dateOfBirthError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        dateOfBirthError: false,
      }));
    }

    if (age < 18) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        ageError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        ageError: false,
      }));
    }

    if (!role) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        roleError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        roleError: false,
      }));
    }

    if (!state) {
      isValid = false;
      setFieldErrors((prev) => ({
        ...prev,
        stateError: true,
      }));
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        stateError: false,
      }));
    }

    return isValid;
  };

  return (
    <>
      <Subtext>
        The control person is an individual who has significant responsibility
        in directing the business. This should be the person completing the
        application.
      </Subtext>
      <Formik
        initialValues={{
          ...data?.control_person_info,
          street_line_1: data?.control_person_info
            ?.control_person_physical_address?.street_line_1
            ? data?.control_person_info?.control_person_physical_address
                ?.street_line_1
            : "",
          city: data?.control_person_info?.control_person_physical_address?.city
            ? data?.control_person_info?.control_person_physical_address?.city
            : "",
          postal_code: data?.control_person_info
            ?.control_person_physical_address?.postal_code
            ? data?.control_person_info?.control_person_physical_address
                ?.postal_code
            : "",
          state: data?.control_person_info?.control_person_physical_address
            ?.state
            ? data?.control_person_info?.control_person_physical_address?.state
            : "",
          control_person_dob: null,
          control_person_ownership_pct:
            data?.control_person_info?.control_person_ownership_pct || 0,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          const { street_line_1, city, postal_code, state, ...rest } = values;

          if (authority) {
            if (validation(values?.control_person_title, values?.state)) {
              handlePost(
                {
                  control_person_info: {
                    ...rest,
                    control_person_physical_address: {
                      street_line_1: values?.street_line_1,
                      city: values?.city,
                      postal_code: values?.postal_code,
                      state: values?.state,
                      country: "US",
                    },
                    control_person_dob: newDate,
                    is_primary_control_person: authority,
                    control_person_phone: phone,
                    control_person_ssn: ssn.split("-").join(""),
                  },
                },
                handleNext
              );
            }
          } else {
            toast.error(
              "Please confirm you have the authority to open an account."
            );
          }

          if (postError) {
            toast.error(postError);
          }
        }}
      >
        {({ handleSubmit, values, errors }) =>
          isLoading ? (
            <LoaderContainer>
              <CircularProgress color="inherit" size={24} />
            </LoaderContainer>
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="mt-8 space-y-6  border-gray-900/10 pb-12 sm:space-y-0  sm:divide-gray-900/10 sm:pb-0 max-w-[872px]">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Full Name*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex flex-col gap-y-3 lg:flex-row lg:gap-x-3">
                      <Field
                        as={StyledTextField}
                        name={`control_person_first_name`}
                        placeholder="First"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        required
                      />
                      <Field
                        as={StyledTextField}
                        name={`control_person_middle_name`}
                        placeholder="Middle (If Applicable)"
                        fullWidth
                        size="small"
                        autoComplete="off"
                      />
                      <Field
                        as={StyledTextField}
                        name={`control_person_last_name`}
                        placeholder="Last"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Residential Address*
                    </label>
                    <p className=" max-w-2xl text-sm leading-6 text-gray-500">
                      Must be your personal residential address. No P.O. Box.
                    </p>
                  </div>

                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Field
                      as={StyledTextField}
                      name={`street_line_1`}
                      placeholder="Street"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      required
                    />
                    <BottomFieldWrapper>
                      <Field
                        as={StyledTextField}
                        placeholder="City"
                        name={`city`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        required
                      />
                      <FormControl size="small" fullWidth>
                        <Field
                          as={StyledSelect}
                          labelId="state_label"
                          name="state"
                          displayEmpty={true}
                          renderValue={(selected) =>
                            selected ? selected : "State"
                          }
                        >
                          {!statesLoading &&
                            statesData.map((item) => (
                              <MenuItem value={item.value} key={item.label}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                      <Field
                        as={StyledTextField}
                        name={`postal_code`}
                        placeholder="Zip Code"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        inputProps={{ maxLength: 5 }}
                        required
                      />
                    </BottomFieldWrapper>
                    {fieldErrors.stateError && (
                      <ErrorMessageContainer>
                        Please select a State.
                      </ErrorMessageContainer>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Email Address*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Field
                      as={StyledTextField}
                      name={`control_person_email`}
                      size="small"
                      autoComplete="off"
                      type="email"
                      required
                      fullWidth
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Personal Phone Number*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Field
                      as={StyledPhoneInput}
                      required
                      name="control_person_phone"
                      size="small"
                      autoComplete="off"
                      country={"us"}
                      onlyCountries={["us"]}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      disableCountryCode
                      placeholder={"(123) 456-7890"}
                      fullWidth
                    />
                    {fieldErrors.phoneError && (
                      <ErrorMessageContainer>
                        Please input a valid phone.
                      </ErrorMessageContainer>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    SSN*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <SSNInput ssn={ssn} setSSN={setSSN} />
                    {fieldErrors.SSNError && (
                      <ErrorMessageContainer>
                        Please provide a valid SSN entry. No spaces and 9 digits
                        are required, ex. 123-45-6789.
                      </ErrorMessageContainer>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Date of Birth*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Field
                      name="control_person_dob"
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            sx={{ padding: 0 }}
                            components={["DatePicker"]}
                          >
                            <DatePicker
                              {...field}
                              sx={{
                                backgroundColor: "#FFF",
                              }}
                              slots={{ layout: StyledPickersLayout }}
                              slotProps={{ textField: { size: "small" } }}
                              onChange={(date) => {
                                const dateObject = `${date.$M + 1}/${date.$D}/${
                                  date.$y
                                }`;
                                setNewDate(dateObject);
                              }}
                              required
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    {fieldErrors.dateOfBirthError && (
                      <ErrorMessageContainer>
                        Please provide a Date Of Birth.
                      </ErrorMessageContainer>
                    )}
                    {fieldErrors.ageError && (
                      <ErrorMessageContainer>
                        You must be 18+.
                      </ErrorMessageContainer>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Role or Title*
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <FormControl
                      size="small"
                      fullWidth
                      style={{ minWidth: 235 }}
                    >
                      <Field
                        as={StyledSelect}
                        name={`control_person_title`}
                        labelId={`role_label`}
                      >
                        {roles.map((item) => (
                          <MenuItem value={item.value} key={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                      {fieldErrors.roleError && (
                        <ErrorMessageContainer>
                          Please select a Role or Title.
                        </ErrorMessageContainer>
                      )}
                    </FormControl>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Ownership Percentage*
                    </label>
                    <p className=" max-w-2xl text-sm leading-6 text-gray-500">
                      Must be a number between 0-100. Decimals are not accepted.
                      Round up to the nearest whole number if needed.
                    </p>
                  </div>

                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Field
                      as={StyledTextField}
                      name={`control_person_ownership_pct`}
                      size="small"
                      type="number"
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      style={{ maxWidth: 190 }}
                      inputProps={{
                        maxLength: 3,
                      }}
                    />
                    <ErrorMessage
                      name={`control_person_ownership_pct`}
                      component="div"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "128.336%",
                        marginTop: "5px",
                        color: colors.error,
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:py-6">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_primary_control_person"
                        value={authority}
                        onChange={() => {
                          setAuthority(!authority);
                        }}
                      />
                    }
                    label="I am the Control Person and have the authority to open an account for this business."
                  />
                </div>
              </div>
              <div className="flex gap-x-4 lg:mt-4">
                <Button
                  variant="white"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="green" type="submit">
                  {isPostLoading ? (
                    <CircularProgress style={{ color: "#FFFFFF" }} size={12} />
                  ) : (
                    "Next"
                  )}
                </Button>
              </div>
              <BasicModal
                title={"Are you sure you want to cancel setup?"}
                text={"If you cancel, you’ll need to start over."}
                open={openModal}
                buttonText={"Yes, cancel setup"}
                onButtonClick={() => history.push("/dashboard")}
                handleClose={handleCloseModal}
              />
            </Form>
          )
        }
      </Formik>
    </>
  );
};

const FormContent = styled.div`
  margin-top: 34px;
`;

const LightText = styled.span`
  font-weight: 400;
  color: #939393;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
`;

const BottomFieldWrapper = styled.div`
  display: flex;
  margin-top: ${({ $noMargin }) => ($noMargin ? "0" : "20px")};
  gap: 15px;
`;

const Subtext = styled.div`
  ${textStyles.body.b2};
  color: ${colors.textGray};
  margin-top: 10px;
`;

const StyledPickersLayout = styled(PickersLayout)({
  ".MuiDateCalendar-root": {
    color: "#000",
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
});

const CheckboxContainer = styled.div`
  margin-top: 40px;
`;

const LoaderContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 5px;
  ${textStyles.body.b2}
  color: ${colors.error};
`;
