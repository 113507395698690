import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { BiLock } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import upload_icon from "assets/images/dashboard/upload.png";
import pocketbook_logo from "assets/images/pocketbook-logo.png";
import { CustomColorPicker, CustomSelector } from "pages/utils/styled-select";
import { StyledDrawer } from "pages/utils/styled-drawer";
import {
  StyledDomainInput,
  StyledInput,
  StyledPasswordInput,
} from "pages/utils/styled-input";
import {
  auth_options,
  color_options,
  cookie_options,
  country_options,
  shortcut_list,
  timezone_options,
} from "pages/demo";
import { MainWrapper, MainHeader, MainTitle, OptionLabel } from "pages/styled";
import {
  BtnSection,
  ChecklistLabel,
  ChecklistSection,
  FormBtn,
  FormField,
  FormInputDescription,
  FormLabel,
  PhoneInputForm,
  ProfileSection,
  SettingDescription,
  UploadSubTitle,
  UploadTitle,
  UploadeLogoSection,
  ValidationText,
} from "pages/settings/styled";
import { useProfile } from "hooks/profile/use-profile";
import { Button } from "components/ui/button-tw";
import { useResetPassword } from "hooks/auth/use-reset-password";
import { useAuth } from "providers/auth";
import { toast } from "react-toastify";
import { Check, Loader2, X } from "lucide-react";
import {
  isValidLowercaseInPassword,
  isValidNumberInPassword,
  isValidPasswordLength,
  isValidSpecialCharacterInPassword,
  isValidUppercaseInPassword,
} from "scripts/functions";
import { removePlusOne } from "utils/string-manipulation";

const CustomerSettings = () => {
  const { accessToken, logout } = useAuth();
  const { mutate: resetPassword, isLoading: resettingPassword } =
    useResetPassword();
  const { settingData } = useSelector((state) => state.account);

  const [shortCut, setShortCut] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [mailingAddr, setMailingAddr] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [timezone, setTimezone] = useState("PST");
  const [country, setCountry] = useState("USA");
  const [domain, setDomain] = useState("");
  const [selectedColor, setSelectedColor] = useState({
    label: "Default (Black)",
    value: "#000000",
  });

  useProfile({
    id: JSON.parse(localStorage.getItem("CognitoId")),
    onSuccess: (data) => {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.business_email);
      setPhone(removePlusOne(data.business_phone));
      setBusinessName(data.business_name);
      setDbaName(data.business_dba);
      setMailingAddr(data.business_address);
      setCity(data.city);
      setState(data.state);
      setCountry(data.country);
      setZipCode(data.zip_code);
      setTimezone(data.time_zone);
    },
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authOption, setAuthOption] = useState({
    label: "Email",
    value: "email",
  });
  const [cookie, setCookie] = useState({ label: "Allowed", value: "allowed" });
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [drawer, setDrawer] = useState({
    top: false,
  });
  const [hide, setHide] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    // Remove listener in clean up function to avoid memory leak
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    const width = window.innerWidth;
    if (width < 700) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  const handleChangeLogo = (file) => {};

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  }, [newPassword, confirmPassword]);

  const handleShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);

  const handleShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const isPaswordResetDisabled = useMemo(() => {
    return (
      !currentPassword ||
      !newPassword ||
      !isValidLowercaseInPassword(newPassword) ||
      !isValidNumberInPassword(newPassword) ||
      !isValidPasswordLength(newPassword) ||
      !isValidSpecialCharacterInPassword(newPassword) ||
      !isValidUppercaseInPassword(newPassword) ||
      newPassword !== confirmPassword ||
      resettingPassword
    );
  }, [resettingPassword, newPassword, confirmPassword, currentPassword]);
  const handleResetPassword = () => {
    if (isPaswordResetDisabled) return;

    resetPassword(
      {
        previous_password: currentPassword,
        new_password: newPassword,
        access_token: accessToken,
      },
      {
        onSuccess: (data) => {
          toast.success(data.message);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        },
        onError: (error) => {
          console.log("[SETTINGS CUSTOMER] Reset password:: ", error);
          toast.error("Was not possible to reset password. Please try again.");
        },
      }
    );
  };

  const handleSaveAuthSettings = () => {
    setDrawer({ ...drawer, top: true });
  };

  return (
    <div className="h-100">
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        <MainHeader>
          <div>
            <MainTitle>Settings</MainTitle>
          </div>
          {hide ? (
            <></>
          ) : (
            <div className="text-end">
              <img
                src={pocketbook_logo}
                alt="logo"
                width="156px"
                height="26px"
              />
              <CustomSelector
                value={shortCut}
                setValue={setShortCut}
                selectList={shortcut_list}
                width={188}
                menuWidth={320}
                mTop={17}
                // linkUrl
                customer
                placeholder={"Quick links"}
              />
            </div>
          )}
        </MainHeader>
        <div>
          <ProfileSection>
            <OptionLabel>Personal Information</OptionLabel>
            <FormField>
              <FormLabel>Name</FormLabel>
              <StyledInput
                value={firstName}
                setValue={setFirstName}
                placeholder={"First Name"}
                width={hide ? "512px" : "244px"}
                mBottom={20}
                mRight={24}
                readOnly
              />
              <StyledInput
                value={lastName}
                setValue={setLastName}
                placeholder={"Last Name"}
                width={hide ? "512px" : "244px"}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>Email address</FormLabel>
              <StyledInput
                value={email}
                setValue={setEmail}
                placeholder={"Email"}
                width={512}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>Phone number</FormLabel>
              <PhoneInputForm>
                <PhoneInput
                  placeholder={"Phone number"}
                  country={"us"}
                  onlyCountries={["us"]}
                  disableCountryCode={true}
                  value={phone}
                  disabled
                  autoComplete="off"
                  onChange={(phone) => {
                    setPhone(phone);
                  }}
                />
              </PhoneInputForm>
            </FormField>
          </ProfileSection>
          <ProfileSection>
            <OptionLabel>Business Information</OptionLabel>
            <FormField>
              <FormLabel>Business name</FormLabel>
              <StyledInput
                value={businessName}
                setValue={setBusinessName}
                placeholder={"Business name"}
                width={512}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>DBA</FormLabel>
              <StyledInput
                value={dbaName}
                setValue={setDbaName}
                placeholder={"DBA"}
                width={512}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>Mailing Address</FormLabel>
              <StyledInput
                value={mailingAddr}
                setValue={setMailingAddr}
                placeholder={"Mailing Address"}
                width={512}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>City</FormLabel>
              <StyledInput
                value={city}
                setValue={setCity}
                placeholder={"City"}
                width={512}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>State / Province</FormLabel>
              <StyledInput
                value={state}
                setValue={setState}
                placeholder={"State"}
                width={hide ? "512px" : "148px"}
                mBottom={20}
                mRight={24}
                readOnly
              />
              <StyledInput
                value={zipCode}
                setValue={setZipCode}
                placeholder={"Zip Code"}
                width={hide ? "512px" : "148px"}
                mBottom={20}
                readOnly
              />
            </FormField>
            <FormField>
              <FormLabel>Timezone</FormLabel>
              <CustomSelector
                value={timezone}
                setValue={setTimezone}
                selectList={timezone_options}
                width={hide ? "100%" : "148px"}
                menuWidth={200}
                mBottom={20}
                menuLeft
                disabled
                // placeholder={"PST"}
              />
            </FormField>
            <FormField>
              <FormLabel>Country</FormLabel>
              <CustomSelector
                value={country}
                setValue={setCountry}
                selectList={country_options}
                width={hide ? "100%" : "148px"}
                menuWidth={200}
                mBottom={20}
                menuLeft
                disabled
                placeholder={"USA"}
              />
            </FormField>
            <SettingDescription>
              To update your personal or business information, please contact
              us:{" "}
              <span>
                <a href="mailto:support@pocketbook.tech">
                  support@pocketbook.tech
                </a>
              </span>
            </SettingDescription>
          </ProfileSection>
          <ProfileSection>
            <OptionLabel>
              Custom branding <BiLock style={{ marginLeft: 14 }} />
            </OptionLabel>
            <FormField>
              <FormLabel>Pocketbook domain</FormLabel>
              <StyledDomainInput
                value={domain}
                setValue={setDomain}
                placeholder={"yourdomain"}
                width={512}
                mBottom={20}
                readOnly={true}
              />
            </FormField>
            <FormField>
              <FormLabel>App color</FormLabel>
              <CustomColorPicker
                value={selectedColor}
                setValue={setSelectedColor}
                selectList={color_options}
                width={hide ? "100%" : "148px"}
                menuWidth={"320px"}
                mBottom={36}
                disabled
                menuLeft
              />
            </FormField>
            <FormField>
              <FormLabel>Logo</FormLabel>
              <FileUploader
                multiple={true}
                handleChange={handleChangeLogo}
                disabled
                name="file"
                children={
                  <UploadeLogoSection>
                    <img
                      src={upload_icon}
                      className="opacity-3"
                      width="40px"
                      height="40px"
                      alt="upload"
                    />
                    <UploadTitle className="opacity-3">
                      <span className="font-medium">Click to upload</span> or
                      drag and drop
                    </UploadTitle>
                    <UploadSubTitle className="opacity-3">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </UploadSubTitle>
                  </UploadeLogoSection>
                }
              />
            </FormField>
            <BtnSection>
              <Button
                className="bg-skin-primary text-white hover:bg-skin-primary/80"
                disabled
              >
                Save changes
              </Button>
            </BtnSection>
          </ProfileSection>
          <ProfileSection>
            <OptionLabel>
              Account statements <BiLock style={{ marginLeft: 14 }} />
            </OptionLabel>
            <FormField>
              <FormLabel>Statements</FormLabel>
              <FormBtn style={{ maxWidth: 194 }} className="opacity-3">
                View all past statements
              </FormBtn>
            </FormField>
          </ProfileSection>
          <ProfileSection>
            <OptionLabel>
              Account details <BiLock style={{ marginLeft: 14 }} />
            </OptionLabel>
            <FormField className="pb-25">
              <FormLabel>Subscription</FormLabel>
              <FormBtn
                style={{ maxWidth: 512, height: 40 }}
                className="opacity-3"
              ></FormBtn>
            </FormField>
            <FormField className="pb-25">
              <FormLabel>Account / Routing #</FormLabel>
              <FormBtn className="mr-32 opacity-3 h-10"></FormBtn>
              <FormBtn className="opacity-3 h-10"></FormBtn>
            </FormField>
            <SettingDescription>
              To update or close your Pocketbook account, please contact us at{" "}
              <span>
                <a href="mailto:support@pocketbook.tech">
                  support@pocketbook.tech
                </a>
              </span>
            </SettingDescription>
            <BtnSection>
              <Button
                className="bg-skin-primary text-white hover:bg-skin-primary/80"
                disabled
              >
                Contact us
              </Button>
            </BtnSection>
          </ProfileSection>
          <ProfileSection>
            <OptionLabel>Privacy & security</OptionLabel>
            <FormField>
              <FormLabel>Current password</FormLabel>
              <StyledPasswordInput
                value={currentPassword}
                setValue={setCurrentPassword}
                // placeholder={"Current Password"}
                type="password"
                width={512}
                mBottom={20}
                showPassword={showCurrentPassword}
                handleShowPassword={handleShowCurrentPassword}
              />
            </FormField>
            <FormField>
              <FormLabel>New password</FormLabel>
              <div className="w-100">
                <StyledPasswordInput
                  value={newPassword}
                  setValue={setNewPassword}
                  type="password"
                  width={512}
                  showPassword={showNewPassword}
                  handleShowPassword={handleShowNewPassword}
                  mBottom={20}
                />
                <ChecklistSection>
                  {isValidPasswordLength(newPassword) ? (
                    <div className="flex">
                      <Check style={{ color: "#00cd4c" }} />
                      <ChecklistLabel style={{ color: "#000000" }}>
                        Minimum length of 12 characters
                      </ChecklistLabel>
                    </div>
                  ) : (
                    <div className="flex">
                      <X />
                      <ChecklistLabel>
                        Minimum length of 12 characters
                      </ChecklistLabel>
                    </div>
                  )}
                  {isValidLowercaseInPassword(newPassword) ? (
                    <div className="flex">
                      <Check style={{ color: "#00cd4c" }} />
                      <ChecklistLabel style={{ color: "#000000" }}>
                        Must contain a lowercase letter
                      </ChecklistLabel>
                    </div>
                  ) : (
                    <div className="flex">
                      <X />
                      <ChecklistLabel>
                        Must contain a lowercase letter
                      </ChecklistLabel>
                    </div>
                  )}
                  {isValidUppercaseInPassword(newPassword) ? (
                    <div className="flex">
                      <Check style={{ color: "#00cd4c" }} />
                      <ChecklistLabel style={{ color: "#000000" }}>
                        Must contain an uppercase letter
                      </ChecklistLabel>
                    </div>
                  ) : (
                    <div className="flex">
                      <X />
                      <ChecklistLabel>
                        Must contain an uppercase letter
                      </ChecklistLabel>
                    </div>
                  )}
                  {isValidNumberInPassword(newPassword) ? (
                    <div className="flex">
                      <Check style={{ color: "#00cd4c" }} />
                      <ChecklistLabel style={{ color: "#000000" }}>
                        Must contain a number
                      </ChecklistLabel>
                    </div>
                  ) : (
                    <div className="flex">
                      <X />
                      <ChecklistLabel>Must contain a number</ChecklistLabel>
                    </div>
                  )}
                  {isValidSpecialCharacterInPassword(newPassword) ? (
                    <div className="flex">
                      <Check style={{ color: "#00cd4c" }} />
                      <ChecklistLabel style={{ color: "#000000" }}>
                        Must contain a special character
                      </ChecklistLabel>
                    </div>
                  ) : (
                    <div className="flex">
                      <X />
                      <ChecklistLabel>
                        Must contain a special character
                      </ChecklistLabel>
                    </div>
                  )}
                </ChecklistSection>
              </div>
            </FormField>
            <FormField>
              <FormLabel>Confirm new password</FormLabel>
              <div className="w-100">
                <StyledPasswordInput
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  // placeholder={"Confirm Your Password"}
                  type="password"
                  width={512}
                  mBottom={4}
                  validation={passwordValidation}
                  showPassword={showConfirmPassword}
                  handleShowPassword={handleShowConfirmPassword}
                />
                {!passwordValidation && (
                  <ValidationText>Does not match. Try again</ValidationText>
                )}
              </div>
            </FormField>
            <BtnSection>
              <Button
                className=" bg-skin-primary text-white hover:bg-skin-primary/80"
                onClick={handleResetPassword}
                disabled={isPaswordResetDisabled}
              >
                Update password{" "}
                {resettingPassword && (
                  <Loader2 className="w-4 h-4 animate-spin ml-2" />
                )}
              </Button>
            </BtnSection>
          </ProfileSection>
          {/* <ProfileSection>
            <FormField>
              <FormLabel>2-Factor Authentication</FormLabel>
              <CustomSelector
                value={authOption}
                setValue={setAuthOption}
                selectList={auth_options}
                width={hide ? "100%" : "120px"}
                menuWidth={200}
                mBottom={20}
              />
            </FormField>
            <FormField>
              <FormLabel>Cookies</FormLabel>
              <CustomSelector
                value={cookie}
                setValue={setCookie}
                selectList={cookie_options}
                width={hide ? "100%" : "140px"}
                menuWidth={200}
              />
            </FormField>
            <BtnSection>
              <Button
                className="bg-skin-primary text-white hover:bg-skin-primary/80"
                onClick={handleSaveAuthSettings}
                disabled
              >
                Save changes
              </Button>
            </BtnSection>
          </ProfileSection> */}
        </div>
      </MainWrapper>
      <StyledDrawer
        state={drawer}
        setState={setDrawer}
        text="Your changes has been saved."
      />
    </div>
  );
};

export default CustomerSettings;
