import axios from "axios";
import { validStatus } from "utils/helpers";
import api from "../config/api";

const baseUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/${process.env.REACT_APP_PBTECH_API_VERSION}/platform/accounts`;

export const getAllAccounts = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/accounts/${clientCognitoId}`);

  return data;
};

export const getAccountBalance = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/accounts/${clientCognitoId}/balance`
  );

  return data;
};

export const getAccountStatement = async (statementData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${statementData.accessToken}`,
      },
      validateStatus: validStatus,
    };

    const response = await api.get(
      `/platform/accounts/statement?month=${statementData.statementMonth}&year=${statementData.statementYear}`,
      config
    );
    const byteCharacters = atob(response.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);

    const downloadFile = () => {
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `AccountStatement_${statementData.statementYear}-${statementData.statementMonth}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    downloadFile();

    return { success: true };
  } catch (error) {
    console.log("Error fetching account statement: ", error);

    let error_message =
      error.response && error.response.status === 401
        ? "Your session has expired. Please log out and log in again."
        : `Error fetching account statement: ${error.message}`;

    throw new Error(error_message);
  }
};
