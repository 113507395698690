import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionTypes from "pages/invoices/actionsTypes";

export const useInvoiceSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSelect = (invoice) => {
    dispatch({
      type: actionTypes.SELECTED_INVOICE,
      payload: invoice,
    });
    console.log("invoice", invoice);
    history.push(`/invoices/detail/${invoice.invoice_id}`);
  };

  return onSelect;
};

export default useInvoiceSelection;
