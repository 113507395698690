import { useForm, Controller } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import { StyledAuthInput } from "pages/utils/styled-auth-input";
import { LoadingWrapper } from "pages/styled";
import {
  FormGroupSection,
  RightSection,
  PocketbookLogo,
  RightImage,
  SignupFormTitle,
  SignupFormDescription,
} from "./styled";
import { Button } from "components/ui/button-tw";
import { ArrowLeft, Loader2 } from "lucide-react";
import { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateEmail } from "api/v1-jwt/auth/auth";
import useToast from "hooks/notifications/use-toast";
import { getSettings } from "../../settings/actions";
import * as actionTypes from "./actionTypes";

const emailRules = {
  required: {
    message: "Email is required",
    value: true,
  },
  pattern: {
    message: "Email format is invalid",
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { settingData } = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(false);
  const { toastNotify2 } = useToast();
  const { handleSubmit, control, formState, setError, reset } = useForm({
    mode: "onChange",
    defaultValues: { email: "" },
  });

  useLayoutEffect(() => {
    if (!settingData) {
      setLoading(true);
      getSettings().then((res) => {
        if (res)
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: res,
          });
        console.log("Settings res: ", res);
        setLoading(false);
      });
    }
  }, [dispatch]);

  const onSubmit = async ({ email }) => {
    try {
      const data = await validateEmail(email);
      if (data?.is_valid) {
        history.replace("/check-email");
      } else {
        await toastNotify2("Email not found", "error", "Email not found");
      }

      reset();
    } catch (error) {
      console.log("Error validating email: ", error);
      setError("email", {
        type: "manual",
        message: error.message,
      });
    }
  };

  const backToLogin = () => {
    history.push("/login");
  };

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="bg-white w-full sm:w-1/2 relative overflow-auto h-full p-6 sm:p-8 lg:p-12 flex flex-col justify-between">
        <div className="lg:mt-2">
          <PocketbookLogo src={logo} alt="logo" />
        </div>

        <div className="max-w-[600px] lg:pr-20 lg:pl-12 pb-24">
          <div>
            <SignupFormTitle>Forgot your password?</SignupFormTitle>
            <SignupFormDescription>
              Enter your email and we’ll send you a link to reset your password.
            </SignupFormDescription>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroupSection>
                <Controller
                  name="email"
                  control={control}
                  rules={emailRules}
                  render={({ field, fieldState, formState }) => {
                    const { onChange, ref, ...rest } = field;
                    return (
                      <StyledAuthInput
                        {...rest}
                        placeholder="Email"
                        validation={
                          fieldState.isDirty //&& formState.isSubmitted
                            ? !fieldState.invalid
                            : undefined
                        }
                        errMsg={fieldState.error?.message}
                        isSubmitted={formState.isSubmitted}
                        handleChange={onChange}
                      />
                    );
                  }}
                />
                <Button
                  disabled={!formState.isValid || formState.isSubmitting}
                  className="w-24"
                >
                  {formState.isSubmitting ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </FormGroupSection>
            </form>
          </div>
        </div>
        <div className="flex justify-start ">
          <Button
            variant="link"
            className="text-slate-600 pl-0"
            onClick={backToLogin}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Button>
        </div>
      </div>
      <RightSection className="">
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
    </div>
  );
};

export default ForgotPassword;
