import styled from "styled-components";

export const StyledCircle = styled.div`
  width: 32px;
  height: 32px;
  border: 2px solid #000000;
  border-radius: 50%;
`;

export const CardTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 18px;
`;

export const CardDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 28px;
`;

export const ConnectBtn = styled.div`
  width: 142px;
  background: #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
`;

export const DrawerText = styled.div`
  position: relative;
  padding: 10px 20px;
  text-align: center;
  background: #1db45a;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;
