import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import InvoiceConfirmDialog from "./components/invoice-confirm-dialog";
import { createInvoice, getInvoiceById, uploadInvoicePDF } from "./actions";
import { InvoicePDFDocument } from "./components/invoice-pdf";
import { MainWrapper } from "pages/styled";
import {
  LineItemsList,
  ToType,
  DatesSection,
  SubtotalAndOptions,
  InvoiceTotal,
  InvoiceSubmit,
} from "./components";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Form, Formik } from "formik";
import initialValues from "./initialValues";
import { getInvoicePayload } from "./utils";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useScrollToError } from "./hooks/useScrollToError";
import useUserProfile from "hooks/useProfile";
import { customTimeout } from "utils/functions";
import { toast } from "react-toastify";
import useToast from "hooks/notifications/use-toast";
import { useAuth } from "providers/auth";
import { InvoicePreview } from "./components/invoice-preview";
import { breakpoints } from "ui/breakpoints";
import { useMediaQuery } from "hooks/useMediaQuery";
import { LimitedAccountWarning } from "components/limited-account-warning";
import { useTenancy } from "hooks/tenancy/use-tenancy";
import { sendInvoiceToCustomer } from "api/v1-jwt/invoice/invoice";
import { useQueryClient } from "@tanstack/react-query";
import { useLocalStorage } from "hooks/use-local-storage";
import dayjs from "dayjs";
import { useEffect } from "react";

function FormComponent({
  confirmDialog,
  confirmData,
  setConfirmDialog,
  createNewInvoice,
  submitLoading,
}) {
  const toTypeRef = useRef();
  const datesSectionRef = useRef();
  const primaryContactRef = useRef();
  const businessInfoRef = useRef();
  const lineItemsRef = useRef();
  const SubTotalRef = useRef();
  const invoiceTotalRef = useRef();

  const [isMobile] = useMediaQuery(breakpoints.smallerThan768);

  const location = useLocation();

  // Parse the search string to get the query parameters
  const queryParams = new URLSearchParams(location.search);
  const contactId = queryParams.get("contact_id");

  useScrollToError({
    toType: toTypeRef,
    datesSection: datesSectionRef,
    primaryContact: primaryContactRef,
    businessInfo: businessInfoRef,
    lineItems: lineItemsRef,
    subTotal: SubTotalRef,
    invoiceTotal: invoiceTotalRef,
  });

  return (
    <div className="flex flex-1 items-stretch overflow-hidden">
      <div className=" md:w-[500px] lg:w-[650px] xl:w-[830px] border-gray-200 bg-white p-4 lg:block  rounded-md">
        {isMobile ? (
          <Form>
            <ToType contact_id={contactId} ref={toTypeRef} />
            <DatesSection ref={datesSectionRef} />
            <LineItemsList ref={lineItemsRef} />
            <SubtotalAndOptions ref={SubTotalRef} />
            <InvoiceTotal ref={invoiceTotalRef} />
            <InvoiceSubmit submitLoading={submitLoading} />
            <InvoiceConfirmDialog
              dialog={confirmDialog}
              values={confirmData}
              setDialog={setConfirmDialog}
              createNewInvoice={createNewInvoice}
            />
          </Form>
        ) : (
          <Tabs defaultValue="form" className="space-y-8">
            <TabsList className="w-full">
              <TabsTrigger value="form" className="grow">
                Form
              </TabsTrigger>
              <TabsTrigger value="preview" className="grow">
                Preview
              </TabsTrigger>
            </TabsList>
            <TabsContent value="form">
              <Form>
                <ToType contact_id={contactId} ref={toTypeRef} />
                <DatesSection ref={datesSectionRef} />
                <LineItemsList ref={lineItemsRef} />
                <SubtotalAndOptions ref={SubTotalRef} />
                <InvoiceTotal ref={invoiceTotalRef} />
                <InvoiceSubmit submitLoading={submitLoading} />
                <InvoiceConfirmDialog
                  dialog={confirmDialog}
                  values={confirmData}
                  setDialog={setConfirmDialog}
                  createNewInvoice={createNewInvoice}
                />
              </Form>
            </TabsContent>
            <TabsContent
              value="preview"
              className="w-full h-[1250px] overflow-auto"
            >
              <InvoicePreview />
              {/* 
                  THIS SHOULD NOT BE ERASED
                  THIS IS THE CODE THAT WILL HELP WITH RENDERING PREVIEW IN MOBILE
  
                  <BlobProvider
                  document={
                    <InvoicePreview
                      settingData={settingData}
                      profile={profile}
                      invoice={invoice}
                      tenancy={tenancy}
                    />
                  }
                >
                  {({ blob, url, loading }) => {
                    return loading ? (
                      "Loading..."
                    ) : (
                      <Document
                        file={url}
                        onLoadSuccess={(pdf) => console.log("LOADED SUCCESS::")}
                        renderMode="canvas"
                      >
                        <Page pageNumber={1} width={500} />
                      </Document>
                    );
                  }}
                </BlobProvider> */}
            </TabsContent>
          </Tabs>
        )}
      </div>
    </div>
  );
}

const NewInvoice = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [processedInvoice] = useLocalStorage("PROCESSED_INVOICE", null);
  const { settingData } = useSelector((state) => state.account);
  const profile = useUserProfile();

  const [shortCut, setShortCut] = useState({
    label: "Create an invoice",
    value: "new-invoice",
  });
  const [currentWidth, setCurrentWidth] = useState(0);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formResetCallback, setFormResetCallback] = useState(null);
  const { toastNotify2 } = useToast();
  const { accessToken, cognitoId } = useAuth();
  const { data: tenancy } = useTenancy();

  useEffect(() => {
    return () => {
      localStorage.setItem("PROCESSED_INVOICE", null);
    };
  }, []);

  const getInitialValues = () => {
    const values = {
      ...initialValues,
      lineItems: [
        {
          description: "",
          quantity: 0,
          unit_price: 0,
          item_total: 0,
        },
      ],
    };

    if (processedInvoice) {
      values["dueDate"] = dayjs(processedInvoice?.invoice_due_date);
      // values["sendDate"] = dayjs(
      //   processedInvoice?.invoice_send_date || undefined
      // );
      values["invoiceTax"] = processedInvoice?.invoice_tax_percentage || 0;
      values["transactionFee"] = processedInvoice?.invoice_pass_processing_fee;

      if (processedInvoice?.invoice_line_items?.length > 0) {
        const lineItems = processedInvoice?.invoice_line_items?.map((item) => ({
          description: item.description,
          quantity: item.quantity,
          unit_price: item.unit_price || item.price,
          item_total: item.amount || item.line_total,
        }));

        values["lineItems"] = lineItems;
      }
    }

    return values;
  };
  const getInvoiceinfoById = async (id, invoice_number) => {
    const res = await getInvoiceById(id);
    const pdfFile = await pdf(
      <InvoicePDFDocument
        settingData={settingData}
        profile={profile}
        invoice={res}
        tenancy={tenancy}
      />
    ).toBlob();
    if (pdfFile) {
      const folderName = `invoices/${id}`;
      const params = `folder_name=${folderName}&file_name=invoice_${invoice_number}.pdf`;
      await uploadInvoicePDF(params, pdfFile);
    }
  };

  // const createNewInvoice = async (data, status, delivery) => {
  //   let payload = getInvoicePayload(data);
  //   payload.accessToken = accessToken;
  //   payload.userId = cognitoId;
  //   if (status === "send") {
  //     payload.invoice_status = "unpaid";
  //   }
  //   setSubmitLoading(true);
  //   try {
  //
  //     const invoice_success_message = payload.invoice_status === "draft" ? "Your draft has been saved." : "Your invoice has been sent.";
  //
  //     const res = await toastNotify2(createInvoice2(payload), 'success', invoice_success_message);
  //     if (res && res.status === "success") {
  //       setSubmitLoading(false);
  //       customTimeout(() => {
  //         history.push("/invoices");
  //       });
  //     } else {
  //       console.log("detail", res.detail);
  //       setSubmitLoading(false);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     setSubmitLoading(false);
  //   }
  // };
  const createNewInvoice = async (data, status, delivery) => {
    const payload = getInvoicePayload(data);
    if (status === "send") {
      payload.invoice_status = "unpaid";
    }
    setSubmitLoading(true);
    try {
      const res = await createInvoice(payload);
      if (res && res.status === "success") {
        if (payload.invoice_status !== "draft") {
          await getInvoiceinfoById(res.invoice_id, res.invoice_number);

          if (payload.invoice_frequency !== "scheduled") {
            await sendInvoiceToCustomer(res.invoice_id);
            toast.success("Your invoice has been sent.");
          } else {
            toast.success("Your invoice has been created.");
          }
        } else {
          toast.success("Your draft has been saved.");
        }
        setSubmitLoading(false);

        queryClient.invalidateQueries({ queryKey: ["invoices"] });
        customTimeout(() => {
          history.push("/invoices");
        });
      } else {
        console.log("detail", res.detail);
        setSubmitLoading(false);
      }
    } catch (err) {
      console.error(err);
      setSubmitLoading(false);
    }
  };
  const handleFormSubmit = async (values) => {
    if (values.status === "draft") {
      await createNewInvoice(values);
    } else {
      setConfirmData(values);
      setConfirmDialog(true);
    }
  };

  const validationSchema = Yup.object().shape({
    dueDate: Yup.date().required("Due date is required"),
    // sendDate: Yup.date().required("Send date is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contact_id: Yup.string().required("Select a contact."),
    // phone: Yup.string().required("Phone number is required"),
    // businessName: Yup.string().required("Business name is required"),
    // dbaName: Yup.string().required("DBA is required"),
    // mailingAddr: Yup.string().required("Mailing address is required"),
    // city: Yup.string().required("City is required"),
    // state: Yup.string().required("State is required"),
    // zipCode: Yup.string().required("Zip code is required"),
    lineItems: Yup.array()
      // .of(lineItemSchema)
      .required("At least one line item is required")
      .test(
        "is-non-empty",
        "At least one non-empty line item is required",
        (lineItems) =>
          lineItems &&
          lineItems.some(
            (lineItem) =>
              lineItem.description &&
              lineItem.quantity > 0 &&
              lineItem.unit_price > 0
          )
      ),
  });

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />

      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Invoices"
            subTitle="Create an invoice"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values, setSubmitting);
            }}
          >
            <FormComponent
              confirmDialog={confirmDialog}
              confirmData={confirmData}
              setConfirmDialog={setConfirmDialog}
              createNewInvoice={createNewInvoice}
              formResetCallback={formResetCallback}
              submitLoading={submitLoading}
            />
          </Formik>
        </MainWrapper>
      </>
    </div>
  );
};

export default NewInvoice;
