import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadLogo, uploadMarketingLogo } from "api/setting/setting";

export const useUploadLogo = ({ onSuccess, onError, ...rest } = {}) => {
  const client = useQueryClient();

  return useMutation(uploadLogo, {
    onSuccess: (...params) => {
      onSuccess && onSuccess(...params);
      client.refetchQueries(["settings"]);
    },
    onError: (error) => {
      onError && onError(error);
      console.log(error);
    },
    ...rest,
  });
};

export const useUploadMarketingImage = ({ onSuccess, onError, ...rest } = {}) => {
  const client = useQueryClient();

  return useMutation(uploadMarketingLogo, {
    onSuccess: (...params) => {
      onSuccess && onSuccess(...params);
      client.refetchQueries(["settings"]);
    },
    onError: (error) => {
      onError && onError(error);
      console.log(error);
    },
    ...rest,
  });
};
