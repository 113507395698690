import styled from "styled-components";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import { breakpoints } from "ui/breakpoints";
import { useState, useEffect } from "react";
import {
  checkPayPalIntegration,
  getPaymentMethods,
  validatePaypalIntegration,
} from "actions/payment-methods";
import useToast from "hooks/notifications/use-toast";
import {
  PaymentDisconnectDialog,
  PaymentCard,
} from "components/payment-methods";
import { useAuth } from "providers/auth";
const PaymentMethodList = ({ className, settingData }) => {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState("");
  const [paymentName, setPaymentName] = useState("");
  const [connectStatus, setConnectStatus] = useState("");
  const [disconnectId, setDisconnectID] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const { cognitoId } = useAuth();
  const toast = useToast();
  useEffect(() => {
    getPaymentMethodList();
  }, []);
  const getPaymentMethodList = async () => {
    setLoading(true);
    await getPaymentMethods({ status: false, user_id: cognitoId }).then(
      (res) => {
        if (res && res.length > 0) {
          setPaymentList(res);
          setLoading(false);
        }
      }
    );
  };

  const canDisconnect = (id) => {
    const plaidConnectionsLeft = paymentList.reduce((acc, item) => {
      if (
        item.payment_method_type === "PLAID" &&
        item.payment_method_id !== id &&
        item.payment_method_status === "active"
      ) {
        return acc + 1;
      }

      return acc;
    }, 0);

    if (plaidConnectionsLeft >= 1) return true;

    return false;
  };

  const handleDisconnect = (type, id, name) => {
    if (type === "PLAID" && !canDisconnect(id)) {
      toast.toastError(
        "Please link another account before disconnecting this account."
      );
      return;
    }

    setPaymentType(type);
    setPaymentName(name);
    setDisconnectID(id);
    setConnectStatus("disconnected");
    setConfirmDialog(true);
  };

  useEffect(() => {
    if (!cognitoId) {
      throw new Error("It was not possible to identify the platform.");
    }

    const urlParams = new URLSearchParams(window.location.search);
    setLoading(true);
    checkPayPalIntegration(cognitoId).then((status) => {
      if (status) {
        setLoading(false);
        setPaymentType("Paypal");
        setConnectStatus("active");
      } else {
        setLoading(false);
        if (urlParams.size > 0) {
          const payload = {
            cognito_id: cognitoId,
            merchant_id: urlParams.get("merchantId"),
            merchant_id_in_paypal: urlParams.get("merchantIdInPayPal"),
            permissions_granted: urlParams.get("permissionsGranted"),
            consent_status: urlParams.get("consentStatus"),
            product_intent_id: urlParams.get("productIntentId"),
            is_email_confirmed: urlParams.get("isEmailConfirmed"),
            account_status: urlParams.get("accountStatus"),
          };
          validatePaypalIntegration(payload).then((res) => {
            if (res && res.status === "success") {
              setPaymentType("Paypal");
              setConnectStatus("active");
              toast.success(`Your Paypal integration has been activated.`);
            }
            // clear params from route
            window.history.replaceState({}, document.title, "/payment-methods");
            getPaymentMethodList();
          });
        }
      }
    });
  }, []);
  return (
    <div className={className}>
      {paymentList.find(
        (item) => item.payment_method_type === "PAYPAL"
      ) ? null : (
        <PaymentCard
          className="shadow-md"
          loading={loading}
          settingData={settingData}
          method_type="PAYPAL"
          payment_method_name="PayPal"
          payment_method_status={connectStatus}
          handleDisconnect={handleDisconnect}
        />
      )}
      {paymentList.map((item, index) => (
        <PaymentCard
          className="shadow-md"
          key={index}
          loading={loading}
          settingData={settingData}
          method_type={item.payment_method_type}
          payment_method_name={item.payment_method_name}
          payment_method_status={item.payment_method_status}
          primary={item.payment_method_default}
          bank_name={
            item.payment_method_data?.institution?.name ||
            capitalizeFirstLetter(item.payment_method_data?.account?.subtype)
          }
          account_type={item.payment_method_data.ach?.account_type}
          last_4={`${item.payment_method_data?.account?.mask}`}
          access_token={item.payment_method_data?.access_token || ""}
          handleDisconnect={handleDisconnect}
          payment_method_id={
            item.payment_method_type == "PAYPAL"
              ? item.payment_method_data.merchant_id_in_paypal
              : item.payment_method_id
          }
          name_on_account={item.payment_method_data.name_on_account}
          account={item.payment_method_data?.account}
          setPaymentList={setPaymentList}
        />
      ))}
      <PaymentCard
        key={"addBANK"}
        settingData={settingData}
        handleDisconnect={handleDisconnect}
        setPaymentList={setPaymentList}
      />
      <PaymentDisconnectDialog
        dialog={confirmDialog}
        setDialog={setConfirmDialog}
        type={paymentType}
        paymentName={paymentName}
        connection_id={disconnectId}
        getPaymentMethodList={getPaymentMethodList}
        paymentList={paymentList}
        setPaymentList={setPaymentList}
      />
    </div>
  );
};

const PaymentMethodListStyled = styled(PaymentMethodList)`
  /* justify-content: center;
    margin-top: 0px;
    gap: 0; */
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  gap: 20px;
  margin-top: 40px;

  justify-content: center;

  @media (${breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

export default PaymentMethodListStyled;
