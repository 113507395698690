import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Pagination, CircularProgress } from "@mui/material";
import { BsDownload, BsTrash } from "react-icons/bs";
import moment from "moment";
import { paginator } from "scripts/functions";
import { downloadContract, sendContractEmail } from "./actions";
import ContractDeleteDialog from "./delete-dialog";
import { PaginationSection, TableItem, TableLabel } from "pages/styled";
import {
  TableToolBtn,
  StyledContractTable,
  TableOutlinedBtn,
  TableProgressSection,
} from "./styled";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button-tw";
import { useContract } from "hooks/contract/use-contract";
import { useUpdateContract } from "hooks/contract/use-update-contract";
import { ContactsCombobox } from "components/contact/contacts-combobox";
import { toast } from "react-toastify";
import ContractComfirmDialog from "./confirm-dialog";
import { sortData, truncateString } from "utils/contracts";
import SortIcon from "ui/SortIcon";
import { DetailTool, Status } from "ui/TableHelpers";

const ContractTable = ({
  filteredList,
  loading,
  getContractList,
  customer,
}) => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);
  const [page, setPage] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [concentDialogOpen, setConcentDialogOpen] = useState(false);
  const [concent, setConcent] = useState([]);
  const [sortField, setSortField] = useState("date");
  const [sortDirection, setSortDirection] = useState("asc");

  const count = Math.ceil(filteredList.length / 10);

  const handlePaginate = (event, value) => {
    setPage(paginator(filteredList, value, 1).page);
  };
  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortField(field);
    console.log(`Sorting by ${field} in ${isAsc ? "desc" : "asc"} order`);
  };

  const sortedData = sortData(
    paginator(filteredList, page, 10).data,
    sortField,
    sortDirection
  );

  const handleDownload = async (item) => {
    const payload = {
      file_location: item.contract_info.contract_full_location,
    };
    await downloadContract(payload).then((res) => {
      if (res && res.downloadURLData) {
        toast.success("Contract downloaded successfully.");
        window.open(res.downloadURLData, "_blank");
      }
    });
  };

  const handleSendContact = async (item) => {
    setSelectedContract(item);
    const specificConcent = concent?.find((c) => c.id === item.contract_id);
    if (
      item?.contract_info?.is_smart_contract &&
      !specificConcent?.concent &&
      !concentDialogOpen
    ) {
      setConcentDialogOpen(true);
    } else {
      await sendContractEmail(item.contract_id).then((res) => {
        if (res && res.status === "sent") {
          toast.success("Contract email sent successfully.");
        }
      });
    }
  };

  const handleDelete = (item) => {
    setSelectedContract(item);
    setDeleteDialog(true);
  };

  return (
    <div>
      <StyledContractTable customer={customer}>
        <TableLabel
          className="bg-skin-primary"
          onClick={() => handleSort("contract_info.uploaded_date")}
        >
          Date
          <SortIcon
            direction={sortDirection}
            display={sortField === "contract_info.uploaded_date"}
          />
        </TableLabel>
        <TableLabel
          className="bg-skin-primary"
          onClick={() => handleSort("contract_info.contract_name")}
        >
          Name
          <SortIcon
            direction={sortDirection}
            display={sortField === "contract_info.contract_name"}
          />
        </TableLabel>
        {!customer ? (
          <TableLabel className="bg-skin-primary">Contact</TableLabel>
        ) : null}
        <TableLabel className="bg-skin-primary">Status</TableLabel>
        <TableLabel className="bg-skin-primary"></TableLabel>
        {loading ? (
          <TableProgressSection>
            <CircularProgress />
          </TableProgressSection>
        ) : (
          sortedData.map((item, index) => (
            <React.Fragment key={"contract" + index}>
              <>
                <TableItem>
                  {/* DATE */}
                  {moment(item.contract_info.uploaded_date).format("ll")}
                </TableItem>
                <TableItem>
                  {/* CONTRACT NAME */}
                  {truncateString(item.contract_info.contract_name, 20)}
                </TableItem>
                {!customer ? (
                  <TableItem>
                    {/* CONTACT */}
                    {item.contact_info ? (
                      `${item.contact_info.first_name} ${item.contact_info.last_name}`
                    ) : (
                      <TableOutlinedBtn
                        onClick={() => {
                          history.push(
                            `${history.location.pathname}?assign=${item.contract_id}`
                          );
                        }}
                      >
                        Assign to Contact
                      </TableOutlinedBtn>
                    )}
                  </TableItem>
                ) : null}
                <TableItem>
                  <Status status={item.contract_info.contract_status}></Status>
                </TableItem>
                <TableItem>
                  <DetailTool
                    item={item}
                    handleDownload={handleDownload}
                    handleDelete={handleDelete}
                    customer={customer}
                    setSelectedContract={setSelectedContract}
                    setConcentDialogOpen={setConcentDialogOpen}
                  />
                </TableItem>
              </>
            </React.Fragment>
          ))
        )}
      </StyledContractTable>
      <PaginationSection className="transaction-pagination">
        <Pagination
          count={count}
          page={page}
          onChange={handlePaginate}
          color="success"
        />
      </PaginationSection>
      <ContractDeleteDialog
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        selectedContract={selectedContract}
        setSelectedContract={setSelectedContract}
        getContractList={getContractList}
      />
      <AssignContactModal />
      <ContractComfirmDialog
        dialog={concentDialogOpen}
        setDialog={setConcentDialogOpen}
        submitFunc={() => {
          setConcentDialogOpen(false);
          setConcent((prevConcent) => [
            ...prevConcent,
            { id: selectedContract.contract_id, concent: true },
          ]);
          handleSendContact(selectedContract);
        }}
      />
    </div>
  );
};

function AssignContactModal() {
  const { search } = useLocation();
  const history = useHistory();
  const [contact, setContact] = useState(null);

  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const contractId = query.get("assign");

  const { data: contract } = useContract(contractId);
  const { mutate: updateContract, isLoading } = useUpdateContract();

  const handleAssign = async () => {
    if (!contact || !!contract?.contact_info) return;

    updateContract(
      { contractId, contact_id: contact?.contact_id },
      {
        onSuccess: () => {
          history.push(history.location.path);
          toast.success("Contract updated");
        },
        onError: () => {
          toast.error("An error has occurred. Please try again.");
        },
      }
    );
  };

  return (
    <>
      <Dialog
        open={contractId}
        onOpenChange={(open) => {
          if (!open) {
            setContact(null);
            history.push(history.location.path);
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-xl">Assign the contract</DialogTitle>
            <DialogDescription>
              Assign the contract to one of your contacts in your list.
            </DialogDescription>
          </DialogHeader>
          {false ? (
            <div className="flex items-center justify-center">
              <Loader2 className="w-8 h-8 animate-spin" />
            </div>
          ) : (
            <ContactsCombobox onSelect={setContact} />
          )}
          <DialogFooter>
            <Button
              type="button"
              className="w-[156px] "
              onClick={handleAssign}
              disabled={!contact || isLoading}
            >
              Assign
              {isLoading && <Loader2 className="w-4 h-4 animate-spin ml-2" />}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContractTable;
