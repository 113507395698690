import React, { useMemo, useState } from "react";
import { BsList, BsTrash } from "react-icons/bs";
import { Pagination, CircularProgress } from "@mui/material";
import moment from "moment";
import { convertThousandSeparator, paginator } from "scripts/functions";
import InvoiceDeleteDialog from "./invoice-delete-dialog";
import { updateInvoice } from "../actions";
import { PaginationSection, TableItem, TableLabel } from "pages/styled";
import { ProgressSection, StyledInvoiceTable, TableToolBtn } from "../styled";

import { useInvoices } from "hooks/invoice/use-invoices";
import { useQueryClient } from "@tanstack/react-query";
import { InvoiceStatusBadge } from "components/invoices/invoice-status-badge";
import { useInvoiceDrawer } from "hooks/invoice/use-invoice-drawer";

const InvoiceTable = ({ search, setSearch, dateOption, getInvoiceKpis }) => {
  const queryClient = useQueryClient();
  const [tableList, setTableList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [status, setStatus] = useState("");
  const [deleteInvoiceDialog, setDeleteInvoiceDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [lineItems, setLineItems] = useState([]);
  const { handleOpen: openInvoiceDrawer } = useInvoiceDrawer();

  const queryString = window.location.search;
  const urlParam = new URLSearchParams(queryString).get("contact_id");

  const { data, isLoading } = useInvoices({
    filters: { contactId: urlParam },
  });
  const invoices = useMemo(() => {
    const filtered = data?.filter((item) => {
      const invoice_name =
        item.invoice_info.invoice_to.first_name +
        " " +
        item.invoice_info.invoice_to.last_name;
      return invoice_name
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(search.toLowerCase().replace(/\s+/g, ""));
    });

    return filtered;
  }, [search, setSearch, data]);

  const count = Math.ceil((invoices || []).length / 10);

  const handlePaginate = (event, value) => {
    setSelectedItem(null);
    setPage(paginator(invoices || [], value, 1).page);
  };

  const handleView = (item) => {
    setSelectedItem(item);
    setLineItems(item.invoice_info.invoice_line_items);
    setStatus({
      label: item.invoice_info.invoice_status,
      value: item.invoice_info.invoice_status,
    });
  };

  const handleInvoiceStatusUpdate = async (payload) => {
    await updateInvoice(selectedItem.invoice_id, payload).then((res) => {
      if (res && res.status === "success") {
        queryClient.invalidateQueries({ queryKey: ["invoices"] });
        setSelectedItem({
          ...selectedItem,
          invoice_info: {
            ...selectedItem.invoice_info,
            invoice_status: payload.invoice_status,
          },
        });

        const updatedTableList = tableList.map((item) => {
          if (item.invoice_id === selectedItem.invoice_id) {
            return {
              ...item,
              invoice_info: {
                ...item.invoice_info,
                invoice_status: payload.invoice_status
                  .toLowerCase()
                  .split(" ")
                  .map(
                    (word) => word.charAt(0).toUpperCase() + word.substring(1)
                  )
                  .join(" "),
              },
            };
          }
          return item;
        });

        setTableList(updatedTableList);
      }
    });
  };

  const handleDelete = (item) => {
    setDeleteItem(item);
    setSelectedItem(null);
    setDeleteInvoiceDialog(true);
  };

  const sortInvoicesBySendDate = (a, b) => {
    const dateA = new Date(a?.invoice_info?.invoice_send_date);
    const dateB = new Date(b?.invoice_info?.invoice_send_date);

    return dateB - dateA;
  };

  return (
    <div>
      <StyledInvoiceTable>
        <TableLabel className="bg-skin-primary">To</TableLabel>
        <TableLabel className="bg-skin-primary">Date sent</TableLabel>
        <TableLabel className="bg-skin-primary">Date due</TableLabel>
        <TableLabel className="bg-skin-primary">Status</TableLabel>
        <TableLabel className="bg-skin-primary">Amount</TableLabel>
        <TableLabel className="justify-center bg-skin-primary">
          View/Delete
        </TableLabel>
        {isLoading && (
          <ProgressSection>
            <CircularProgress />
          </ProgressSection>
        )}
        {paginator(invoices || [], page, 10)
          .data.sort(sortInvoicesBySendDate)
          .map((item, index) => (
            <React.Fragment key={"invoice" + index}>
              <TableItem>
                {item.invoice_info.invoice_to.first_name +
                  " " +
                  item.invoice_info.invoice_to.last_name}
              </TableItem>
              <TableItem>
                {moment(item.invoice_info.invoice_send_date).format(
                  "MM/DD/YYYY"
                )}
              </TableItem>
              <TableItem>
                {moment(item.invoice_info.invoice_due_date).format(
                  "MM/DD/YYYY"
                )}
              </TableItem>
              <TableItem>
                <InvoiceStatusBadge
                  status={item.invoice_info?.invoice_status}
                />
              </TableItem>
              <TableItem>
                ${convertThousandSeparator(item.invoice_info.invoice_total)}
              </TableItem>
              <TableItem className="justify-center">
                <TableToolBtn
                  onClick={() => {
                    openInvoiceDrawer(item.invoice_id);
                    // handleView(item);
                  }}
                >
                  <BsList style={{ fontSize: 20 }} />
                </TableToolBtn>
                <TableToolBtn
                  className="ml-08"
                  onClick={() => {
                    handleDelete(item);
                  }}
                >
                  <BsTrash style={{ fontSize: 18 }} />
                </TableToolBtn>
              </TableItem>
            </React.Fragment>
          ))}
      </StyledInvoiceTable>
      <PaginationSection className="transaction-pagination">
        <Pagination
          count={count}
          page={page}
          onChange={handlePaginate}
          color="success"
        />
      </PaginationSection>
      <InvoiceDeleteDialog
        dialog={deleteInvoiceDialog}
        setDialog={setDeleteInvoiceDialog}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        dateOption={dateOption}
        getInvoiceKpis={getInvoiceKpis}
      />
    </div>
  );
};

export default InvoiceTable;
