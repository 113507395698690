import { useLayoutEffect, useState } from "react";
import { getProfileInfo } from "pages/setup/actions";

const useProfileInfo = () => {
  const [profileInfo, setProfileInfo] = useState({});
  const [welcomeDialog, openWelcomeDialog] = useState(false);

  useLayoutEffect(() => {
    let isMounted = true;

    const getProfile = async () => {
      await getProfileInfo()
        .then((res) => {
          if (isMounted && res) {
            setProfileInfo(res);
            if (res.tutorial_completed === false) {
              openWelcomeDialog(true);
            }
          }
        })
        .catch((error) => {
          console.log("USE_PROFILE_INFO::GET_PROFILE_INFO::ERROR::", error);
        });
    };

    getProfile();

    return () => {
      isMounted = false;
    };
  }, []);

  return { profileInfo, welcomeDialog, openWelcomeDialog };
};

export default useProfileInfo;
