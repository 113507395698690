import { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
// import demo_login_logo from "assets/images/DBA-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import {
  StyledAuthInput,
  StyledAuthPassword,
} from "pages/utils/styled-auth-input";
import * as actionTypes from "../signup/actionTypes";
import * as invoiceActionTypes from "pages/invoices/actionsTypes";
import * as quoteActionTypes from "pages/contracts/actionTypes";
import { getSettings } from "pages/settings/actions";
import { getInvoiceById } from "pages/invoices/actions";
import { LoadingWrapper } from "pages/styled";
import {
  ContactBtn,
  FormGroupSection,
  LeftHeader,
  LeftSection,
  LoginFormDescription,
  LoginFormSection,
  LoginFormTitle,
  LoginLeftFooter,
  LoginWrapper,
  PocketbookLogo,
  RightImage,
  RightSection,
  FeatureSection,
  CheckboxText,
  SignUpBtn,
} from "./styled";
import { useAuth } from "providers/auth";
import { toast } from "react-toastify";
import { Button } from "../../../components/ui/button";
import useQueryParams from "utils/useQueryParams";
import { getContractById } from "api/v1-jwt/contract/contract";

const CustomerLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { signin, isSigningIn } = useAuth();

  const { settingData } = useSelector((state) => state.account);
  // const auth = useSelector((state) => state.auth);
  const { selectedInvoice } = useSelector((state) => state.page);
  const { selectedQuote } = useSelector((state) => state.page);
  // const localToken = localStorage.getItem("AuthToken");
  // const match = localToken && localToken === auth;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [validated, setValidated] = useState({
    email: null,
    password: null,
  });
  const [errMessage, setErrMessage] = useState({
    email: "Business Email is required!",
  });
  const [loading, setLoading] = useState(false);

  const params = useQueryParams();

  useEffect(() => {
    const { invoice, contact, email, expired, quote } = params;

    if (email) {
      validateEmail(email);
      setEmail(email);
    }

    if (expired) {
      toast.warning(
        "You’ve been automatically signed out for security. Please log in again."
      );
    }

    localStorage.setItem("contactId", contact);
    localStorage.setItem("invoiceId", invoice);
    localStorage.setItem("quoteId", quote);
    if (invoice) {
      dispatch({
        type: invoiceActionTypes.SELECTED_INVOICE,
        payload: invoice,
      });
    }
    if (quote) {
      dispatch({
        type: quoteActionTypes.SELECTED_QUOTE,
        payload: quote,
      });
    }
  }, [params]);

  useLayoutEffect(() => {
    if (!settingData) {
      setLoading(true);
      getSettings().then((res) => {
        if (res)
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: res,
          });
        setLoading(false);
      });
    }
  }, [dispatch]);

  const validateEmail = (value) => {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email is required!",
      }));
    } else if (!validEmailRegex.test(value)) {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email must be valid",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, email: true }));
    }
  };

  const validatePassword = (value) => {
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, password: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        password: "Must be at least 8 characters.",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, password: true }));
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailChange = (event) => {
    setIsSubmitted(false);
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setIsSubmitted(false);
    validatePassword(event.target.value);
    setPassword(event.target.value);
  };

  const handleRemember = (event) => {
    setRemember(event.target.checked);
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    setValidated({
      email: validated.email === null ? false : validated.email,
    });
    if (validated.email) {
      const payload = {
        username: email,
        password: password,
      };

      await signin(payload, {
        onSuccess: async (data) => {
          console.log("[LOGIN SUCCESS]::::::: ");

          if (data && data.client_tenancy_info) {
            const userType = data.client_tenancy_info.user_type;

            dispatch({
              type: actionTypes.SET_PROFILE_DATA,
              payload: data.user_profile,
            });

            if (userType === "client") {
              if (data.client_tenancy_info.account_setup_completed) {
                history.push("/dashboard");
              } else {
                history.push("/");
              }
            } else if (userType === "customer") {
              if (!selectedInvoice && !selectedQuote) {
                history.push("/dashboard");
              } else {
                if (selectedInvoice) {
                  await getInvoiceById(selectedInvoice).then((res) => {
                    if (res && res.invoice_id) {
                      dispatch({
                        type: invoiceActionTypes.SELECTED_INVOICE,
                        payload: res,
                      });
                      history.push(`/invoices/detail/${res.invoice_id}`);
                    }
                  });
                } else if (selectedQuote) {
                  await getContractById(selectedQuote).then((res) => {
                    if (res && res.contract_id) {
                      dispatch({
                        type: quoteActionTypes.SELECTED_QUOTE,
                        payload: res,
                      });
                      history.push(`/quotes/${res.contract_id}`);
                    }
                  });
                }
              }
            }
          }
        },

        onError: (error) => {
          console.log("[LOGIN ERROR]::::::: ", error);
          toast.error("Login failed. Please, check your credentials");
        },
      });
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <LoginWrapper>
      <LeftSection>
        <LeftHeader>
          <PocketbookLogo src={logo} alt="logo" />
        </LeftHeader>
        <LoginFormSection>
          <div style={{ width: "100%" }}>
            <LoginFormTitle>Login</LoginFormTitle>
            <LoginFormDescription>
              Welcome back! Please enter your details.
              {/* <span
                className="text-underline cursor-pointer"
                onClick={() => history.push("/signup")}
              >
                Create your Pocketbook account today.
              </span> */}
            </LoginFormDescription>
            <SignUpBtn>
              Don't have an account?{" "}
              <span
                className="text-underline"
                onClick={() => history.push("/signup")}
              >
                Create your Pocketbook account today.
              </span>
            </SignUpBtn>
            <FormGroupSection>
              <StyledAuthInput
                name="businessEmail"
                value={email}
                placeholder="Business Email"
                validation={validated.email}
                errMsg={errMessage.email}
                isSubmitted={isSubmitted}
                handleChange={handleEmailChange}
              />
              <StyledAuthPassword
                name="password"
                value={password}
                placeholder="Password"
                validation={validated.password}
                errMsg={errMessage.password}
                isSubmitted={isSubmitted}
                handleChange={handlePasswordChange}
                showPassword={showPassword}
                handleShowPassword={handleClickShowPassword}
              />
              <FeatureSection>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={handleRemember}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<CheckboxText>Remember for 30 days</CheckboxText>}
                />
              </FeatureSection>
              <Button
                variant="contained"
                color="dark"
                onClick={onSubmit}
                endIcon={
                  isSigningIn && <CircularProgress color="inherit" size={14} />
                }
                disabled={isSigningIn}
                sx={{ marginTop: "20px", width: "120px" }}
              >
                Submit
              </Button>
            </FormGroupSection>
          </div>
        </LoginFormSection>
        <LoginLeftFooter>
          <ContactBtn className="pb-10">
            Forgot password?{" "}
            <span
              className="text-underline"
              onClick={() => history.push("/forgot-password")}
            >
              Send me a reset password email
            </span>
          </ContactBtn>
        </LoginLeftFooter>
      </LeftSection>
      <RightSection style={{ background: `${settingData?.primary_color}` }}>
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="login-image"
        />
      </RightSection>
    </LoginWrapper>
  );
};

export default CustomerLogin;
