import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

import { Button } from "components/ui/button-tw";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useContact } from "hooks/contact/use-contact";
import { Loader2 } from "lucide-react";
import moment from "moment";
import { ConfirmDialog } from "components/confirm-dialog";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const amountRules = {
  required: {
    message: "Amount is required",
    value: true,
  },
};

function PaySomeoneModal({ open, onOpenChange, contactId }) {
  const { push } = useHistory();
  const [confirm, setConfirm] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: "",
    },
  });
  const { data: contact } = useContact(contactId, {
    enabled: !!contactId,
  });

  const confirmDescription = useMemo(() => {
    if (!contact) return "";

    return `$${
      getValues().amount
    } will be transferred from Your Pocketbook to ${
      contact?.contact_info?.first_name
    } ${contact?.contact_info?.last_name} on ${moment().format(
      "LL"
    )}. Estimated availability of funds will be 2-3 business days.`;
  }, [getValues().amount, contact]);

  return (
    <>
      <ConfirmDialog
        open={confirm}
        onOpenChange={setConfirm}
        onConfirm={() => {
          reset();
          setConfirm(false);
          onOpenChange(false);
          push("/transactions");
        }}
        title="Confirm your payment"
        description={confirmDescription}
      />
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <form onSubmit={handleSubmit(() => setConfirm(true))}>
            <DialogHeader className="mb-6">
              <DialogTitle className="text-xl">
                Pay someone from Your Pocketbook
              </DialogTitle>
            </DialogHeader>
            {!contact ? (
              <div className="flex items-center justify-center">
                <Loader2 className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="space-y-4">
                <dl className="">
                  <div className="px-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-slate-900">
                      Recipient:
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                      {`${contact.contact_info.first_name} ${contact.contact_info.last_name}`}
                    </dd>
                  </div>
                  <div className="px-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-slate-900">
                      Type:
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                      Individual - Checking
                    </dd>
                  </div>
                  <div className="px-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-slate-900">
                      Routing #:
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                      •••••1234
                    </dd>
                  </div>
                  <div className="px-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-slate-900">
                      Account #:
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                      •••••1234
                    </dd>
                  </div>
                </dl>
                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="amount">Amount</Label>
                  <Input
                    id="amount"
                    type="number"
                    step="0.01"
                    placeholder="$1000,50"
                    aria-invalid={errors.amount ? "true" : "false"}
                    {...register("amount", amountRules)}
                  />
                  {errors.amount && (
                    <p role="alert" className="text-sm text-red-600">
                      {errors.amount.message}
                    </p>
                  )}
                </div>
              </div>
            )}
            <DialogFooter className="mt-6 gap-1">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => {
                  reset();
                  onOpenChange(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-1/2"
                onClick={() => setConfirm(true)}
                disabled={!isValid}
              >
                Pay
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PaySomeoneModal;
