import { useQuery } from "@tanstack/react-query";
import { checkPayPalIntegration } from "api/v1-jwt/paypal/paypal";
import { useAuth } from "providers/auth";

export const useCheckPaypal = ({ queryFn, queryKey, ...rest } = {}) => {
  const { cognitoId } = useAuth();

  return useQuery({
    queryKey: ["paypal", "check", cognitoId],
    queryFn: async () => await checkPayPalIntegration(cognitoId),
    staleTime: Infinity,
    ...rest,
  });
};
