import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export const BasicTooltip=({children, helperText, placement="top-right"})=>{
  return (
    <Tooltip title={helperText} placement={placement}>
      <IconButton>
        {children}
      </IconButton>
    </Tooltip>
  );
}