import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import quickbook_logo from "assets/images/quickbook-logo.png";
import { syncContacts } from "./actions";
import { CardTitle, CardDescription, ConnectBtn } from "./styled";
import { breakpoints } from "ui/breakpoints";

const firstCharUpper = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const IntegrationCard = ({
  className,
  imgUrl,
  cardTitle,
  connectFunc,
  disconnectFunc,
  cardDescription,
  status,
  loading,
  subdomain,
  integartionData,
  setIsOpen,
  setIntegrationForDialog,
}) => {
  const { settingData } = useSelector((state) => state.account);

  const handleSync = () => {
    setIntegrationForDialog(cardTitle);
    setIsOpen(true);
  };

  if (loading) {
    return (
      <div className={className} style={{ justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  const cardTitleUpper = cardTitle.charAt(0).toUpperCase() + cardTitle.slice(1);
  let image;
  if (cardTitleUpper === "Quickbooks") {
    image = quickbook_logo;
  } else
    image =
      "https://upload.wikimedia.org/wikipedia/en/9/9f/Xero_software_logo.svg";
  const StyedConnectBtn = styled(ConnectBtn)`
    background-color: ${settingData?.primary_color};
  `;
  return (
    <div className={className}>
      <div>
        <img
          src={image}
          alt={cardTitleUpper}
          width={"38px"}
          height={"38px"}
        ></img>
        <CardTitle>{cardTitleUpper}</CardTitle>
        {status === "active" ? (
          <CardDescription>{cardDescription}</CardDescription>
        ) : null}
        {!status || status === "pending" ? (
          <StyedConnectBtn
            onClick={() => connectFunc(cardTitleUpper, subdomain)}
          >
            Connect
          </StyedConnectBtn>
        ) : status === "active" ? (
          <>
            <StyedConnectBtn onClick={() => handleSync()} className="mb-5">
              Sync
            </StyedConnectBtn>
            <StyedConnectBtn onClick={() => disconnectFunc()}>
              Disconnect
            </StyedConnectBtn>
          </>
        ) : null}
      </div>
    </div>
  );
};

const IntegrationList = ({
  className,
  integrations,
  connectFunc,
  disconnectFunc,
  loading,
  subdomain,
  setIsOpen,
  setIntegrationForDialog,
}) => {
  if (!integrations) {
    return <div className={className}>{loading && <CircularProgress />}</div>;
  }

  return (
    <div className={className}>
      {Object.entries(integrations).map(([key, value]) => {
        /**
         * Hiding XERO intregration
         *
         * REMOVE THIS STATEMENT TO HAVE XERO BACK.
         */
        if (key === "xero") return null;

        return (
          <IntegrationCardStyled
            key={key}
            cardTitle={key}
            cardDescription={`Integration id: ${value.cognito_id} `}
            subdomain={subdomain}
            connectFunc={connectFunc}
            disconnectFunc={disconnectFunc}
            status={value.integration_status}
            integartionData={value}
            setIsOpen={setIsOpen}
            setIntegrationForDialog={setIntegrationForDialog}
          />
        );
      })}
    </div>
  );
};

const IntegrationCardStyled = styled(IntegrationCard)`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  min-height: 285px;
  padding: 35px;
  display: flex;
  align-items: center;
  ${(props) => (props.status === "active" ? "border: 3px solid #1DB45A" : null)}
`;

const IntegrationListStyled = styled(IntegrationList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 16px;
  justify-content: center;

  @media (${breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

export {
  IntegrationCardStyled as IntegrationCard,
  IntegrationListStyled as IntegrationList,
};
