import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setDefaultPayment } from "api/v1-jwt/payment-methods/payment-methods";
import { useAuth } from "providers/auth";

export const useSetDefaultPayment = () => {
  const query = useQueryClient();
  const { cognitoId } = useAuth();

  return useMutation({
    mutationFn: (paymentId) =>
      setDefaultPayment({ userId: cognitoId, paymentId }),
    onSuccess: () => {
      query.invalidateQueries(["payment_methods"]);
    },
  });
};
