import api from "../config/api";

export const fetchBills = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/bills/${userId || clientCognitoId}`
  );

  return data;
};

export const fetchBill = async ({ userId, billId }) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/bills/${userId || clientCognitoId}/${billId}`
  );

  return data;
};

export const createBill = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/bills/${clientCognitoId}/create`,
    payload
  );

  return data;
};

export const processImageBill = async (file) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const base64Content = await toBase64(file);
  const base64Image = base64Content.split(",")[1];

  const fileType = file.type === "application/pdf" ? "pdf" : "image";

  const payload = {
    file_type: fileType,
    content: base64Image,
  };

  try {
    const { data } = await api.post(`/platform/bills/process_image`, payload);

    return data;
  } catch (error) {
    console.error("Error processing file:", error);
    throw error;
  }
};
