import { TextField, IconButton, InputAdornment, MenuItem } from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import InputMask from "react-input-mask";
import styled from "styled-components";
import true_check from "assets/images/green-check.png";
import false_check from "assets/images/false-icon.png";

const ErrMsg = styled.div`
  color: #f15a25;
  font-weight: 400;
  font-size: 14px;
`;

const handleTabKeyPress = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    const currentTextField = event.target;
    const textFields = Array.from(document.querySelectorAll("input"));
    const currentIndex = textFields.indexOf(currentTextField);
    const nextTextField = textFields[currentIndex + 1];
    if (nextTextField) {
      nextTextField.focus();
    }
  }
};

export const StyledAuthInput = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
  showDescription,
  disabled,
}) => {
  return (
    <TextField
      type="text"
      name={name}
      value={value}
      placeholder={placeholder}
      variant="standard"
      disabled={disabled}
      sx={{
        width: "100%",
        marginBottom: showDescription ? "10px" : "40px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {name !== "dbaName" && (
              <>
                {name === "businessDomain" && (
                  <div className="pr-10">.pocketbook.tech</div>
                )}
                {validation === true && (
                  <IconButton>
                    <img src={true_check} alt="check-true" width={24} />
                  </IconButton>
                )}
                {validation === false && (
                  <div className="flex align-items-center">
                    {isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                    <IconButton>
                      <img src={false_check} alt="check-false" width={24} />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </InputAdornment>
        ),
        sx: {
          fontSize: "14px",
          padding: "0 0 20px",
        },
      }}
      onKeyDown={handleTabKeyPress}
      onChange={handleChange}
    />
  );
};

export const StyledAuthPhone = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
}) => {
  return (
    <InputMask
      mask="999 999 9999"
      value={value}
      disabled={false}
      maskChar=""
      onChange={handleChange}
    >
      {() => (
        <TextField
          type="text"
          name={name}
          placeholder={placeholder}
          variant="standard"
          sx={{
            width: "100%",
            marginBottom: "40px",
          }}
          id="formatted-text-mask-input"
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {validation === true && (
                  <IconButton>
                    <img src={true_check} alt="check-true" width={24} />
                  </IconButton>
                )}
                {validation === false && (
                  <div className="flex align-items-center">
                    {isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                    <IconButton>
                      <img src={false_check} alt="check-false" width={24} />
                    </IconButton>
                  </div>
                )}
              </InputAdornment>
            ),
            sx: {
              fontSize: "14px",
              padding: "0 0 20px",
            },
          }}
          onKeyDown={handleTabKeyPress}
        />
      )}
    </InputMask>
  );
};

export const StyledAuthSelector = ({
  label,
  name,
  value,
  handleChange,
  selectList,
}) => {
  return (
    <TextField
      label={label}
      name={name}
      variant="standard"
      value={value}
      sx={{
        width: "100%",
        "& .MuiSvgIcon-root": {
          top: "calc(50% - 0.9em)",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        marginBottom: "40px",
      }}
      InputProps={{
        sx: {
          fontSize: "14px",
          padding: "0 0 20px",
        },
      }}
      onChange={handleChange}
      select
    >
      {selectList.map((item, index) => (
        <MenuItem value={item.value} key={index}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const StyledAuthPassword = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
  showPassword,
  handleShowPassword,
  noErrMsg,
}) => {
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      placeholder={placeholder}
      variant="standard"
      sx={{
        width: "100%",
        marginBottom: "40px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
            </IconButton>

            {validation === true && (
              <IconButton>
                <img src={true_check} alt="check-true" width={24} />
              </IconButton>
            )}
            {validation === false && (
              <div className="flex align-items-center">
                {!noErrMsg && isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                <IconButton>
                  <img src={false_check} alt="check-false" width={24} />
                </IconButton>
              </div>
            )}
          </InputAdornment>
        ),
        sx: {
          fontSize: "14px",
          padding: "0 0 20px",
        },
      }}
      onKeyDown={handleTabKeyPress}
      onChange={handleChange}
    />
  );
};
