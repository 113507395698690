import api from "api/v1-jwt/config/api";

export const createNewUser = async (payload, id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    encodeURI(
      `/tenancy/clients/${clientCognitoId}/user_signup?from_contact_id=${id}`
    ),
    payload
  );

  return data;
};
