import api from "api/v1-jwt/config/api";

export const downloadFile = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/settings/download_url/${clientCognitoId}`,
    payload
  );

  return data;
};
