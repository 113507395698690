import { Field, useFormikContext, ErrorMessage } from "formik";
import { OptionLabel } from "pages/styled";
import { StyledInput } from "../styled";
import { StyledError } from ".";
import { forwardRef } from "react";

const BusinessInformation = forwardRef(({ ...props }, ref) => {
  const { setFieldValue, values, errors, touched } = useFormikContext(); // Use formik context

  return (
    <div ref={ref}>
      <OptionLabel>Business information</OptionLabel>
      <div className="flex gap-4 mb-4">
        <div className="flex flex-col">
          <Field
            readOnly
            name="businessName"
            as={StyledInput}
            onChange={(businessName) =>
              setFieldValue("businessName", businessName)
            }
            placeholder="Business name"
            width="244px"
            mRight="24px"
            mBottom="0px"
            error={errors.businessName && touched.businessName}
          />
          <ErrorMessage
            name="businessName"
            component={StyledError}
            className="error"
          />
        </div>
        <div className="flex flex-col">
          <Field
            readOnly
            name="dbaName"
            as={StyledInput}
            onChange={(dbaName) => setFieldValue("dbaName", dbaName)}
            placeholder="DBA"
            width="244px"
            mRight="24px"
            mBottom="0px"
            error={errors.dbaName && touched.dbaName}
          />
          <ErrorMessage
            name="dbaName"
            component={StyledError}
            className="error"
          />
        </div>
      </div>
      <div className="mb-4">
        <Field
          readOnly
          name="mailingAddr"
          as={StyledInput}
          onChange={(mailingAddr) => setFieldValue("mailingAddr", mailingAddr)}
          placeholder="Mailing Address"
          width="512px"
          mBottom="0px"
          error={errors.mailingAddr && touched.mailingAddr}
        />
        <ErrorMessage
          name="mailingAddr"
          component={StyledError}
          className="error"
        />
      </div>
      <div className="flex mb-4">
        <div className="flex flex-col">
          <Field
            readOnly
            name="city"
            as={StyledInput}
            onChange={(city) => setFieldValue("city", city)}
            placeholder="City"
            width="154px"
            mRight="24px"
            mBottom="0px"
            error={errors.city && touched.city}
          />
          <ErrorMessage name="city" component={StyledError} className="error" />
        </div>
        <div className="flex flex-col">
          <Field
            readOnly
            name="state"
            as={StyledInput}
            onChange={(state) => setFieldValue("state", state)}
            placeholder="State"
            width="154px"
            mRight="24px"
            mBottom="0px"
            error={errors.state && touched.state}
          />
          <ErrorMessage
            name="state"
            component={StyledError}
            className="error"
          />
        </div>
        <div className="flex flex-col">
          <Field
            readOnly
            name="zipCode"
            as={StyledInput}
            onChange={(zipCode) => setFieldValue("zipCode", zipCode)}
            placeholder="Zip code"
            width="154px"
            mRight="24px"
            mBottom="0px"
            error={errors.zipCode && touched.zipCode}
          />
          <ErrorMessage
            name="zipCode"
            component={StyledError}
            className="error"
          />
        </div>
      </div>
    </div>
  );
});

export default BusinessInformation;
