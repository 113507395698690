import styled from "styled-components";

export const PocketbookLogo = styled.img`
  width: 156px;
  height: 26px;
`;

export const SignupWrapper = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const LeftSection = styled.div`
  background-color: #ffffff;
  flex-basis: 50%;
  position: relative;
  overflow: auto;
  // align-items: center;
  // display: flex;
  @media (max-width: 600px) {
    display: block;
    height: 100%;
    flex-basis: 100%;
    padding: 0px 20px;
  }
`;

export const LeftHeader = styled.div`
  padding: 50px 60px;
`;

export const SignupFormSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px 100px 35px 50px;
  max-width: 600px;
  // height: calc(100% - 273px);
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 30px 50px 20px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
    padding: 30px 20px;
  }
`;

export const SignupFormTitle = styled.div`
  font-size: 32px;
  font-weight: 590;
  font-family: Inter;
  line-height: 38px;
  font-style: normal;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export const SignupFormDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 22px;
`;

export const FormGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
  margin-top: 50px;
`;

export const FormSubmitBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
  background-color: #000000;
  cursor: pointer;
  width: fit-content;
  margin-top: 20px;
`;

export const SignupLeftFooter = styled.div`
  padding: 50px 60px;
`;

export const ContactBtn = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #939393;

  @media (max-width: 600px) {
    position: initial;
    // margin-top: 30px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  background: #000000;
  position: relative;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const RightImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const TermsText = styled.div`
  color: #f15a25;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const CheckedTermsText = styled.div`
  color: #000000;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const SetPasswordBackBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ChecklistLabel = styled.div`
  color: #939393;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
`;

export const ChecklistSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
`;

export const ErrMsg = styled.div`
  color: #f15a25;
  font-weight: 400;
  font-size: 14px;
`;

export const ForgotFormSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px 87px 35px 50px;
  max-width: 600px;

  @media (max-width: 1000px) {
    padding: 30px 50px 20px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 28px;
    padding: 30px 50px 20px 20px;
  }
`;
