import { useEffect } from "react";
import { useFormikContext } from "formik";

const fieldToSectionMap = {
  dueDate: "datesSection",
  sendDate: "datesSection",
  firstName: "primaryContact",
  lastName: "primaryContact",
  email: "primaryContact",
  phone: "primaryContact",
  businessName: "businessInfo",
  dbaName: "businessInfo",
  mailingAddr: "businessInfo",
  city: "businessInfo",
  state: "businessInfo",
  zipCode: "businessInfo",
  invoiceAddPaypal: "subTotal",
  paymentOption: "subTotal",
  subTotal: "subTotal",
  invoiceTotal: "invoiceTotal",
};

export const useScrollToError = (fieldRefs) => {
  const { errors, touched, isSubmitting } = useFormikContext();
  useEffect(() => {
    if (isSubmitting) {
      const firstErrorKey = Object.keys(errors).find(
        (key) => touched[key] && errors[key]
      );
      if (firstErrorKey) {
        let refName = fieldToSectionMap[firstErrorKey];
        const errorFieldRef = fieldRefs[refName];
        if (errorFieldRef && errorFieldRef.current) {
          errorFieldRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [errors, touched, isSubmitting, fieldRefs]);
};
