import React, { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Button, colors, textStyles } from "ui";
import tempQr from "assets/images/tempQr.png";
import { BillsDragAndDrop } from "./BillsDragAndDrop";
import { useHistory } from "react-router-dom";
import { processImageBill } from "../actions";
import { useDispatch } from "react-redux";
import * as actionTypes from "../utils/actionTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export const BillsModal = ({ open, handleClose }) => {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleFileProcessing = async (file) => {
    try {
      setLoading(true);
      const data = await processImageBill(file);
      console.log("File::", data);
      dispatch({
        type: actionTypes.SET_FILE,
        payload: data[0],
      });
      history.push("/bills/create");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-bills"
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs sx={{ padding: "5px 20px" }}>
            <Title>You can upload a file</Title>
            <Description>
              Feel free to upload your .jpeg, .png or .pdf file
            </Description>
            <BillsDragAndDrop file={file} setFile={setFile} />
          </Grid>
          {/* <Divider orientation="vertical" flexItem>
            or
          </Divider>
          <Grid item xs sx={{ padding: "5px 20px" }}>
            <Title>You can use your phone</Title>
            <Description>
              You can scan this QR code to upload the file using your phone
            </Description>
            <img src={tempQr} alt="tempQr" />
          </Grid> */}
        </Grid>
        <ButtonWapper>
          <Button
            disabled={!file}
            variant="green"
            onClick={() => handleFileProcessing(file)}
          >
            {loading ? <CircularProgress size={14} color="inherit" /> : "Next"}
          </Button>
        </ButtonWapper>
      </Box>
    </Modal>
  );
};

const Title = styled.div`
  ${textStyles.title.h3}
`;

const Description = styled.div`
  ${textStyles.body.b3}
  color: ${colors.textGray};
`;

const ButtonWapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
