import { Button as MuiButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const BaseButton = styled(MuiButton)`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  text-transform: capitalize;
  padding: 7px 12px;
  font-family: Inter, Roboto, "Arial Narrow Bold", sans-serif;
  letter-spacing: normal;
  line-height: 24px;
  font-weight: 600;

  &:disabled {
    opacity: 0.5;
  }

  &.MuiButton-contained {
    background-color: black;
    box-shadow: none;

    &:hover {
      background-color: rgba(24, 24, 27, 0.9);
    }

    &:focus-visible {
      outline-color: transparent;
      outline-style: solid;
      outline-width: 2px;
      outline-offset: 2px;
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px,
        rgb(161, 161, 170) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }
  }
`;

const COLORS = [
  "dark",
  "light",
  "action",
  "primary",
  "secondary",
  "warning",
  "error",
];

const getColorStyles = ({ color, theme }) => {
  //console.log("[COLOR_THEME] Color: ", color, theme);
  if (!color || !COLORS.includes(color)) {
    throw new Error("[BUTTON_COLOR_ERROR] Color provided is not allowed.");
  }

  const colorStyles = {
    dark: {
      "&.MuiButton-contained": {
        color: "white",
        backgroundColor: theme.palette.common.black,
        borderColor: theme.palette.common.black,
        "&:hover": {
          backgroundColor: "rgba(24, 24, 27, 0.9)",
          borderColor: "rgba(24, 24, 27, 0.9)",
        },
      },
      "&.MuiButton-outlined": {
        color: alpha(theme.palette.common.black, 0.9),
        borderColor: theme.palette.grey[300],
        "&:hover": {
          backgroundColor: alpha(theme.palette.grey[900], 0.04),
        },
      },
    },
    action: {
      "&.MuiButton-contained": {
        color: theme.palette.success.contrastText,
        backgroundColor: "#1DB45A",
        borderColor: "#1DB45A",
        "&:hover": {
          backgroundColor: alpha("#1DB45A", 0.85),
          borderColor: alpha("#1DB45A", 0.85),
        },
      },
      "&.MuiButton-outlined": {
        color: alpha("#1DB45A", 0.9),
        borderColor: alpha("#1DB45A", 0.3),
        "&:hover": {
          backgroundColor: alpha("#1DB45A", 0.04),
        },
      },
    },
    error: {
      "&.MuiButton-contained": {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
          backgroundColor: alpha(theme.palette.error.main, 0.85),
          borderColor: alpha(theme.palette.error.main, 0.85),
        },
      },
      "&.MuiButton-outlined": {
        color: alpha(theme.palette.error.main, 0.9),
        borderColor: alpha(theme.palette.error.main, 0.3),
        "&:hover": {
          backgroundColor: alpha(theme.palette.error.main, 0.04),
        },
      },
    },
    warning: {
      "&.MuiButton-contained": {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        "&:hover": {
          backgroundColor: alpha(theme.palette.warning.main, 0.85),
          borderColor: alpha(theme.palette.warning.main, 0.85),
        },
      },
      "&.MuiButton-outlined": {
        color: alpha(theme.palette.warning.main, 0.9),
        borderColor: alpha(theme.palette.warning.main, 0.3),
        "&:hover": {
          backgroundColor: alpha(theme.palette.warning.main, 0.04),
        },
      },
    },
  };

  return {
    "&.MuiButtonBase-root.MuiButton-root": colorStyles[color],
  };
};

export const Button = styled(BaseButton, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  ...(color && getColorStyles({ color, theme })),
}));
