import { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import SetupSidebar from "./sidebar";
import AccountSetup1 from "./steps/step1";
import AccountSetup2 from "./steps/step2";
import AccountSetup3 from "./steps/step3";
import AccountSetup4 from "./steps/step4";
import AccountSetup5 from "./steps/step5";
import AccountSetup6 from "./steps/step6";
import AccountSetup7 from "./steps/step7";
import AccountSetup8 from "./steps/step8";
import AccountSetup9 from "./steps/step9";
import * as actionTypes from "./actionTypes";
import { getClientInfo, getProfileInfo } from "./actions";
import { LoadingWrapper } from "pages/styled";
import { SetupMainWrapper } from "./styled";

const AccountSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});

  useLayoutEffect(() => {
    getProfile();
    getClient();
  }, []);

  const getProfile = async () => {
    setLoading(true);
    await getProfileInfo().then((res) => {
      if (res) {
        setLoading(false);
        setProfile(res);
        dispatch({
          type: actionTypes.PERSONA_INQUIRY_ID,
          payload: res.persona_inquiry_id,
        });
        dispatch({
          type: actionTypes.SUBSCRIPTION,
          payload: res.subscription_plan,
        });
        if (res && res.account_setup_completed) {
          history.push("/dashboard");
        } else if (res && !res.account_setup_completed) {
          setStep(res.account_setup_step);
        }
      }
    });
  };

  const getClient = async () => {
    await getClientInfo().then((res) => {
      if (res && res.account_type === "business") {
        dispatch({
          type: actionTypes.BOND_BUSINESS_ID,
          payload: res.bond_id,
        });
      } else if (res && res.account_type === "consumer") {
        dispatch({
          type: actionTypes.BOND_BUSINESS_ID,
          payload: res.bond_consumer_id,
        });
      }
    });
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <SetupMainWrapper>
      <SetupSidebar step={step} setStep={setStep} />

      {step === 1 && (
        <AccountSetup1 step={step} setStep={setStep} profile={profile} />
      )}
      {step === 2 && <AccountSetup2 step={step} setStep={setStep} />}
      {step === 3 && <AccountSetup3 step={step} setStep={setStep} />}
      {step === 4 && <AccountSetup4 step={step} setStep={setStep} />}
      {step === 5 && <AccountSetup5 step={step} setStep={setStep} />}
      {step === 6 && <AccountSetup6 step={step} setStep={setStep} />}
      {step === 7 && <AccountSetup7 step={step} setStep={setStep} />}
      {step === 8 && <AccountSetup8 step={step} setStep={setStep} />}
      {step === 9 && <AccountSetup9 step={step} setStep={setStep} />}
    </SetupMainWrapper>
  );
};

export default AccountSetup;
