import { useState, useEffect } from "react";
import { syncContacts, syncInvoices } from "./actions";

const useSyncContacts = (integrationName, integrationData) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ err: false, msg: "" });
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await syncContacts(integrationName, integrationData);
        setData(data);
      } catch (error) {
        setError({ err: true, msg: error.message });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [integrationName]);

  return { loading, data, error };
};

const useSyncInvoices = (integrationName, integrationData) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ err: false, msg: "" });
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await syncInvoices(integrationName, integrationData);
        setData(data);
      } catch (error) {
        setError({ err: true, msg: error.message });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [integrationName]);

  return { loading, data, error };
};

export { useSyncContacts, useSyncInvoices };
