import { useState, useEffect } from "react";
import { getAllInvoiceKpis } from "pages/invoices/actions";

const useInvoiceKpis = (dateOption) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);

  const [dateForAmount, setDateForAmount] = useState("");
  const [totalValueForAmount, setTotalValueForAmount] = useState("");
  const [paidValueForAmount, setPaidValueForAmount] = useState("");
  const [unpaidValueForAmount, setUnpaidValueForAmount] = useState("");

  const [dateForCount, setDateForCount] = useState("");
  const [totalValueForCount, setTotalValueForCount] = useState("");
  const [paidValueForCount, setPaidValueForCount] = useState("");
  const [unpaidValueForCount, setUnpaidValueForCount] = useState("");
  const [invoiceChartData, setInvoiceChartData] = useState();
  const [pendingCount, setPendingCount] = useState();

  useEffect(() => {
    let isMounted = true;

    const getInvoiceKpis = async (dateOption) => {
      await getAllInvoiceKpis(dateOption).then((res) => {
        if (isMounted && res) {
          setInvoiceChartData(res);

          setTotalAmount(res.dollars.total_amount_invoices);
          setPaidAmount(res.dollars.total_amount_invoices_paid);
          setUnpaidAmount(res.dollars.total_amount_invoices_unpaid);
          setPendingAmount(res.dollars.total_amount_invoices_pending);

          setTotalCount(res.count.total_number_invoices);
          setPaidCount(res.count.total_number_invoices_paid);
          setUnpaidCount(res.count.total_number_invoices_unpaid);
          setPendingCount(res.count.total_number_invoices_pending);

          setDateForAmount(res.dollars.graph_data.created_dates);
          setTotalValueForAmount(res.dollars.graph_data.created);
          setPaidValueForAmount(res.dollars.graph_data.paid);
          setUnpaidValueForAmount(res.dollars.graph_data.unpaid);

          setDateForCount(res.count.graph_data.created_dates);
          setTotalValueForCount(res.count.graph_data.created);
          setPaidValueForCount(res.count.graph_data.paid);
          setUnpaidValueForCount(res.count.graph_data.unpaid);
        }
      });
    };

    getInvoiceKpis(dateOption);

    return () => {
      isMounted = false;
    };
  }, [dateOption]);

  return {
    invoiceChartData,
    totalAmount,
    paidAmount,
    unpaidAmount,
    pendingAmount,
    totalCount,
    paidCount,
    unpaidCount,
    dateForAmount,
    totalValueForAmount,
    paidValueForAmount,
    unpaidValueForAmount,
    dateForCount,
    totalValueForCount,
    paidValueForCount,
    unpaidValueForCount,
    pendingCount,
  };
};

export default useInvoiceKpis;
