import api from "api/v1-jwt/config/api";

export const sendNerRequest = async (text) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/ai/ner`, {
    text,
    // text: "write me a contract for Jeff selling 40 pounds of steel",
    cognito_id: clientCognitoId,
  });

  return data;
};

export const getAllContracts = async (queryObj) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  let queryParams;
  if (queryObj) {
    queryParams = Object.entries(queryObj)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  }

  const url = queryParams
    ? `/platform/contracts/${clientCognitoId}?${queryParams}`
    : `/platform/contracts/${clientCognitoId}`;

  try {
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const getContractsByContactId = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/contracts/${clientCognitoId}/contact/${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const uploadUrl = async (params, file) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    // Get URL
    const { data: url } = await api.get(
      `/settings/upload_url/${clientCognitoId}?${params}`
    );

    // Upload New way
    const presignedData = url.uploadURLData;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", file);
    const uploadResponse = await fetch(presignedData.url, {
      method: "POST",
      body: formData,
      redirect: "follow",
    });
    return { url: url, response: uploadResponse };
  } catch (err) {
    console.error(err);
  }
};

export const createContract = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contracts/${clientCognitoId}`,
    payload
  );

  return data;
};

export const getContractById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `/platform/contracts/${clientCognitoId}/${id}`
  );

  return data;
};

export const downloadContract = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/settings/download_url/${clientCognitoId}`,
    payload
  );

  return data;
};

export const deleteContract = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/contracts/${clientCognitoId}/${id}`
  );

  return data;
};

export const sendContractEmail = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contracts/${clientCognitoId}/send_email/${id}`,
    {}
  );

  return data;
};

export const createSmartContract = async (
  userId,
  contractText,
  invoiceId = null,
  contact,
  isQuote = false,
  quoteItems = null,
  expirationDate,
  // selectedOption,
  uploadedDate = new Date()
) => {
  const payload = {
    contact_id: contact.contact_id,
    uploaded_date: uploadedDate,
    contact: contact,
    invoice_id: invoiceId,
    contract_text: contractText,
    is_smart_contract: true,
    is_quote: isQuote,
    quote_items: quoteItems,
    expiration_date: new Date(expirationDate),
    delivery_option: "none",
    contract_status: "pending approval",
  };

  const { data } = await api.post(
    `/platform/contracts/${userId}/smart_contract`,
    payload
  );

  return data;
};

export const changeContractStatus = async (
  contractId,
  contractStatus,
  deliveryOption
) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const payload = {
    contract_status: contractStatus,
    platform_id: clientCognitoId,
    contract_id: contractId,
  };

  // Add delivery_option to payload if it's not undefined
  if (deliveryOption !== undefined) {
    payload.delivery_option = deliveryOption;
  }

  const { data } = await api.patch(
    `/platform/contracts/customer/status`,
    payload
  );

  return data;
};

export const getQuoteOrContract = async (contractText) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(
    `/platform/contracts/quote_or_contract/${clientCognitoId}`,
    {
      text: contractText,
    }
  );
  return data;
};

export const getLineItemsFromPrompt = async (contractText) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const res = await api.post(
    `/platform/contracts/quote_items_from_prompt/${clientCognitoId}`,
    {
      text: contractText,
    }
  );

  return res;
};
