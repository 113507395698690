import * as Yup from "yup";

const generateInitialValues = () => ({
  invoiceName: "",
  invoiceType: { label: "One time", value: "one-time" },
  dueDate: null,
  sendDate: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  businessName: "",
  dbaName: "",
  mailingAddr: "",
  city: "",
  state: "",
  zipCode: "",
  invoiceText: "",
  invoiceTax: 0,
  invoiceAddPaypal: false,
  paymentOption: { label: "Credit card", value: "credit-card" },
  subTotal: 0,
  invoiceTotal: 0,
  invoice_add_paypal: false,
  lineItems: [
    {
      description: "",
      quantity: 0,
      unit_price: 0,
      item_total: 0,
    },
  ],
  transactionFee: false,
  transactionFeeAmount: 0.5,
});

const initialValues = {
  invoiceName: "",
  invoiceType: { label: "One time", value: "one-time" },
  dueDate: null,
  sendDate: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  businessName: "",
  dbaName: "",
  mailingAddr: "",
  city: "",
  state: "",
  zipCode: "",
  invoiceText: "",
  invoiceTax: 0,
  invoiceAddPaypal: false,
  paymentOption: { label: "Credit card", value: "credit-card" },
  subTotal: 0,
  invoiceTotal: 0,
  invoice_add_paypal: false,
  lineItems: [
    {
      description: "",
      quantity: 0,
      unit_price: 0,
      item_total: 0,
    },
  ],
  transactionFee: false,
  transactionFeeAmount: 0.5,
};

export const validationSchema = Yup.object().shape({
  invoiceName: Yup.string().required("Required"),
  invoiceType: Yup.object().shape({
    label: Yup.string().required("Required"),
    value: Yup.string().required("Required"),
  }),
  dueDate: Yup.date().required("Required"),
  sendDate: Yup.date().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  businessName: Yup.string().required("Required"),
  dbaName: Yup.string().required("Required"),
  mailingAddr: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zipCode: Yup.string().required("Required"),
  invoiceText: Yup.string().required("Required"),
  invoiceTax: Yup.number().required("Required"),
  paymentOption: Yup.object().shape({
    label: Yup.string().required("Required"),
    value: Yup.string().required("Required"),
  }),
  subTotal: Yup.number().required("Required"),
  invoiceTotal: Yup.number().required("Required"),
  invoice_add_paypal: Yup.boolean().required("Required"),
  lineItems: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Required"),
      quantity: Yup.number().required("Required"),
      unit_price: Yup.number().required("Required"),
      item_total: Yup.number().required("Required"),
    })
  ),
  transactionFee: Yup.boolean().required("Required"),
  transactionFeeAmount: Yup.number().required("Required"),
});

export default initialValues;
