import { Dialog } from "@mui/material";
import { BsXLg } from "react-icons/bs";
import {
  DialogTitle,
  DialogDescription,
  DialogSection,
  DialogHeader,
  DialogCancelBtn,
  DialogSubmitBtn,
} from "../../styled";

const InvoiceConfirmDialog = ({
  dialog,
  setDialog,
  createNewInvoice,
  values,
}) => {
  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleSendInvoice = async () => {
    setDialog(false);
    await createNewInvoice(values, "send", values.submit_type);
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="customized-delete-dialog"
      open={dialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogSection>
        <DialogHeader>
          <DialogTitle>Double checking...</DialogTitle>
          <BsXLg onClick={handleDialogClose} />
        </DialogHeader>
        <DialogDescription className="text-start">
          {values?.submit_type === "text" &&
            `Are you sure ${values.phone} is the correct number to send this invoice to?`}
          {values?.submit_type === "email" &&
            `Are you sure ${values.email} is the correct address to send this invoice to?`}
          {values?.submit_type === "all" &&
            `Are you sure ${values?.phone} is the correct number and ${values?.email} is the correct email address to send this invoice to?`}
        </DialogDescription>
        <div className="flex">
          <DialogCancelBtn onClick={handleDialogClose}>
            Change information
          </DialogCancelBtn>
          <DialogSubmitBtn onClick={handleSendInvoice}>
            Yes, send it
          </DialogSubmitBtn>
        </div>
      </DialogSection>
    </Dialog>
  );
};

export default InvoiceConfirmDialog;
