import styled from "styled-components";

const MainWrapper = styled.div`
  padding: 38px;
  background-color: #f8f8f8;
  position: relative;
  margin-left: 315px;
  height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    margin-left: 0px;
    height: calc(100% - 101px);
    padding: 30px;
  }

  @media (max-width: 400px) {
    margin-left: 0px;
    padding: 20px 16px;
  }
`;

export default MainWrapper;
