import { useForm, Controller } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import { StyledAuthPassword } from "pages/utils/styled-auth-input";
import { LoadingWrapper } from "pages/styled";
import {
  FormGroupSection,
  RightSection,
  PocketbookLogo,
  RightImage,
  SignupFormTitle,
} from "./styled";
import { Button } from "components/ui/button-tw";
import { ArrowLeft, Check, Loader2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import useToast from "hooks/notifications/use-toast";
import { getSettings } from "../../settings/actions";
import * as actionTypes from "./actionTypes";
import { useLoggedOutResetPassword } from "hooks/auth/use-logged-out-password-reset";
import { toast } from "react-toastify";

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(12, "Password length should be at least 12 characters")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Passwors must contain at least one special character"
    )
    .matches(/[A-Z]/, "Passwors must contain at least one uppercase letter"),
  confirm: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

export const ResetPassword = () => {
  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { settingData } = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { mutate } = useLoggedOutResetPassword();
  const { handleSubmit, control, formState, setError, reset } = useForm({
    mode: "onChange",
    defaultValues: { password: "", confirm: "" },
    resolver: yupResolver(formSchema),
  });

  useLayoutEffect(() => {
    if (!settingData) {
      setLoading(true);
      getSettings().then((res) => {
        if (res)
          dispatch({
            type: actionTypes.SETTING_DATA,
            payload: res,
          });
        console.log("Settings res: ", res);
        setLoading(false);
      });
    }
  }, [dispatch]);

  useLayoutEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const userId = search.get("email");
    const code = search.get("code");
    if (!userId || !code) {
      history.push("/login");
      toast.error("Your reset password link expired.", {
        autoClose: 15000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }, [window.location.search]);

  const onSubmit = async ({ password }) => {
    const search = new URLSearchParams(window.location.search);
    const userId = search.get("email");
    const code = search.get("code");
    const payload = {
      userId,
      password,
      code,
    };

    mutate(payload, {
      onSuccess: () => {
        history.push("/login");
        toast.success(
          "Password set successfully. You can now use the new password to login.",
          {
            autoClose: 15000,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      },
      onError: (error) => {
        toast.error("Something went wrong while resetting the password.", {
          autoClose: 15000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        console.log("PASSWORD RESET ERROR::", error);
      },
    });
  };

  const backToLogin = () => {
    history.push("/login");
  };

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress disableShrink />
      </LoadingWrapper>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="bg-white w-full sm:w-1/2 relative overflow-auto h-full p-6 sm:p-8 lg:p-12 flex flex-col justify-between">
        <div className="lg:mt-2">
          <PocketbookLogo src={logo} alt="logo" />
        </div>

        <div className="max-w-[600px] lg:pr-20 lg:pl-12 pb-24">
          <div>
            <SignupFormTitle>Set your password</SignupFormTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroupSection>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState, formState }) => {
                    const { onChange, ref, ...rest } = field;
                    return (
                      <StyledAuthPassword
                        {...rest}
                        placeholder="Password"
                        isSubmitted={formState.isSubmitted}
                        handleChange={onChange}
                        showPassword={showPassword}
                        handleShowPassword={() =>
                          setShowPassword((show) => !show)
                        }
                        errMsg={fieldState.error?.message}
                        validation={
                          fieldState.isDirty //&& formState.isSubmitted
                            ? !fieldState.invalid
                            : undefined
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="confirm"
                  control={control}
                  render={({ field, fieldState, formState }) => {
                    const { onChange, ref, ...rest } = field;
                    return (
                      <StyledAuthPassword
                        {...rest}
                        placeholder="Retype your password to confirm"
                        validation={
                          fieldState.isDirty //&& formState.isSubmitted
                            ? !fieldState.invalid
                            : undefined
                        }
                        errMsg={fieldState.error?.message}
                        isSubmitted={formState.isSubmitted}
                        handleChange={onChange}
                        showPassword={showConfirmPassword}
                        handleShowPassword={() =>
                          setShowConfirmPassword((show) => !show)
                        }
                      />
                    );
                  }}
                />
                <Button
                    disabled={!formState.isValid || formState.isSubmitting}
                    className="w-24"
                >
                  {formState.isSubmitting ? (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                      "Submit"
                  )}
                </Button>
              </FormGroupSection>
            </form>
          </div>
        </div>
        <div className="flex justify-start ">
          <Button
            variant="link"
            className="text-slate-600 pl-0"
            onClick={backToLogin}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Login
          </Button>
        </div>
      </div>
      <RightSection className="bg-skin-primary">
        <RightImage
          src={
            settingData?.logo
              ? `https://cdn.pocketbook.tech/${settingData?.logo}`
              : pocketbook_logo
          }
          alt="signup-right-image"
        />
      </RightSection>
      <SuccessDialog
        open={emailSuccess}
        onOpenChange={setEmailSuccess}
        action={backToLogin}
      />
    </div>
  );
};

function SuccessDialog({ open, onOpenChange, action }) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <Check className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-1 sm:text-left space-y-2">
              <DialogTitle>Check your email!</DialogTitle>
              <DialogDescription>
                An email with further instructions was sent to the provided
                email address.
              </DialogDescription>
            </div>
          </div>
        </DialogHeader>
        <DialogFooter>
          <Button type="button" onClick={action}>
            Back to login
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ResetPassword;
