import api from "../config/api";

export const getContacts = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `${process.env.REACT_APP_PBTECH_API_VERSION}/platform/contacts/${clientCognitoId}`
  );

  return data;
};

export const getContactById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(
    `${process.env.REACT_APP_PBTECH_API_VERSION}/platform/contacts/${clientCognitoId}?contact_id=${id}`
  );

  return data;
};

export const getContactByName = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/platform/contacts/contact/by_name`, {
    ...payload,
    cognito_id: clientCognitoId,
  });

  return data;
};

export const createContact = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/platform/contacts/${clientCognitoId}`, {
    ...payload,
    cognito_id: clientCognitoId,
  });

  return data;
};

export const updateContact = async (id, payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/contacts/${clientCognitoId}/${id}`,
    payload
  );

  return data;
};

export const deleteContact = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/contacts/${clientCognitoId}/${id}`
  );
  return data;
};

export const saveBankInfo = async (name_on_account, values, contact_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const bank_data = {
    name_on_account,
    ach: {
      account_number: values.account,
      routing_number: values.routing,
      account_type: values.accountType,
    },
  };

  const { data } = await api.post(
    `/platform/contacts/add_bank/${clientCognitoId}/${contact_id}`,
    bank_data
  );

  return data;
};
