import {
  DRAFT_INVOICE_STATUS,
  PAID_INVOICE_STATUS,
  PAST_DUE_INVOICE_STATUS,
  PENDING_INVOICE_STATUS,
  PROCESSING_INVOICE_STATUS,
  UNPAID_AND_OVERDUE_INVOICE_STATUS,
  UNPAID_INVOICE_STATUS,
  invoiceStatus,
} from "constants/invoice";
import { cn } from "utils/classnames";

export function InvoiceStatusBadge({ status }) {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded-md bg-green-50 ml-2 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
        {
          "text-red-700 ring-red-600/20 bg-red-50":
            status === PAST_DUE_INVOICE_STATUS ||
            status === UNPAID_AND_OVERDUE_INVOICE_STATUS,
          "text-yellow-700 ring-yellow-600/20 bg-yellow-50":
            status === UNPAID_INVOICE_STATUS ||
            status === PENDING_INVOICE_STATUS ||
            status === PROCESSING_INVOICE_STATUS,
          "text-green-700 ring-green-600/20 bg-green-50":
            status === PAID_INVOICE_STATUS,
          "text-blue-700 ring-blue-600/20 bg-blue-50":
            status === DRAFT_INVOICE_STATUS,
        }
      )}
    >
      <span className="">{invoiceStatus[status]?.label}</span>
    </span>
  );
}
