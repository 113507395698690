import { useState, useEffect } from "react";
import pocketbook_logo from "assets/images/pocketbook-logo.png";
import Sidebar from "../sidebar/sidebar";
import DisconnectQuickBookDialog from "./disconnect-dialog";
import { getIntegrationInfo, connectFunc } from "./actions";
import { MainTitle, MainHeader, MainSubTitle, MainWrapper } from "../styled";
import { IntegrationList } from "./integration-list";
import { SettingsDialog } from "./settings-dialog";
import ClientMainHeader from "pages/header";
import { useTenancyinfo } from "providers/auth";
import { LimitedAccountWarning } from "components/limited-account-warning";
const Integrations = () => {
  // const [shortCut, setShortCut] = useState("");
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState();
  const [connectedIntegration, setConnectedIntegration] = useState();
  const [integrationForDialog, setIntegrationForDialog] = useState("");
  const [integrationDialog, setIntegrationDialog] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [shortCut, setShortCut] = useState("");
  const { subdomain } = useTenancyinfo();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    getIntegrationFromAPI();
  }, []);

  useEffect(() => {
    if (
      urlParams.get("integrationId") &&
      urlParams.get("status") === "active"
    ) {
      let capitalized =
        urlParams.get("integrationId").charAt(0).toUpperCase() +
        urlParams.get("integrationId").slice(1);
      console.log(capitalized);
      setConnectedIntegration(capitalized);
      setIntegrationDialog(true);
    }
  }, []);

  const getIntegrationFromAPI = async () => {
    setLoading(true);
    await getIntegrationInfo().then((res) => {
      if (res) {
        setLoading(false);
        setIntegrations(res);
      }
    });
  };

  const handleDisconnect = () => {
    setDialog(true);
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Integrations"
            subTitle="Connect to other tools you use every day"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <IntegrationList
            integrations={integrations}
            subdomain={subdomain}
            connectFunc={connectFunc}
            disconnectFunc={handleDisconnect}
            loading={loading}
            setIntegrationForDialog={setIntegrationForDialog}
            setIsOpen={setIntegrationDialog}
          />
        </MainWrapper>
      </>
      {integrationDialog ? (
        <SettingsDialog
          isOpen={integrationDialog}
          integrationName={
            urlParams.get("integrationId")
              ? urlParams.get("integrationId")
              : integrationForDialog
          }
          integrations={integrations}
          setIsOpen={setIntegrationDialog}
        />
      ) : null}
      <DisconnectQuickBookDialog dialog={dialog} setDialog={setDialog} />
    </div>
  );
};

export default Integrations;
