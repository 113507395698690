import React from 'react';
import { StyledTextField } from '../FormLabelStyles';

export const EINInput = ({ein, setEin}) => {

  const formatEin = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Limit to 9 characters
    const limitedValue = numericValue.slice(0, 9);

    // Format with dashes
    let formattedSSN = '';
    for (let i = 0; i < limitedValue.length; i++) {
      if (i === 2) {
        formattedSSN += '-';
      }
      formattedSSN += limitedValue[i];
    }

    return formattedSSN;
  };

  const handleEINChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatEin(inputValue);
    setEin(formattedValue);
  };

  return (
    <StyledTextField
      id="einInput"
      value={ein}
      onChange={handleEINChange}
    />
  );
};

