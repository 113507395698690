import { Fragment, useMemo, useState } from "react";
import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "utils/classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  CalendarIcon,
  ChevronDown,
  ChevronRight,
  FilePlusIcon,
  Paperclip,
  PenLine,
  PlusIcon,
  TagIcon,
  XCircle,
} from "lucide-react";

import { Button } from "components/ui/button-tw";
import { useContactDrawer } from "hooks/contact/use-contact-drawer";
import { useContactInvoices } from "hooks/invoice/use-contact-invoices";
import { InvoiceStatusBadge } from "components/invoices/invoice-status-badge";
import { useInvoiceDrawer } from "hooks/invoice/use-invoice-drawer";
import {
  PAID_INVOICE_STATUS,
  PAST_DUE_INVOICE_STATUS,
  PENDING_INVOICE_STATUS,
  PROCESSING_INVOICE_STATUS,
  UNPAID_AND_OVERDUE_INVOICE_STATUS,
  UNPAID_INVOICE_STATUS,
} from "constants/invoice";
import { capitalizeFirstLetter } from "utils/string-manipulation";
import { useContactContracts } from "hooks/contract/use-contact-contracts";
import { mapStatusColor } from "components/invoices/customer-invoices-table/columns";
import { formatPhoneNumber, formatPhoneUS } from "utils/functions";

function Loading() {
  return (
    <div>
      <div role="status" className="px-6 py-8 animate-pulse">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className=" p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className="absolute bottom-0 inset-x-0 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      ></div>
    </div>
  );
}

function Error() {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There was an error loading the contact details.
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              <li>Make sure your internet connection is stable</li>
              <li>Try closing and opening the drawer again</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const tabs = [
  { name: "Description", value: "description" },
  { name: "Invoices", value: "invoices" },
  { name: "Quotes", value: "quotes", current: false },
];

export default function ContactDrawer() {
  const history = useHistory();
  const {
    drawerData: contact,
    open,
    handleClose,
    isLoading,
    isError,
  } = useContactDrawer();
  const [currTab, setCurrTab] = useState(tabs[0].value);

  const contactFields = useMemo(() => {
    const info = contact?.contact_info || {};

    return {
      "First name": info.first_name || "",
      "Last name": info.last_name || "",
      "Business name": info.business_name || "",
      "Business DBA": info.business_dba || "",
      Phone: formatPhoneUS(info.phone),
      Email: info.email || "",
      Address: info.business_address || "",
      City: info.business_city || "",
      State: info.business_state || "",
      Zip: info.business_zip || "",
    };
  }, [contact]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setCurrTab(tabs[0].value);
            handleClose();
          }}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative pointer-events-auto w-screen max-w-2xl">
                    <div className=" pb-[120px] flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6 bg-skin-primary">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Contact
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-gray-50 hover:text-gray-300 focus:ring-2 focus:ring-white"
                              onClick={() => {
                                setCurrTab(tabs[0].value);
                                handleClose();
                              }}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      {isLoading ? (
                        <Loading />
                      ) : isError ? (
                        <Error />
                      ) : (
                        <div>
                          {/* Header */}
                          <div>
                            <div className="flex mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                              <div className="mt-6 min-w-0 flex-1 2xl:block">
                                <h1 className="truncate text-2xl font-bold text-gray-900">
                                  {`${contact?.contact_info?.first_name} ${contact?.contact_info?.last_name}`}
                                </h1>
                              </div>
                              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                                <Menu
                                  as="div"
                                  className="relative inline-block text-left"
                                >
                                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    Options
                                    <ChevronDown
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <div
                                              onClick={() =>
                                                history.push(
                                                  `/contacts/update/${contact?.contact_id}`
                                                )
                                              }
                                              className={cn(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                              )}
                                            >
                                              Edit
                                            </div>
                                          )}
                                        </Menu.Item>

                                        <Menu.Item>
                                          {({ active }) => (
                                            <div
                                              onClick={() =>
                                                history.push("/invoices/new")
                                              }
                                              className={cn(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                              )}
                                            >
                                              Create invoice
                                            </div>
                                          )}
                                        </Menu.Item>

                                        <Menu.Item>
                                          {({ active }) => (
                                            <div
                                              onClick={() =>
                                                history.push("/smart-quotes")
                                              }
                                              className={cn(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                              )}
                                            >
                                              Create quote
                                            </div>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </div>
                          {/* Tabs */}
                          <div className="mt-6 sm:mt-2 2xl:mt-5">
                            <div className="border-b border-gray-200">
                              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                                <nav
                                  className="-mb-px flex space-x-8"
                                  aria-label="Tabs"
                                >
                                  {tabs.map((tab) => (
                                    <button
                                      key={tab.name}
                                      onClick={() => setCurrTab(tab.value)}
                                      className={cn(
                                        tab.value === currTab
                                          ? "border-skin-primary text-gray-900"
                                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                      )}
                                      aria-current={
                                        currTab === tab.value
                                          ? "page"
                                          : undefined
                                      }
                                    >
                                      {tab.name}
                                    </button>
                                  ))}
                                </nav>
                              </div>
                            </div>
                          </div>

                          {/* Description list */}
                          {currTab === "description" && (
                            <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                {Object.keys(contactFields).map((field) => (
                                  <div key={field} className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                      {field}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                      {contactFields[field]}
                                    </dd>
                                  </div>
                                ))}
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500 mb-4">
                                    Notes
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {contact?.contact_info?.notes}
                                  </dd>
                                  {/* <NotesTextArea /> */}
                                </div>
                              </dl>
                            </div>
                          )}
                          {currTab === "invoices" && (
                            <InvoicesTabContent
                              contactId={contact?.contact_id}
                            />
                          )}
                          {currTab === "quotes" && (
                            <QuotesTabContent contactId={contact?.contact_id} />
                          )}
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const labels = [{ name: "Unlabelled", value: null }];
const dueDates = [
  { name: "No reminder", value: null },
  { name: "Today", value: "today" },
  { name: "Tomorrow", value: "tomorrow" },
  // More items...
];

function NotesTextArea() {
  const [labelled, setLabelled] = useState(labels[0]);
  const [dated, setDated] = useState(dueDates[0]);

  return (
    <form action="#" className="relative">
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-skin-primary focus-within:ring-1 focus-within:ring-skin-primary">
        <label htmlFor="title" className="sr-only">
          Title
        </label>
        <input
          type="text"
          name="title"
          id="title"
          className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
          placeholder="Title"
        />
        <label htmlFor="description" className="sr-only">
          Description
        </label>
        <textarea
          rows={2}
          name="description"
          id="description"
          className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Write a description..."
          defaultValue={""}
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
          <Listbox
            as="div"
            value={labelled}
            onChange={setLabelled}
            className="flex-shrink-0"
          >
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">Add a label</Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <TagIcon
                      className={cn(
                        labelled.value === null
                          ? "text-gray-300"
                          : "text-gray-500",
                        "h-5 w-5 flex-shrink-0 sm:-ml-1"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        labelled.value === null ? "" : "text-gray-900",
                        "hidden truncate sm:ml-2 sm:block"
                      )}
                    >
                      {labelled.value === null ? "Label" : labelled.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {labels.map((label) => (
                        <Listbox.Option
                          key={label.value}
                          className={({ active }) =>
                            cn(
                              active ? "bg-gray-100" : "bg-white",
                              "relative cursor-default select-none px-3 py-2"
                            )
                          }
                          value={label}
                        >
                          <div className="flex items-center">
                            <span className="block truncate font-medium">
                              {label.name}
                            </span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>

          <Listbox
            as="div"
            value={dated}
            onChange={setDated}
            className="flex-shrink-0"
          >
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">
                  Add a due date
                </Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <CalendarIcon
                      className={cn(
                        dated.value === null
                          ? "text-gray-300"
                          : "text-gray-500",
                        "h-5 w-5 flex-shrink-0 sm:-ml-1"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        dated.value === null ? "" : "text-gray-900",
                        "hidden truncate sm:ml-2 sm:block"
                      )}
                    >
                      {dated.value === null ? "Reminder" : dated.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {dueDates.map((dueDate) => (
                        <Listbox.Option
                          key={dueDate.value}
                          className={({ active }) =>
                            cn(
                              active ? "bg-gray-100" : "bg-white",
                              "relative cursor-default select-none px-3 py-2"
                            )
                          }
                          value={dueDate}
                        >
                          <div className="flex items-center">
                            <span className="block truncate font-medium">
                              {dueDate.name}
                            </span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            <button
              type="button"
              className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
            >
              <Paperclip
                className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <span className="text-sm italic text-gray-500 group-hover:text-gray-600">
                Attach a file
              </span>
            </button>
          </div>
          <div className="flex-shrink-0">
            <Button>Create</Button>
          </div>
        </div>
      </div>
    </form>
  );
}

function EmptyInvoicesList() {
  const history = useHistory();

  return (
    <div className="text-center">
      <FilePlusIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No invoices</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new invoice.
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-skin-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-skin-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-skin-primary"
          onClick={() => history.push("/invoices/new")}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Invoice
        </button>
      </div>
    </div>
  );
}

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function InvoicesTabContent({ contactId }) {
  const { handleOpen: handleOpenInvoice } = useInvoiceDrawer();
  const { data } = useContactInvoices({
    contactId,
    enabled: !!contactId,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    createdAfter: 9999,
    status: [],
  });

  const invoices = useMemo(() => {
    return data?.filter(({ created_at }) => {
      let filterIn = false;
      const invoiceCreated = moment(created_at);
      const dateFilter = moment().subtract(appliedFilters.createdAfter, "days");

      if (invoiceCreated.isAfter(dateFilter)) {
        filterIn = true;
      }

      return filterIn;
    });
  }, [appliedFilters, data]);

  const aggrMetrics = useMemo(() => {
    return (invoices || []).reduce(
      (acc, { invoice_info: info }) => {
        const status = info.invoice_status;

        switch (status) {
          case PAID_INVOICE_STATUS:
            acc["paid"] = acc["paid"] + info.invoice_total;
            break;
          case PAST_DUE_INVOICE_STATUS:
          case UNPAID_AND_OVERDUE_INVOICE_STATUS:
            acc["pastDue"] = acc["pastDue"] + info.invoice_total;
            break;
          case PENDING_INVOICE_STATUS:
          case PROCESSING_INVOICE_STATUS:
          case UNPAID_INVOICE_STATUS:
            acc["outstanding"] = acc["outstanding"] + info.invoice_total;
            break;
          default:
            console.log("AGGREGATE_METRICS::STATUS::NOT_RECOGNIZED::", status);
        }

        acc["total"] = acc["total"] + info.invoice_total;
        return acc;
      },
      {
        paid: 0.0,
        outstanding: 0.0,
        pastDue: 0.0,
        total: 0.0,
      }
    );
  }, [invoices]);

  return (
    <div>
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 border-b border-gray-100">
        {["paid", "pastDue", "outstanding", "total"].map((stat) => (
          <div
            key={stat}
            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-6 sm:px-8 sm:py-10"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {capitalizeFirstLetter(stat).replace(/([a-z])([A-Z])/g, "$1 $2")}
            </dt>
            {/* <dd
              className={cn(
                stat.changeType === "negative"
                  ? "text-rose-600"
                  : "text-gray-700",
                "text-xs font-medium"
              )}
            >
              {stat.change}
            </dd> */}
            <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
              {USDollar.format(aggrMetrics[stat])}
            </dd>
          </div>
        ))}
      </dl>

      <div className="bg-white lg:min-w-0 lg:flex-1">
        <div className="border-b border-t border-gray-200 pb-4 pl-4 pr-8 pt-4 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-8 xl:pt-6">
          <div className="flex items-center">
            <h1 className=" text-lg font-medium mr-6">Invoices</h1>
            <div className="order-last grow flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 9999,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 9999,
                  }))
                }
              >
                All time
              </button>
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 7,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 7,
                  }))
                }
              >
                Last 7 days
              </button>
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 30,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 30,
                  }))
                }
              >
                Last 30 days
              </button>
            </div>
          </div>
        </div>
        {invoices?.length ? (
          <ul className="divide-y divide-gray-200 border-b border-gray-200">
            {invoices?.map(({ invoice_id: id, invoice_info: info }) => (
              <li
                key={id}
                className="relative py-5 pl-4 pr-6 hover:bg-gray-50 hover:cursor-pointer sm:py-6 sm:pl-6 lg:pl-8 "
                onClick={() => handleOpenInvoice(id)}
              >
                <div className="flex items-center justify-between space-x-4">
                  <div className="flex-col space-y-2">
                    <div className="flex items-start gap-x-3">
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        {USDollar.format(info.invoice_total)}
                      </div>
                      <InvoiceStatusBadge status={info.invoice_status} />
                    </div>

                    <div className="text-sm text-gray-500">
                      Invoice <span className="">#{info.invoice_number}</span>
                    </div>
                  </div>

                  <div className="flex items-center gap-x-6">
                    <div className="hidden flex-shrink-0 flex-col items-end space-y-3 sm:flex">
                      <p className="flex items-center space-x-4">
                        <span className="relative text-sm font-medium text-gray-500 hover:text-gray-900">
                          {info.invoice_frequency}
                        </span>
                      </p>
                      <p className="flex space-x-2 text-sm text-gray-500">
                        <span>{info.invoice_delivery_method}</span>
                        <span aria-hidden="true">&middot;</span>
                        <span>
                          Sent on {moment(info.invoice_send_date).format("LL")}
                        </span>
                      </p>
                    </div>
                    <div className="">
                      <ChevronRight
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="py-24">
            <EmptyInvoicesList />
          </div>
        )}
      </div>
    </div>
  );
}

const QuoteStatus = ({ className, status }) => {
  return (
    <span className={`${className} flex items-center space-x-1`}>
      <span
        className={cn(
          mapStatusColor(status).accent,
          "h-4 w-4 flex items-center justify-center rounded-full"
        )}
        aria-hidden="true"
      >
        <span
          className={cn(mapStatusColor(status).main, "h-2 w-2 rounded-full")}
        />
      </span>
      <p className={cn("text-xs leading-5 text-gray-500 font-medium")}>
        {capitalizeFirstLetter(status)}
      </p>
    </span>
  );
};

function EmptyQuoteList() {
  const history = useHistory();

  return (
    <div className="text-center">
      <PenLine className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No quotes found
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Create a new quote for this contact.
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-skin-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-skin-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-skin-primary"
          onClick={() => history.push("/smart-quotes")}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Quote
        </button>
      </div>
    </div>
  );
}

function QuotesTabContent({ contactId }) {
  const history = useHistory();
  const { data } = useContactContracts({
    contactId,
    enabled: !!contactId,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    createdAfter: 9999,
    status: [],
  });

  const quotes = useMemo(() => {
    return data?.filter(({ created_at }) => {
      let filterIn = false;
      const invoiceCreated = moment(created_at);
      const dateFilter = moment().subtract(appliedFilters.createdAfter, "days");

      if (invoiceCreated.isAfter(dateFilter)) {
        filterIn = true;
      }

      return filterIn;
    });
  }, [appliedFilters, data]);

  const aggrMetrics = useMemo(() => {
    return (quotes || []).reduce(
      (acc, { contract_info: info }) => {
        const status = info.contract_status;

        switch (status) {
          case "pending approval":
            acc["pendingApproval"] = acc["pendingApproval"] + 1;
            break;
          case "draft":
            acc["draft"] = acc["draft"] + 1;
            break;
          case "approved":
            acc["approved"] = acc["approved"] + 1;
            break;
          case "rejected":
            acc["rejected"] = acc["rejected"] + 1;
            break;
          default:
            console.log("AGGREGATE_METRICS::STATUS::NOT_RECOGNIZED::", status);
        }

        // acc["total"] = acc["total"] + 1;
        return acc;
      },
      {
        pendingApproval: 0,
        rejected: 0,
        approved: 0,
        draft: 0,
      }
    );
  }, [quotes]);

  return (
    <div>
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 border-b border-gray-100">
        {Object.keys(aggrMetrics).map((stat) => (
          <div
            key={stat}
            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-6 sm:px-8 sm:py-10"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {capitalizeFirstLetter(stat).replace(/([a-z])([A-Z])/g, "$1 $2")}
            </dt>
            <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
              {aggrMetrics[stat]}
            </dd>
          </div>
        ))}
      </dl>

      <div className="bg-white lg:min-w-0 lg:flex-1">
        <div className="border-b border-t border-gray-200 pb-4 pl-4 pr-8 pt-4 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-8 xl:pt-6">
          <div className="flex items-center">
            <h1 className=" text-lg font-medium mr-6">Quotes</h1>
            <div className="order-last grow flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 9999,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 9999,
                  }))
                }
              >
                All time
              </button>
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 7,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 7,
                  }))
                }
              >
                Last 7 days
              </button>
              <button
                className={cn("text-gray-500", {
                  "text-skin-primary": appliedFilters.createdAfter === 30,
                })}
                onClick={() =>
                  setAppliedFilters((filters) => ({
                    ...filters,
                    createdAfter: 30,
                  }))
                }
              >
                Last 30 days
              </button>
            </div>
          </div>
        </div>
        {quotes?.length ? (
          <ul className="divide-y divide-gray-200 border-b border-gray-200">
            {quotes?.map(
              ({ contract_id: id, contract_info: info, created_at }) => (
                <li
                  key={id}
                  className="relative py-5 pl-4 pr-6 hover:bg-gray-50 hover:cursor-pointer sm:py-6 sm:pl-6 lg:pl-8 "
                  onClick={() => history.push(`/quotes/${id}`)}
                >
                  <div className="flex items-center justify-between space-x-4">
                    <div className="flex-col space-y-2 overflow-hidden">
                      <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                        {info.contract_name}
                      </p>

                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created on{" "}
                          <time dateTime={created_at}>
                            {moment(created_at).format("LL")}
                          </time>
                        </p>
                        <svg
                          viewBox="0 0 2 2"
                          className="h-0.5 w-0.5 fill-current"
                        >
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <QuoteStatus status={info.contract_status} />
                      </div>
                    </div>

                    <div className="flex items-center gap-x-6">
                      <div className="">
                        <ChevronRight
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        ) : (
          <div className="py-24">
            <EmptyQuoteList />
          </div>
        )}
      </div>
    </div>
  );
}
