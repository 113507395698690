import { useQuery } from "@tanstack/react-query";
import { fetchBill } from "api/v1-jwt/bill/bill";

export const useBill = ({
  userId,
  billId,
  queryFn,
  queryKey,
  ...rest
} = {}) => {
  return useQuery({
    queryKey: ["bills", billId],
    queryFn: () => fetchBill({ userId, billId }),
    enabled: !!billId,
    ...rest,
  });
};
