import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.minimal.css';

const useToast = ( props = {} ) => {

    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        ...props
    };


    const toastSuccess = (message) => {
        toast.success(message, options);
    };

    const toastError = (message) => {
        toast.error(message, options);
    };

    const toastWarning = (message) => {
        toast.warning(message, options);
    };

    const toastInfo = (message) => {
        toast.info(message, options);
    };

    const toastBasic = (message) => {
        toast(message, options);
    };

    const toastNotify = (input, type, successMessage) => {
        if (input instanceof Promise) {
            return input.then(data => {
                toast.promise(input, {
                    pending: 'Loading...',
                    error: {
                        render: () => {
                            const message = `Error encountered: ${data.message}`;

                            switch (type) {
                                case "success":
                                    toastSuccess(message);
                                    break;
                                case "error":
                                    toastError(message);
                                    break;
                                default:
                                    toastBasic(message);
                                    break;
                            }
                        }
                    },
                    success: {
                        render: () => {
                            const message = successMessage || data.message || "Operation successfully completed";

                            switch (type) {
                                case "success":
                                    toastSuccess(message);
                                    break;
                                case "error":
                                    toastError(message);
                                    break;
                                default:
                                    toastBasic(message);
                                    break;
                            }
                        }
                    },
                });
                return data;
            });
        } else {
            const message = input;
            switch (type) {
                case "success":
                    toastSuccess(message);
                    break;
                case "error":
                    toastError(message);
                    break;
                case "warning":
                    toastWarning(message);
                    break;
                case "info":
                    toastInfo(message);
                    break;
                default:
                    toastBasic(message);
                    break;
            }
        }
    };

    const toastNotify2 = async (promise, type, successMessage) => {
        if (promise instanceof Promise) {
            try {
                const response = await promise;
                const { success, message } = response;
                let toastMessage = success ? successMessage : message;
                toastMessage = toastMessage || "Operation successfully completed";

                if (!success) {
                    toastError(toastMessage);
                } else {
                    switch (type) {
                        case "success":
                            toastSuccess(toastMessage);
                            break;
                        case "error":
                            toastError(toastMessage);
                            break;
                        default:
                            toastBasic(toastMessage);
                            break;
                    }
                }
                return response;
            } catch (error) {
                toastError(error.message);
            }
        } else {
            const message = promise;

            switch (type) {
                case "success":
                    toastSuccess(message);
                    break;
                case "error":
                    toastError(message);
                    break;
                case "warning":
                    toastWarning(message);
                    break;
                case "info":
                    toastInfo(message);
                    break;
                default:
                    toastBasic(message);
                    break;
            }
        }
    };

    return {
        toastSuccess,
        toastError,
        toastWarning,
        toastInfo,
        toastBasic,
        toastNotify,
        toastNotify2
    };
};

export default useToast;