import api from "api/v1-jwt/config/api";

const setAccessToken = async (
  publicToken,
  metadata,
  cognitoId,
  name_on_account = "",
  defaultPaymentMethod = false
) => {
  try {
    const apiUrl = `/platform/payment_methods/plaid/set_access_token`;

    const payload = {
      public_token: publicToken,
      metadata: metadata,
      cognito_id: cognitoId,
      payment_method_default: defaultPaymentMethod,
    };
    if (name_on_account !== "") {
      payload.name_on_account = name_on_account;
    }

    const { data } = await api.post(apiUrl, payload);

    return data;
  } catch (err) {
    console.error("Error fetching access token:", err);
    if (err.response) {
      console.error("Server responded with:", err.response.data);
    }
  }
};

export default setAccessToken;
