import styled from "styled-components";

export const DialogSection = styled.div`
  padding: 24px;
  background-color: #ffffff;
  text-align: center;
`;

export const DialogTitle = styled.div`
  font-family: Inter;
  font-size: 22px;
  font-weight: 590;
  line-height: 26px;
  color: #000000;
  padding-bottom: 4px;
`;

export const DialogDescription = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #000000;
  padding-bottom: 20px;
`;

export const DialogBtn = styled.div`
  background-color: #000000;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-family: Inter;
  font-weight: 590;
  line-height: 17px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
`;
