import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PaymentMethodList, MainWrapper } from "components/payment-methods";
import CustomerSidebar from "pages/sidebar/customer-sidebar";
import CustomerHeader from "../header";
import { styled } from "@mui/material/styles";

const CustomerPaymentMethods = () => {
  const history = useHistory();
  const { settingData } = useSelector((state) => state.account);
  const [shortCut, setShortCut] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);

  return (
    <div className="h-100">
      <CustomerSidebar
        currentWidth={currentWidth}
        setCurrentWidth={setCurrentWidth}
      />
      <MainWrapper>
        {/* <HeaderContainer> */}
        <CustomerHeader
          title="Payment Methods"
          subTitle="Your connected accounts"
          shortCut={shortCut}
          setShortCut={setShortCut}
        />
        {/* </HeaderContainer> */}
        <PaymentMethodList settingData={settingData} />
      </MainWrapper>
    </div>
  );
};

export const HeaderContainer = styled("div")`
  padding: 47px 50px;

  @media (max-width: 768px) {
    padding: 30px 40px;
  }

  @media (max-width: 400px) {
    padding: 20px 24px 0;
  }
`;

export default CustomerPaymentMethods;
