/**
 * Safely retrieves the nested object value given a path.
 * @param {Object} obj - The object to query.
 * @param {String} path - The path to the property (supports dot notation).
 * @returns {*} - The value at the specified path.
 */
function getNestedValue(obj, path) {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}
/**
 * Sorts an array of objects based on a specified field and direction.
 * @param {Array} data - The array of objects to sort.
 * @param {String} field - The field name to sort by.
 * @param {String} direction - The direction of the sort ('asc' or 'desc').
 * @returns {Array} - The sorted array.
 */
function sortData(data, field, direction) {
  const sortedData = [...data].sort((a, b) => {
    const aValue = getNestedValue(a, field);
    const bValue = getNestedValue(b, field);
    console.log(aValue, bValue);
    if (typeof aValue === "string") {
      return direction === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return direction === "asc" ? aValue - bValue : bValue - aValue;
    }
  });
  console.log(sortedData);
  return sortedData;
}

/**
 * Truncates a string to a specified length and appends an ellipsis if longer.
 * @param {String} str - The string to truncate.
 * @param {Number} maxLength - The maximum length of the string before truncating.
 * @returns {String} - The possibly truncated string with ellipsis.
 */
function truncateString(str, maxLength) {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
}

export { sortData, truncateString };
