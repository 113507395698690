import { useLocalStorage } from "hooks/use-local-storage";
import { useDispatch } from "react-redux";
import { usePlatformData } from "./use-platform-data";
import * as actionTypes from "pages/customer/signup/actionTypes";
import { useEffect, useMemo } from "react";
import { createTheme } from "@mui/material";
import { toast } from "react-toastify";
import { getAccessibleColor, getRGBVariable } from "utils/colors";

export function usePlatformSetup() {
  const dispatch = useDispatch();
  const [platformId, setPlatformId] = useLocalStorage("PLATFORM_ID");

  const { data: settings, ...rest } = usePlatformData({
    onSuccess: ({ platform_id, ...rest }) => {
      setPlatformId(platform_id);
      dispatch({
        type: actionTypes.SETTING_DATA,
        payload: rest,
      });
    },
    onError: (error) => {
      console.log("PLATFORM ID FETCH ERROR::", error.response.status);
    },
  });

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: settings?.primary_color || "#000000",
          },
        },
      }),
    [settings?.primary_color]
  );

  useEffect(() => {
    const primary = getRGBVariable(
      settings?.primary_color || "#000000",
      "primary"
    ).split(":");
    const accessible = getRGBVariable(
      getAccessibleColor(settings?.primary_color || "#000000"),
      "primary-foreground"
    ).split(":");

    const root = document.documentElement;
    root.style.setProperty(accessible[0], accessible[1].replace(";", ""));
    root.style.setProperty(primary[0], primary[1].replace(";", ""));
  }, [settings?.primary_color]);

  return {
    theme,
    platformId,
    settings,
    ...rest,
  };
}
