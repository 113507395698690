import { Route, Switch } from "react-router-dom";
import {
  AccountSetup,
  Dashboard,
  Integrations,
  Transactions,
  Contacts,
  NewContact,
  UpdateContact,
  Contracts,
  SmartContracts,
  SmartQuotes,
  Invoices,
  NewInvoice,
  PaymentMethods,
  ConnectPaymentMethod,
  Support,
  Settings,
  AccountStatement,
  MoveMoney,
  Plaid,
  KYBLanding,
  Bills,
  CreateABill,
  ContractDetails,
} from "pages/index.js";
import { NoMatch } from "components/no-match-redirect";
import EditInvoice from "pages/invoices/edit-invoice";

const ClientRoutes = () => {
  return (
    <Switch>
      <Route path="/account-setup" exact component={AccountSetup} />
      <Route path="/dashboard" component={Dashboard} />
      <Route exact path="/bills" component={Bills} />
      <Route path="/bills/create" component={CreateABill} />
      <Route path="/kyb" component={KYBLanding} />
      <Route path="/integrations" component={Integrations} />
      <Route path="/transactions" component={Transactions} />
      <Route exact path="/contacts/update/:id" component={UpdateContact} />
      <Route exact path="/contacts" component={Contacts} />
      <Route path="/new-contact" component={NewContact} />
      <Route path="/quotes/:id" component={ContractDetails} />
      <Route path="/quotes" component={Contracts} />
      <Route path="/smart-quotes" component={SmartQuotes} />
      <Route exact path="/invoices" component={Invoices} />
      <Route path="/invoices/new" component={NewInvoice} />
      <Route path="/invoices/edit/:id" component={EditInvoice} />
      <Route path="/payment-methods" component={PaymentMethods} />
      <Route path="/create-payment" component={ConnectPaymentMethod} />
      <Route path="/support" component={Support} />
      <Route path="/settings" component={Settings} />
      <Route path="/account-statement" component={AccountStatement} />
      <Route path="/move-money" component={MoveMoney} />
      <Route path="/plaid" component={Plaid} />
      <Route path="/" exact component={Dashboard} />
      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default ClientRoutes;
