import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const getAllTransactions = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(`/platform/transactions/${clientCognitoId}`);

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};
