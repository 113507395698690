import { useEffect, useState } from "react";

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    let currValue;

    try {
      currValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
    } catch (error) {
      currValue = defaultValue;
    }

    return currValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};
