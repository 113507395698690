import styled from "styled-components";

import {
  InvoiceDetailInfo,
  InvoiceDetailSection,
  InvoiceTotalSection,
  NewInvoiceBtn,
} from "../styled";
import { OptionLabel } from "pages/styled";

import { convertThousandSeparator } from "scripts/functions";
import { StyledTextInput } from "pages/utils/styled-input";

import { useFormikContext } from "formik";
import { forwardRef } from "react";
import { Input } from "components/ui/input";
import { cn } from "utils/classnames";

const InvoiceTotal = forwardRef(({ ...props }, ref) => {
  const { values, setFieldValue } = useFormikContext();

  console.log(values);
  return (
    <div ref={ref} className="pt-4 border-t">
      <label
        htmlFor="lineItems"
        className="block text-base font-medium leading-6 text-gray-900 mb-2"
      >
        Invoice Summary
      </label>
      <div className="mt-4">
        <dl className="">
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Subtotal
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              ${convertThousandSeparator(values.subTotal)}
            </dd>
          </div>
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">Tax</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              $
              {convertThousandSeparator(
                Number(values.subTotal) * Number(values.invoiceTax / 100)
              )}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Transaction fee
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              ${convertThousandSeparator(values.transactionFeeAmount)}
            </dd>
          </div>
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Total
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              ${convertThousandSeparator(values.invoiceTotal)}
            </dd>
          </div>
        </dl>
      </div>
      {/* <InvoiceDetailSection>
        <InvoiceDetailInfo>
          Tax:{" "}
          <span className="font-medium">
            $
            {convertThousandSeparator(
              Number(values.subTotal) * Number(values.invoiceTax / 100)
            )}
          </span>
        </InvoiceDetailInfo>
        <InvoiceDetailInfo>
          Transaction fee:{" "}
          <span className="font-medium">
            ${convertThousandSeparator(values.transactionFeeAmount)}
          </span>
        </InvoiceDetailInfo>
      </InvoiceDetailSection>
      <OptionLabel>
        Invoice total: ${convertThousandSeparator(values.invoiceTotal)}
      </OptionLabel> */}
      <Note
        className="mb-4 mt-4"
        value={values.invoiceText}
        onChange={(e) => setFieldValue("invoiceText", e.target.value)}
        placeholder={
          "Add any notes you’d like included when sending this invoice to your customer."
        }
        rows={5}
      />
    </div>
  );
});

function Note({ className, ...props }) {
  return (
    <textarea
      className={cn(
        "flex w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      rows={6}
      {...props}
    />
  );
}

export default InvoiceTotal;
