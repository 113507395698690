import { useQuery } from "@tanstack/react-query";
import { getAccountBalance } from "api/v1-jwt/account/account";

export function useAccountBalance({ queryFn, queryKey, ...rest } = {}) {
  return useQuery({
    queryKey: ["platform", "balance"],
    queryFn: getAccountBalance,
    staleTime: Infinity,
    ...rest,
  });
}
