import api from "api/v1-jwt/config/api";

const { default: req } = require("api/config");

const fetchBusinessTypes = async () => {
  const response = await api.get("/platform/application/business_type_list");
  return response.data;
};

const fetchRoleTitle = async () => {
  const response = await api.get("/platform/application/role_title_list");
  return response.data;
};

const fetchEstimatedMonthlySales = async () => {
  const response = await api.get(
    "/platform/application/est_monthly_sales_list"
  );
  return response.data;
};

const fetchNaicsList = async () => {
  const response = await api.get("/platform/application/naics_list");
  return response.data;
};

export const platformEndpoints = {
  fetchBusinessTypes,
  fetchRoleTitle,
  fetchEstimatedMonthlySales,
  fetchNaicsList,
};
