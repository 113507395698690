import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "utils/classnames";
import { Checkbox } from "components/ui/check-box";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useProfile } from "hooks/profile/use-profile";
import { formatPhoneNumber, formatPhoneUS } from "utils/functions";
import {
  addresseeArea,
  addresseeBusinessAddr,
  addresseeEmail,
  addresseeNamePriority,
  addresseePhone,
} from "utils/invoice";
import { convertThousandSeparator } from "scripts/functions";
import { ChevronDown, XCircle } from "lucide-react";
import { useSendReminder } from "hooks/invoice/use-send-reminder";
import { useTenancy } from "hooks/tenancy/use-tenancy";

import { toast } from "react-toastify";
import { InvoicePDFDocument } from "pages/invoices/components/invoice-pdf";
import { useSettings } from "hooks/setting/use-settings";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button-tw";
import {
  getInvoiceById,
  sendInvoiceToCustomer,
  updateInvoice,
  uploadInvoicePDF,
} from "api/v1-jwt/invoice/invoice";
import { useQueryClient } from "@tanstack/react-query";
import { InvoiceStatusBadge } from "./invoice-status-badge";
import {
  DRAFT_INVOICE_STATUS,
  PAID_INVOICE_STATUS,
  PAST_DUE_INVOICE_STATUS,
  UNPAID_INVOICE_STATUS,
} from "constants/invoice";
import { useDeleteInvoice } from "hooks/invoice/use-delete-invoice";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { useUpdateInvoice } from "hooks/invoice/use-update-invoice";
import { useInvoiceDrawer } from "hooks/invoice/use-invoice-drawer";

function Loading() {
  return (
    <div>
      <div role="status" className="px-6 py-8 animate-pulse">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className=" p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
      <div
        role="status"
        className="absolute bottom-0 inset-x-0 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      ></div>
    </div>
  );
}

function Error() {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There was an error loading the invoice details.
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>Make sure your internet connection is stable</li>
              <li>Try closing and opening the drawer again</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function InvoiceSlideIn() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    drawerData: invoice,
    open,
    handleClose,
    isLoading,
    isError,
  } = useInvoiceDrawer();
  const { data: tenancy } = useTenancy();
  const { data: settings } = useSettings();
  const { data: profile } = useProfile({
    enabled: open,
  });

  const [changeToPaid, setChangeToPaid] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { mutate: updateInvoice, isLoading: updating } = useUpdateInvoice();
  const { mutate: deleteInvoice, isLoading: deleting } = useDeleteInvoice();
  const { mutate: sendEmailReminder, isLoading: sendingEmail } =
    useSendReminder();

  const handleSendEmailReminder = async () => {
    if (!invoice)
      return toast.warn("Please select an invoice to send a reminder.");

    sendEmailReminder(invoice?.invoice_id, {
      onSuccess: () => toast.success("Your invoice email has been sent."),
      onError: (error) => {
        console.log("ERROR::EMAIL::", error);
        toast.error(
          "Something went wrong. Please try to send the email again."
        );
      },
    });
  };

  const isEditable = (invoice) =>
    !["paid", "pending", "processing"].includes(
      invoice?.invoice_info?.invoice_status?.toLowerCase() || ""
    );

  const handleEditInvoice = () => {
    if (!isEditable(invoice)) {
      return toast.error("This invoice is not editable.");
    }

    history.push(`/invoices/edit/${invoice.invoice_id}`);
  };

  const getInvoiceinfoById = async (id, invoice_number) => {
    const res = await getInvoiceById(id);

    const pdfFile = await pdf(
      <InvoicePDFDocument
        settingData={settings}
        profile={profile}
        invoice={res}
        tenancy={tenancy}
      />
    ).toBlob();
    if (pdfFile) {
      const folderName = `invoices/${id}`;
      const params = `folder_name=${folderName}&file_name=invoice_${invoice_number}.pdf`;
      await uploadInvoicePDF(params, pdfFile);
    }
  };

  const handleSendInvoice = async (
    invoiceId,
    invoiceNumber,
    deliveryMethod
  ) => {
    const payload = {
      invoice_status: "unpaid",
      invoice_delivery_method: deliveryMethod,
    };

    try {
      updateInvoice(
        { id: invoiceId, ...payload },
        {
          onSuccess: async () => {
            await getInvoiceinfoById(invoiceId, invoiceNumber);
            await sendInvoiceToCustomer(invoiceId);
            queryClient.invalidateQueries({ queryKey: ["invoices"] });
            toast.success(`Your invoice has been sent.`);
          },
          onError: () =>
            toast.error(`Something went wrong while updating the invoice.`),
        }
      );
    } catch (error) {
      toast.error(`Something went wrong while sending the invoice.`);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative pointer-events-auto w-screen max-w-2xl">
                    <div className=" pb-[120px] flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6 bg-skin-primary">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {isLoading || isError
                              ? "Invoice"
                              : `Invoice #${invoice?.invoice_info?.invoice_number}`}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-gray-50 hover:text-gray-300 focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      {isLoading ? (
                        <Loading />
                      ) : isError ? (
                        <Error />
                      ) : (
                        <div className=" divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="flex-col space-y-4 px-4 sm:px-6 min-h-full">
                              <div className="flex justify-between items-center mt-6 sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      $
                                      {convertThousandSeparator(
                                        Number(
                                          invoice?.invoice_info.invoice_total
                                        )
                                      )}
                                    </h3>
                                    <InvoiceStatusBadge
                                      status={
                                        invoice?.invoice_info.invoice_status ||
                                        DRAFT_INVOICE_STATUS
                                      }
                                    />
                                  </div>
                                  {/* <p className="text-sm text-gray-500">Total</p> */}
                                </div>
                                <div className="flex gap-x-3">
                                  <div className="flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                                    <div className="ml-3 inline-flex sm:ml-0">
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                          Options
                                          <ChevronDown
                                            className="-mr-1 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                              {invoice?.invoice_info?.invoice_status
                                                .toLowerCase()
                                                .startsWith("unpaid") && (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <div
                                                      onClick={
                                                        handleSendEmailReminder
                                                      }
                                                      className={cn(
                                                        active
                                                          ? "bg-gray-100 text-gray-900"
                                                          : "text-gray-700",
                                                        "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                                      )}
                                                    >
                                                      Send reminder
                                                    </div>
                                                  )}
                                                </Menu.Item>
                                              )}
                                              {isEditable(invoice) && (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <div
                                                      onClick={
                                                        handleEditInvoice
                                                      }
                                                      className={cn(
                                                        active
                                                          ? "bg-gray-100 text-gray-900"
                                                          : "text-gray-700",
                                                        "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                                      )}
                                                    >
                                                      Edit
                                                    </div>
                                                  )}
                                                </Menu.Item>
                                              )}
                                              <PDFDownloadLink
                                                style={{
                                                  textDecoration: "initial",
                                                }}
                                                document={
                                                  <InvoicePDFDocument
                                                    settingData={settings}
                                                    profile={profile}
                                                    invoice={invoice}
                                                    tenancy={tenancy}
                                                  />
                                                }
                                                fileName="invoice.pdf"
                                              >
                                                {({ blob }) => {
                                                  return (
                                                    <Menu.Item>
                                                      {({ active }) => (
                                                        <div
                                                          className={cn(
                                                            active
                                                              ? "bg-gray-100 text-gray-900"
                                                              : "text-gray-700",
                                                            "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                                          )}
                                                        >
                                                          Download
                                                        </div>
                                                      )}
                                                    </Menu.Item>
                                                  );
                                                }}
                                              </PDFDownloadLink>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <div
                                                    onClick={() =>
                                                      setConfirmDelete(true)
                                                    }
                                                    className={cn(
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                      "block px-4 py-2 text-sm cursor-pointer disabled:cursor-not-allowed"
                                                    )}
                                                  >
                                                    Delete
                                                  </div>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-5 sm:px-6 sm:py-0 sm:pb-8">
                            {/* Invoice */}
                            <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                              <div className="sm:pr-4">
                                <dt className="inline text-gray-500">
                                  Issued on
                                </dt>{" "}
                                <dd className="inline text-gray-700">
                                  <time>
                                    {moment(
                                      invoice?.invoice_info?.invoice_send_date
                                    ).format("LL")}
                                  </time>
                                </dd>
                              </div>
                              <div className="mt-2 sm:mt-0 sm:pl-4">
                                <dt className="inline text-gray-500">Due on</dt>{" "}
                                <dd className="inline text-gray-700">
                                  <time>
                                    {moment(
                                      invoice?.invoice_info?.invoice_due_date
                                    ).format("LL")}
                                  </time>
                                </dd>
                              </div>
                              <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                                <dt className="font-semibold text-gray-900">
                                  From
                                </dt>
                                <dd className="mt-2 text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {profile?.business_name}
                                  </span>
                                  <br />
                                  {profile?.business_email}
                                  <br />
                                  {formatPhoneUS(profile?.business_phone)}
                                </dd>
                              </div>

                              <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                                <dt className="font-semibold text-gray-900">
                                  To
                                </dt>
                                <dd className="mt-2 text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {addresseeNamePriority(invoice)}
                                  </span>

                                  <br />
                                  {addresseeEmail(invoice) || ""}
                                  <br />
                                  {formatPhoneUS(addresseePhone(invoice))}
                                  <br />
                                </dd>
                              </div>
                            </dl>
                            <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
                              <colgroup>
                                <col className="w-full" />
                                <col />
                                <col />
                                <col />
                              </colgroup>
                              <thead className="border-b border-gray-200 text-gray-900">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-0 py-3 font-semibold"
                                  >
                                    Line Items
                                  </th>
                                  <th
                                    scope="col"
                                    className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                                  >
                                    Units
                                  </th>
                                  <th
                                    scope="col"
                                    className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                                  >
                                    Rate
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 pl-8 pr-0 text-right font-semibold"
                                  >
                                    Price
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {(
                                  invoice?.invoice_info?.invoice_line_items ||
                                  []
                                ).map((item, i) => (
                                  <tr
                                    key={i}
                                    className="border-b border-gray-100"
                                  >
                                    <td className="max-w-0 px-0 py-5 align-top">
                                      <div className="truncate font-medium text-gray-900">
                                        {item.description}
                                      </div>
                                    </td>
                                    <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                      {item.quantity}
                                    </td>
                                    <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                      $
                                      {convertThousandSeparator(
                                        Number(item.unit_price)
                                      )}
                                    </td>
                                    <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                      $
                                      {convertThousandSeparator(
                                        Number(item.item_total)
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th
                                    scope="row"
                                    className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
                                  >
                                    Subtotal
                                  </th>
                                  <th
                                    scope="row"
                                    colSpan={3}
                                    className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                                  >
                                    Subtotal
                                  </th>
                                  <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                                    $
                                    {convertThousandSeparator(
                                      Number(
                                        invoice?.invoice_info.invoice_subtotal
                                      )
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    className="pt-4 font-normal text-gray-700 sm:hidden"
                                  >
                                    Tax
                                  </th>
                                  <th
                                    scope="row"
                                    colSpan={3}
                                    className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                                  >
                                    Tax
                                  </th>
                                  <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                                    $
                                    {convertThousandSeparator(
                                      Number(
                                        invoice?.invoice_info.invoice_tax_amount
                                      )
                                    )}
                                  </td>
                                </tr>
                                {invoice?.invoice_info
                                  .invoice_pass_processing_fee && (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="pt-4 font-normal text-gray-700 sm:hidden"
                                    >
                                      Fee
                                    </th>
                                    <th
                                      scope="row"
                                      colSpan={3}
                                      className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                                    >
                                      Fee
                                    </th>
                                    <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                                      $
                                      {convertThousandSeparator(
                                        Number(
                                          invoice?.invoice_info
                                            .invoice_processing_fee
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <th
                                    scope="row"
                                    className="pt-4 font-semibold text-gray-900 sm:hidden"
                                  >
                                    Total
                                  </th>
                                  <th
                                    scope="row"
                                    colSpan={3}
                                    className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                                  >
                                    Total
                                  </th>
                                  <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                                    $
                                    {convertThousandSeparator(
                                      Number(
                                        invoice?.invoice_info.invoice_total
                                      )
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            {[
                              UNPAID_INVOICE_STATUS,
                              PAST_DUE_INVOICE_STATUS,
                            ].includes(
                              invoice?.invoice_info?.invoice_status
                            ) && (
                              <div className="absolute bottom-0 left-0 right-0 p-4 bg-white border-t">
                                <div className="flex gap-x-3">
                                  <div className="flex items-center gap-x-2">
                                    <Checkbox
                                      id="paid"
                                      checked={changeToPaid}
                                      onCheckedChange={() => {
                                        setChangeToPaid((curr) => !curr);
                                      }}
                                    />
                                    <label
                                      htmlFor="paid"
                                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                      Mark this invoice as paid
                                    </label>
                                  </div>
                                  <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 cursor-pointer shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-white disabled:cursor-not-allowed"
                                    onClick={() => {
                                      if (!invoice) return;
                                      updateInvoice(
                                        {
                                          id: invoice?.invoice_id,
                                          invoice_status: PAID_INVOICE_STATUS,
                                        },
                                        {
                                          onSuccess: () => {
                                            toast.success(
                                              "Invoice marked as paid."
                                            );
                                          },
                                          onError: () => {
                                            toast.error(
                                              "Ops some problem occurred while updating invoice. Please try again."
                                            );
                                          },
                                        }
                                      );
                                    }}
                                    disabled={
                                      !changeToPaid ||
                                      !invoice ||
                                      deleting ||
                                      updating
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                                <button
                                  type="button"
                                  disabled={sendingEmail}
                                  onClick={handleSendEmailReminder}
                                  className="w-full inline-flex mt-6 flex-shrink-0 items-center justify-center rounded-md bg-skin-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-skin-primary/85 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-skin-primary sm:flex-1"
                                >
                                  Send email reminder to customer
                                </button>
                              </div>
                            )}

                            {invoice?.invoice_info?.invoice_status.toLowerCase() ===
                              DRAFT_INVOICE_STATUS && (
                              <div className="absolute bottom-0 left-0 right-0 p-4 bg-white border-t">
                                <DropdownMenu className=" ">
                                  <DropdownMenuTrigger
                                    asChild
                                    className="w-full"
                                  >
                                    <Button className="w-full bg-skin-primary">
                                      Send Invoice{" "}
                                      <ChevronDown className="w-4 h-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent className="w-[300px] sm:w-[640px]">
                                    <DropdownMenuLabel>
                                      Send invoice to contact
                                    </DropdownMenuLabel>

                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem
                                      onClick={() =>
                                        handleSendInvoice(
                                          invoice?.invoice_id,
                                          invoice?.invoice_info?.invoice_number,
                                          "email"
                                        )
                                      }
                                      className="focus:bg-gray-100 px-4 py-2 -mx-2 rounded-none"
                                    >
                                      Send by email
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      onClick={() =>
                                        handleSendInvoice(
                                          invoice?.invoice_id,
                                          invoice?.invoice_info?.invoice_number,
                                          "text"
                                        )
                                      }
                                      className="focus:bg-gray-100 px-4 py-2 -mx-2 rounded-none"
                                    >
                                      Send by SMS
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      onClick={() =>
                                        handleSendInvoice(
                                          invoice?.invoice_id,
                                          invoice?.invoice_info?.invoice_number,
                                          "all"
                                        )
                                      }
                                      className="focus:bg-gray-100 px-4 py-2 -mx-2 rounded-none"
                                    >
                                      Send by email and SMS
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <AlertDialog open={confirmDelete} onOpenChange={setConfirmDelete}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              invoice.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteInvoice(invoice?.invoice_id, {
                  onSuccess: () => {
                    setConfirmDelete(false);
                    handleClose();
                    toast.success("Your invoice was successfully deleted.");
                  },
                  onError: () => {
                    setConfirmDelete(false);
                    toast.error(
                      "There was a problem deleting this invoice. Please try again."
                    );
                  },
                });
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
