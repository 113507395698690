import {
  CustomerLogin,
  CustomerSignup,
  CustomerSetPassword,
  ForgotPassword,
  ResetPassword,
  EmailSent,
} from "pages";
import { Route, Switch } from "react-router-dom";
import { NoMatch } from "components/no-match-redirect";
import AdminSignUp from "pages/customer/signup/admin-sign-up";
import { AdminNoAccess } from "pages/customer/signup/admin-no-access";

const GuestRoutes = () => (
  <Switch>
    <Route path="/login" component={CustomerLogin} />
    <Route path="/signup" component={CustomerSignup} />
    <Route path="/admin-invite" component={AdminSignUp} />
    <Route path="/admin-no-access" component={AdminNoAccess} />
    <Route path="/set-password" component={CustomerSetPassword} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/check-email" component={EmailSent} />
    <Route path="/" exact component={CustomerLogin} />
    <Route path="*" component={NoMatch} />
  </Switch>
);

export default GuestRoutes;
