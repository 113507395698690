import {
  StyledInput,
  StyledPriceInput,
  StyledTextInput,
} from "pages/utils/styled-input";
import { Trash2 } from "lucide-react";
import { Button } from "components/ui/button-tw";
import { useLineItemActions } from "../hooks";
import { Input } from "components/ui/input";
const LineItem = ({ index, lineItem }) => {
  const { modifyItemProperty, deleteLineItem } = useLineItemActions();

  return (
    <div key={index} className="flex ">
      <div className="flex flex-col space-y-2 grow">
        <Input
          value={lineItem.description}
          onChange={(e) => {
            modifyItemProperty(index, "description", e.target.value);
          }}
          placeholder={`Item ${index + 1}`}
        />
        <div className="flex space-x-2">
          <QuantityInput
            value={lineItem.quantity <= 0 ? null : lineItem.quantity}
            onChange={(e) => {
              // let numberValue = +newValue;
              // numberValue = isNaN(numberValue) ? 0 : numberValue;
              modifyItemProperty(index, "quantity", Math.round(e.target.value));
            }}
            placeholder={"Quantity"}
          />
          <AmountInput
            value={lineItem.unit_price}
            onChange={(e) => {
              // let numberValue = +newValue;
              // numberValue = isNaN(numberValue) ? 0 : numberValue;
              modifyItemProperty(index, "unit_price", e.target.value);
            }}
            placeholder={"Price"}
          />
          {/* <StyledPriceInput
            value={(lineItem.quantity * lineItem.unit_price).toFixed(2)}
            setValue={() => {}}
            mBottom="20px"
            placeholder={"Total"}
            readOnly={true}
          /> */}
        </div>
      </div>
      <div className="pl-5">
        <Button
          type="button"
          className=" bg-red-50 text-red-500 hover:bg-red-100 hover:text-red-600 focus-visible:ring-red-700"
          size="icon"
          variant="secondary"
          onClick={() => deleteLineItem(index)}
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

function QuantityInput(props) {
  return (
    <div>
      <div className="relative rounded-lg ">
        <input
          type="number"
          name="quantity"
          id="quantity"
          {...props}
          className=" text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6
        flex h-10 w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50
        "
          placeholder="Quantity"
          aria-describedby="quantity"
        />
      </div>
    </div>
  );
}

function AmountInput(props) {
  return (
    <div>
      <div className="relative rounded-lg ">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="number"
          name="price"
          id="price"
          {...props}
          className="pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6
        flex h-10 w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50
        "
          placeholder="0.00"
          aria-describedby="price-currency"
        />
      </div>
    </div>
  );
}

export default LineItem;
