import { Checkbox } from "@mui/material";
import { CheckboxLabel, PayPalImg, VenmoImg } from "../styled";
import paypal_icon from "assets/images/paypal.png";
import venmo_icon from "assets/images/venmo.png";
import { useCheckPaypal } from "hooks/integrations/use-check-paypal";

const PayPalCheck = ({ checked, onChange }) => {
  const { data: hasPaypal, loading, error } = useCheckPaypal();

  if (loading) return null;
  if (error) return null;
  if (!hasPaypal) return null;

  return (
    <div className={`flex align-items-center`}>
      <Checkbox
        size="small"
        inputProps={{ "aria-label": "controlled" }}
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
      />
      <CheckboxLabel style={{ minWidth: 500 }}>
        Add <PayPalImg src={paypal_icon} alt="paypal" /> and/or
        <VenmoImg src={venmo_icon} alt="venmo" />
        option to this invoice (if applicable).
      </CheckboxLabel>
    </div>
  );
};

export default PayPalCheck;
