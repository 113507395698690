import { useState, useEffect } from "react";
import axios from "axios";
import api from "api/v1-jwt/config/api";

const useLinkToken = (cognitoId, accessToken = false) => {
  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLinkToken = async () => {
    setLoading(true);
    try {
      const requestBody = {
        user_id: cognitoId,
      };

      if (accessToken) {
        requestBody.access_token = accessToken;
      }
      const { data } = await api.post(
        `/platform/payment_methods/plaid/link-token`,
        requestBody
      );
      const linkToken = data.link_token;
      setLinkToken(linkToken);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkToken();
  }, []);

  return { linkToken, loading, error };
};

export default useLinkToken;
