import req from "api/config";
import api from "api/v1-jwt/config/api";
import { useState, useEffect } from "react";

export const useFetchStates = (country_code) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseURL = `/platform/application/state_list?country_code=${country_code}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(baseURL);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [baseURL]);

  return { data, error, isLoading };
};
