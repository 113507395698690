import { useQuery } from "@tanstack/react-query";
import { fetchBills } from "api/v1-jwt/bill/bill";

export const useBills = ({ userId, queryFn, queryKey, ...rest } = {}) => {
  return useQuery({
    queryKey: ["bills"],
    queryFn: () => fetchBills(userId),
    placeholderData: [],
    ...rest,
  });
};
