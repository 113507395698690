import logo from "assets/images/pocketbook-logo.png";
import pocketbook_logo from "assets/images/logo.png";
import {
  LeftHeader,
  LeftSection,
  Description,
  MainSection,
  Title,
  LeftFooter,
  Wrapper,
  PocketbookLogo,
  RightImage,
  RightSection,
} from "./styled";

const NotFound = () => {
  return (
    <Wrapper>
      <LeftSection>
        <LeftHeader>
          <PocketbookLogo src={logo} alt="logo" />
        </LeftHeader>
        <MainSection>
          <div style={{ width: "100%" }}>
            <Title>404</Title>
            <Description>
              The platform you are trying to access cannot be found.
            </Description>
          </div>
        </MainSection>
        <LeftFooter>
          {/* <ContactBtn className="pb-10">
            Forgot password?{" "}
            <span
              className="text-underline"
              onClick={() => history.push("/forgot-password")}
            >
              Send me a reset password email
            </span>
          </ContactBtn> */}
        </LeftFooter>
      </LeftSection>
      <RightSection style={{ background: `#000` }}>
        <RightImage src={pocketbook_logo} alt="login-image" />
      </RightSection>
    </Wrapper>
  );
};

export default NotFound;
