import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/ui/button-tw";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { ArrowRight, Loader2, UploadCloud } from "lucide-react";
import { useProcessInvoiceImage } from "hooks/invoice/useProcessInvoiceImage";
import { useLocalStorage } from "hooks/use-local-storage";

export const UploadInvoiceButton = () => {
  const [file, setFile] = useState(null);
  const [_, setProcessedData] = useLocalStorage("PROCESSED_INVOICE", null);
  const { mutate: processImage, isLoading: processing } =
    useProcessInvoiceImage();
  const history = useHistory();

  const handleFileProcessing = async () => {
    processImage(file, {
      onSuccess: (data) => {
        if (!data?.[0]) {
          return toast.error(
            "It was not possible to extract data from this invoice."
          );
        }
        setProcessedData(data[0]);
      },
      onError: (error) => {
        toast.error("Something went wrong while extracting invoice data.");
      },
      onSettled: () => history.push("/invoices/new"),
    });
  };

  return (
    <Dialog onOpenChange={() => setFile(null)}>
      <DialogTrigger asChild>
        <Button variant="outline">Upload an invoice</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Upload your invoice</DialogTitle>
          <DialogDescription>
            The accepted formats to upload are JPEG, PNG or PDF
          </DialogDescription>
        </DialogHeader>
        <div className="">
          <FileUploader
            multiple={false}
            handleChange={setFile}
            name="file"
            onTypeError={(err) => toast(`Error: ${err}`)}
            types={["JPEG", "PNG", "PDF"]}
            children={
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:border-skin-primary dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-skin-primary/10 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <UploadCloud className="w-8 h-8 mb-4 text-gray-600" />
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      {file ? (
                        <span class="font-semibold text-skin-primary">
                          {file.name}
                        </span>
                      ) : (
                        <span class="font-semibold text-skin-primary">
                          Click to upload or drag and drop
                        </span>
                      )}
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">PNG, JPEG, OR PDF</span>{" "}
                      (MAX of 1MB)
                    </p>
                  </div>
                </label>
              </div>
            }
          />
        </div>
        <DialogFooter className="">
          <Button
            type="button"
            disabled={!file || processing}
            onClick={handleFileProcessing}
          >
            Extract data
            {processing ? (
              <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            ) : (
              <ArrowRight className="w-4 h-4 ml-2" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
