import { ArrowRight, ChevronRightIcon } from "lucide-react";
import moment from "moment";
import { cn } from "utils/classnames";
import { mapStatusColor } from "./columns";
import useInvoiceSelection from "hooks/invoice/use-invoice-selection";
import { capitalizeFirstLetter } from "utils/string-manipulation";

export default function CustomerMobileList({ data }) {
  const select = useInvoiceSelection();

  return (
    <div className="bg-white lg:min-w-0 lg:flex-1 lg:hidden">
      <ul className="divide-y divide-gray-200 border-b border-gray-200">
        {data.map((invoice) => (
          <li
            key={invoice.invoice_id}
            onClick={() => select(invoice)}
            className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 cursor-pointer"
          >
            <div className="flex items-center justify-between space-x-4">
              <div className="flex space-x-4">
                <div className="min-w-0 space-y-3 flex flex-col justify-start h-full">
                  <span
                    className={cn(
                      mapStatusColor(
                        invoice.invoice_info.invoice_status.toLowerCase()
                      ).accent,
                      "h-4 w-4 flex items-center justify-center rounded-full mt-0.5"
                    )}
                    aria-hidden="true"
                  >
                    <span
                      className={cn(
                        mapStatusColor(
                          invoice.invoice_info.invoice_status.toLowerCase()
                        ).main,
                        "h-2 w-2 rounded-full"
                      )}
                    />
                  </span>
                </div>
                <div className="min-w-0 space-y-3">
                  <div className="flex items-center space-x-3">
                    <h2 className="text-sm font-medium">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {`${new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(invoice.invoice_info.invoice_total)} USD`}
                    </h2>
                    <div className="rounded h-[3px] w-[3px] bg-primary"></div>
                    <span className="flex items-center space-x-1">
                      <p className={cn("text-xs font-medium text-slate-400")}>
                        {capitalizeFirstLetter(
                          invoice.invoice_info.invoice_status
                        )}
                      </p>
                    </span>
                  </div>
                  <div className="group relative flex items-center space-x-2.5">
                    <span className="truncate text-sm font-medium text-slate-400">
                      Sent on{" "}
                      {moment(invoice.invoice_info.invoice_send_date).format(
                        "MMMM Do, YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="sm:hidden">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              {/* Repo meta info */}
              <div className="hidden flex-shrink-0 flex-col items-end space-y-3 sm:flex">
                <button
                  type="button"
                  className="rounded-full flex space-x-1 bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <span>
                    {invoice.invoice_info.invoice_status.toLowerCase() ===
                      "paid" ||
                    invoice.invoice_info.invoice_status.toLowerCase() ===
                      "pending"
                      ? "View"
                      : "Pay"}
                  </span>
                  <ArrowRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
