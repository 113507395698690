import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import { CustomSelector } from "pages/utils/styled-select";
import { StyledInput } from "pages/utils/styled-input";
// import { convertThousandSeparator } from "scripts/functions";
import { chart_date_options } from "pages/demo";
import TransactionTable from "./transaction-table";
import { AvailableBalance, CurrentBalance } from "./balance-card";
import { getBondBalance } from "pages/setup/actions";
import { MainWrapper } from "pages/styled";
import {
  CardSection,
  OptionLabel,
  ToolBtn,
  ToolSection,
  // BalanceLabel,
  // BalanceCard,
  // CardLabel,
} from "./styled";
import { FeatureBalanceCard } from "pages/dashboard/styled";
import { useBondBalance } from "pages/dashboard/hooks";
import { useFetchKYBData } from "pages/dashboard/hooks/useFetchKYBData";
import useGetClientInfo from "pages/dashboard/hooks/useGetClientInfo";
import { LimitedAccountWarning } from "components/limited-account-warning";

const Transactions = () => {
  const history = useHistory();
  const [shortCut, setShortCut] = useState("");
  const [search, setSearch] = useState("");
  const [currentWidth, setCurrentWidth] = useState(0);
  const [dateOption, setDateOption] = useState({
    label: "30 days",
    value: 30,
  });
  const bondBalance = useBondBalance();
  const { data, isLoading, error } = useFetchKYBData();
  const clientInfo = useGetClientInfo();

  useEffect(() => {
    // getClient();
  }, []);

  // const getClient = async () => {
  //   await getClientInfo().then((res) => {
  //     if (res) setClientInfo(res);
  //   });
  // };

  return (
    <div>
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Transactions"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <div>
            <OptionLabel>Summary</OptionLabel>
            <ToolSection>
              <CustomSelector
                value={dateOption}
                setValue={setDateOption}
                selectList={chart_date_options}
                width={140}
                menuWidth={140}
              />
              <ToolBtn onClick={() => history.push("/move-money")}>
                Move money
              </ToolBtn>
              <StyledInput
                value={search}
                setValue={setSearch}
                placeholder={"Search transactions"}
                width={"350px"}
              />
            </ToolSection>
          </div>
          <div>
            <CardSection>
              <FeatureBalanceCard
                status={clientInfo?.baas_status}
                balance={bondBalance?.available_balance}
                data={data}
                isLoading={isLoading}
                error={error}
              />
              {/* {clientInfo &&
              clientInfo.record_status === "active" &&
              clientInfo.bond_status === "kyb.verification.approved" && (
                <BalanceCard>
                  <CardLabel>Current Balance</CardLabel>
                  <BalanceLabel>
                    ${convertThousandSeparator(12987)}
                  </BalanceLabel>
                </BalanceCard>
              )} */}
            </CardSection>
          </div>
          <TransactionTable search={search} setSearch={setSearch} />
        </MainWrapper>
      </>
    </div>
  );
};

export default Transactions;
