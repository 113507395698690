import { OptionLabel, MainWhiteBtn } from "pages/styled";
import LineItem from "./LineItem";
import { useFormikContext, ErrorMessage } from "formik";
import { useLineItemActions } from "../hooks";
import { forwardRef, useEffect } from "react";
import { StyledError } from ".";
import { Plus } from "lucide-react";
import { Button } from "components/ui/button-tw";

const LineItemsList = forwardRef(({ ...props }, ref) => {
  const { values, setFieldValue } = useFormikContext();
  const { addLineItem, clearLineItems } = useLineItemActions();

  // useEffect(() => {
  //   console.log("CLEARED");
  //   clearLineItems();
  // }, []);

  return (
    <div ref={ref} className="mb-5 pt-4 border-t">
      <label
        htmlFor="lineItems"
        className="block text-base font-medium leading-6 text-gray-900 mb-2"
      >
        Line items
      </label>
      <div className="space-y-4">
        {values?.lineItems?.map((lineItem, index) => (
          <LineItem key={index} lineItem={lineItem} index={index} />
        ))}
        <ErrorMessage
          name="lineItems"
          component={StyledError}
          className="error"
        />
        <Button type="button" variant="secondary" onClick={addLineItem}>
          <Plus className="w-4 h-4 mr-2" />
          Add item
        </Button>
      </div>
    </div>
  );
});

export default LineItemsList;
