import { useState, useEffect } from "react";
import Sidebar from "pages/sidebar/sidebar";
import ClientMainHeader from "pages/header";
import ContractTable from "./contract-table";
import ContractUploadDialog from "./upload-contract";
import { StyledInput } from "pages/utils/styled-input";
import { CustomSelector } from "pages/utils/styled-select";
import { chart_date_options, customer_list } from "pages/demo";
import { getContactById } from "pages/contacts/actions";
import { useHistory } from "react-router-dom";

import { getAllContracts, getContractsByContactId } from "./actions";
import { MainWrapper, OptionLabel } from "pages/styled";
import {
  SmartContractBtn,
  ToolBtnSection,
  ToolSection,
  UploadBtn,
} from "./styled";
import { LimitedAccountWarning } from "components/limited-account-warning";

const Contracts = () => {
  const history = useHistory();
  const [shortCut, setShortCut] = useState("");
  const [search, setSearch] = useState("");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);

  const [dateOption, setDateOption] = useState({ label: "30 days", value: 30 });
  const [customerOption, setCustomerOption] = useState({
    label: "Customer",
    value: "Customer",
  });
  const [tableList, setTableList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);

  const queryString = window.location.search;

  useEffect(() => {
    const urlParam = new URLSearchParams(queryString).get("contact_id");
    if (urlParam) {
      setSelectedContactId(urlParam);
      getContractListByContactId(urlParam);
    } else {
      setSelectedContactId(null);
      getContractList();
    }
  }, [queryString, dateOption]);

  useEffect(() => {
    const filteredArr = tableList.filter((item) => {
      // const contact_name =
      //   item.contact_info.first_name + " " + item.contact_info.last_name;
      return item.contract_info.contract_name
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(search.toLowerCase().replace(/\s+/g, ""));
    });
    setFilteredList(filteredArr);
  }, [search, setSearch, tableList]);

  const getContractList = async () => {
    setLoading(true);
    const contacts = await getAllContracts();
    console.log(contacts);
    if (contacts && !contacts.detail) {
      setTableList(contacts);
      setLoading(false);
    } else {
      setTableList([]);
      setLoading(false);
    }
  };

  const getContractListByContactId = async (id) => {
    setLoading(true);
    await getContractsByContactId(id).then(async (res) => {
      if (res && res.length > 0) {
        const updatedContracts = [];
        await Promise.all(
          res.map(async (item) => {
            if (item.contract_info.contact_id !== "") {
              await getContactById(item.contract_info.contact_id).then(
                (result) => {
                  updatedContracts.push({
                    ...item,
                    contact_name:
                      result[0].contact_info.first_name +
                      " " +
                      result[0].contact_info.last_name,
                  });
                }
              );
            } else {
              updatedContracts.push({ ...item, contact_name: "" });
            }
          })
        );
        setTableList(updatedContracts);
        setFilteredList(updatedContracts);
        setLoading(false);
      } else if (res.length === 0) {
        setTableList([]);
        setFilteredList([]);
        setLoading(false);
      }
    });
  };

  return (
    <div className="h-100">
      <Sidebar currentWidth={currentWidth} setCurrentWidth={setCurrentWidth} />
      <>
        <LimitedAccountWarning />

        <MainWrapper>
          <ClientMainHeader
            title="Quotes"
            subTitle="Create quotes tailored to your business"
            shortCut={shortCut}
            setShortCut={setShortCut}
          />
          <ToolSection>
            <ToolBtnSection>
              <UploadBtn onClick={() => setUploadDialog(true)}>
                Upload a Quote
              </UploadBtn>
              <SmartContractBtn onClick={() => history.push("/smart-quotes")}>
                Create Smart Quote
              </SmartContractBtn>
            </ToolBtnSection>
            <StyledInput
              value={search}
              setValue={setSearch}
              placeholder={"Search quotes"}
              width={"320px"}
            />
          </ToolSection>
          {/* <div className="pt-24">
          <OptionLabel>Summary</OptionLabel>
          <div className="flex justify-between">
            <div className="flex">
              <CustomSelector
                value={dateOption}
                setValue={setDateOption}
                selectList={chart_date_options}
                width={140}
                menuWidth={140}
                mRight={18}
                mBottom={20}
              />
              <CustomSelector
                value={customerOption}
                setValue={setCustomerOption}
                selectList={customer_list}
                width={140}
                menuWidth={140}
                mBottom={20}
              />
            </div>
          </div>
        </div> */}
          <div className="pt-24">
            <OptionLabel>All quotes</OptionLabel>
            <ContractTable
              filteredList={filteredList}
              loading={loading}
              getContractList={getContractList}
            />
          </div>
        </MainWrapper>
      </>
      <ContractUploadDialog
        dialog={uploadDialog}
        setDialog={setUploadDialog}
        getAllContracts={getContractList}
      />
    </div>
  );
};

export default Contracts;
