import api from "api/v1-jwt/config/api";

export const fetchContacts = async () => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.get(`/platform/contacts/${clientCognitoId}`);

  return data;
};

export const fetchContactById = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/contacts/${clientCognitoId}?contact_id=${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createContact = async (payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.post(`/platform/contacts/${clientCognitoId}`, {
    ...payload,
    cognito_id: clientCognitoId,
  });

  return data;
};

export const updateContact = async (id, payload) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.patch(
    `/platform/contacts/${clientCognitoId}/${id}`,
    payload
  );

  return data;
};

export const deleteContact = async (id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { data } = await api.delete(
    `/platform/contacts/${clientCognitoId}/${id}`
  );

  return data;
};
