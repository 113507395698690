import { useQuery } from "@tanstack/react-query";
import { fetchContracts } from "api/contract/contract";
import { useAuth } from "providers/auth";

export const useContracts = ({
  queryFn,
  queryKey,
  fromContactId,
  ...rest
} = {}) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const { userRole } = useAuth();
  const cognito_id = JSON.parse(localStorage.getItem("CognitoId"));
  const id = userRole === "customer" ? cognito_id : clientCognitoId;

  return useQuery({
    queryKey: ["contracts", userRole, id],
    queryFn: async () => {
      return await fetchContracts({
        id,
        type: userRole,
      });
    },
    staleTime: 60 * 1000,
    ...rest,
  });
};
