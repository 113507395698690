import CheckBox from "./CheckBox";
import PayPalCheck from "./PayPalCheck";
import { useFormikContext } from "formik";
import { useLineItemActions } from "../hooks";
import { forwardRef } from "react";

const SubtotalAndOptions = forwardRef(({ ...props }, ref) => {
  const { setFieldValue, values } = useFormikContext();
  const { modifyInvoiceTax, modifyTransactionFee } = useLineItemActions();
  return (
    <div ref={ref} className="pt-4 mb-4 border-t">
      {/* <label
        htmlFor="lineItems"
        className="block text-base font-medium leading-6 text-gray-900 mb-2"
      >
        Subtotal: ${convertThousandSeparator(values.subTotal)}
      </label> */}
      <label
        htmlFor="lineItems"
        className="block text-base font-medium leading-6 text-gray-900 mb-2"
      >
        Tax %
      </label>
      <TaxInput
        value={values.invoiceTax}
        onChange={(e) => modifyInvoiceTax(e.target.value)}
      />
      {/* <StyledPercentageInput
        value={values.invoiceTax}
        setValue={(invoiceTax) => modifyInvoiceTax(invoiceTax)}
        placeholder={"Tax"}
        width="124px"
        icon="%"
        mBottom={16}
      /> */}
      <div className="mt-2">
        <CheckBox
          checked={values.transactionFee}
          onChange={(transactionFee) => {
            modifyTransactionFee(transactionFee);
          }}
          label={"Pass along transaction fee to customer"}
        />
        <PayPalCheck
          checked={values.invoice_add_paypal}
          onChange={(invoiceAddPaypal) =>
            setFieldValue("invoice_add_paypal", invoiceAddPaypal)
          }
        />
      </div>
    </div>
  );
});

function TaxInput(props) {
  return (
    <div>
      <div className="relative rounded-lg ">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">%</span>
        </div>
        <input
          type="number"
          name="tax"
          id="tax"
          {...props}
          className="pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6
        flex h-10 w-full rounded-lg border border-input bg-background px-3 py-2 focus:border-none text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring  disabled:cursor-not-allowed disabled:opacity-50
        "
          placeholder="Tax"
          aria-describedby="tax percentage"
        />
      </div>
    </div>
  );
}

export default SubtotalAndOptions;
