import api from "api/v1-jwt/config/api";
import { toast } from "react-toastify";

export const getBondToken = async ({ userType = "business", id }) => {
  const payload = {
    [userType === "business" ? "business_id" : "customer_id"]: id,
  };
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  const { data } = await api.post(`/bond/get_temp_auth`, payload, options);

  return data;
};

export const accountLink = async (
  user_type,
  linked_account_id,
  businessId,
  userId
) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  try {
    const { data } = await api.post(
      `/bond/confirm_account_link`,
      {
        customer_or_business_id: businessId,
        cognito_id: userId || clientCognitoId,
        is_business: user_type === "business" ? true : false,
        linked_account_id: linked_account_id,
        account_type: user_type,
      },
      options
    );

    return data;
  } catch (err) {
    throw err;
  }
};

export const getTokenWithInquiry = async (inquiryId) => {
  const options = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_PBTECH_API_KEY,
    },
  };
  const { data } = await api.post(
    `/bond/get_token_with_inquiry`,
    { inquiry_id: inquiryId },
    options
  );

  return data;
};

export const getProfileInfo = async (userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/profile/${userId || clientCognitoId}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBondBalance = async () => {
  const clientCognitoId = localStorage.getItem("PLATFORM_ID");

  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/platform/accounts/${JSON.parse(clientCognitoId)}/balance`
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const updateProfile = async (payload, userId) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.patch(
      `/platform/profile/${userId || clientCognitoId}`,
      payload
    );

    return data;
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};

export const getClientInfo = async (user_id) => {
  const clientCognitoId = JSON.parse(localStorage.getItem("PLATFORM_ID"));
  if (!clientCognitoId) {
    throw new Error("It was not possible to identify the platform.");
  }

  try {
    const { data } = await api.get(
      `/tenancy/clients/${clientCognitoId}?user_id=${
        user_id || clientCognitoId
      }`
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
};
